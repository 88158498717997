/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, ClickAwayListener } from '@material-ui/core';
import styles from './TopBarStyle';

class ErrorBtn extends Component {
  state = {
    open: false,
  };

  handleClickAway = () => {
    this.setState({ ...this.state, open: false });
  };

  handleCreate = () => {
    this.setState({ ...this.state, open: true });
  };

  render() {
    const { open } = this.state;
    const { classes, errors } = this.props;
    const errorTitle = 'View Errors';
    const errormsg = [];
    Object.keys(errors).forEach((key) => {
      errormsg.push(errors[key][0]);
    });
    return (
      <div className={classes.errbtncontainer}>
        <div className={classes.errorbtn} onClick={this.handleCreate}>
          <Typography className={classes.errortitle} component="span">
            {errorTitle}
          </Typography>
        </div>
        { open ?
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <div className={`err ${classes.errcontainer}`}>
              <ul>
                <li className="header">
                  <strong>Errors</strong>
                </li>
                {errormsg.map((msg, index) => (
                  <li key={index}>
                    - {msg}
                  </li>
                ))}
              </ul>
            </div>
          </ClickAwayListener>
        : null }
      </div>
    );
  }
}

ErrorBtn.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  searchType: PropTypes.string, // eslint-disable-line
  setProductsFile: PropTypes.func, // eslint-disable-line
  errors: PropTypes.instanceOf(Object), // eslint-disable-line
};

export default withStyles(styles)(ErrorBtn);
