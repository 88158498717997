import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal from 'ui/components/modal/Modal';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography/* , Fab */ } from '@material-ui/core';
import { ReactComponent as Plus } from 'resources/icons/plus.svg';
import SnackBarNotify from 'ui/components/common/SnackBarNotify';
import MobileMenuSelector from 'ui/components/common/MobileMenuSelector';
import AccountStaffListing from './AccountStaffListing';
import AddEditStaff from './AddEditStaff';
import styles from './AccountStaffStyle';

class AccountStaff extends Component {
  componentWillMount() {
    this.props.fetchstaff(10, 1);
    this.props.fetchrolelist(10, 1);
  }

  componentDidUpdate() {
    const {
      actionloading, setloadtype, getloadtype, rolename,
    } = this.props;
    if (!actionloading && getloadtype === 'updated role name') {
      setloadtype('');
      this.notifchild.showMessage(`Changes to ${rolename} have been successfully updated`, 'success');
    }
    if (!actionloading && getloadtype === 'added role name') {
      setloadtype('');
      this.notifchild.showMessage(`${rolename} successfully added`, 'success');
    }
  }

  showModal = (e) => {
    e.stopPropagation();
    const { actions } = this.props;
    const modalKey = 'modal-staff';
    const params = { type: 'add' };
    actions.ui.openModal({ modalKey, params });
  };

  render() {
    const { classes, ...others } = this.props;
    const {
      actions, modal, profile,
    } = this.props;

    return (
      <Fragment>
        <div className={classes.mainwrapper}>
          <div className={classes.mobileselect}><MobileMenuSelector {...others} /></div>
          <Typography variant="h1" className={`h1top ${classes.h1} ${classes.mobile}`}>
            Account Staff
          </Typography>
          <div className={classes.container}>
            <Grid container className={classes.headercontainer}>
              <Grid item xs={6}>
                <Typography variant="h1" className={`${classes.h1} ${classes.regular} ${classes.desktop}`}>
                  Account Staff
                </Typography>
                { profile.role.role.MANAGE_ACCOUNTS ?
                  <Plus className={classes.plus} onClick={this.showModal} />
                : null }
              </Grid>
              <Grid className={classes.btnwrapper} item xs={6} />
            </Grid>
            <AccountStaffListing {...this.props} />
          </div>
        </div>
        <Modal
          modalKey="modal-staff"
          modalState={modal}
          closeAction={actions.ui.closeModal}
        >
          <AddEditStaff actions={actions} modal={modal} {...others} />
        </Modal>
        <div className={classes.spacer} />
        <SnackBarNotify onRef={(instance) => { this.notifchild = instance; }} />
      </Fragment>
    );
  }
}

AccountStaff.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  fetchstaff: PropTypes.func.isRequired,
  fetchrolelist: PropTypes.func.isRequired,
  rolename: PropTypes.string.isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
  profile: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(AccountStaff);

