import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Typography, ClickAwayListener, Button } from '@material-ui/core';
import { ReactComponent as Plus } from 'resources/icons/plus.svg';
import FormField from 'ui/components/form/FormField';
import { update, setFieldValue, populateOptionFields, resetOptionFields } from 'ui/components/form/FormActions';
import styles from './TopBarStyle';

const WAIT_INTERVAL = 500;

class Addbtn extends Component {
  state = {
    open: false,
    ordervalue: '',
    formdata: {
      order_id: {
        element: 'autocomplete',
        value: '',
        config: {
          name: 'order_id',
          type: 'number',
          label: 'Type Order ID *',
          options: [],
        },
        validation: {
          required: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
        addeditform: true,
        addNewClicked: (addNew, value) => this.addNewClicked(addNew, value),
        onChange: value => this.handleOrderChange(value),
        hasfunction: true,
        loadstatus: false,
        loadbtnstatus: false,
      },
    },
  };

  componentWillMount() {
    this.timer = null;
  }

  componentDidUpdate() {
    const {
      getloadtype, setloadtype, actionloading, autoOrders,
    } = this.props;
    if (!actionloading && getloadtype === 'auto order') {
      setloadtype('');
      if (autoOrders.length) {
        const list = [];
        for (const key in autoOrders) { // eslint-disable-line
          list.push({ _id: autoOrders[key].attributes.id, name: autoOrders[key].attributes.order_no }); // eslint-disable-line
        }
        const newFormData = populateOptionFields(this.state.formdata, list, 'order_id');
        newFormData.order_id.loadstatus = false;
        this.updateFields(newFormData);
      } else {
        const newFormdata = resetOptionFields(this.state.formdata, 'order_id');
        newFormdata.order_id.loadstatus = false;
        this.updateFields(newFormdata);
      }
    }
  }

  handleOrderChange(ordervalue) {
    clearTimeout(this.timer);
    this.setState({ ...this.state, ordervalue });
    this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
    const newFormdata = { ...this.state.formdata };
    newFormdata.order_id.loadstatus = true;
    this.updateFields(newFormdata);
  }

  triggerChange = () => {
    const { ordervalue } = this.state;
    const { getAutoOrder, resetAutoOrder, setloadtype } = this.props;
    if (ordervalue.trim() !== '') {
      getAutoOrder(ordervalue);
    } else {
      resetAutoOrder();
    }
    setloadtype('auto order');
  }
  addNewClicked = (addNew, value) => {
    const { autoOrders } = this.props;
    let vendorInfo = [];
    let selectedVendor = {};
    if (autoOrders.length) {
      vendorInfo = autoOrders
        .filter(data => data.attributes.id === value)
        .map(vendor => (vendor.attributes));
      const [vendorData] = vendorInfo;
      selectedVendor = vendorData;
    }
    if (typeof selectedVendor === 'undefined') {
      selectedVendor = {};
    }
    const newFormdata = setFieldValue(this.state.formdata, 'order_id', value);
    this.updateFields(newFormdata);
  }
  updateFields = (newFormdata) => {
    this.setState({
      ...this.state,
      formdata: newFormdata,
    });
  }
  updateForm = (element) => {
    const newFormdata = update(element, this.state.formdata, '');
    this.setState({
      ...this.state,
      formdata: newFormdata,
    });
  }

  handleClickAway = () => {
    this.setState({ ...this.state, open: false });
  };

  handleCreate = () => {
    this.setState({ ...this.state, open: true });
  };

  showModal = (e) => {
    e.stopPropagation();
    const { actions, setloadtype } = this.props;
    const modalKey = 'modal-order';
    const params = { type: 'create', id: parseInt(this.state.formdata.order_id.value, 10) };
    actions.ui.openModal({ modalKey, params });
    setloadtype('ra modal');
  };

  render() {
    const { open } = this.state;
    const { classes, searchType } = this.props;
    const addTitle = 'Create RA';

    let stype = '';
    if (typeof searchType !== 'undefined') {
      stype = searchType;
    }

    return (
      <div className={classes.createbtncontainer}>
        <div className={`${classes.addbtn} ${stype}btn`} onClick={this.handleCreate}>
          <IconButton className={classes.iconbtn} aria-label="Add">
            <Plus
              className={classes.icon}
            />
          </IconButton>
          <Typography className={`${classes.addtitle} ${stype}`} component="span">
            {addTitle}
          </Typography>
        </div>
        { open ?
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <div className={classes.racontainer}>
              <Typography component="label">
                Create RA
              </Typography>
              <FormField
                id="order_id"
                formdata={this.state.formdata.order_id}
                change={element => this.updateForm(element)}
              />
              <div className="grid">
                <Typography>
                  Note: Only shipped orders are allowed for RA
                </Typography>
                <Button
                  classes={{ root: classes.proceedbtn }}
                  variant="contained"
                  color="secondary"
                  onClick={this.showModal}
                >
                  Proceed
                </Button>
              </div>
            </div>
          </ClickAwayListener>
        : null }
      </div>
    );
  }
}

Addbtn.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  searchType: PropTypes.string, // eslint-disable-line
  getAutoOrder: PropTypes.func.isRequired,
  resetAutoOrder: PropTypes.func.isRequired,
  autoOrders: PropTypes.instanceOf(Object).isRequired,
  setloadtype: PropTypes.func.isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
  getloadtype: PropTypes.string.isRequired,
  actionloading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Addbtn);
