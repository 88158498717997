import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { ReactComponent as Dropdown } from 'resources/icons/dropdown.svg';
import { ReactComponent as Close } from 'resources/icons/closev2.svg';
import { Grow, Paper, Popper, Typography, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './FilterStyle';

class MoreFilters extends Component {
  state = {
    open: false,
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = (event) => {
    const dialog = document.querySelector('div[role="dialog"]');
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    if (dialog !== null) {
      return;
    }

    this.setState({ open: false });
  };

  closeFilter = () => {
    this.setState({ open: false });
  }

  render() {
    const {
      classes, children, header, title,
    } = this.props;
    const { open } = this.state;

    return (
      <div className={classes.filterbox}>
        <div className={classes.morefilter}>
          <IconButton
            className={classes.moreiconbtn}
            buttonRef={(node) => {
              this.anchorEl = node;
            }}
            aria-owns={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={this.handleToggle}
            disableRipple
          >
            <span>{title}</span> <Dropdown />
          </IconButton>
        </div>
        <Popper
          className={`${classes.popper} more ${title}`}
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper className={`${classes.paper} ${title}`}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <div>
                    <div className={classes.listhead}>
                      <Typography>{header}</Typography>
                      <Close onClick={this.handleClose} className={classes.closeX} />
                    </div>
                    {React.cloneElement(children, { handleClose: this.closeFilter })}
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

MoreFilters.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string, // eslint-disable-line
  header: PropTypes.string, // eslint-disable-line
};

export default withStyles(styles)(MoreFilters);
