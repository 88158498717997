import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ProductFilterForm from 'ui/pages/products/ProductFilterForm';
import Searchbtn from './Searchbtn';
import Addbtn from './Addbtn';
import AddCommonBtn from './AddCommonBtn';
import MoreFilters from './MoreFilters';
import UpdateSyncBtn from './UpdateSyncBtn';
import styles from './AllFiltersStyle';

class ProductFilters extends Component {
  triggerSearch = (e) => {
    if (e.charCode === 13) {
      const { onSearch } = this.props;
      onSearch(e);
    }
  }
  triggerSearchClose = () => {
    const { onSearchClose } = this.props;
    onSearchClose();
  }

  handleSorting = (order, orderBy) => {
    this.props.onRequestSort(order, orderBy);
  };

  render() {
    const { classes, ...other } = this.props;
    const { location } = this.props;
    let showfilters = true;
    let showsyncbutton = true;
    let globalbtn = true;
    switch (location.pathname) {
      case '/products-vendor-orders':
        showfilters = false;
        showsyncbutton = false;
        globalbtn = false;
        break;
      default:
        break;
    }

    return (
      <div className={classes.root}>
        { showsyncbutton ?
          <div className={classes.updatebtn}>
            <UpdateSyncBtn displayfor="desktop" {...other} />
          </div>
        : null }
        <ul className="ulfilterlist">
          <li className="lifilterlist">
            <div className="sbox">
              <Searchbtn
                {...other}
                searchType="infilter"
                triggerSearch={e => this.triggerSearch(e)}
                triggerSearchClose={this.triggerSearchClose}
              />
            </div>
          </li>
          { showfilters ?
            <li className="lifilterlist">
              <div className="btn">
                <MoreFilters header="Filters" title="More Filters" {...other}>
                  <ProductFilterForm {...other} />
                </MoreFilters>
              </div>
            </li>
          : null }
          <li className="lifilterlist">
            <div className="sdiv" />
            <div className={classes.add}>
              { globalbtn ?
                <AddCommonBtn searchType="infilter" {...other} />
              :
                <Addbtn searchType="infilter" {...other} />
              }
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

ProductFilters.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  onSearch: PropTypes.func, // eslint-disable-line
  onSearchClose: PropTypes.func, // eslint-disable-line
  onRequestSort: PropTypes.func, // eslint-disable-line
  location: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(ProductFilters);
