import axios from 'axios';
import { SITE_SERVER } from './misc';

if (process.env.NODE_ENV !== 'development') {
  axios.defaults.withCredentials = true;
}

export const login = (username, password) => axios({
  method: 'POST',
  url: `${SITE_SERVER}/auth/login`,
  headers: [],
  data: {
    username,
    password,
  },
});

export const adminlogin = (username, password) => axios({
  method: 'POST',
  url: `${SITE_SERVER}/auth/adminlogin`,
  headers: [],
  data: {
    username,
    password,
  },
});

export const forgotPass = data => axios({
  method: 'POST',
  url: `${SITE_SERVER}/password/forgot`,
  headers: [],
  data,
});

