import { login, adminlogin, forgotPass } from 'api/login';
import { loginUser } from 'state/logged-user/actions';
import { showActionLoading, hideActionLoading } from 'state/loading/actions';
import { SET_LOGIN_ERROR_MESSAGE, SET_SHOW_PASSWORD, SET_RESET_ERROR_MESSAGE } from 'state/login-form/types';

export const setLoginErrorMessage = message => ({
  type: SET_LOGIN_ERROR_MESSAGE,
  payload: {
    message,
  },
});

export const setResetErrorMessage = message => ({
  type: SET_RESET_ERROR_MESSAGE,
  payload: {
    message,
  },
});

export const setShowPassword = showPassword => ({
  type: SET_SHOW_PASSWORD,
  payload: {
    showPassword,
  },
});

// thunks

const ERROR_LOGIN_MESSAGE = 'Email or Password is invalid';
const ERROR_ADMIN_LOGIN_MESSAGE = 'User Name or Password is invalid';

export const successResponse = response => (dispatch) => {
  if (response.data) {
    const logindata = {
      customer: response.data.data.attributes.customer,
      customer_user: response.data.data.attributes.customer_user,
      role: response.data.data.attributes.role,
    };
    dispatch(loginUser(
      response.data.data.attributes.customer_user.username,
      response.data.data.attributes.access_token.trim(),
      logindata,
    ));
    dispatch(setLoginErrorMessage('noError'));
  } else {
    dispatch(setLoginErrorMessage(ERROR_LOGIN_MESSAGE));
  }
  dispatch(hideActionLoading());
};

export const successResetResponse = response => (dispatch) => {
  if (response.data) {
    dispatch(setResetErrorMessage('noError'));
  } else {
    dispatch(setResetErrorMessage(ERROR_LOGIN_MESSAGE));
  }
  dispatch(hideActionLoading());
};

export const errorResetResponse = () => (dispatch) => {
  dispatch(setResetErrorMessage(ERROR_LOGIN_MESSAGE));
  dispatch(hideActionLoading());
};

export const errorResponse = () => (dispatch) => {
  dispatch(setLoginErrorMessage(ERROR_LOGIN_MESSAGE));
  dispatch(hideActionLoading());
};

export const errorAdminResponse = () => (dispatch) => {
  dispatch(setLoginErrorMessage(ERROR_ADMIN_LOGIN_MESSAGE));
  dispatch(hideActionLoading());
};


export const doLogin = (username, password) => dispatch => (
  new Promise((resolve) => {
    if (username && password) {
      dispatch(showActionLoading());
      dispatch(setLoginErrorMessage(''));
      login(username, password).then(
        response => dispatch(successResponse(response)),
        err => dispatch(errorResponse(err)),
      );
    } else {
      dispatch(hideActionLoading());
      dispatch(setLoginErrorMessage(ERROR_LOGIN_MESSAGE));
      resolve();
    }
  })
);

export const doAdminLogin = (username, password) => dispatch => (
  new Promise((resolve) => {
    if (username && password) {
      dispatch(showActionLoading());
      dispatch(setLoginErrorMessage(''));
      adminlogin(username, password).then(
        response => dispatch(successResponse(response)),
        err => dispatch(errorAdminResponse(err)),
      );
    } else {
      dispatch(hideActionLoading());
      dispatch(setLoginErrorMessage(ERROR_ADMIN_LOGIN_MESSAGE));
      resolve();
    }
  })
);

export const requestReset = email => dispatch => (
  new Promise((resolve) => {
    if (email) {
      dispatch(showActionLoading());
      dispatch(setResetErrorMessage(''));
      forgotPass(email).then(
        response => dispatch(successResetResponse(response)),
        err => dispatch(errorResetResponse(err)),
      );
    } else {
      dispatch(hideActionLoading());
      dispatch(setResetErrorMessage(ERROR_LOGIN_MESSAGE));
      resolve();
    }
  })
);
