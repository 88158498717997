import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';

export default withStyles({
  root: {
    height: '60px',
    '&.selected': {
      backgroundColor: '#446BB0',
      color: '#fff',
    },
    '& td': {
      position: 'relative',
      color: '#333',
      fontWeight: 500,
    },
    '&.selected td': {
      color: '#fff',
    },
    '&.selected td span.sender': {
      color: '#fff',
    },
  },
  hover: {
    '&:hover': {
      backgroundColor: '#FAFAFB !important',
      cursor: 'pointer',
    },
    '&:hover td': {
      color: '#09B0EE',
      fontWeight: 500,
    },
    '&:hover td strong, &:hover td p': {
      color: '#09B0EE',
    },
    '&:hover td[data-status="Open"]': {
      color: '#339B40',
    },
    '&:hover td[data-status="Solved"]': {
      color: '#949DB2',
    },
    '&:hover td[data-status="New"]': {
      color: '#339B40',
    },
    '&:hover td[data-status="pending"]': {
      color: '#B557FB',
    },
    '&:hover td[data-status="shipped"]': {
      color: '#99CE43',
    },
    '&:hover td[data-status="Cancelled"]': {
      color: '#949DB2',
    },
    '&:hover td[data-status="On Hold"]': {
      color: '#E3C000',
    },
    '&:hover td[data-status="Backordered"]': {
      color: '#FF6565',
    },
    '&:hover td[data-status="O"]': {
      color: '#339B40',
    },
    '&:hover td[data-status="C"]': {
      color: '#949DB2',
    },
    '&.selected:hover': {
      backgroundColor: '#446BB0 !important',
      color: '#fff',
    },
    '&.selected:hover td': {
      color: '#fff',
    },
    '&:hover .smbadge span': {
      backgroundColor: '#09B0EE',
    },
    '&:hover td span.sender': {
      color: '#09B0EE',
    },
    '&.selected:hover td span.sender': {
      color: '#fff',
    },
  },
})(TableRow);
