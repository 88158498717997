import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import styles from './DrawerContainerStyle';

class DrawerContainer extends Component {
  state = {
    variant: 'persistent',
  };

  componentDidMount() {
    this.match = window.matchMedia('(max-width: 1038px)');
    this.checkWidth = (e) => {
      if (e.matches) {
        this.setVariant('temporary');
      } else {
        this.setVariant('persistent');
      }
    };

    this.checkWidth(this.match);
    this.match.addListener(this.checkWidth);
  }

  componentWillUnmount() {
    this.match.removeListener(this.checkWidth);
  }

  setVariant = (variant) => {
    this.setState({
      variant,
    });
  }

  render() {
    const { variant } = this.state;
    const { classes, children, drawer } = this.props;
    const boxshadow = drawer.showDrawer ? classes.drawerPaperShadow : '';
    return (
      <Drawer
        className={classes.drawer}
        variant={variant}
        anchor="right"
        open={drawer.showDrawer}
        classes={{
          paper: classNames(classes.drawerPaper, boxshadow),
        }}
      >
        {children}
      </Drawer>
    );
  }
}

DrawerContainer.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  drawer: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(DrawerContainer);
