import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Dropdown } from 'resources/icons/dropdown.svg';
import { ReactComponent as Close } from 'resources/icons/closev2.svg';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Grow, Paper, Popper, Typography, List, ListItem, ListItemText, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './ColumnsStyle';

class StatusList extends Component {
  state = {
    open: false,
  };

  componentDidMount() {
    const { getOrderStatuses } = this.props;
    getOrderStatuses();
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };

  handleStatus = (e) => {
    let closenow = false;
    const filterData = {
      id: 4,
      type: parseInt(e.currentTarget.dataset.id, 10),
      status: e.currentTarget.dataset.value,
    };
    this.addSearchFilter(filterData, 'status');
    closenow = true;
    if (closenow) {
      this.setState({ open: false });
    }
  };

  addSearchFilter = (filterData, elid) => {
    const { filtersdata, setfilters, setloadtype } = this.props;
    const filterItems = [...filtersdata];
    if (filterItems.length) {
      let notfound = true;
      for (const key in filterItems) { // eslint-disable-line
        if (filterItems[key].id === filterData.id) {
          if (elid === 'status') {
            filterItems[key].status = filterData.status;
            filterItems[key].type = filterData.type;
          }
          notfound = false;
        }
      }
      if (notfound) {
        filterItems.push({ ...filterData });
      }
    } else {
      filterItems.push({ ...filterData });
    }
    setfilters(filterItems);
    setloadtype('filter updated');
  }

  render() {
    const { classes, orderstatuses } = this.props;
    const { open } = this.state;
    const statusType = '';
    return (
      <div className={classes.statusbox}>
        <div className={classes.statuscolumn}>
          <IconButton
            className={classes.statusiconbtn}
            buttonRef={(node) => {
              this.anchorEl = node;
            }}
            aria-owns={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={this.handleToggle}
            disableRipple
          >
            <span>Status</span> <Dropdown />
          </IconButton>
        </div>
        <Popper
          className={classes.statussortpopper}
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <div>
                    <div className={classes.sortlisthead}>
                      <Typography>Status</Typography>
                      <Close onClick={this.handleClose} className={classes.closeX} />
                    </div>
                    {typeof orderstatuses !== 'undefined' && orderstatuses.length ?
                      <List className={classes.sortulistcontainer}>
                        {orderstatuses.map((menu) => {
                          if (menu.attributes.id !== -2) {
                            return (
                              <ListItem
                                key={menu.attributes.id}
                                data-id={menu.attributes.id}
                                data-value={menu.attributes.status === 'pending' ? 'Processing' : menu.attributes.status}
                                onClick={this.handleStatus}
                                className={menu.attributes.id === statusType ? 'selected' : ''}
                              >
                                <ListItemText
                                  className="itext"
                                  primary={menu.attributes.status === 'pending' ? 'Processing' : menu.attributes.status}
                                />
                              </ListItem>
                            );
                          }
                          return '';
                        })}
                      </List>
                    : null }
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

StatusList.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  setloadtype: PropTypes.func.isRequired,
  setfilters: PropTypes.func.isRequired,
  filtersdata: PropTypes.instanceOf(Object).isRequired,
  getOrderStatuses: PropTypes.func.isRequired,
  orderstatuses: PropTypes.instanceOf(Array), // eslint-disable-line
};

export default withStyles(styles)(StatusList);
