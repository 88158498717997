import React, { Component } from 'react';
import { Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { update, isFormValid, validateInputs } from 'ui/components/form/FormActions';
import FormField from 'ui/components/form/FormField';
import Faqs from 'ui/pages/faq/Faqs';
import styles from 'ui/components/form/InputFormStyle';
import SupportStyle from './SupportStyle';

class SupportForm extends Component {
  state = {
    saving: false,
    limit: 20,
    formdata: {
      subject: {
        element: 'input',
        value: '',
        config: {
          label: '',
          name: 'subject',
          type: 'text',
        },
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        validationMessage: '',
        addeditform: true,
      },
      reasons: {
        element: 'select',
        value: '',
        config: {
          label: 'Support Type',
          name: 'reasons',
          options: [
            { key: 1, value: 'Billing/Account Info' },
            { key: 2, value: 'Technical Assistance' },
            { key: 3, value: 'Trace an Order' },
            { key: 4, value: 'Cancel an Order' },
            { key: 5, value: 'General Question/Comment' },
          ],
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: true,
        validationMessage: '',
        showlabel: false,
        addeditform: true,
        showEmpty: true,
      },
      message: {
        element: 'textarea',
        value: '',
        config: {
          label: '',
          name: 'message',
          type: 'text',
        },
        validation: {
          required: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
        addeditform: true,
      },
    },
  }

  updateForm = (element) => {
    const newFormdata = update(element, this.state.formdata, '');
    this.setState({
      ...this.state,
      formdata: newFormdata,
    });
  }
  submitForm = (e) => {
    e.preventDefault();
    const { formdata } = this.state;
    /* const { loginData } = this.props; */
    const formIsValid = isFormValid(formdata, '');
    if (formIsValid) {
      const reasontxt = formdata.reasons.config.options.filter(sel => sel.key === parseInt(formdata.reasons.value, 10)); // eslint-disable-line
      const url = `mailto:support@fulfillmentservices.com?&subject=${formdata.subject.value}&body=Reason: ${reasontxt[0].value}%0D%0D${formdata.message.value}`;
      window.open(url, '_blank');
    } else {
      const newFormData = validateInputs(formdata);
      this.setState({
        ...this.state,
        formdata: newFormData,
      });
    }
  }

  render() {
    const {
      classes, ...others
    } = this.props;
    const { /* loginData,  */profile } = this.props;
    if (!profile.role.role.SUPPORT) {
      return true;
    }
    return (
      <div className={classes.container}>
        <div className={classes.supportcontent}>
          <div className={`${classes.leftcontent} ${classes.lform}`}>
            <Typography className="assist" component="p">
              Need assistance? We can help! Please provide your message.
              Our Client Support Departments are available Monday-Friday from 9am-5:30pm.
            </Typography>
            {/* <Typography className="from" component="p">
              From:&nbsp;&nbsp;
              <strong>
                {loginData.customer.client_name} ({loginData.customer_user.email})
              </strong>
            </Typography> */}
            <div className={classes.inputcontainer}>
              <label>Subject *</label>
              <div className="inpt">
                <FormField
                  id="subject"
                  formdata={this.state.formdata.subject}
                  change={element => this.updateForm(element)}
                />
              </div>
            </div>
            <div className={classes.inputcontainer}>
              <label>Reason *</label>
              <div className="inpt">
                <FormField
                  id="reasons"
                  formdata={this.state.formdata.reasons}
                  change={element => this.updateForm(element)}
                />
              </div>
            </div>
            <Typography className={classes.spacer} component="span" />
            <div className={classes.inputcontainer}>
              <div className="inpt">
                <FormField
                  id="message"
                  formdata={this.state.formdata.message}
                  change={element => this.updateForm(element)}
                />
              </div>
            </div>
            <div className="createbtn">
              <Button
                className={`${classes.modalBtn} ${classes.w148}`}
                variant="contained"
                color="secondary"
                onClick={this.submitForm}
              >
                Create Email
              </Button>
            </div>
          </div>
          <div className={classes.rightcontent}>
            <Faqs {...others} />
          </div>
        </div>
      </div>
    );
  }
}

SupportForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  /* loginData: PropTypes.instanceOf(Object).isRequired, */
  addchat: PropTypes.func.isRequired,
  setloadtype: PropTypes.func.isRequired,
  profile: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(theme => ({
  ...styles(theme),
  ...SupportStyle(theme),
}))(SupportForm);
