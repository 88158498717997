import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { Typography, List, ListItem } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ExpansionPanel = withStyles({
  root: {
    borderTop: '1px solid #E8EAEE',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&.top': {
      borderTop: 0,
    },
  },
  disabled: {
    backgroundColor: 'transparent',
    '& .summtop': {
      opacity: 1,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
  },
  expanded: {
    margin: 'auto',
  },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #E8EAEE',
    marginBottom: -1,
    minHeight: 56,
    padding: '0 34px',
    textAlign: 'left',
    display: 'inline-block',
    width: '100%',
    '& p': {
      fontSize: 14,
      fontWeight: 400,
      color: '#949DB2',
      paddingRight: '24px !important',
    },
    '&[aria-expanded="true"] p': {
      color: '#446BB0',
    },
    '&$expanded': {
      minHeight: 56,
    },
    '&.summtop': {
      minHeight: '92px',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    '&.summtop p': {
      fontSize: 16,
      fontWeight: 500,
      color: '#446BB0',
    },
    '&.summtop > div': {
      margin: '34px 0 0',
    },
    '&.summbottom': {
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
    '&.summbottom[aria-expanded="false"]': {
      borderBottom: 0,
    },
    '&.summbottom[aria-expanded="true"]': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    '@media (max-width:1240px)': {
      padding: '0 24px',
      '&.summtop': {
        backgroundColor: '#F6F7F7',
        minHeight: 60,
        borderRadius: 0,
        lineHeight: '60px',
        padding: '0 24px',
      },
      '&.summtop > div': {
        margin: 0,
      },
    },
  },
  content: {
    margin: '17px 0',
    display: 'inline-block',
    '&$expanded': {
      margin: '17px 0',
    },
  },
  expanded: {},
  expandIcon: {
    position: 'relative',
    top: 11,
    margin: 0,
    right: 0,
    bottom: 0,
    display: 'inline-block',
    padding: 0,
    transform: 'translateY(-50%) rotate(270deg)',
    '& svg': {
      width: 18,
      fill: '#09B0EE',
    },
    '&$expanded': {
      transform: 'translateY(-50%) rotate(360deg)',
    },
  },
})(props => <MuiExpansionPanelSummary {...props} />);

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    backgroundColor: '#F8F8F9',
    padding: theme.spacing.unit * 0,
    '& ul': {
      padding: 0,
      width: '100%',
    },
    '& ul li': {
      padding: '12px 54px',
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '14px',
      color: '#333',
      borderBottom: '1px solid #E8EAEE',
    },
    '& ul li:hover': {
      fontWeight: 500,
      color: '#09B0EE',
      cursor: 'pointer',
    },
    '& ul li:last-child': {
      borderBottom: 0,
    },
    '& ul li.mobile': {
      display: 'none',
    },
    '@media (max-width:1240px)': {
      '& ul li': {
        padding: '12px 44px',
      },
      '& ul li.desktop': {
        display: 'none',
      },
      '& ul li.mobile': {
        display: 'block',
      },
    },
  },
}))(MuiExpansionPanelDetails);

class CustomizedExpansionPanel extends React.Component {
  state = {
    expanded: 'panel1',
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  openDetails = (e) => {
    const { actions, setloadtype } = this.props;
    const params = {
      category: e.currentTarget.dataset.category,
      title: e.currentTarget.dataset.title,
    };
    actions.ui.openDrawer({ params });
    setloadtype('faq details');
  }

  render() {
    const { expanded } = this.state;
    return (
      <div>
        <ExpansionPanel
          className="top"
          square
          disabled
        >
          <ExpansionPanelSummary className="summtop">
            <Typography>Frequently Asked Questions</Typography>
          </ExpansionPanelSummary>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={expanded === 'panel1'}
          onChange={this.handleChange('panel1')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Sales Orders</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List>
              <ListItem data-category="Sales Orders" data-title="How do I add a new sales order?" onClick={this.openDetails}>
                &bull;&nbsp; How do I add a new sales order?
              </ListItem>
              <ListItem data-category="Sales Orders" data-title="How do I upload multiple sales orders?" onClick={this.openDetails}>
                &bull;&nbsp; How do I upload multiple sales orders?
              </ListItem>
              <ListItem data-category="Sales Orders" data-title="How do I view all my sales orders?" onClick={this.openDetails} >
                &bull;&nbsp; How do I view all my sales orders?
              </ListItem>
              <ListItem data-category="Sales Orders" data-title="How do I view my pending orders?" onClick={this.openDetails} >
                &bull;&nbsp; How do I view my pending orders?
              </ListItem>
              <ListItem data-category="Sales Orders" data-title="How do I view my shipped orders?" onClick={this.openDetails} >
                &bull;&nbsp; How do I view my shipped orders?
              </ListItem>
              <ListItem data-category="Sales Orders" data-title="How do I view my returned orders?" onClick={this.openDetails} >
                &bull;&nbsp; How do I view my returned orders?
              </ListItem>
              <ListItem data-category="Sales Orders" data-title="How do I create a return authorization (RA)?" onClick={this.openDetails} >
                &bull;&nbsp; How do I create a return authorization (RA)?
              </ListItem>
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={expanded === 'panel2'}
          onChange={this.handleChange('panel2')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Purchase Orders</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List>
              <ListItem data-category="Purchase Orders" data-title="How do I add a new purchase order?" onClick={this.openDetails}>
                &bull;&nbsp; How do I add a new purchase order?
              </ListItem>
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={expanded === 'panel3'}
          onChange={this.handleChange('panel3')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Supply Receipts</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List>
              <ListItem data-category="Supply Receipts" data-title="How do I view a supply receipt?" onClick={this.openDetails}>
                &bull;&nbsp; How do I view a supply receipt?
              </ListItem>
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={expanded === 'panel4'}
          onChange={this.handleChange('panel4')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Products</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List>
              <ListItem data-category="Products" data-title="How do I view my products?" onClick={this.openDetails}>
                &bull;&nbsp; How do I view my products?
              </ListItem>
              <ListItem data-category="Products" data-title="How do I add a new product?" onClick={this.openDetails}>
                &bull;&nbsp; How do I add a new product?
              </ListItem>
              <ListItem data-category="Products" data-title="How do I upload multiple products?" onClick={this.openDetails}>
                &bull;&nbsp; How do I upload multiple products?
              </ListItem>
              <ListItem data-category="Products" data-title="How do I view my top selling products?" onClick={this.openDetails}>
                &bull;&nbsp; How do I view my top selling products?
              </ListItem>
              <ListItem data-category="Products" data-title="How do I view my low stock products?" onClick={this.openDetails}>
                &bull;&nbsp; How do I view my low stock products?
              </ListItem>
              <ListItem data-category="Products" data-title="How do I view my products that are out of stock?" onClick={this.openDetails}>
                &bull;&nbsp; How do I view my products that are out of stock?
              </ListItem>
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={expanded === 'panel5'}
          onChange={this.handleChange('panel5')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Stock Alerts</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List>
              <ListItem className="desktop" data-category="Stock Alerts" data-title="How do I add a stock alert?" onClick={this.openDetails}>
                &bull;&nbsp; How do I add a stock alert?
              </ListItem>
              <ListItem className="mobile" data-category="Stock Alerts" data-title="How do I make a stock alert?" onClick={this.openDetails}>
                &bull;&nbsp; How do I make a stock alert?
              </ListItem>
              <ListItem data-category="Stock Alerts" data-title="How do I add a new email address to receive stock alerts?" onClick={this.openDetails}>
                &bull;&nbsp; How do I add a new email address to receive stock alerts?
              </ListItem>
              <ListItem className="mobile" data-category="Stock Alerts" data-title="How do I add multiple email addresses to receive stock alerts?" onClick={this.openDetails}>
                &bull;&nbsp; How do I add multiple email addresses to receive stock alerts?
              </ListItem>
              <ListItem className="mobile" data-category="Stock Alerts" data-title="How do I edit a stock alert for my product?" onClick={this.openDetails}>
                &bull;&nbsp; How do I edit a stock alert for my product?
              </ListItem>
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={expanded === 'panel6'}
          onChange={this.handleChange('panel6')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Reports</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List>
              <ListItem data-category="Reports" data-title="How do I view my sales order report?" onClick={this.openDetails}>
                &bull;&nbsp; How do I view my sales order report?
              </ListItem>
              <ListItem data-category="Reports" data-title="How do I view my sales by SKU report?" onClick={this.openDetails}>
                &bull;&nbsp; How do I view my sales by SKU report?
              </ListItem>
              <ListItem data-category="Reports" data-title="How do I view my inventory report?" onClick={this.openDetails}>
                &bull;&nbsp; How do I view my inventory report?
              </ListItem>
              <ListItem data-category="Reports" data-title="How do I view my returned orders report?" onClick={this.openDetails}>
                &bull;&nbsp; How do I view my returned orders report?
              </ListItem>
              <ListItem data-category="Reports" data-title="How do I view my supply receipts report?" onClick={this.openDetails}>
                &bull;&nbsp; How do I view my supply receipts report?
              </ListItem>
              <ListItem data-category="Reports" data-title="How do I export my reports?" onClick={this.openDetails}>
                &bull;&nbsp; How do I export my reports?
              </ListItem>
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={expanded === 'panel7'}
          onChange={this.handleChange('panel7')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Support</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List>
              <ListItem data-category="Support" data-title="Where can I get support?" onClick={this.openDetails}>
                &bull;&nbsp; Where can I get support?
              </ListItem>
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={expanded === 'panel8'}
          onChange={this.handleChange('panel8')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Settings</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List>
              <ListItem data-category="Settings" data-title="How do I view and change my notification settings?" onClick={this.openDetails}>
                &bull;&nbsp; How do I view and change my notification settings?
              </ListItem>
              <ListItem className="desktop" data-category="Settings" data-title="How do I view and change my account staff?" onClick={this.openDetails}>
                &bull;&nbsp; How do I view and change my account staff?
              </ListItem>
              <ListItem className="mobile" data-category="Settings" data-title="How do I add and change my account staff?" onClick={this.openDetails}>
                &bull;&nbsp; How do I add and change my account staff?
              </ListItem>
              <ListItem className="desktop" data-category="Settings" data-title="How do I view and change my staff roles?" onClick={this.openDetails}>
                &bull;&nbsp; How do I view and change my staff roles?
              </ListItem>
              <ListItem className="mobile" data-category="Settings" data-title="How do I add and change my staff roles?" onClick={this.openDetails}>
                &bull;&nbsp; How do I add and change my staff roles?
              </ListItem>
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={expanded === 'panel9'}
          onChange={this.handleChange('panel9')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Profile</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List>
              <ListItem className="desktop" data-category="Profile" data-title="How do I view and change my profile?" onClick={this.openDetails}>
                &bull;&nbsp; How do I view and change my profile?
              </ListItem>
              <ListItem className="mobile" data-category="Profile" data-title="How do I edit my profile?" onClick={this.openDetails}>
                &bull;&nbsp; How do I edit my profile?
              </ListItem>
              <ListItem data-category="Profile" data-title="How do I view my account information?" onClick={this.openDetails}>
                &bull;&nbsp; How do I view my account information?
              </ListItem>
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={false}
          data-category="Terms of Service"
          data-title="Terms of Service"
          onClick={this.openDetails}
        >
          <ExpansionPanelSummary className="summbottom">
            <Typography>Terms of Service</Typography>
          </ExpansionPanelSummary>
        </ExpansionPanel>
        <ExpansionPanel
          className="bottom"
          square
          expanded={false}
          data-category="Glossary of Terms"
          data-title="Glossary of Terms"
          onClick={this.openDetails}
        >
          <ExpansionPanelSummary className="summbottom">
            <Typography>Glossary of Terms</Typography>
          </ExpansionPanelSummary>
        </ExpansionPanel>
      </div>
    );
  }
}

CustomizedExpansionPanel.propTypes = {
  /* classes: PropTypes.instanceOf(Object).isRequired, */
  actions: PropTypes.instanceOf(Object).isRequired,
  setloadtype: PropTypes.func.isRequired,
};

export default CustomizedExpansionPanel;
