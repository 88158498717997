import { makeRequest, METHODS } from '@entando/apimanager';
import { getMonthlySales, getWeeklySales, getDailySales, getOrdersReport, getStock, topSelling } from 'test/mocks/reports';
import axios from 'axios';
import { fuelheaders } from 'state/logged-user/actions';
import { SITE_SERVER } from './misc';

if (process.env.NODE_ENV !== 'development') {
  axios.defaults.withCredentials = true;
}

export const getSalesMonthly = () => (
  makeRequest({
    uri: '/api/reports',
    method: METHODS.GET,
    mockResponse: getMonthlySales(),
    useAuthentication: false,
  })
);

export const getSalesWeekly = () => (
  makeRequest({
    uri: '/api/reports',
    method: METHODS.GET,
    mockResponse: getWeeklySales(),
    useAuthentication: false,
  })
);

export const getSalesDaily = () => (
  makeRequest({
    uri: '/api/reports',
    method: METHODS.GET,
    mockResponse: getDailySales(),
    useAuthentication: false,
  })
);

export const getReportsOrder = () => (
  makeRequest({
    uri: '/api/reports',
    method: METHODS.GET,
    mockResponse: getOrdersReport(),
    useAuthentication: false,
  })
);

export const getStockProducts = () => (
  makeRequest({
    uri: '/api/reports',
    method: METHODS.GET,
    mockResponse: getStock(),
    useAuthentication: false,
  })
);

export const getTopSellingProducts1 = () => (
  makeRequest({
    uri: '/api/reports',
    method: METHODS.GET,
    mockResponse: topSelling(),
    useAuthentication: false,
  })
);

export const getDashboard = (type, year) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/dashboard?type=${type}${year !== null && typeof year !== 'undefined' ? `&year=${year}` : ''}`,
  headers: fuelheaders(),
});

export const getTopSellingProducts = (limit, page, date) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/reports/products/top_selling?page=${page}&limit=${limit}${date !== null ? `&date=${date}` : ''}`,
  headers: fuelheaders(),
});

export const getThisWeekProducts = (limit, page) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/reports/products/current_week?page=${page}&limit=${limit}`,
  headers: fuelheaders(),
});

export const getThisMonthProducts = (limit, page) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/reports/products/current_month?page=${page}&limit=${limit}`,
  headers: fuelheaders(),
});

export const getOrderReportsDaily = (year, month, startDay, endDay) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/reports/orders/daily?${year !== null ? `year=${year}` : ''}${month !== null ? `&month=${month}` : ''}${startDay !== null ? `&start_day=${startDay}` : ''}${endDay !== null ? `&end_day=${endDay}` : ''}`,
  headers: fuelheaders(),
});

export const getOrderReportsWeekly = (year, month) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/reports/orders/weekly?${year !== null ? `year=${year}` : ''}${month !== null ? `&month=${month}` : ''}`,
  headers: fuelheaders(),
});

export const getOrderReportsMonthly = (type, threeyears, year) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/reports/orders/monthly?${type !== null ? `&type=${type}` : ''}${threeyears !== null ? `&three_years=${threeyears}` : ''}${year !== null ? `&year=${year}` : ''}`,
  headers: fuelheaders(),
});

export const getProductReportsMonthly = (id, type, sortType) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/reports/product_orders/${id}/monthly${type !== null ? `?type=${type}` : ''}${typeof sortType !== 'undefined' && sortType !== null ? `&sort_type=${sortType}` : ''}`,
  headers: fuelheaders(),
});

export const getProductReportsWeekly = (id, year, month) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/reports/product_orders/${id}/weekly?${year !== null ? `year=${year}` : ''}${month !== null ? `&month=${month}` : ''}`,
  headers: fuelheaders(),
});

export const getProductReportsDaily = (id, year, month, startDay, endDay) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/reports/product_orders/${id}/daily?${year !== null ? `year=${year}` : ''}${month !== null ? `&month=${month}` : ''}${startDay !== null ? `&start_day=${startDay}` : ''}${endDay !== null ? `&end_day=${endDay}` : ''}`,
  headers: fuelheaders(),
});

export const generateExportUrl = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/export/create_url`,
  headers: fuelheaders(),
  data: dataToSubmit,
});
