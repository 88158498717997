import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from 'ui/components/common/ModalContainerStyle';
import PageLoader from 'ui/components/loader/PageLoader';
import AddEditStaffHeader from './AddEditStaffHeader';
import AccountStaffForm from './AccountStaffForm';

class AddEditStaff extends Component {
  submitStaff = () => {
    this.child.submitForm();
  }
  render() {
    const { acctData, modal } = this.props;
    if (typeof modal.params !== 'undefined' && modal.params.type === 'edit') {
      if (Object.keys(acctData).length === 0) {
        return (
          <Fragment>
            <PageLoader />
          </Fragment>
        );
      }
    }
    const { classes, ...others } = this.props;
    /* console.log(this.props); // eslint-disable-line */
    return (
      <div className={classes.container}>
        <AddEditStaffHeader {...others} submitStaff={this.submitStaff} />
        <div className={classes.content}>
          <AccountStaffForm
            {...others}
            onRef={(instance) => { this.child = instance; }}
          />
        </div>
      </div>
    );
  }
}

AddEditStaff.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  modal: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(AddEditStaff);
