const style = theme => ({
  logoWrapper: {
    padding: '90px 0 0 0',
    '&.resetlink': {
      padding: 0,
    },
    '& div': {
      backgroundColor: theme.palette.background.default,
      position: 'relative',
      margin: '0 auto',
      width: 760,
      height: 120,
      paddingTop: 18,
      textAlign: 'center',
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      boxShadow: '0 0 12px rgba(68, 107, 176, 0.91)',
    },
    '@media (max-width:1240px)': {
      padding: 0,
      '& div': {
        width: '100%',
        height: 110,
        padding: '32px 30px 0',
        boxShadow: 'none',
        textAlign: 'left',
        '& img': {
          height: 48,
        },
      },
    },
  },
  formWrapper: {
    backgroundColor: theme.palette.common.white,
    position: 'relative',
    width: 760,
    minHeight: 480,
    margin: '-10px auto 0',
    padding: '90px 0 0 0',
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    zIndex: 2,
    boxShadow: '0 3px 9px rgba(217, 215, 215, 0.18)',
    '@media (max-width:1240px)': {
      margin: 0,
      padding: '42px 0 0 0',
      width: '100%',
      height: 'calc(100vh - 110px)',
      borderRadius: 0,
    },
  },
  h1: {
    fontSize: 20,
    fontWeight: 400,
    color: theme.palette.text.primary,
    margin: '0 0 36px 0',
    '&.reset-link': {
      fontSize: 20,
    },
    '@media (max-width:1240px)': {
      fontSize: 18,
      '&.reset-link': {
        fontSize: 16,
      },
    },
  },
  rh1: {
    margin: '0 0 14px 0',
  },
  formcont: {
    width: 340,
    margin: '0 auto',
    '@media (max-width:1240px)': {
      margin: 0,
      width: '100%',
      padding: '0 30px',
    },
  },
  rformcont: {
    width: 340,
  },
  rlformcont: {
    width: 380,
  },
  btn: {
    position: 'relative',
    display: 'block',
    textTransform: 'none',
    width: 148,
    height: 34,
    borderRadius: 100,
    fontSize: 14,
    lineHeight: '14px',
    boxShadow: '0 3px 8px rgba(217, 215, 215, 0.95)',
    '& span > div': {
      position: 'absolute',
      top: 6,
      left: '42%',
    },
  },
  rbtn: {
    marginTop: 0,
    width: 'auto',
    paddingRight: 20,
    paddingLeft: 20,
  },
  notif: {
    backgroundColor: 'beige',
    padding: 10,
    marginBottom: 20,
  },
  link: {
    textDecoration: 'none',
    display: 'inline-block',
    marginBottom: 42,
  },
  linktxt: {
    fontSize: 12,
    color: theme.palette.secondary.main,
  },
  rlink: {
    margin: '40px 0 0 0',
  },
  note: {
    fontSize: 12,
    lineHeight: '18px',
    color: theme.palette.text.primary,
    marginBottom: 30,
  },
  errbox: {
    position: 'relative',
  },
  errorLabel: {
    position: 'absolute',
    left: 0,
    top: 36,
    color: theme.palette.error.main,
    fontSize: 11,
  },
  errorLabel1: {
    top: 16,
  },
  media: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 750,
    height: 464,
  },
  snackbar: {
    position: 'absolute',
    top: -60,
    width: '100%',
    padding: '2px 24px',
  },
  message: {
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    letterSpacing: 'normal',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
});

export default style;
