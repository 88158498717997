/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import styles from './DetailsStyle';

class GloassaryOfTerms extends Component {
  render() {
    const { classes } = this.props;
    return (
      <ul className={`glossary ${classes.ulist}`}>
        <li>
          <ul>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>3rd Party Billing</strong>&nbsp;&mdash;&nbsp;
                    This is a billing option for shipments via small parcel or LTL carriers.
                    This indicates that the shipping charges will be paid by a 3rd party
                    other than iPS (“the shipper”) or the recipient.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>3rd Party Billing Acct#</strong>&nbsp;&mdash;&nbsp;
                    A 3rd Party Billing Account Number is the shipping carrier account number
                    that has been assigned to an organization for billing shipping charges
                    to their account.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Available Inventory (Available QTY)</strong>&nbsp;&mdash;&nbsp;
                  Available inventory is the quantity of products which is available to order.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Backordered</strong>&nbsp;&mdash;&nbsp;
                  An order for a good or service that cannot be filled at the current time due
                  to a lack of available supply
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Carrier Code</strong>&nbsp;&mdash;&nbsp;
                  This is a code within an order which represents the manner in which the order should ship.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Commercial Invoice</strong>&nbsp;&mdash;&nbsp;
                  This is the documentation used by customs to assign taxes and fees to an
                  international shipment. All international shipments require a commercial invoice.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Commercial Invoice Value</strong>&nbsp;&mdash;&nbsp;
                  The price on a commercial invoice.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Damaged Returns</strong>&nbsp;&mdash;&nbsp;
                  A damaged return is a return that was received in a condition which
                  cannot be reshipped or put back into available inventory.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>EAN (European Article Number)</strong>&nbsp;&mdash;&nbsp;
                  The standard product identifier used in Europe. It is a standardized barcode,
                  also referred to as International Article Number.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>FBAUS</strong>&nbsp;&mdash;&nbsp;
                  Fulfillment by Amazon in the United States.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>FBAINTIL</strong>&nbsp;&mdash;&nbsp;
                  Fulfillment by Amazon internationally.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>FCL</strong>&nbsp;&mdash;&nbsp;
                  Full container load.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Floor Stacked</strong>&nbsp;&mdash;&nbsp;
                  The storing of pallets directly on the floor.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Freight Forwarder</strong>&nbsp;&mdash;&nbsp;
                  Facilitates, coordinates, and produces the outcome of international freight
                  movement from point of origin to ultimate destination including
                  export and import processes.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Fulfillment Order</strong>&nbsp;&mdash;&nbsp;
                  This represents a transaction that a client has submitted as a fulfillment order.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>FTL</strong>&nbsp;&mdash;&nbsp;
                  Full trailer load
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Global Minimum Quantity Threshold</strong>&nbsp;&mdash;&nbsp;
                  The minimum quantity Fuel recognizes as low stock.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>GTIN (Global Trade Item Number)</strong>&nbsp;&mdash;&nbsp;
                  is an identifier used to look up product information about a retailer,
                  manufacturer, collector, researcher, or other entity.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Harmonized Tariff Code (HTS)</strong>&nbsp;&mdash;&nbsp;
                  A product classification code between 8-10 digits (10 in the U.S. administered
                  by the U.S. International Trade Commission). The first six digits are an HS code,
                  and the countries of import assign the subsequent digits to provide additional classification.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Hold for Instructions</strong>&nbsp;&mdash;&nbsp;
                  Hold order periods can be used to put a pause on an order for extra instructions.
                  This avoids mistakes in delivery and packaging and ensures the best customer experience.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Hold for Client Instructions</strong>&nbsp;&mdash;&nbsp;
                  Hold order periods can be used when a client needs to add instructions
                  for an order. This avoids mistakes in the warehouse, transit, and delivery.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Hold for Labels</strong>&nbsp;&mdash;&nbsp;
                  Hold order periods can be put in place for an order to receive the proper
                  labels before it is shipped. This avoids mistakes in transit and delivery.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Hold Potential Fraud</strong>&nbsp;&mdash;&nbsp;
                  Hold order periods can be used to help detect fraud before you fulfill
                  fraudulent orders. Hold orders long enough to allow payment companies
                  and/or customers to identify and respond to fraudulent charges.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>International/Commercial Invoice</strong>&nbsp;&mdash;&nbsp;
                  A commercial invoice (AKA international invoice) is a legal document
                  issued by the seller (exporter) to the buyer (importer) in an international
                  transaction and serves as a contract and a proof of sale
                  between the buyer and seller.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>INV</strong>&nbsp;&mdash;&nbsp;
                  A non-document shipment where a paper invoice is required and all
                  information is keyed (information will not be transmitted electronically).
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>ISBN (International Standard Book Number)</strong>&nbsp;&mdash;&nbsp;
                  A numeric commercial book identifier which is intended to be unique.
                  An ISBN is assigned to each separate edition and variation of a publication.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Lot ID</strong>&nbsp;&mdash;&nbsp;
                  A lot number is combined with a serial number to form the identification number
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Lot No.</strong>&nbsp;&mdash;&nbsp;
                  An identification number assigned to a particular quantity or lot of material from a single manufacturer
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>LTL Carrier</strong>&nbsp;&mdash;&nbsp;
                  Less than truckload shipping carrier.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Low Stock</strong>&nbsp;&mdash;&nbsp;
                  Fuel’s Global Low Stock Threshold is anything 50 units or below.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>MSRP (Manufacturer Suggested Retail Price)</strong>&nbsp;&mdash;&nbsp;
                  The price that a manufacturer recommends a product should be sold for at point of sale.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Order ID</strong>&nbsp;&mdash;&nbsp;
                  An Order ID is the unique number associated with every order placed through
                  a secure checkout. This is the number used to reference a specific purchase.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Palletized</strong>&nbsp;&mdash;&nbsp;
                  To place, stack, or transport (goods) on a pallet or pallets
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Pending</strong>&nbsp;&mdash;&nbsp;
                  Shipment/order record saved to be processed later
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Physical Inventory (Physical QTY)</strong>&nbsp;&mdash;&nbsp;
                  Physical inventory is the quantity of products we have within the warehouse.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Purchase Order</strong>&nbsp;&mdash;&nbsp;
                  is a legally binding document created by a buyer and presented
                  to a seller (essentially a list of what you want to buy)
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Reference ID</strong>&nbsp;&mdash;&nbsp;
                  A reference ID number is a number established by or on behalf of the
                  reporting corporation identified in Part I that is assigned to 25% foreign
                  shareholders and/or related foreign parties with respect to which
                  Form 5472 reporting is required.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Returns</strong>&nbsp;&mdash;&nbsp;
                  A return is the transaction which represents the return of a parcel
                  from a shipping carrier which is related to a shipment or
                  product we stock in the WMS.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Return Authorization (RA)</strong>&nbsp;&mdash;&nbsp;
                  A numbered authorization provided by a vendor to permit the return of a product form.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Serialized</strong>&nbsp;&mdash;&nbsp;
                  A process of tagging individual saleable units of inventory with unique
                  identifiers that are assigned sequentially to each product.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Service Level</strong>&nbsp;&mdash;&nbsp;
                  An entity that is used to express the duration of transportation.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>SKU</strong>&nbsp;&mdash;&nbsp;
                  A SKU or Stock Keeping Unit is an identifier that is used by merchants
                  to permit the systematic tracking of products and services offered to customers.
                  Usage of the SKU system is rooted in the drill down method, pertaining to data management.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>SKU #</strong>&nbsp;&mdash;&nbsp;
                  The identifier for the Stock Keeping Unit.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Small Parcel Carriers</strong>&nbsp;&mdash;&nbsp;
                  Shipping Carriers for small parcels; FedEx, UPS, and USPS.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>SPC</strong>&nbsp;&mdash;&nbsp;
                  Statistical Process Control, a method of quality control.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Stock Alert</strong>&nbsp;&mdash;&nbsp;
                  A stock alert is an alert that is sent when the stock of an item reaches
                  a set number. The stock alert will be sent to the entered email based on the
                  low stock alert quantity entered and the over stock alert quantity entered.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Supply Receipt</strong>&nbsp;&mdash;&nbsp;
                  A supply receipt is a transaction which represents the receipt of goods
                  from our client or our client’s supplier.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Ticket</strong>&nbsp;&mdash;&nbsp;
                  A ticket (also known as an incident ticket or trouble ticket) is contained
                  within GEM (see GEM), the iPS issue tracking system, which contains information
                  about client support needs or third parties on behalf of an end user who has
                  reported an incident. The ticket will have a unique reference number,
                  also known as a case, which is used to allow the user or support staff
                  to quickly locate, add to or communicate the status of the user's issue or request.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>TL (also called FTL)</strong>&nbsp;&mdash;&nbsp;
                  Full truckload shipments.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Top Selling Products</strong>&nbsp;&mdash;&nbsp;
                  The top ten selling products for the quarter.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Tracking Number</strong>&nbsp;&mdash;&nbsp;
                  The number assigned by a carrier, at the point of shipment, to a shipped parcel.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Transaction Date</strong>&nbsp;&mdash;&nbsp;
                  A date upon which a trade takes place for a security or other financial
                  instrument (represents the time at which ownership officially transfers)
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>UID (Unique Identifier)</strong>&nbsp;&mdash;&nbsp;
                  A numeric or alphanumeric string associated with a single entity within a given system.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>UPC</strong>&nbsp;&mdash;&nbsp;
                  Universal product code, is a type of code printed on retail product packaging to aid in identifying a particular item
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Vendor</strong>&nbsp;&mdash;&nbsp;
                  A person or company offering something for sale
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Vendor Product Number</strong>&nbsp;&mdash;&nbsp;
                  A unique string used by vendors to identify a product.
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                {/* <span><strong>-</strong></span> */}
                <span>
                  <strong>Will Call</strong>&nbsp;&mdash;&nbsp;
                  This is the carrier code used by a client when they want to pick up the order.
                  A will call order should not be shipped via shipping carrier.
                </span>
              </Typography>
            </li>
          </ul>
        </li>
      </ul>
    );
  }
}

GloassaryOfTerms.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(GloassaryOfTerms);

