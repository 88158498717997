const styles = theme => ({
  ...theme.classes,
  lastupdate: {
    fontSize: 11,
    fontWeight: 400,
    color: theme.palette.primary.light,
  },
  submitWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  modalBtn2: {
    backgroundColor: '#0CD581',
    height: 28,
    minHeight: 28,
    margin: '0 34px 0 15px',
    padding: 0,
    minWidth: 100,
    '&:hover': {
      backgroundColor: '#0CD581',
    },
    '& span': {
      color: '#fff !important',
      fontWeight: '500 !important',
      fontSize: '13px !important',
    },
  },
  '@keyframes rotating': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  rotating: {
    animation: 'rotating 1s linear infinite',
  },
});

export default styles;
