import {
  getSalesMonthly,
  getSalesWeekly,
  getSalesDaily,
  getReportsOrder,
  getStockProducts,
  getTopSellingProducts1,
  getTopSellingProducts,
  getDashboard,
  getThisWeekProducts,
  getThisMonthProducts,
  getOrderReportsDaily,
  getOrderReportsWeekly,
  getOrderReportsMonthly,
  getProductReportsMonthly,
  getProductReportsWeekly,
  getProductReportsDaily,
  generateExportUrl,
} from 'api/reports';
import { showActionLoading, hideActionLoading } from 'state/loading/actions';
import responseCheker from 'api/responseCheker';
import { openAlertModal } from 'state/modal-drawer/actions';

import {
  SET_SALES_DATA,
  SET_ORDERS_DATA,
  SET_SALES_TAB_DATA,
  SET_STOCK_PRODUCTS_DATA,
  SET_VIEW_REPORT_DATA,
  SET_SELLING_PRODUCTS_DATA,
  SET_DASHBOARD_DATA,
  SET_WEEKLY_PRODUCTS_DATA,
  SET_MONTHLY_PRODUCTS_DATA,
  SET_TIME_PERIOD_DATA,
  SET_ORDER_REPORTS_DAILY_DATA,
  SET_ORDER_REPORTS_WEEKLY_DATA,
  SET_ORDER_REPORTS_MONTHLY_DATA,
  SET_ORDER_REPORTS_MONTHLY_DASHBOARD_DATA,
  RESET_ORDER_REPORTS_MONTHLY_DASHBOARD_DATA,
  SET_DAILY_PROPS_DATA,
  SET_PRODUCT_REPORTS_MONTHLY_DATA,
  SET_PRODUCT_REPORTS_WEEKLY_DATA,
  SET_PRODUCT_REPORTS_DAILY_DATA,
  SET_SKU_DATA,
  SET_DAY_DATA,
  SET_COLUMN_SETTINGS,
  SET_EXPORT_URL_DATA,
} from './types';

export const setSalesReport = sales => ({
  type: SET_SALES_DATA,
  payload: { sales },
});

export const setOrdersReport = orders => ({
  type: SET_ORDERS_DATA,
  payload: { orders },
});

export const setSalesTab = tab => ({
  type: SET_SALES_TAB_DATA,
  payload: { tab },
});

export const setStockProducts = products => ({
  type: SET_STOCK_PRODUCTS_DATA,
  payload: { products },
});

export const viewDetailedReport = view => ({
  type: SET_VIEW_REPORT_DATA,
  payload: { view },
});

export const setTopSellingProducts = sellproducts => ({
  type: SET_SELLING_PRODUCTS_DATA,
  payload: { sellproducts },
});

export const setDashboard = dashboard => ({
  type: SET_DASHBOARD_DATA,
  payload: { dashboard },
});

export const setWeeklyProducts = weeklyproducts => ({
  type: SET_WEEKLY_PRODUCTS_DATA,
  payload: { weeklyproducts },
});

export const setMonthlyProducts = monthlyproducts => ({
  type: SET_MONTHLY_PRODUCTS_DATA,
  payload: { monthlyproducts },
});

export const setByTimePeriod = timeperiod => ({
  type: SET_TIME_PERIOD_DATA,
  payload: { timeperiod },
});

export const setOrderReportsDaily = ordaily => ({
  type: SET_ORDER_REPORTS_DAILY_DATA,
  payload: { ordaily },
});

export const setOrderReportsWeekly = orweekly => ({
  type: SET_ORDER_REPORTS_WEEKLY_DATA,
  payload: { orweekly },
});

export const setOrderReportsMonthly = ormonthly => ({
  type: SET_ORDER_REPORTS_MONTHLY_DATA,
  payload: { ormonthly },
});

export const setOrderReportsMonthlyDashboard = ormonthlydashboard => ({
  type: SET_ORDER_REPORTS_MONTHLY_DASHBOARD_DATA,
  payload: { ormonthlydashboard },
});

export const resetOrderReportsMonthlyDashboard = () => ({
  type: RESET_ORDER_REPORTS_MONTHLY_DASHBOARD_DATA,
  payload: {},
});

export const setProductReportsMonthly = prodmonthly => ({
  type: SET_PRODUCT_REPORTS_MONTHLY_DATA,
  payload: { prodmonthly },
});

export const setProductReportsWeekly = prodweekly => ({
  type: SET_PRODUCT_REPORTS_WEEKLY_DATA,
  payload: { prodweekly },
});

export const setProductReportsDaily = proddaily => ({
  type: SET_PRODUCT_REPORTS_DAILY_DATA,
  payload: { proddaily },
});

export const setSKUData = skudata => ({
  type: SET_SKU_DATA,
  payload: { skudata },
});

export const setDailyProps = dailyprops => ({
  type: SET_DAILY_PROPS_DATA,
  payload: { dailyprops },
});

export const setDayData = data => ({
  type: SET_DAY_DATA,
  payload: { data },
});

export const setColumnSettings = reportColumnData => ({
  type: SET_COLUMN_SETTINGS,
  payload: { reportColumnData },
});

export const setExportUrl = exportdata => ({
  type: SET_EXPORT_URL_DATA,
  payload: { exportdata },
});

export const successResponse = (response, type) => (dispatch) => {
  dispatch(responseCheker(response));
  const cond = type;
  if (response) {
    switch (cond) {
      case 'dashboard':
        dispatch(setDashboard(response.data.data));
        break;
      case 'top products':
      case 'weeklyproducts':
      case 'monthlyproducts':
        dispatch(setTopSellingProducts(response.data.data));
        break;
      case 'ordaily':
        dispatch(setOrderReportsDaily(response.data));
        break;
      case 'orweekly':
        dispatch(setOrderReportsWeekly(response.data));
        break;
      case 'ormonthly':
        dispatch(setOrderReportsMonthly(response.data));
        break;
      case 'ormonthlydashboard':
        dispatch(setOrderReportsMonthlyDashboard(response.data));
        break;
      case 'prodmonthly':
        dispatch(setProductReportsMonthly(response.data));
        break;
      case 'prodweekly':
        dispatch(setProductReportsWeekly(response.data));
        break;
      case 'proddaily':
        dispatch(setProductReportsDaily(response.data));
        break;
      case 'exporturl':
        dispatch(setExportUrl(response.data));
        break;
      default:
        break;
    }
  }
  dispatch(hideActionLoading());
};

export const errorResponse = err => (dispatch) => {
  const result = dispatch(responseCheker(err.response));
  if (result === 'error') {
    const modalKey = 'alert';
    const params = {
      type: 'error',
      title: '',
      description: err.response.data.errors[0].detail,
    };
    dispatch(openAlertModal({ modalKey, params }));
  }
  if (result === 'TypeError') {
    const modalKey = 'alert';
    const params = {
      type: 'error',
      title: '',
      description: 'Something went wrong. This has been reported',
    };
    dispatch(openAlertModal({ modalKey, params }));
  }
  dispatch(hideActionLoading());
};

export const fetchMonthlySales = () => dispatch => new Promise((resolve) => {
  dispatch(showActionLoading());
  getSalesMonthly().then((response) => {
    response.json().then((json) => {
      dispatch(hideActionLoading());
      if (response.ok) {
        dispatch(setSalesReport(json.payload));
        resolve(json.payload);
      } else {
        console.log('error: ', json.errors.map(err => err.message).join(', ')); // eslint-disable-line
        resolve();
      }
    });
  });
});

export const fetchWeeklySales = () => dispatch => new Promise((resolve) => {
  dispatch(showActionLoading());
  getSalesWeekly().then((response) => {
    response.json().then((json) => {
      dispatch(hideActionLoading());
      if (response.ok) {
        dispatch(setSalesReport(json.payload));
        resolve(json.payload);
      } else {
        console.log('error: ', json.errors.map(err => err.message).join(', ')); // eslint-disable-line
        resolve();
      }
    });
  });
});

export const fetchDailySales = () => dispatch => new Promise((resolve) => {
  dispatch(showActionLoading());
  getSalesDaily().then((response) => {
    response.json().then((json) => {
      dispatch(hideActionLoading());
      if (response.ok) {
        dispatch(setSalesReport(json.payload));
        resolve(json.payload);
      } else {
        console.log('error: ', json.errors.map(err => err.message).join(', ')); // eslint-disable-line
        resolve();
      }
    });
  });
});

export const fetchOrdersReport = () => dispatch => new Promise((resolve) => {
  dispatch(showActionLoading());
  getReportsOrder().then((response) => {
    response.json().then((json) => {
      dispatch(hideActionLoading());
      if (response.ok) {
        dispatch(setOrdersReport(json.payload));
        resolve(json.payload);
      } else {
        console.log('error: ', json.errors.map(err => err.message).join(', ')); // eslint-disable-line
        resolve();
      }
    });
  });
});

export const fetchStockProducts = () => dispatch => new Promise((resolve) => {
  dispatch(showActionLoading());
  getStockProducts().then((response) => {
    response.json().then((json) => {
      dispatch(hideActionLoading());
      if (response.ok) {
        dispatch(setStockProducts(json.payload));
        resolve(json.payload);
      } else {
        console.log('error: ', json.errors.map(err => err.message).join(', ')); // eslint-disable-line
        resolve();
      }
    });
  });
});

export const fetchTopSellingProducts1 = () => dispatch => new Promise((resolve) => {
  dispatch(showActionLoading());
  getTopSellingProducts1().then((response) => {
    response.json().then((json) => {
      dispatch(hideActionLoading());
      if (response.ok) {
        dispatch(setTopSellingProducts(json.payload));
        resolve(json.payload);
      } else {
        console.log('error: ', json.errors.map(err => err.message).join(', ')); // eslint-disable-line
        resolve();
      }
    });
  });
});

export const fetchDashboard = (type, year) => (dispatch) => {
  dispatch(showActionLoading());
  getDashboard(type, year).then(
    response => dispatch(successResponse(response, 'dashboard')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchTopSellingProducts = (limit, page, date) => (dispatch) => {
  let d = null;
  if (typeof date !== 'undefined') {
    d = date;
    if (date === '') {
      d = null;
    }
  }
  dispatch(showActionLoading());
  getTopSellingProducts(limit, page, d).then(
    response => dispatch(successResponse(response, 'top products')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchWeeklyProduct = (limit, page) => (dispatch) => {
  dispatch(showActionLoading());
  getThisWeekProducts(limit, page).then(
    response => dispatch(successResponse(response, 'weeklyproducts')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchMonthlyProduct = (limit, page) => (dispatch) => {
  dispatch(showActionLoading());
  getThisMonthProducts(limit, page).then(
    response => dispatch(successResponse(response, 'monthlyproducts')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchOrderReportsDaily = (year, month, startDay, endDay) => (dispatch) => {
  const oyear = typeof year === 'undefined' ? null : year;
  const omonth = typeof month === 'undefined' ? null : month;
  const ostartDay = typeof startDay === 'undefined' ? null : startDay;
  const oendDay = typeof endDay === 'undefined' ? null : endDay;
  dispatch(showActionLoading());
  getOrderReportsDaily(oyear, omonth, ostartDay, oendDay).then(
    response => dispatch(successResponse(response, 'ordaily')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchOrderReportsWeekly = (year, month) => (dispatch) => {
  const oyear = typeof year === 'undefined' ? null : year;
  const omonth = typeof month === 'undefined' ? null : month;
  dispatch(showActionLoading());
  getOrderReportsWeekly(oyear, omonth).then(
    response => dispatch(successResponse(response, 'orweekly')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchOrderReportsMonthly = (type, threeyears, year) => (dispatch) => {
  const ytype = typeof type === 'undefined' ? null : type;
  const yyear = typeof year === 'undefined' ? null : year;
  const ythreeyears = typeof threeyears === 'undefined' ? null : threeyears;
  dispatch(showActionLoading());
  getOrderReportsMonthly(ytype, ythreeyears, yyear).then(
    response => dispatch(successResponse(response, 'ormonthly')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchOrderReportsMonthlyDashboard = (type, threeyears, year) => (dispatch) => {
  const ytype = typeof type === 'undefined' ? null : type;
  const yyear = typeof year === 'undefined' ? null : year;
  const ythreeyears = typeof threeyears === 'undefined' ? null : threeyears;
  dispatch(showActionLoading());
  getOrderReportsMonthly(ytype, ythreeyears, yyear).then(
    response => dispatch(successResponse(response, 'ormonthlydashboard')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchProductReportsMonthly = (id, type, sortType) => (dispatch) => {
  const ytype = typeof type === 'undefined' ? null : type;
  dispatch(showActionLoading());
  getProductReportsMonthly(id, ytype, sortType).then(
    response => dispatch(successResponse(response, 'prodmonthly')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchProductReportsWeekly = (id, year, month) => (dispatch) => {
  const oyear = typeof year === 'undefined' ? null : year;
  const omonth = typeof month === 'undefined' ? null : month;
  dispatch(showActionLoading());
  getProductReportsWeekly(id, oyear, omonth).then(
    response => dispatch(successResponse(response, 'prodweekly')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchProductReportsDaily = (id, year, month, startDay, endDay) => (dispatch) => {
  const oyear = typeof year === 'undefined' ? null : year;
  const omonth = typeof month === 'undefined' ? null : month;
  const ostartDay = typeof startDay === 'undefined' ? null : startDay;
  const oendDay = typeof endDay === 'undefined' ? null : endDay;
  dispatch(showActionLoading());
  getProductReportsDaily(id, oyear, omonth, ostartDay, oendDay).then(
    response => dispatch(successResponse(response, 'proddaily')),
    err => dispatch(errorResponse(err)),
  );
};

export const getExportUrl = data => (dispatch) => {
  dispatch(showActionLoading());
  generateExportUrl(data).then(
    response => dispatch(successResponse(response, 'exporturl')),
    err => dispatch(errorResponse(err)),
  );
};
