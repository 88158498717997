import axios from 'axios';
import { fuelheaders } from 'state/logged-user/actions';
import { SITE_SERVER } from './misc';

if (process.env.NODE_ENV !== 'development') {
  axios.defaults.withCredentials = true;
}

export const userRoleList = (limit, page) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/roles?limit=${limit}&page=${page}`,
  headers: fuelheaders(),
});

export const userRoleById = id => axios({
  method: 'GET',
  url: `${SITE_SERVER}/roles/${id}`,
  headers: fuelheaders(),
});

export const insertUserRole = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/roles`,
  headers: fuelheaders(),
  data: dataToSubmit,
});

export const modifyUserRole = (dataToSubmit, id) => axios({
  method: 'PUT',
  url: `${SITE_SERVER}/roles/${id}`,
  headers: fuelheaders(),
  data: dataToSubmit,
});

export const removeUserRole = (dataToSubmit, id) => axios({
  method: 'DELETE',
  url: `${SITE_SERVER}/roles/${id}`,
  headers: fuelheaders(),
  data: dataToSubmit,
});
