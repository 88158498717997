/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import styles from './DetailsStyle';

class TermsOfService extends Component {
  render() {
    const { classes } = this.props;
    return (
      <ul className={`glossary ${classes.ulist}`}>
        <li>
          <ul>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>1. Applicability</strong>
                  <br /><br />
                  The terms and conditions of these Terms of Service govern the services provided by Internet Packaging Solutions, Inc. to the Client. These Terms of Service should be read together with any CUSTOMER INTERACTION SERVICE AGREEMENT entered into between Internet Packaging Solutions, Inc. and the Client. Each CUSTOMER INTERACTION SERVICE AGREEMENT and Terms of Service (individually and collectively referred to as “Agreement”) comprises the entire agreement between the parties, and supersede all prior or contemporaneous understandings, agreements, negotiations, representations and warranties, and communications, both written and oral. If and to the extent that the Terms of Service conflicts with the CUSTOMER INTERACTION SERVICE AGREEMENT, the CUSTOMER INTERACTION SERVICE AGREEMENT shall prevail.
                  <br /><br />
                  References to ‘we’, ‘us’ and ‘our’ are to Internet Packaging Solutions, Inc. and references to ‘you’ and ‘your’ are to the Client.
                  <br /><br />
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>2. Our Services</strong>
                  <br /><br />
                  Internet Packaging Solutions, Inc. shall provide the services (the “Services”) to the Client as described in the CUSTOMER INTERACTION SERVICE AGREEMENT in accordance with these Terms of Service. Internet Packaging Solutions, Inc. will perform the Services with reasonable skill and care.
                  <br /><br />
                  We may outsource certain functions and work in relation to the Services to third party carriers, couriers, customs brokers, agents and others to which the Client’s goods and packaging materials (the “Client Goods”) are entrusted for transportation, packaging, handling, delivery, storage or otherwise (“Third Parties”) either locally or abroad. Where this occurs, we will take all reasonable steps to ensure that those Third Parties recognize and comply with their obligations of confidentiality. You consent to such outsourcing arrangements including the transfer of any personal data to such Third Parties. Internet Packaging Solutions, Inc. shall not be liable or responsible for any negligence, malpractice, fault, errors or omissions in the performance of the Services by any Third Parties.
                  <br /><br />
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>3. Client Obligations</strong>
                  <br /><br />
                  The Client shall:
                  <br /><br />
                  (i) Cooperate with Internet Packaging Solutions, Inc. in all matters relating to the Services and provide such materials and/or information as may be required by Internet Packaging Solutions, Inc. for the purposes of carrying out the Services in a timely manner and ensure that such materials or information are complete and accurate;
                  <br /><br />
                  (ii) Respond promptly to Internet Packaging Solutions, Inc.’s requests to provide direction, information, approvals, authorizations or decisions that are reasonably necessary for Internet Packaging Solutions, Inc. to carry out the Services in accordance with the requirements of these Terms of Service; and
                  <br /><br />
                  (iii) Obtain and maintain all necessary licenses and consents and comply with all applicable laws in relation to Internet Packaging Solutions, Inc.’s services before the date on which the Services are to commence.
                  <br /><br />
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>4. Carrier Rates and Shipping Charges</strong>
                  <br /><br />
                  (a) Internet Packaging Solutions, Inc. shall provide the Client with an initial shipping charges based on the carrier’s response to the information provided by the Client for shipment. Shipment shipping charges provided by Internet Packaging Solutions, Inc. to the Client are for informational purposes only and are subject to change without notice. Shipment shipping charges shall not be binding upon Internet Packaging Solutions, Inc.
                  <br /><br />
                  (b) Typically, known surcharges such as fuel surcharge and delivery area surcharges will be included in the shipping price at the time of shipment. The amount set out in the final billing invoice may differ from the initial shipment shipping charge as the final chargeable amount may vary based upon a number of factors including, inter alia, incomplete or inaccurate product or delivery information, returns to sender, changes in service levels or delivery address and additional services requested by the Client. Therefore, Internet Packaging Solutions, Inc. reserves the right to bill for, and the Client shall be responsible for, additional fees including, without limitation, customs and brokerage fees, additional delivery attempts and other carrier adjustments that are billed to Internet Packaging Solutions, Inc.
                  <br /><br />
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>5. Internet Packaging Solutions, Inc. Fulfillment Portals: Gateway.iPS or Fuel</strong>
                  <br /><br />
                  (a) As part of the Services provided by Internet Packaging Solutions, Inc., the Client may integrate or connect its online shopping cart or ecommerce platform with Internet Packaging Solutions, Inc.’s cloud-based fulfillment portal (“Gateway.iPS” or “Fuel”). The Internet Packaging Solutions, Inc. Fulfillment Portals may be updated and modified from time to time, often without prior notice to you. Your continued use of the Internet Packaging Solutions, Inc. Fulfillment Portal constitutes your acceptance of such modifications.
                  <br /><br />
                  (b) The Client may specify one or more administrators (each an “Administrator”) to manage its account on the Internet Packaging Solutions, Inc. Fulfillment Portal. Administrators have the ability to access, monitor, use, export and disclose content on the Internet Packaging Solutions, Inc. Fulfillment Portal. The Client is responsible for
                  <br /><br />
                  (i) the selection of its Administrator(s);
                  <br /><br />
                  (ii) maintaining the confidentiality of passwords and Administrator accounts;
                  <br /><br />
                  (iii) managing access to Administrator accounts; and
                  <br /><br />
                  (iv) ensuring that each Administrator’s use of the Internet Packaging Solutions, Inc. Fulfillment Portal complies with these Terms of Service. Internet Packaging Solutions, Inc. shall not be held liable for any actions on the part of the Client’s Administrator(s).
                  <br /><br />
                  (c) The Internet Packaging Solutions, Inc. Fulfillment Portal is made available to you by Internet Packaging Solutions, Inc. for the purposes of carrying out the Services, which use must be in compliance with all applicable laws, rules and regulations and must not infringe or violate third party rights.
                  <br /><br />
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>6. Inbound Goods and Receiving Policy</strong>
                  <br /><br />
                  (a) Internet Packaging Solutions, Inc. may require the Client to send product samples to Internet Packaging Solutions, Inc.’s office address for inspection before confirmation of the Inbound Date.
                  <br /><br />
                  (b) The Client shall be responsible for arranging inbound shipment of the Client Goods to Internet Packaging Solutions, Inc.’s warehouse and for all related transportation and handling charges with relevant third parties, including duties, customs or charges related to the Client Goods.
                  <br /><br />
                  (c) The Client shall be responsible for ensuring that all Client Goods are properly packaged and correctly labelled with SKU ID and quantity of units, before delivery to Internet Packaging Solutions, Inc.’s warehouse.
                  <br /><br />
                  (d) Internet Packaging Solutions, Inc. shall not be liable for any discrepancy in the quantity, quality, or condition of the Client Goods that are received at Internet Packaging Solutions, Inc.’s warehouse. Receiving checks for incoming Client Goods will be based on a carton level, provided that each carton is clearly and properly labelled, unless there is a prior agreement between the Client and Internet Packaging Solutions, Inc. for a per piece inventory count. The Client shall be responsible for insuring all inbound shipment of Client Goods.
                  <br /><br />
                  (e) Internet Packaging Solutions, Inc. may, in its sole discretion, refuse, return or dispose of any inbound shipment that does not comply with its receiving policy or is determined to be illegal, hazardous or otherwise, at the Client’s cost, and Internet Packaging Solutions, Inc. shall not be liable or responsible for any loss or damage of any nature to, or related to, such refused goods.
                  <br /><br />
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>7. Labelling and Orders Policy</strong>
                  <br /><br />
                  (a) The Client shall be responsible for providing such information as Internet Packaging Solutions, Inc. may require in accordance with the timeline set out in the CUSTOMER INTERACTION SERVICE AGREEMENT. The Client shall ensure that all information provided is accurate and complete. Any inaccurate or incomplete information may result in delays to the Fulfillment Date and Internet Packaging Solutions, Inc. shall not be liable for any resulting loss or damage.
                  <br /><br />
                  (b) The Client shall be responsible for sending all order submissions to Internet Packaging Solutions, Inc. in accordance with Internet Packaging Solutions, Inc.’s requirements and that all information provided is complete and accurate to ensure that shipments are not delayed. Internet Packaging Solutions, Inc. shall not be responsible for any order submission errors or delays by the Client. Any amendments made by the Client after
                  <br /><br />
                  (i) an order has been approved and is pending fulfillment; or
                  <br /><br />
                  (ii) the applicable cut-off time
                  <br /><br />
                  may not be processed and Internet Packaging Solutions, Inc. shall not be responsible for any shipping delays or incorrect shipments as a result thereof. Any subsequent requests by the Client to amend an order or shipping labels may result in delays and/or the Client incurring additional charges.
                  <br /><br />
                  (c) The Client shall be responsible for ensuring that all Client Goods (at both carton and unit level) have the proper SKU ID visible, to ensure proper inventory management as well as proper pick and pack and fulfillment processes. Internet Packaging Solutions, Inc. shall not be held liable for the accurate record keeping or fulfillment of Client Goods that are not properly and correctly labelled. Internet Packaging Solutions, Inc. may label any Client Goods at an additional cost to the Client.
                  <br /><br />
                  (d) Internet Packaging Solutions, Inc.’s order edit cut off time is 12 p.m. Pacific Time from Monday to Friday (herein “Cut-Off Time”). Internet Packaging Solutions, Inc. aims to process all orders that have been submitted and approved via the Fulfillment Portal, submitted before the Cut-Off Time within 24 business hours. However, order processing may be delayed during
                  <br /><br />
                  (i) peak season dates including the month of November and December;
                  <br /><br />
                  (ii) national holidays in the United States; or
                  <br /><br />
                  (iii) a day throughout or for part of which a national or local weather advisory.
                  <br /><br />
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>8. Couriers and Shipping Policy</strong>
                  <br /><br />
                  (a) Internet Packaging Solutions, Inc. shall not be responsible or liable for delays or failed deliveries and any resulting loss or damage caused by Third Parties selected to render the Services. Delays may be caused by various factors beyond Internet Packaging Solutions, Inc.’s control, such as natural disasters, strikes or peak fulfillment periods during holiday seasons. Further, Internet Packaging Solutions, Inc. cannot guarantee delivery for international shipments as customs and customs agents can delay, refuse to process, assess brokerage, lose or impose unanticipated customs, tax or duties to international shipments at their discretion.
                  <br /><br />
                  (b) Internet Packaging Solutions, Inc. shall use its best judgement in selecting the appropriate packaging and gift packaging for all orders. If the Client has provided ship-ready Client Goods or custom packaging and Internet Packaging Solutions, Inc. determines the packaging to be unfit for shipping, Internet Packaging Solutions, Inc. may offer additional packaging for an additional fee or return the Client Goods to you at your cost.
                  <br /><br />
                  (c) Internet Packaging Solutions, Inc. shall provide tracking numbers to the Client, if applicable, and shall not be held responsible for any delayed or missing tracking updates, as this information is and can only be updated by Third Parties.
                  <br /><br />
                  (d) The Client may request a proof of delivery (herein “POD”) if there is no status update from any Third Parties for 14 Business Days or if the delivery status does not indicate whether the Client Goods have been delivered after normal postal transit time of 3 to 4 weeks from the fulfillment date. Upon the Client’s request, Internet Packaging Solutions, Inc. shall use all reasonable efforts to acquire a POD on behalf of the Client from Third Parties. Internet Packaging Solutions, Inc. cannot guarantee that Third Parties will provide a POD and shall not be responsible for any delays caused by Third Parties in providing a POD.
                  <br /><br />
                  (e) All claims in connection with damaged or lost Client Goods during delivery shall be brought solely against relevant Third Parties and/or its agents. In connection with any such claim, Internet Packaging Solutions, Inc. shall reasonably assist and cooperate with the Client, which may be liable for any charges or costs incurred by Internet Packaging Solutions, Inc. As Third Parties require claims to be submitted within a specific time period, it is the Client’s responsibility to notify Internet Packaging Solutions, Inc. in advance of any claims it intends to bring.
                  <br /><br />
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>9. Duties, taxes or clearance related charges</strong>
                  <br /><br />
                  (a) The Client shall be responsible for all duties, taxes, or clearance related charges for all applicable inbound and outbound shipments of the Client Goods. If the Client chooses to send packages Delivery Duties Paid (DDP) for outbound shipments, the Client shall also be responsible for all administration fees charged by Third Parties for processing such duties, taxes or clearance related charges. Where the Client decides to ship Delivery Duties Unpaid (DDU), no administrative fees for processing duties, taxes or clearances related charges will be charged to the Client.
                  <br /><br />
                  (b) Internet Packaging Solutions, Inc. will not provide credit terms to the Client in respect of, inter alia, duties, taxes, and tariffs such as gross or general sales taxes (GST) and value added taxes (VAT) or any related charges. The Client must either prepay such amounts to Internet Packaging Solutions, Inc. prior to shipping, or place a security deposit on these amounts.
                  <br /><br />
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>10. Storage and Inventory Policy</strong>
                  <br /><br />
                  (a) The Client Goods are not insured by Internet Packaging Solutions, Inc. against any loss or damage, however caused. Internet Packaging Solutions, Inc. requires that the Client procure insurance for the Client Goods at its own cost.
                  <br /><br />
                  (b) Internet Packaging Solutions, Inc. does not anticipate inventory shrinkage for Client Goods held by Internet Packaging Solutions, Inc. However, Internet Packaging Solutions, Inc. will have an annual 5% shrinkage allowance based on the stated cost value of the Client Goods held at Internet Packaging Solutions, Inc.’s warehouse as set out in the Fulfillment Portal. In the event of inventory loss in excess of the annual inventory shrinkage allowance due to inventory count inaccuracies for which Internet Packaging Solutions, Inc. is held legally liable, Internet Packaging Solutions, Inc.’s liability shall be limited to the actual value of the Client Goods. In no event shall Internet Packaging Solutions, Inc. be liable for any lost sales revenue from the inventory loss due to inventory count inaccuracies.
                  <br /><br />
                  (c) Title to the Client Goods will remain with the Client. Notwithstanding anything herein to the contrary, nothing in this Agreement may be deemed to waive or otherwise limit any lien rights that Internet Packaging Solutions, Inc. may have under applicable law with respect to the Client Goods.
                  <br /><br />
                  (d) Internet Packaging Solutions, Inc. reserves the right to dispose or sell the Client Goods by giving 10 Business Days’ notice if the Client fails to collect any Client Goods stored in Internet Packaging Solutions, Inc.’s warehouse within a reasonable period after the due date of the final invoice.
                  <br /><br />
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>11. Fees and Payment Terms</strong>
                  <br /><br />
                  (a) The Client agrees to pay our fees as set out in our invoices in consideration of the Services provided by Internet Packaging Solutions, Inc. Our invoices are to be paid in the currency stated therein.
                  <br /><br />
                  (b) Internet Packaging Solutions, Inc. shall bill the Client on a monthly, weekly or daily basis as determined by Internet Packaging Solutions, Inc. from time to time.
                  <br /><br />
                  (c) Internet Packaging Solutions, Inc.’s service pricing is subject to change. A minimum of 30 days’ notice will be provided to the Client in writing before new prices are in effect. Fees charged by Third Parties are subject to change without notice and shall not under any circumstances be binding upon Internet Packaging Solutions, Inc.
                  <br /><br />
                  (d) The Client shall pay all invoiced amounts due to Internet Packaging Solutions, Inc. within 14 Business Days from the date of Internet Packaging Solutions, Inc.’s invoice. Internet Packaging Solutions, Inc. will notify the Client of the accepted payment methods and regularly communicate any changes to such payment methods. The Client shall be responsible for any bank remittance service charges levied and Internet Packaging Solutions, Inc. will only credit the final amount received to the Client’s account.
                  <br /><br />
                  (e) In the event payments are not received by Internet Packaging Solutions, Inc. within 14 Business Days after becoming due, Internet Packaging Solutions, Inc. reserves the right to charge interest on any such unpaid amounts at a rate of 1% per month from the date such payment was due until the date paid and suspend performance for all services until payment has been made in full.
                  <br /><br />
                  (f) Internet Packaging Solutions, Inc. may at its sole discretion, request full payment as a condition for release of the Client Goods. Internet Packaging Solutions, Inc. reserves the right to charge the Client for any reasonable charges and services incurred on behalf of the Client, including, without limitation, customs, duties, taxes, remote area delivery type charges, unexpected storage charges, revised billings from Third Parties, and ad-hoc labor requests.
                  <br /><br />
                  (g) Documentation handling fees (“Documentation Fee”) will be charged for orders that are shipped on the Client’s own courier accounts instead of Internet Packaging Solutions, Inc.’s accounts. The Documentation Fee is a handling fee only and does not replace the work of advice of a professional customs and clearance brokers. Supporting documentation may be requested and this does not waive the Documentation Fee. Internet Packaging Solutions, Inc. may publish or change the Documentation Fee at any time without notice.
                  <br /><br />
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>12. Lien</strong>
                  <br /><br />
                  Internet Packaging Solutions, Inc. shall have a lien on the Client Goods in Internet Packaging Solutions, Inc.’s actual or constructive possession, custody or control in respect of all sums of whatever nature that are due and payable by the Client to Internet Packaging Solutions, Inc., including, but not limited to, interest and legal costs and expenses. Internet Packaging Solutions, Inc. may refuse to surrender possession of the Client Goods until all sums due and payable to Internet Packaging Solutions, Inc. are paid in full. If such amounts remain unpaid for 60 days after Internet Packaging Solutions, Inc.’s demand for payment, Internet Packaging Solutions, Inc. may, at its absolute discretion, but upon ten (10) business days written notice to client, sell the Client Goods by way of public or private sale or any other method Internet Packaging Solutions, Inc. deems appropriate without further notice and apply the net proceeds to the sums owed to Internet Packaging Solutions, Inc. Any surplus from such sale shall be transferred to the Client and the rights of Internet Packaging Solutions, Inc. are reserved for any shortfall subsequent to the disposal of the Client Goods. If Internet Packaging Solutions, Inc. after a reasonable effort is unable to sell the Client Goods, after ten (10) business days' written notice to client of the same, Internet Packaging Solutions, Inc. may dispose of them in any lawful manner and shall incur no liability by reason of such disposition.
                  <br /><br />
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>13. Termination</strong>
                  <br /><br />
                  (a) Without affecting any other right or remedy provided under these Terms of Service, Internet Packaging Solutions, Inc. may at any time terminate the Agreement with immediate effect by giving written notice to the Client if the Client:
                  <br /><br />
                  (i) fails to pay any amount when due under this Agreement and such failure continues for 14 Business Days after receipt of written notice of nonpayment;
                  <br /><br />
                  (ii) has not otherwise performed or complied with any of the Terms of Service, in whole or in part;
                  <br /><br />
                  (iii) suspends, or threatens to suspend, or ceases or threatens to cease to carry on all or a substantial part of its business; or
                  <br /><br />
                  (iv) becomes insolvent, files a petition for bankruptcy or commences or has commenced against it proceedings relating to bankruptcy, receivership, reorganization or assignment for the benefit of creditors.
                  <br /><br />
                  (b) Either Internet Packaging Solutions, Inc. or the Client may, without prejudice to its other rights or remedies, terminate this Agreement for any reason upon giving 60 days’ written notice to the other party (the “Termination Notice Period"). The Client may have full or limited access to the Internet Packaging Solutions, Inc. Fulfillment Portal during the Termination Notice Period. Internet Packaging Solutions, Inc. shall continue to process and fulfill all orders which have been submitted by the Client via the Internet Packaging Solutions, Inc. Fulfillment Portal or otherwise during the Termination Notice Period.
                  <br /><br />
                  (c) Upon termination of this Agreement for any reason, the Client shall immediately pay Internet Packaging Solutions, Inc. any outstanding unpaid invoices and interest due to Internet Packaging Solutions, Inc.. Internet Packaging Solutions, Inc. shall submit invoices for any Services that it has supplied, but for which no invoice has been submitted, and the Client shall pay these invoices immediately on receipt. Internet Packaging Solutions, Inc. shall promptly refund such portion of the sums prepaid by the Client as it relates to the period after expiry or termination on a pro rata basis.
                  <br /><br />
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>14. Intellectual Property</strong>
                  <br /><br />
                  (a) Internet Packaging Solutions, Inc. shall retain ownership of all its patents, rights to inventions, copyright and related rights, trade marks, business names and domain names, rights in get-up, goodwill and the right to sue for passing off, rights in designs, database rights, rights to use, and protect the confidentiality of, confidential information (including know-how), and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, renewals or extensions of, and rights to claim priority from, such rights and all similar or equivalent rights or forms of protection which subsist or will subsist now or in the future in any part of the world (“Intellectual Property Rights”).
                  <br /><br />
                  (b) The Client shall retain ownership of all Intellectual Property Rights in the Client Goods and any other materials and data supplied by the Client to Internet Packaging Solutions, Inc.
                  <br /><br />
                  (c) Client agrees that Internet Packaging Solutions, Inc. may use and refer to Client’s company name, trademarks, service marks, trade names, image, character, logos, domain names and other distinctive brand features or identification in Internet Packaging Solutions, Inc.’s marketing and advertising materials upon approval from Client.
                  <br /><br />
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>15. Confidential Information</strong>
                  <br /><br />
                  (a) Neither Internet Packaging Solutions, Inc. nor the Client shall disclose to any person any confidential information concerning the business, affairs, customers, clients or suppliers of the other party except to:
                  <br /><br />
                  (i) its employees, officers, representatives, subcontractors or advisers who need to know such information for the purposes of carrying out the party's obligations under the Agreement. Each party shall ensure that its employees, officers, representatives, subcontractors or advisers to whom it discloses the other party's confidential information comply with this Clause 15; and
                  <br /><br />
                  (ii) as may be required by law, a court of competent jurisdiction or any governmental or regulatory authority.
                  <br /><br />
                  (b) Neither party shall use any other party's confidential information for any purpose other than to perform its obligations under the Agreement.
                  <br /><br />
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>16. Limitation of liability</strong>
                  <br /><br />
                  (a) The Client acknowledges and agrees that Internet Packaging Solutions, Inc. shall not be responsible or liable for any damage to or loss suffered by the Client whether due to non-delivery or mis-delivery or mis-direction of the Client Goods or for any delay or deviation in respect of the transportation or delivery or other handling of the Client Goods as a result of any act or omission (including, without limitation, any negligence or willful neglect or default) of any Third Parties whether or not selected by or retained by Internet Packaging Solutions, Inc..
                  <br /><br />
                  (b) Without prejudice to the Agreement, Internet Packaging Solutions, Inc. shall not be responsible or liable for any damage to or loss suffered by the Client unless it is proved that such damage or loss was due to the willful neglect or willful default of Internet Packaging Solutions, Inc. or its agents, subcontractors and personnel.
                  <br /><br />
                  (c) Subject to Clause 16(b), Internet Packaging Solutions, Inc.’s total liability to the Client, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, arising under or in connection with the Agreement shall be limited to the actual value of the Client Goods or the amount paid or payable by the Client to Internet Packaging Solutions, Inc. for the Services (excluding fees to Third Parties), whichever is less.
                  <br /><br />
                  (d) The Client agrees that, in entering into this Agreement, either it did not rely on any representations (whether written or oral) of any kind or of any person other than those expressly set out in this Agreement or (if it did rely on any representations, whether written or oral, not expressly set out in this Agreement) that it shall have no remedy in respect of such representations and (in either case) Internet Packaging Solutions, Inc. shall not in any circumstances have any liability otherwise than in accordance with the express terms of this Agreement.
                  <br /><br />
                  (e) Without prejudice to the generality of this Clause 16, Internet Packaging Solutions, Inc. shall not in any event, whether under Clause 16(b) or otherwise, be liable to the Client for any incidental, indirect, consequential loss or damage of any kind (including, without limitation, loss of market, profits, sales, agreements, contracts, anticipated savings, business or goodwill) whether in contract, tort (including negligence), for breach of statutory duty, or otherwise.
                  <br /><br />
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>17. Indemnity</strong>
                  <br /><br />
                  The Client agrees to indemnify and to hold harmless Internet Packaging Solutions, Inc. and its officers, directors, employees and agents from and against any and all claims, liabilities, damages, losses or expenses, including reasonable attorneys’ fees and costs, due to or arising out of your use of Internet Packaging Solutions, Inc.’s Services and/or the Client’s violation of any term or condition of the Agreement.
                  <br /><br />
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>18. Force Majeure</strong>
                  <br /><br />
                  (a) Neither party shall be in breach of this Agreement nor liable for delay in performing, or failure to perform, any of its obligations under this Agreement if such delay or failure result from events, circumstances or causes beyond its reasonable control. In such circumstances the affected party shall be entitled to a reasonable extension of the time for performing such obligations. If the period of delay or non-performance continues for one month, the party not affected may terminate this Agreement by giving 14 days’ notice to the affected party.
                  <br /><br />
                  (b) If termination occurs under Clause 18(a), all sums paid to Internet Packaging Solutions, Inc. by the Client under this Agreement shall be refunded to the Client, except that Internet Packaging Solutions, Inc. shall be entitled to payment on a quantum meruitbasis for all work done before termination, provided that Internet Packaging Solutions, Inc. takes all reasonable steps to mitigate the amount due.
                  <br /><br />
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>19. Severability</strong>
                  <br /><br />
                  Each clause and sub-clause of the Agreement shall be independently interpreted and enforceable. If any clause or sub-clause of the Agreement is declared void, illegal or otherwise unenforceable by a court of competent jurisdiction, the remainder shall survive unaffected.
                  <br /><br />
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>20. Counterparts</strong>
                  <br /><br />
                  This Agreement may be executed in any number of counterparts, each of which when executed and delivered shall constitute a duplicate original, but all the counterparts shall together constitute the one Agreement.
                  <br /><br />
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>21. Waiver</strong>
                  <br /><br />
                  Our failure to exercise or enforce any right or provision of this Agreement, including any failure to act with respect to a breach, will not constitute a waiver of such right or provision or our right to act with respect to subsequent or similar breaches.
                  <br /><br />
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>22. Amendments and Modifications</strong>
                  <br /><br />
                  (a) Any variation to the Services set out in the CUSTOMER INTERACTION SERVICE AGREEMENT shall not be effective unless it is in writing and signed by the parties (or their authorized representatives).
                  <br /><br />
                  (b) Internet Packaging Solutions, Inc. may amend or replace these Terms of Service from time to time. In such circumstances we shall notify you of the changes and the new or amended Terms of Service will become part of the Agreement 14 days after.
                  <br /><br />
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>23. Notices</strong>
                  <br /><br />
                  Any notices or other communications permitted or required hereunder, including those regarding modifications to these Terms of Service and notices related to the Client’s account, will be in writing and given via email using the email address associated with your account. The date of receipt will be deemed the date on which such notice is transmitted.
                  <br /><br />
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>24. Disputes: Mediation and Arbitration</strong>
                  <br /><br />
                  (a) Should any dispute or claim arise out of or in connection with the Services or the Agreement, we and you will attempt to resolve the dispute or claim in good faith in the first instance by negotiation.
                  <br /><br />
                  (b) If such negotiation is unsuccessful, then any dispute, controversy, difference or claim arising out of or relating to this Agreement, including the existence, validity, interpretation, performance, breach or termination thereof or any dispute regarding non-contractual obligations arising out of or relating to it, the parties agree to first attempt to settle such dispute through good-faith mediation in accordance with the procedures of the United States Arbitration & Mediation Association, through the offices of a mediator acceptable to both parties. The parties agree to share equally in the costs of mediation.
                  <br /><br />
                  (c) If it is not possible to resolve the dispute or claim by negotiation or mediation, then the parties would then participate in binding arbitration, using an arbitrator acceptable to both parties and subject to the laws of the State of California without respect to conflicts of laws principles.
                  <br /><br />
                </span>
              </Typography>
            </li>
            <li>
              <Typography className={`${classes.txt} ${classes.txt2}`}>
                <span>
                  <strong>25. Governing Law</strong>
                  <br /><br />
                  Any dispute or claim arising out of or in connection with this Agreement or its subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of the State of California.
                  <br /><br />
                </span>
              </Typography>
            </li>
          </ul>
        </li>
      </ul>
    );
  }
}

TermsOfService.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(TermsOfService);

