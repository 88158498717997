import React, { Component } from 'react';
import { Grid, Button, Fab, CircularProgress, Typography, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { update, isFormValid, populateFields, validateInputs } from 'ui/components/form/FormActions';
import { ReactComponent as Close } from 'resources/icons/closev2.svg';
import FormField from 'ui/components/form/FormField';
import AddZipCityState from 'ui/components/form/AddZipCityState';
import styles from 'ui/components/form/InputFormStyle';
import OrderFormStyle from 'ui/pages/orders/OrderFormStyle';

class AddNewVendorForm extends Component {
  state = {
    loading: false,
    formdata: {
      name: {
        element: 'input',
        value: '',
        config: {
          label: 'Vendor Contact Name',
          name: 'contact_name',
          type: 'text',
        },
        validation: {
          required: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
        addeditform: true,
      },
      company: {
        element: 'input',
        value: '',
        config: {
          label: 'Vendor Company Name *',
          name: 'company',
          type: 'text',
        },
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        validationMessage: '',
        addeditform: true,
      },
      phone: {
        element: 'input',
        value: '',
        config: {
          label: 'Phone',
          name: 'phone',
          type: 'text',
        },
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
        validationMessage: '',
        addeditform: true,
      },
      email: {
        element: 'input',
        value: '',
        config: {
          name: 'email',
          type: 'email',
          label: 'Email',
        },
        validation: {
          required: false,
          email: true,
        },
        valid: true,
        touched: false,
        validationMessage: '',
      },
    },
  }
  componentDidMount() {
    const { edit, editdata } = this.props;
    if (typeof editdata !== 'undefined' && Object.keys(editdata).length) {
      let data = {};
      if (edit === 'vendor') {
        data = {
          name: editdata.name,
          company: editdata.company_name,
          phone: editdata.phone,
          email: editdata.email,
        };
      }
      populateFields(this.state.formdata, data);
    }
  }

  componentDidUpdate() {
    const {
      getloadtype, setloadtype, actionloading, addVendorClicked,
    } = this.props;
    if (getloadtype === 'address data') {
      this.submitVendorForm();
    }
    if (!actionloading && getloadtype === 'add vendor') {
      setloadtype('');
      setTimeout(() => {
        this.setState({
          ...this.state,
          loading: false,
        });
      }, 1);
      addVendorClicked();
    }
    if (!actionloading && getloadtype === 'error message') {
      setloadtype('');
      setTimeout(() => {
        this.setState({
          ...this.state,
          loading: false,
        });
      }, 1);
    }
  }

  updateForm = (element) => {
    const newFormdata = update(element, this.state.formdata, 'vendor');
    if (element.id === 'email') {
      if (!newFormdata.email.validation.required && newFormdata.email.value === '') {
        newFormdata.email.valid = true;
      }
    }
    this.setState({
      ...this.state,
      formdata: newFormdata,
    });
  }

  submitForm = () => {
    this.child.submitAddress();
    this.setState({
      ...this.state,
      loading: true,
    });
  }

  submitVendorForm = () => {
    const {
      addressData, addvendor, setloadtype, editdata, savevendor,
    } = this.props;
    const formIsValid = isFormValid(this.state.formdata, 'vendor');
    if (formIsValid) {
      if (addressData.isvalid) {
        const data = {
          company_name: this.state.formdata.company.value,
          status: 'A',
          address1: addressData.formdata.address.value,
          country_code: addressData.formdata.country.value,
        };
        if (this.state.formdata.name.value.trim() !== '') {
          data.name = this.state.formdata.name.value;
        }
        if (this.state.formdata.email.value.trim() !== '') {
          data.email = this.state.formdata.email.value;
        }
        if (this.state.formdata.phone.value.trim() !== '') {
          data.phone = this.state.formdata.phone.value;
        }
        if (addressData.formdata.city.value.trim() !== '') {
          data.city = addressData.formdata.city.value;
        }
        if (addressData.formdata.state.value.trim() !== '') {
          data.state = addressData.formdata.state.value;
        }
        if (addressData.formdata.zip.value.trim() !== '') {
          data.zip = addressData.formdata.zip.value;
        }
        /* addvendor({}); */
        if (typeof editdata !== 'undefined' && Object.keys(editdata).length) {
          savevendor(editdata.id, data);
        } else {
          addvendor(data);
        }
        setloadtype('add vendor');
        /* console.log(data); // eslint-disable-line */
      } else {
        this.setState({
          ...this.state,
          loading: false,
        });
        setloadtype('');
      }
    } else {
      const newFormData = validateInputs(this.state.formdata);
      this.setState({
        loading: false,
        formdata: newFormData,
      });
      setloadtype('');
    }
  }
  render() {
    const { loading } = this.state;
    const {
      classes, cancelClicked, edit, editdata,
    } = this.props;
    return (
      <div className={classes.anvcontainer}>
        <div className={classes.anvheader}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Typography className={`htitle ${classes.title}`}>New Vendor</Typography>
            </Grid>
            <Grid className="right" item xs={6}>
              <div className={classes.btn}>
                <Button
                  className={`${classes.modalBtn} ${classes.modalAddBtn}`}
                  classes={{ focusVisible: classes.focusbtn }}
                  variant="contained"
                  color="secondary"
                  disabled={loading}
                  onClick={this.submitForm}
                >
                  Add
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
              <IconButton
                className={`${classes.icon} ${classes.iconbtn} ${classes.modclosebtn}`}
                aria-label="Close"
                onClick={cancelClicked}
              >
                <Close className={classes.closev2} />
              </IconButton>
            </Grid>
          </Grid>
        </div>
        <div className={`sc2content ${classes.sc} ${classes.sc2} ${classes.boxshadow}`}>
          <Grid className={`${classes.gridContainer} ${classes.desktopdisplay}`} container spacing={0}>
            <Typography className={classes.title}>Add New Vendor</Typography>
          </Grid>
          <Grid className={classes.gridContainer} container spacing={0}>
            <Grid className={classes.col1a} item xs={6}>
              <FormField
                id="company"
                formdata={this.state.formdata.company}
                change={element => this.updateForm(element)}
              />
              <AddZipCityState
                formfor="add purchase order"
                onRef={(instance) => { this.child = instance; }}
                edit={edit}
                editdata={editdata}
              />
            </Grid>
            <Grid className={classes.col3a} item xs={6}>
              <FormField
                id="name"
                formdata={this.state.formdata.name}
                change={element => this.updateForm(element)}
              />
              <FormField
                id="phone"
                formdata={this.state.formdata.phone}
                change={element => this.updateForm(element)}
              />
              <FormField
                id="email"
                formdata={this.state.formdata.email}
                change={element => this.updateForm(element)}
              />
              <div className={`${classes.btnswrapper} ${classes.desktopdisplay}`}>
                <div className={classes.btn}>
                  <Button
                    className={classes.modalBtn}
                    variant="contained"
                    color="secondary"
                    disabled={loading}
                    onClick={this.submitForm}
                  >
                    { typeof editdata !== 'undefined' && Object.keys(editdata).length ? 'Update' : 'Add Vendor' }
                  </Button>
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
                <Fab onClick={cancelClicked} variant="extended" aria-label="Close" className={classes.close}>
                  Cancel
                </Fab>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

AddNewVendorForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  cancelClicked: PropTypes.func.isRequired,
  addVendorClicked: PropTypes.func.isRequired,
  addressData: PropTypes.object, // eslint-disable-line
  getloadtype: PropTypes.string.isRequired,
  setloadtype: PropTypes.func.isRequired,
  addvendor: PropTypes.func.isRequired,
  savevendor: PropTypes.func.isRequired,
  actionloading: PropTypes.bool.isRequired,
  edit: PropTypes.string, // eslint-disable-line
  editdata: PropTypes.object, // eslint-disable-line
};

export default withStyles(theme => ({
  ...styles(theme),
  ...OrderFormStyle(theme),
}))(AddNewVendorForm);
