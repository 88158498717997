import {
  postAddProduct,
  requestProducts,
  requestProductsReport,
  requestProductDetails,
  autoSearchProduct,
  requestProductTypes,
  postAddVendor,
  updateVendor,
  postAddVendorOrders,
  autoSearchVendor,
  postAddManufacturer,
  autoSearchManufacturer,
  lastImportProducts,
  importWMSProducts,
  requestProductSalesOrder,
  stockAlertListing,
  addProductStockAlert,
  updateProductStockAlert,
  deleteProductStockAlert,
  stockAlertRecipients,
  addUpdateStockAlertRecipients,
  requestSupplyReceipts,
  requestSupplyReceiptsReport,
  getSupplyReceiptInfo,
  autoSearchFreight,
  postFreight,
  cancelVendorOrder,
  settingsStockAlert,
  saveSettingsStockAlert,
  postCSVProduct,
  saveCSVProduct,
} from 'api/products';
import { showActionLoading, hideActionLoading, showLoading, hideLoading, setLoadType } from 'state/loading/actions';
import responseCheker from 'api/responseCheker';
import { openAlertModal } from 'state/modal-drawer/actions';

import {
  SET_PRODUCTS_DATA,
  SET_MORE_PRODUCTS_DATA,
  SET_PRODUCT_DETAILS_DATA,
  RESET_PRODUCT_DETAILS_DATA,
  RESET_PRODUCTS_DATA,
  SET_PRODUCT_TYPES_DATA,
  SET_PRODUCT_INFO_DATA,
  SET_VENDOR_DATA,
  SET_VENDOR_ORDERS_DATA,
  SET_VENDOR_AUTOCOMPLETE_DATA,
  RESET_VENDOR_AUTOCOMPLETE_DATA,
  SET_MANUFACTURER_DATA,
  SET_MANUFACTURER_AUTOCOMPLETE_DATA,
  RESET_MANUFACTURER_AUTOCOMPLETE_DATA,
  SET_PRODUCT_AUTOCOMPLETE_DATA,
  RESET_PRODUCT_AUTOCOMPLETE_DATA,
  SET_FILTERS_DATA,
  SET_IMPORT_INFO_DATA,
  SET_IMPORT_PRODUCTS_DATA,
  SET_SALES_ORDER_DATA,
  SET_MORE_SALES_ORDER_DATA,
  SET_SALES_ORDER_PAGE_DATA,
  SET_STOCK_ALERT_DATA,
  SET_MORE_STOCK_ALERT_DATA,
  ADD_STOCK_ALERT_DATA,
  SET_STOCK_RECIPIENT_DATA,
  SET_RECEIPTS_DATA,
  SET_MORE_RECEIPTS_DATA,
  SET_FREIGHT_AUTOCOMPLETE_DATA,
  RESET_FREIGHT_AUTOCOMPLETE_DATA,
  SET_FREIGHT_DATA,
  SET_SUPPLY_RECEIPT_DETAILS_DATA,
  RESET_SUPPLY_RECEIPT_DETAILS_DATA,
  SET_STOCK_ALERT_SETTINGS_DATA,
  SET_PRODUCTS_FILE_DATA,
  SET_CSV_PRODUCT_DATA,
  SET_IMPORT_CSV_PRODUCT_DATA,
} from './types';

export const setProducts = products => ({
  type: SET_PRODUCTS_DATA,
  payload: { products },
});

export const setMoreProducts = products => ({
  type: SET_MORE_PRODUCTS_DATA,
  payload: { products },
});

export const setSupplyReceipts = receipts => ({
  type: SET_RECEIPTS_DATA,
  payload: { receipts },
});

export const setMoreSupplyReceipts = receipts => ({
  type: SET_MORE_RECEIPTS_DATA,
  payload: { receipts },
});

export const setProductDetails = detail => ({
  type: SET_PRODUCT_DETAILS_DATA,
  payload: { detail },
});

export const resetProductDetails = () => ({
  type: RESET_PRODUCT_DETAILS_DATA,
});

export const setSupplyReceiptDetails = supplyreceiptdetail => ({
  type: SET_SUPPLY_RECEIPT_DETAILS_DATA,
  payload: { supplyreceiptdetail },
});

export const resetSupplyReceiptDetails = () => ({
  type: RESET_SUPPLY_RECEIPT_DETAILS_DATA,
});

export const setProductTypes = prodTypes => ({
  type: SET_PRODUCT_TYPES_DATA,
  payload: { prodTypes },
});

export const setProductInfo = prodInfo => ({
  type: SET_PRODUCT_INFO_DATA,
  payload: { prodInfo },
});

export const setVendor = vendorData => ({
  type: SET_VENDOR_DATA,
  payload: { vendorData },
});

export const setVendorOrders = vendorOrdersData => ({
  type: SET_VENDOR_ORDERS_DATA,
  payload: { vendorOrdersData },
});

export const setVendorAutocomplete = autocompleteVendor => ({
  type: SET_VENDOR_AUTOCOMPLETE_DATA,
  payload: { autocompleteVendor },
});

export const resetVendorAutocomplete = () => ({
  type: RESET_VENDOR_AUTOCOMPLETE_DATA,
});

export const setManufacturer = manufacturerData => ({
  type: SET_MANUFACTURER_DATA,
  payload: { manufacturerData },
});

export const setManufacturerAutocomplete = autocompleteManufacturer => ({
  type: SET_MANUFACTURER_AUTOCOMPLETE_DATA,
  payload: { autocompleteManufacturer },
});

export const resetManufacturerAutocomplete = () => ({
  type: RESET_MANUFACTURER_AUTOCOMPLETE_DATA,
});

export const setProductAutocomplete = autocompleteProduct => ({
  type: SET_PRODUCT_AUTOCOMPLETE_DATA,
  payload: { autocompleteProduct },
});

export const resetProductAutocomplete = () => ({
  type: RESET_PRODUCT_AUTOCOMPLETE_DATA,
});

export const resetProducts = () => ({
  type: RESET_PRODUCTS_DATA,
});

export const setFilters = filters => ({
  type: SET_FILTERS_DATA,
  payload: { filters },
});

export const setImportInfo = importinfo => ({
  type: SET_IMPORT_INFO_DATA,
  payload: { importinfo },
});

export const setImportProducts = importproducts => ({
  type: SET_IMPORT_PRODUCTS_DATA,
  payload: { importproducts },
});

export const setSalesOrder = salesorder => ({
  type: SET_SALES_ORDER_DATA,
  payload: { salesorder },
});

export const setMoreSalesOrder = salesorder => ({
  type: SET_MORE_SALES_ORDER_DATA,
  payload: { salesorder },
});

export const setSalesOrderPage = salesorderpage => ({
  type: SET_SALES_ORDER_PAGE_DATA,
  payload: { salesorderpage },
});

export const setStocks = stockalerts => ({
  type: SET_STOCK_ALERT_DATA,
  payload: { stockalerts },
});

export const setMoreStocks = stockalerts => ({
  type: SET_MORE_STOCK_ALERT_DATA,
  payload: { stockalerts },
});

export const addStock = addstock => ({
  type: ADD_STOCK_ALERT_DATA,
  payload: { addstock },
});

export const setStockRecipient = stockrecipient => ({
  type: SET_STOCK_RECIPIENT_DATA,
  payload: { stockrecipient },
});

export const setFreightAutocomplete = autocompleteFreight => ({
  type: SET_FREIGHT_AUTOCOMPLETE_DATA,
  payload: { autocompleteFreight },
});

export const resetFreightAutocomplete = () => ({
  type: RESET_FREIGHT_AUTOCOMPLETE_DATA,
});

export const setFreight = freightData => ({
  type: SET_FREIGHT_DATA,
  payload: { freightData },
});

export const setStocksettings = stockSettings => ({
  type: SET_STOCK_ALERT_SETTINGS_DATA,
  payload: { stockSettings },
});

export const setProductsFile = productsfile => ({
  type: SET_PRODUCTS_FILE_DATA,
  payload: { productsfile },
});

export const setCSVProduct = csvproduct => ({
  type: SET_CSV_PRODUCT_DATA,
  payload: { csvproduct },
});

export const setImportCSVProduct = importcsvproduct => ({
  type: SET_IMPORT_CSV_PRODUCT_DATA,
  payload: { importcsvproduct },
});

export const clearImportCSVProduct = () => ({
  type: SET_IMPORT_CSV_PRODUCT_DATA,
  payload: { importcsvproduct: {} },
});

export const successResponse = (response, type) => (dispatch) => {
  dispatch(responseCheker(response));
  let cond = type;
  if (response) {
    if (cond === 'list' || cond === 'slist') {
      dispatch(setProducts(response.data));
    }
    if (cond === 'morelist') {
      dispatch(setMoreProducts(response.data));
    }
    if (cond === 'listreceipts' || cond === 'slistreceipts') {
      dispatch(setSupplyReceipts(response.data));
    }
    if (cond === 'morelistreceipts') {
      dispatch(setMoreSupplyReceipts(response.data));
    }
    if (cond === 'detail') {
      dispatch(setProductDetails(response.data));
    }
    if (cond === 'srdetail') {
      dispatch(setSupplyReceiptDetails(response.data));
    }
    if (cond === 'prodtypes') {
      dispatch(setProductTypes(response.data));
    }
    if (cond === 'addvendor') {
      dispatch(setVendor(response.data.data));
    }
    if (cond === 'addvendororders') {
      dispatch(setVendorOrders(response.data.data));
    }
    if (cond === 'autocomplete') {
      dispatch(setVendorAutocomplete(response.data.data));
    }
    if (cond === 'addmanufacturer') {
      dispatch(setManufacturer(response.data.data));
    }
    if (cond === 'automanufacturer') {
      dispatch(setManufacturerAutocomplete(response.data.data));
    }
    if (cond === 'autoproduct') {
      dispatch(setProductAutocomplete(response.data.data));
    }
    if (cond === 'importsinfo') {
      dispatch(setImportInfo(response.data));
    }
    if (cond === 'importproducts') {
      dispatch(setImportProducts(response.data));
    }
    if (cond === 'salesorder') {
      dispatch(setSalesOrder(response.data));
    }
    if (cond === 'moresalesorder') {
      dispatch(setMoreSalesOrder(response.data));
    }
    if (cond === 'stocks') {
      dispatch(setStocks(response.data));
    }
    if (cond === 'morestocks') {
      dispatch(setMoreStocks(response.data));
    }
    if (cond === 'addstock') {
      dispatch(addStock(response.data.data));
    }
    if (cond === 'stockrecipient') {
      dispatch(setStockRecipient(response.data));
    }
    if (cond === 'autocompletefreight') {
      dispatch(setFreightAutocomplete(response.data.data));
    }
    if (cond === 'addfreight') {
      dispatch(setFreight(response.data.data));
    }
    if (cond === 'stocksettings') {
      dispatch(setStocksettings(response.data.data));
    }
    if (cond === 'reviewcsvproduct') {
      dispatch(setCSVProduct(response.data.data));
    }
    if (cond === 'importcsvproduct') {
      dispatch(setImportCSVProduct(response.data.data));
    }
  }

  switch (cond) {
    case 'slist':
    case 'morelist':
    case 'detail':
    case 'addproduct':
    case 'autoproduct':
    case 'prodtypes':
    case 'autocomplete':
    case 'addmanufacturer':
    case 'automanufacturer':
    case 'addvendororders':
    case 'importsinfo':
    case 'importproducts':
    case 'salesorder':
    case 'moresalesorder':
    case 'stocks':
    case 'morestocks':
    case 'addstock':
    case 'deletestock':
    case 'stockrecipient':
    case 'autocompletefreight':
    case 'addfreight':
    case 'cancelpo':
    case 'srdetail':
    case 'reviewcsvproduct':
    case 'importcsvproduct':
    case 'addvendor':
      cond = false;
      break;
    default:
      break;
  }

  if (cond) {
    dispatch(hideLoading());
  }
  dispatch(hideActionLoading());
};

export const errorResponse = (err, type = true, bypassError = false) => (dispatch) => {
  const result = dispatch(responseCheker(err.response));
  if (result === 'error') {
    if (!bypassError) {
      let errmsg = err.response.data.errors[0].detail;
      if (errmsg === 'The address1 must be a string.') {
        errmsg = 'Invalid address entry';
      }
      if (errmsg === 'The zip must be a string.') {
        errmsg = 'Zip code required';
      }
      if (errmsg === 'The po no has already been taken.') {
        errmsg = 'PO # already in use. Please select a new PO number';
      }
      if (errmsg === 'The selected product id is has already been added.') {
        errmsg = 'SKU already been added. Please try another one';
      }
      if (errmsg.indexOf('The max quantity must be greater than') !== -1) {
        errmsg = errmsg.replace('The max quantity', 'Over Stock Alert Quantity');
      }
      const modalKey = 'alert';
      const params = {
        type: 'error',
        title: '',
        description: errmsg,
      };
      dispatch(openAlertModal({ modalKey, params }));
      dispatch(setLoadType('error message'));
    } else {
      dispatch(setLoadType(err.response.data.errors[0].detail));
    }
  }
  if (result === 'TypeError') {
    const modalKey = 'alert';
    const params = {
      type: 'error',
      title: '',
      description: 'Something went wrong. This has been reported',
    };
    dispatch(openAlertModal({ modalKey, params }));
  }
  if (type) {
    dispatch(hideLoading());
  }
  dispatch(hideActionLoading());
};

export const addProduct = (dataToSubmit, id = null) => (dispatch) => {
  dispatch(showActionLoading());
  postAddProduct(dataToSubmit, id).then(
    response => dispatch(successResponse(response, 'addproduct')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const fetchProductList = (
  limit = 20,
  page = 1,
  type = null,
  text,
  vendorId,
  sortColumn,
  sortType,
) => (dispatch) => {
  let cond = 'list';
  let txt = null;
  let vid = null;
  let loadAction = false;
  let sc = null;
  let st = null;
  if (typeof vendorId !== 'undefined') {
    vid = vendorId;
    if (vendorId === '') {
      vid = null;
    }
    loadAction = true;
  }
  if (typeof text !== 'undefined') {
    txt = text;
    if (text === '') {
      txt = null;
    }
    cond = 'slist';
    loadAction = true;
  }
  if (typeof sortColumn !== 'undefined') {
    sc = sortColumn;
    if (sortColumn === '') {
      sc = null;
    }
  }
  if (typeof sortType !== 'undefined') {
    st = sortType;
    if (sortType === '') {
      st = null;
    }
  }
  if (loadAction) {
    dispatch(showActionLoading());
  } else {
    dispatch(showLoading());
  }
  requestProducts(limit, page, type, txt, vid, sc, st).then(
    response => dispatch(successResponse(response, cond)),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchMoreProductList = (
  limit = 20,
  page = 1,
  type = null,
  text,
  vendorId,
  sortColumn,
  sortType,
) => (dispatch) => {
  let txt = null;
  let vid = null;
  if (typeof vendorId !== 'undefined') {
    vid = vendorId;
    if (vid === '') {
      vid = null;
    }
  }
  if (typeof text !== 'undefined') {
    txt = text;
    if (text === '') {
      txt = null;
    }
  }
  dispatch(showActionLoading());
  requestProducts(limit, page, type, txt, vid, sortColumn, sortType).then(
    response => dispatch(successResponse(response, 'morelist')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchProductListReport = (
  limit = 20,
  page = 1,
  type = null,
  text,
  sku,
  description,
  sortColumn,
  sortType,
) => (dispatch) => {
  let cond = 'list';
  let txt = null;
  let psku = null;
  let pdesc = null;
  let loadAction = false;
  let sc = null;
  let st = null;
  if (typeof sku !== 'undefined') {
    psku = sku;
    if (sku === '') {
      psku = null;
    }
    loadAction = true;
  }
  if (typeof description !== 'undefined') {
    pdesc = description;
    if (description === '') {
      pdesc = null;
    }
    loadAction = true;
  }
  if (typeof text !== 'undefined') {
    txt = text;
    if (text === '') {
      txt = null;
    }
    cond = 'slist';
    loadAction = true;
  }
  if (typeof sortColumn !== 'undefined') {
    sc = sortColumn;
    if (sortColumn === '') {
      sc = null;
    }
  }
  if (typeof sortType !== 'undefined') {
    st = sortType;
    if (sortType === '') {
      st = null;
    }
  }
  if (loadAction) {
    dispatch(showActionLoading());
  } else {
    dispatch(showLoading());
  }
  requestProductsReport(limit, page, type, txt, psku, pdesc, sc, st).then(
    response => dispatch(successResponse(response, cond)),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchMoreProductListReport = (
  limit = 20,
  page = 1,
  type = null,
  text,
  sku,
  description,
  sortColumn,
  sortType,
) => (dispatch) => {
  let txt = null;
  let psku = null;
  let pdesc = null;
  if (typeof sku !== 'undefined') {
    psku = sku;
    if (psku === '') {
      psku = null;
    }
  }
  if (typeof description !== 'undefined') {
    pdesc = description;
    if (pdesc === '') {
      pdesc = null;
    }
  }
  if (typeof text !== 'undefined') {
    txt = text;
    if (text === '') {
      txt = null;
    }
  }
  dispatch(showActionLoading());
  requestProductsReport(limit, page, type, txt, psku, pdesc, sortColumn, sortType).then(
    response => dispatch(successResponse(response, 'morelist')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchSupplyReceipts = (
  limit = 20,
  page = 1,
  type = null,
  text,
  start,
  end,
  vendor,
  sortColumn,
  sortType,
) => (dispatch) => {
  let cond = 'listreceipts';
  let txt = null;
  let dstart = null;
  let dend = null;
  let vname = null;
  let loadAction = false;
  let sc = null;
  let st = null;
  if (typeof start !== 'undefined') {
    dstart = start;
    if (start === '') {
      dstart = null;
    }
    loadAction = true;
  }
  if (typeof end !== 'undefined') {
    dend = end;
    if (end === '') {
      dend = null;
    }
    loadAction = true;
  }
  if (typeof vendor !== 'undefined') {
    vname = vendor;
    if (vendor === '') {
      vname = null;
    }
    loadAction = true;
  }
  if (typeof text !== 'undefined') {
    txt = text;
    if (text === '') {
      txt = null;
    }
    cond = 'slistreceipts';
    loadAction = true;
  }
  if (typeof sortColumn !== 'undefined') {
    sc = sortColumn;
    if (sortColumn === '') {
      sc = null;
    }
  }
  if (typeof sortType !== 'undefined') {
    st = sortType;
    if (sortType === '') {
      st = null;
    }
  }
  if (loadAction) {
    dispatch(showActionLoading());
  } else {
    dispatch(showLoading());
  }
  requestSupplyReceipts(limit, page, type, txt, dstart, dend, vname, sc, st).then(
    response => dispatch(successResponse(response, cond)),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchMoreSupplyReceipts = (
  limit = 20,
  page = 1,
  type = null,
  text,
  start,
  end,
  vendor,
  sortColumn,
  sortType,
) => (dispatch) => {
  let txt = null;
  let dstart = null;
  let dend = null;
  let vname = null;
  if (typeof start !== 'undefined') {
    dstart = start;
    if (start === '') {
      dstart = null;
    }
  }
  if (typeof end !== 'undefined') {
    dend = end;
    if (end === '') {
      dend = null;
    }
  }
  if (typeof vendor !== 'undefined') {
    vname = vendor;
    if (vname === '') {
      vname = null;
    }
  }
  if (typeof text !== 'undefined') {
    txt = text;
    if (text === '') {
      txt = null;
    }
  }
  dispatch(showActionLoading());
  requestSupplyReceipts(limit, page, type, txt, dstart, dend, vname, sortColumn, sortType).then(
    response => dispatch(successResponse(response, 'morelistreceipts')),
    err => dispatch(errorResponse(err)),
  );
};


export const fetchSupplyReceiptsReport = (
  limit = 20,
  page = 1,
  type = null,
  text,
  start,
  end,
  vendor,
  po,
  sku,
  sortColumn,
  sortType,
) => (dispatch) => {
  let cond = 'listreceipts';
  let txt = null;
  let dstart = null;
  let dend = null;
  let vname = null;
  let vpo = null;
  let vsku = null;
  let loadAction = false;
  let sc = null;
  let st = null;
  if (typeof start !== 'undefined') {
    dstart = start;
    if (start === '') {
      dstart = null;
    }
    loadAction = true;
  }
  if (typeof end !== 'undefined') {
    dend = end;
    if (end === '') {
      dend = null;
    }
    loadAction = true;
  }
  if (typeof vendor !== 'undefined') {
    vname = vendor;
    if (vendor === '') {
      vname = null;
    }
    loadAction = true;
  }
  if (typeof po !== 'undefined') {
    vpo = po;
    if (po === '') {
      vpo = null;
    }
    loadAction = true;
  }
  if (typeof sku !== 'undefined') {
    vsku = sku;
    if (sku === '') {
      vsku = null;
    }
    loadAction = true;
  }
  if (typeof text !== 'undefined') {
    txt = text;
    if (text === '') {
      txt = null;
    }
    cond = 'slistreceipts';
    loadAction = true;
  }
  if (typeof sortColumn !== 'undefined') {
    sc = sortColumn;
    if (sortColumn === '') {
      sc = null;
    }
  }
  if (typeof sortType !== 'undefined') {
    st = sortType;
    if (sortType === '') {
      st = null;
    }
  }
  if (loadAction) {
    dispatch(showActionLoading());
  } else {
    dispatch(showLoading());
  }
  requestSupplyReceiptsReport(limit, page, type, txt, dstart, dend, vname, vpo, vsku, sc, st).then(
    response => dispatch(successResponse(response, cond)),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchMoreSupplyReceiptsReport = (
  limit = 20,
  page = 1,
  type = null,
  text,
  start,
  end,
  vendor,
  po,
  sku,
  sortColumn,
  sortType,
) => (dispatch) => {
  let txt = null;
  let dstart = null;
  let dend = null;
  let vname = null;
  let vpo = null;
  let vsku = null;
  if (typeof start !== 'undefined') {
    dstart = start;
    if (start === '') {
      dstart = null;
    }
  }
  if (typeof end !== 'undefined') {
    dend = end;
    if (end === '') {
      dend = null;
    }
  }
  if (typeof vendor !== 'undefined') {
    vname = vendor;
    if (vname === '') {
      vname = null;
    }
  }
  if (typeof po !== 'undefined') {
    vpo = po;
    if (vpo === '') {
      vpo = null;
    }
  }
  if (typeof sku !== 'undefined') {
    vsku = sku;
    if (vsku === '') {
      vsku = null;
    }
  }
  if (typeof text !== 'undefined') {
    txt = text;
    if (text === '') {
      txt = null;
    }
  }
  dispatch(showActionLoading());
  requestSupplyReceiptsReport(
    limit, page, type, txt, dstart, dend, vname, vpo, vsku,
    sortColumn, sortType,
  ).then(
    response => dispatch(successResponse(response, 'morelistreceipts')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchSupplyReceiptDetail = id => (dispatch) => {
  dispatch(showActionLoading());
  getSupplyReceiptInfo(id).then(
    response => dispatch(successResponse(response, 'srdetail')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchProductDetail = id => (dispatch) => {
  dispatch(showActionLoading());
  requestProductDetails(id).then(
    response => dispatch(successResponse(response, 'detail')),
    err => dispatch(errorResponse(err)),
  );
};

export const autocompleteProduct = (txt, limit = 20, page = 1) => (dispatch) => {
  dispatch(showActionLoading());
  autoSearchProduct(txt, limit, page).then(
    response => dispatch(successResponse(response, 'autoproduct')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const addVendor = dataToSubmit => (dispatch) => {
  dispatch(showActionLoading());
  postAddVendor(dataToSubmit).then(
    response => dispatch(successResponse(response, 'addvendor')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const saveVendor = (id, dataToSubmit) => (dispatch) => {
  dispatch(showActionLoading());
  updateVendor(id, dataToSubmit).then(
    response => dispatch(successResponse(response, 'addvendor')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const addVendorOrders = dataToSubmit => (dispatch) => {
  dispatch(showActionLoading());
  postAddVendorOrders(dataToSubmit).then(
    response => dispatch(successResponse(response, 'addvendororders')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const autocompleteVendor = (txt, limit = 20, page = 1) => (dispatch) => {
  dispatch(showActionLoading());
  autoSearchVendor(txt, limit, page).then(
    response => dispatch(successResponse(response, 'autocomplete')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const addManufacturer = dataToSubmit => (dispatch) => {
  dispatch(showActionLoading());
  postAddManufacturer(dataToSubmit).then(
    response => dispatch(successResponse(response, 'addmanufacturer')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const autocompleteManufacturer = (txt, limit = 20, page = 1) => (dispatch) => {
  dispatch(showActionLoading());
  autoSearchManufacturer(txt, limit, page).then(
    response => dispatch(successResponse(response, 'automanufacturer')),
    err => dispatch(errorResponse(err, false)),
  );
};


export const productTypes = () => (dispatch) => {
  dispatch(showActionLoading());
  requestProductTypes().then(
    response => dispatch(successResponse(response, 'prodtypes')),
    err => dispatch(errorResponse(err)),
  );
};

/* export const productTypes = () => dispatch => new Promise((resolve) => {
  dispatch(showActionLoading());
  requestProductTypes().then((response) => {
    response.json().then((json) => {
      if (response.ok) {
        dispatch(setProductTypes(json.payload));
        dispatch(hideActionLoading());
        resolve(json.payload);
      } else {
        console.log('error: ',
        json.errors.map(err => err.message).join(', ')); // eslint-disable-line
        resolve();
      }
    });
  });
}); */

export const importsInfo = () => (dispatch) => {
  dispatch(showActionLoading());
  lastImportProducts().then(
    response => dispatch(successResponse(response, 'importsinfo')),
    err => dispatch(errorResponse(err, false, true)),
  );
};

export const importProducts = () => (dispatch) => {
  dispatch(showActionLoading());
  importWMSProducts().then(
    response => dispatch(successResponse(response, 'importproducts')),
    err => dispatch(errorResponse(err, false, true)),
  );
};

export const fetchProductSalesOrder = (
  id, limit, page, more = false, type,
  start, end,
) => (dispatch) => {
  let ptype = null;
  let dstart = null;
  let dend = null;
  if (typeof type !== 'undefined') {
    ptype = type;
    if (type === '') {
      ptype = null;
    }
  }
  if (typeof start !== 'undefined') {
    dstart = start;
    if (start === '') {
      dstart = null;
    }
  }
  if (typeof end !== 'undefined') {
    dend = end;
    if (end === '') {
      dend = null;
    }
  }
  dispatch(showActionLoading());
  const moredata = more ? 'moresalesorder' : 'salesorder';
  requestProductSalesOrder(id, limit, page, ptype, dstart, dend).then(
    response => dispatch(successResponse(response, moredata)),
    err => dispatch(errorResponse(err, false)),
  );
};

export const fetchStockAlert = (limit = 20, page = 1, more = false) => (dispatch) => {
  dispatch(showActionLoading());
  const moredata = more ? 'morestocks' : 'stocks';
  stockAlertListing(limit, page).then(
    response => dispatch(successResponse(response, moredata)),
    err => dispatch(errorResponse(err)),
  );
};

export const addStockAlert = dataToSubmit => (dispatch) => {
  dispatch(showActionLoading());
  addProductStockAlert(dataToSubmit).then(
    response => dispatch(successResponse(response, 'addstock')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const updateStockAlert = (id, dataToSubmit) => (dispatch) => {
  dispatch(showActionLoading());
  updateProductStockAlert(id, dataToSubmit).then(
    response => dispatch(successResponse(response, 'addstock')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const deleteStockAlert = id => (dispatch) => {
  dispatch(showActionLoading());
  deleteProductStockAlert(id).then(
    response => dispatch(successResponse(response, 'deletestock')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const fetchStockAlertRecipient = () => (dispatch) => {
  dispatch(showActionLoading());
  stockAlertRecipients().then(
    response => dispatch(successResponse(response, 'stockrecipient')),
    err => dispatch(errorResponse(err)),
  );
};

export const addStockAlertRecipient = dataToSubmit => (dispatch) => {
  dispatch(showActionLoading());
  addUpdateStockAlertRecipients(JSON.stringify(dataToSubmit)).then(
    response => dispatch(successResponse(response, 'stockrecipient')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const autocompleteFreight = (txt, limit = 20, page = 1) => (dispatch) => {
  dispatch(showActionLoading());
  autoSearchFreight(txt, limit, page).then(
    response => dispatch(successResponse(response, 'autocompletefreight')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const addFreight = dataToSubmit => (dispatch) => {
  dispatch(showActionLoading());
  postFreight(dataToSubmit).then(
    response => dispatch(successResponse(response, 'addfreight')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const cancelPurchaseOrder = id => (dispatch) => {
  dispatch(showActionLoading());
  cancelVendorOrder(id).then(
    response => dispatch(successResponse(response, 'cancelpo')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const fetchStockAlertSettings = () => (dispatch) => {
  dispatch(showActionLoading());
  settingsStockAlert().then(
    response => dispatch(successResponse(response, 'stocksettings')),
    err => dispatch(errorResponse(err)),
  );
};

export const updateStockAlertSettings = dataToSubmit => (dispatch) => {
  dispatch(showActionLoading());
  saveSettingsStockAlert(JSON.stringify(dataToSubmit)).then(
    response => dispatch(successResponse(response, 'stocksettings')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const reviewCSVProduct = dataToSubmit => (dispatch) => {
  dispatch(showActionLoading());
  postCSVProduct(dataToSubmit).then(
    response => dispatch(successResponse(response, 'reviewcsvproduct')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const importCSVProduct = dataToSubmit => (dispatch) => {
  dispatch(showActionLoading());
  saveCSVProduct(JSON.stringify(dataToSubmit)).then(
    response => dispatch(successResponse(response, 'importcsvproduct')),
    err => dispatch(errorResponse(err, false)),
  );
};
