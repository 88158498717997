import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
// import 'date-fns';
import { format, parseISO } from 'date-fns';
import { convertToLocalTime } from 'date-fns-timezone';
import { FormControl, FormControlLabel, Checkbox, TextField, NativeSelect, Select, MenuItem, InputLabel, OutlinedInput, InputAdornment, IconButton, Typography } from '@material-ui/core';
import enLocale from 'date-fns/locale/en-US';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as ArrowDown } from 'resources/icons/arrowDown.svg';
import { ReactComponent as Calendar } from 'resources/icons/calendar.svg';
import style from './FormFieldStyle';
import TextAutoComplete from './TextAutoComplete';

export const DEFAULT_DATE_FORMAT = 'MM-dd-yyyy';

export const localeMap = {
  en: enLocale,
};

export const localeUtilsMap = {
  en: DateFnsUtils,
};

export const formatDate = (date) => {
  if (!date) return new Date().toLocaleString();

  // Get the timezone from browser using native methods
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateTmp = Date.parse(date.toLocaleString());
  const localDate = convertToLocalTime(dateTmp, {
    timeZone: timezone,
  });
  return format(localDate, DEFAULT_DATE_FORMAT);
};

class Formfield extends Component {
  state = {
    showPassword: false,
    labelWidth: 0,
    selectedDate: null,
    locale: 'en',
  };

  componentWillMount() {
    const { formdata } = this.props;
    setTimeout(() => {
      if (formdata.element === 'date' && formdata.value !== null) {
        this.setState({ // eslint-disable-line
          ...this.state,
          selectedDate: parseISO(formdata.value),
        });
      }
    }, 10);
  }

  componentDidMount() {
    const lbw = ReactDOM.findDOMNode(this.InputLabelRef) !== null ?  // eslint-disable-line
      ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth : 0;  // eslint-disable-line

    this.setState({ // eslint-disable-line
      ...this.state,
      labelWidth: lbw,
    });
  }

  getFormattedDate = () => {
    const date = new Date();
    const day = (`0${date.getDate()}`).slice(-2);
    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  getFormattedTime = () => {
    const date = new Date();
    const time = (`0${date.getTime()}`).slice(-2);
    return time;
  }

  putPwdEye = (cl, cleye, type, showpwd) => {
    if (type === 'password') {
      return (
        <InputAdornment position="end" className={cl}>
          <IconButton
            aria-label="Toggle password visibility"
            onClick={() => showpwd.passClicked()}
          >
            {
              this.state.showPassword ? <VisibilityOff className={cleye} /> :
              <Visibility className={cleye} />
            }
          </IconButton>
        </InputAdornment>
      );
    }
    return false;
  };

  checkPassType = (type) => {
    if (type === 'password') {
      return this.state.showPassword ? 'text' : 'password';
    }
    return type;
  };

  handleDateAtOnChange = (val) => {
    this.setState({ ...this.state, selectedDate: val });
    return formatDate(val);
  };

  render() {
    const { selectedDate, locale } = this.state;
    const {
      formdata, change, id, classes,
    } = this.props;
    const showError = () => {
      let errorMessage = null;

      if (formdata.validation && !formdata.valid) {
        errorMessage = (
          <Typography className={`errorlbl ${classes.errorLabel}`}>
            {formdata.validationMessage}
          </Typography>
        );
      }
      return errorMessage;
    };

    const handleClickShowPassword = () => {
      this.setState(state => ({ showPassword: !state.showPassword }));
    };

    const renderTemplate = () => {
      let formTemplate = null;
      switch (formdata.element) {
        case ('input'):
          formTemplate = (
            <div className={`inputHolder ${classes.inputHolder}`} data-disabled={formdata.disabled}>
              <TextField
                {...formdata.config}
                margin="normal"
                variant="outlined"
                value={formdata.value}
                onBlur={event => change({ event, id, blur: true })}
                onChange={event => change({ event, id })}
                type={this.checkPassType(formdata.config.type)}
                disabled={formdata.disabled}
                className={classNames(formdata.addeditform ?
                  classes.inputWrapperMb20
                  : classes.inputWrapper, formdata.validation.required ?
                  classes.inptRequired
                  : '')}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                    shrink: classes.cssShrink,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classNames(
                      formdata.validation.required ?
                      classes.cssOutlinedInputRequired
                      : classes.cssOutlinedInput,
                      formdata.disabled ?
                      classes.cssInputDisabled : '',
                    ),
                    input: classNames(
                      classes.input1,
                      formdata.validation.required ?
                        classes.requiredInput
                      : null,
                    ),
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                  endAdornment: (
                    this.putPwdEye(
                      classes.adorn,
                      classes.eye,
                      formdata.config.type,
                      { passClicked: () => handleClickShowPassword() },
                    )
                  ),
                  inputProps: {
                    min: typeof formdata.config.min !== 'undefined' ? formdata.config.min : 0,
                    step: typeof formdata.config.step !== 'undefined' ? formdata.config.step : 0.01,
                    maxLength: typeof formdata.config.maxLength !== 'undefined' ? formdata.config.maxLength : 100,
                    tabIndex: formdata.tabIndex ? formdata.tabIndex : 0,
                  },
                }}
              />
              {showError()}
            </div>
          );
          break;
        case ('selectmodal'):
          formTemplate = (
            <FormControl
              variant="outlined"
              className={`selectHolder ${formdata.addeditform ?
                      classes.selectInputHolderMb20
                      : classes.selectInputHolder}
                      ${formdata.validation.required ?
                      classes.inptRequired
                      : ''}
                      ${formdata.disabled ?
                      classes.cssSelectDisabled
                      : ''}`}
            >
              {formdata.showlabel ?
                <InputLabel
                  classes={{
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                    shrink: classes.cssShrink2,
                  }}
                  ref={(ref) => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor={`outlined-${formdata.config.label}-simple`}
                >
                  {formdata.config.label}
                </InputLabel>
                : null}
              <Select
                classes={{
                  root: classes.cssSelect,
                }}
                disabled={formdata.disabled}
                IconComponent={() => (
                  <i className={classes.arrowDownIcon}>
                    <ArrowDown />
                  </i>
                )}
                MenuProps={{
                  classes: {
                    paper: classes.paperStyle,
                  },
                }}
                value={formdata.value}
                onChange={event => change({ event, id, change: true })}
                input={
                  <OutlinedInput
                    classes={{
                      root: classNames(
                        formdata.validation.required ?
                        classes.cssOutlinedInputRequired
                        : classes.cssOutlinedInput,
                        formdata.disabled ?
                        classes.cssOutlinedInputDisabled
                        : '',
                      ),
                      input: classNames(
                        classes.input1,
                        classes.inputOnSelect,
                        formdata.validation.required ?
                          classes.requiredInput
                          : '',
                      ),
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    }}
                    name={formdata.config.name}
                    labelWidth={this.state.labelWidth}
                    id={`outlined-${formdata.config.label}-simple`}
                  />
                }
              >
                {formdata.showEmpty ?
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                : null}
                {
                  formdata.config.options.map(item => (
                    <MenuItem
                      key={item.key}
                      value={item.key}
                    >
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
              {showError()}
            </FormControl>
          );
          break;
        case ('select'):
          formTemplate = (
            <FormControl
              variant="outlined"
              className={`selectHolder ${formdata.addeditform ?
                      classes.selectInputHolderMb20
                      : classes.selectInputHolder}
                      ${formdata.validation.required ?
                      classes.inptRequired
                      : ''}
                      ${formdata.disabled ?
                      classes.cssSelectDisabled
                      : ''}`}
            >
              {formdata.showlabel ?
                <InputLabel
                  classes={{
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                    shrink: classes.cssShrink2,
                  }}
                  ref={(ref) => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor={`outlined-${formdata.config.label}-simple`}
                >
                  {formdata.config.label}
                </InputLabel>
                : null}
              <NativeSelect
                classes={{
                  root: classes.cssSelect,
                }}
                disabled={formdata.disabled}
                IconComponent={() => (
                  <i className={classes.arrowDownIcon}>
                    <ArrowDown />
                  </i>
                )}
                value={formdata.value}
                onChange={event => change({ event, id, change: true })}
                input={
                  <OutlinedInput
                    classes={{
                      root: classNames(
                        formdata.validation.required ?
                        classes.cssOutlinedInputRequired
                        : classes.cssOutlinedInput,
                        formdata.disabled ?
                        classes.cssOutlinedInputDisabled
                        : '',
                      ),
                      input: classNames(
                        classes.input1,
                        classes.inputOnSelect,
                        formdata.validation.required ?
                          classes.requiredInput
                          : '',
                      ),
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    }}
                    name={formdata.config.name}
                    labelWidth={this.state.labelWidth}
                    id={`outlined-${formdata.config.label}-simple`}
                  />
                }
              >
                {formdata.showEmpty ?
                  <option style={{ height: 0 }} value="" disabled />
                : null}
                {
                  formdata.config.options.map(item => (
                    <option key={item.key} value={item.key}>{item.value}</option>
                  ))
                }
                {formdata.showEmpty ?
                  <option value="" disabled />
                : null}
              </NativeSelect>
              {showError()}
            </FormControl>
          );
          break;
        case ('autocomplete'):
          formTemplate = (
            <div
              className={`${formdata.validation.required ? 'required' : ''} ${classes.autocompleteHolder}`}
              data-disabled={formdata.disabled}
            >
              <span className="bg" />
              <TextAutoComplete {...formdata} />
              {showError()}
            </div>
          );
          break;
        case ('textarea'):
          formTemplate = (
            <div className={`formBlock ${classes.txtareawrapper}`} data-disabled={formdata.disabled}>
              <TextField
                {...formdata.config}
                placeholder={formdata.config.placeholder}
                label={formdata.config.label}
                multiline
                rows="4"
                value={formdata.value}
                margin="normal"
                variant="outlined"
                onBlur={event => change({ event, id, blur: true })}
                onChange={event => change({ event, id })}
                disabled={formdata.disabled}
                className={`textareaHolder ${formdata.customized ?
                  classes.txtArea82
                  : classes.txtArea}
                  ${formdata.validation.required ?
                  classes.inptRequired
                  : ''}`}
                InputLabelProps={{
                  classes: {
                    root: classNames(classes.cssLabel, classes.cssTxtAreaLabel),
                    focused: classes.cssFocused,
                    shrink: classes.cssShrink,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classNames(formdata.validation.required ?
                      classes.cssTextAreaRequired
                      : classes.cssTextArea),
                    input: classNames(
                      classes.input1,
                      formdata.validation.required ?
                        classes.requiredInput
                      : null,
                    ),
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
              {showError()}
            </div>
          );
          break;
        case ('date'):
          formTemplate = (
            <MuiPickersUtilsProvider utils={localeUtilsMap[locale]} locale={localeMap[locale]}>
              <div className="formBlock">
                <DatePicker
                  margin="normal"
                  label={formdata.config.label}
                  variant="outlined"
                  className={`rootdate ${classes.rootDate}`}
                  format={DEFAULT_DATE_FORMAT}
                  /* value={formatDate(formdata.value)} */
                  value={selectedDate}
                  autoOk
                  onChange={event => change({
                    event: this.handleDateAtOnChange(event),
                    id,
                    change: true,
                    type: formdata.config.type,
                  })}
                  mask={value => (value ? [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/] : [])}
                  animateYearScrolling={false}
                  disablePast={formdata.disablePast}
                  InputLabelProps={{
                    classes: {
                      root: classNames(classes.cssLabel, classes.cssDateLabel),
                      focused: classes.cssFocused,
                      shrink: classNames(classes.cssShrink, classes.cssDateShrink),
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classNames(formdata.validation.required ?
                        classes.cssDateRequired
                        : classes.cssDate),
                      input: classNames(
                        classes.input1,
                        formdata.validation.required ?
                          classes.requiredInput
                          : null,
                      ),
                      focused: classes.cssFocused,
                    },
                    endAdornment: (
                      <InputAdornment className={`dateIcon ${classes.dateIcon}`} position="end">
                        <Calendar />
                      </InputAdornment>
                    ),
                  }}
                />
                {showError()}
              </div>
            </MuiPickersUtilsProvider>
          );
          break;
        case ('date2'):
          formTemplate = (
            <div className="formBlock">
              <TextField
                {...formdata.config}
                defaultValue={this.getFormattedDate()}
                onChange={event => change({ event, id })}
                className={`rootdate ${classes.rootDate}`}
                variant="outlined"
                InputLabelProps={{
                  classes: {
                    root: classNames(classes.cssLabel, classes.cssDateLabel),
                    focused: classes.cssFocused,
                    shrink: classNames(classes.cssShrink, classes.cssDateShrink),
                  },
                }}
                InputProps={{
                  classes: {
                    root: classNames(formdata.validation.required ?
                      classes.cssDateRequired
                      : classes.cssDate),
                    input: classNames(
                      classes.input1,
                      formdata.validation.required ?
                        classes.requiredInput
                        : null,
                    ),
                    focused: classes.cssFocused,
                  },
                  endAdornment: (
                    <InputAdornment className={classes.dateIcon} position="end">
                      <Calendar />
                    </InputAdornment>
                  ),
                }}
              />
              {showError()}
            </div>
          );
          break;
        case ('time'):
          formTemplate = (
            <div className="formBlock">
              <TextField
                {...formdata.config}
                defaultValue="07:30"
                onChange={event => change({ event, id })}
                className={classes.rootDate}
                variant="outlined"
                InputLabelProps={{
                  classes: {
                    root: classNames(classes.cssLabel, classes.cssDateLabel),
                    focused: classes.cssFocused,
                    shrink: classNames(classes.cssShrink, classes.cssDateShrink),
                  },
                }}
                InputProps={{
                  step: 300, // 5 min
                  classes: {
                    root: classNames(formdata.validation.required ?
                      classes.cssDateRequired
                      : classes.cssDate),
                    input: classNames(
                      classes.input1,
                      formdata.validation.required ?
                        classes.requiredInput
                        : null,
                    ),
                    focused: classes.cssFocused,
                  },
                }}
              />
              {showError()}
            </div>
          );
          break;
        case ('checkbox'):
          formTemplate = (
            <div className={classes.inputHolder}>
              <FormControlLabel
                control={
                  <Checkbox
                    {...formdata.config}
                    checked={formdata.checked}
                    value={formdata.value}
                    onChange={event => change({ event, id })}
                    classes={{
                      root: classes.chkroot,
                      checked: classes.chkrootchecked,
                    }}
                  />
                }
                label={formdata.config.label}
                classes={{
                  root: classes.formControlLabel,
                }}
              />
              {showError()}
            </div>
          );
          break;
        default:
          formTemplate = null;
      }
      return formTemplate;
    };
    return (
      <Fragment>
        {renderTemplate()}
      </Fragment>
    );
  }
}

export default withStyles(style)(Formfield);
