const styles = theme => ({
  filterbox: {
    position: 'relative',
    zIndex: 2,
    width: '100%',
  },
  filter: {
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: 16,
    '@media (max-width:370px)': {
      marginRight: 10,
    },
  },
  morefilter: {
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '100%',
  },
  iconbtn: {
    backgroundColor: theme.palette.common.white,
    height: 28,
    border: '1px solid #C5D1E0',
    borderRadius: 100,
    padding: '0 14px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    },
    '& span span': {
      fontSize: 12,
      marginRight: 10,
      '@media (max-width:370px)': {
        fontSize: 10,
        marginRight: 2,
      },
    },
  },
  moreiconbtn: {
    backgroundColor: 'transparent',
    height: 52,
    padding: '0 18px',
    width: '100%',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: 'transparent !important',
      boxShadow: 'none',
    },
    '& span span': {
      fontSize: 12,
      color: theme.palette.primary.light,
      '@media (max-width:370px)': {
        fontSize: 10,
      },
    },
    '& > span': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
  },
  popper: {
    top: '6px !important',
    left: '-142px !important',
    '&.more': {
      top: '1px !important',
      left: '136px !important',
      '&.Date': {
        left: '86px !important',
      },
    },
    '@media (max-width:1240px)': {
      top: '6px !important',
      left: '5px !important',
      zIndex: 2,
      width: '100%',
    },
  },
  paper: {
    backgroundColor: theme.palette.common.white,
    width: 398,
    boxShadow: '0 0 12px rgba(68, 107, 176, 0.91)',
    borderRadius: 6,
    '&.Date': {
      width: 300,
      '& p': {
        color: theme.palette.primary.main,
      },
    },
    '&.Filter p': {
      color: theme.palette.primary.main,
    },
    '@media (max-width:420px)': {
      width: '95%',
    },
  },
  ulistcontainer: {
    padding: 0,
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '100%',
    '& li': {
      padding: '12px 18px',
      borderTop: '1px solid #E8EAEE',
    },
    '& p': {
      lineHeight: '14px',
    },
    '& span': {
      color: '#2C2C2C',
      fontSize: 12,
      lineHeight: '14px',
      fontWeight: 300,
    },
    '& a': {
      fontSize: 12,
      lineHeight: '14px',
      textDecoration: 'none',
      color: theme.palette.secondary.main,
    },
  },
  listhead: {
    padding: '8px 18px',
    textAlign: 'left',
    '& p': {
      fontSize: 11,
      lineHeight: '13px',
      fontWeight: 500,
    },
    '@media (max-width:1240px)': {
      borderBottom: '1px solid #E8EAEE',
    },
  },
  itext: {
    padding: '0 0 0 16px',
  },
  fdisp: {
    position: 'absolute',
    left: 6,
    bottom: -16,
    width: '100%',
    '@media (max-width:1240px)': {
      left: 0,
    },
  },
  fdisp2: {
    backgroundColor: '#F6f7f7',
    bottom: -26,
    paddingBottom: 10,
    '&.utrow': {
      backgroundColor: 'transparent',
      bottom: 0,
      padding: '0 18px 8px',
    },
  },
  fwrapper: {
    display: 'inline-block',
  },
  chip: {
    backgroundColor: '#E8EAEE !important',
    border: '1px solid #C5D1E0',
    height: 22,
    margin: '2px 8px 2px 0',
    '& label': {
      color: '#5D5B5B',
      fontSize: 10,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    '& span': {
      color: '#09B0EE',
      fontSize: 10,
      fontWeight: 500,
      textTransform: 'capitalize',
      verticalAlign: 'baseline',
    },
    '& svg': {
      width: 16,
    },
  },
  filterlbl: {
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: '0 10px 0 5px',
    fontSize: 12,
    color: '#949db2',
    fontWeight: 500,
  },
  closeX: {
    display: 'none',
    '@media (max-width:1240px)': {
      top: 5,
      right: 16,
      display: 'block',
      position: 'absolute',
      padding: 4,
      width: 20,
      height: 20,
    },
  },
});

export default styles;
