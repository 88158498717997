const style = theme => ({
  ...theme.classes,
  listingloader: {
    '@media (max-width:1240px)': {
      marginTop: '30%',
    },
  },
  tblwrapper: {
    position: 'relative',
    padding: '8px 6px 6px',
    overflow: 'hidden',
    '@media (max-width:1240px)': {
      padding: '75px 0 0 0',
    },
  },
  tblwrapperstatic: {
    padding: '0 6px 6px',
    overflow: 'hidden',
    marginTop: -8,
    '&.csv': {
      overflow: 'visible',
      marginTop: 0,
    },
    '@media (max-width:1240px)': {
      padding: '42px 0 0 0',
      marginTop: -5,
      '&.csv': {
        marginTop: 0,
      },
    },
  },
  hasfilter: {
    '@media (max-width:1240px)': {
      padding: '75px 0 0 0',
    },
  },
  table: {
    position: 'relative',
    background: '#fff',
    width: '100%',
    borderCollapse: 'inherit',
    '& tr:hover .btns': {
      display: 'flex',
    },
  },
  tbody: {
    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.10)',
    position: 'relative',
    background: '#fff',
    width: '100%',
  },
  tbodylist: {
    '& td': {
      whiteSpace: 'initial',
    },
  },
  tbodyfilter: {
    '& tr': {
      height: 40,
    },
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  csvstatus: {
    width: '10%',
    textAlign: 'center',
  },
  status: {
    '&[data-status="Active"]': {
      color: '#339B40',
    },
    '&[data-status="New"]': {
      color: '#339B40',
    },
    '&[data-status="Cancelled"]': {
      color: '#949DB2',
    },
    '&[data-status="On Hold"]': {
      color: '#E3C000',
    },
    '&[data-status="Backordered"]': {
      color: '#FF6565',
    },
  },
  cancel: {
    backgroundColor: '#fff',
    width: 68,
    height: 24,
    color: '#09B0EE',
    fontSize: 12,
    fontWeight: 400,
    borderRadius: 100,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
    border: '1px solid #C5D1E0',
    '&:hover': {
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    },
  },
  hold: {
    backgroundColor: '#E3C000',
    width: 68,
    height: 24,
    lineHeight: '24px',
    color: '#fff',
    fontSize: 12,
    fontWeight: 400,
    borderRadius: 100,
    textAlign: 'center',
    display: 'inline-block',
    marginRight: 16,
    '&:hover': {
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    },
  },
  create: {
    backgroundColor: theme.palette.secondary.main,
    width: 95,
    fontWeight: 500,
    '@media (max-width:1240px)': {
      width: 75,
    },
  },
  tg1: {
    padding: '4px 0 4px 29px',
    whiteSpace: 'initial',
    '@media (max-width:1350px)': {
      padding: '4px 0 4px 12px',
    },
  },
  tg2: {
    padding: '4px 20px',
  },
  tg3: {
    padding: '4px 0',
  },
  statusplus: {
    width: '100%',
    '& a': {
      color: '#09B0ee',
    },
    '& .verified': {
      fontSize: 12,
      fontWeight: 400,
      color: '#339B40 !important',
    },
    '& .error': {
      fontSize: 12,
      fontWeight: 400,
      color: '#FF6565 !important',
    },
    '@media (max-width:1240px)': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  edit: {
    color: '#09B0EE',
    width: 20,
    height: 20,
  },
  delete: {
    fill: '#949DB2',
    width: 20,
    height: 20,
    padding: 2,
    marginLeft: 24,
  },
  actionbtns: {
    backgroundColor: '#FAFAFB',
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    padding: '0 15px 0 27px',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  cmdbtns: {
    width: 60,
    '& .btn-container': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& [aria-label="Delete"] svg': {
        width: 15,
        height: 15,
      },
    },
  },
  tglast: {
    padding: '0 !important',
    width: 0,
  },
  tglastb: {
    padding: '0 40px 0 0 !important',
  },
  tddesktop: {
    display: 'table',
    '@media (max-width:1240px)': {
      display: 'none',
    },
  },
  tdmobile: {
    display: 'none',
    '@media (max-width:1240px)': {
      display: 'table',
    },
  },
  tblrowMobile: {
    position: 'relative',
    height: 82,
    zIndex: 1,
  },
  tblrowMobile2: {
    height: 'auto',
  },
  tblrowMobile4: {
    position: 'static',
  },
  tdleft: {
    padding: '0 10px 0 24px',
    whiteSpace: 'inherit',
  },
  tdcenter: {
    padding: '14px 24px 10px 24px !important',
    whiteSpace: 'inherit',
  },
  tdright: {
    padding: '0 20px 0 0',
  },
  txtDesc: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '19px',
    color: theme.palette.secondary.main,
    marginBottom: 10,
    width: '80%',
    '&.po': {
      width: '60%',
    },
    '@media (max-width:1240px)': {
      marginBottom: 6,
    },
  },
  txtRC: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    color: theme.palette.text.primary,
    marginBottom: 10,
    '&.sku': {
      fontSize: 14,
    },
    '& label': {
      color: theme.palette.primary.lightmobile,
      display: 'inline-block',
      width: 68,
      '&.qty': {
        display: 'initial',
        marginRight: 6,
      },
      '@media (max-width:370px)': {
        marginRight: 2,
      },
    },
    '& label.vo': {
      width: 118,
    },
    '& strong': {
      fontWeight: 600,
      color: theme.palette.text.primary,
    },
    '&.morespace label': {
      width: 102,
    },
    '@media (max-width:1240px)': {
      marginBottom: 6,
    },
  },
  txtstatus: {
    position: 'absolute',
    top: 50,
    right: 24,
    fontSize: 14,
    lineHeight: '19px',
    fontWeight: 400,
    '&[data-status="Active"]': {
      color: '#339B40',
    },
    '&[data-status="Out Of Stock"]': {
      color: '#FF6565',
    },
    '&[data-status="Low In Stock"], &[data-status="Awaiting Receipt"]': {
      color: '#E3C000',
    },
    '&.awaiting': {
      top: 22,
    },
  },
  filterMobile: {
    display: 'none',
    '@media (max-width:1240px)': {
      display: 'block',
      '& .filters': {
        position: 'relative',
        top: 0,
        bottom: 0,
      },
    },
  },
  hasfilterMobile: {
    '@media (max-width:1240px)': {
      '& .filters': {
        backgroundColor: theme.palette.common.white,
        padding: '16px 20px',
      },
    },
  },
  orderdiv: {
    display: 'flex',
    flexDirection: 'column',
    '& :nth-child(1)': {
      order: 1,
    },
    '& :nth-child(2)': {
      order: 2,
    },
    '& :nth-child(3)': {
      order: 3,
    },
    '& :nth-child(4)': {
      order: 4,
    },
    '& :nth-child(5)': {
      order: 5,
    },
    '& :nth-child(6)': {
      order: 6,
      position: 'absolute',
      right: 24,
    },
  },
  topicon: {
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: 32,
    width: 38,
    flex: 'none',
    '& .num': {
      position: 'absolute',
      top: 6.2,
      left: 0,
      fontSize: 16,
      lineHeight: '17px',
      fontWeight: 500,
      color: '#fff',
      display: 'block',
      width: '100%',
      textAlign: 'center',
      paddingRight: 2,
    },
    '&.n4 .num, &.n5 .num, &.n6 .num, &.n7 .num, &.n8 .num, &.n9 .num, &.n10 .num': {
      color: '#949DB2',
      top: -11,
    },
    '@media (max-width:1240px)': {
      position: 'absolute',
      right: 24,
      bottom: 18,
      marginRight: 0,
      '&.n4 .num, &.n5 .num, &.n6 .num, &.n7 .num, &.n8 .num, &.n9 .num, &.n10 .num': {
        top: -24,
      },
    },
  },
  breaktext: {
    wordBreak: 'break-all',
    fontWeight: 500,
  },
  paddtxt: {
    paddingRight: '20%',
  },
  flexbox: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      margin: 0,
      color: '#333',
      fontWeight: 500,
    },
  },
});

export default style;
