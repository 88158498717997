import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as Close } from 'resources/icons/closev2.svg';
import { Grid, Typography, IconButton } from '@material-ui/core';
import styles from './DetailsHeaderStyle';

class DetailsHeader extends Component {
  handleDrawerClose = () => {
    if (document.querySelector('.selected') !== null) {
      document.querySelector('.selected').classList.remove('selected');
    }
    const { actions } = this.props;
    actions.ui.closeDrawer();
  };

  render() {
    const { classes, drawer } = this.props;
    return (
      <div className={classes.drawerHeader}>
        <Grid className={classes.headContainer} container spacing={0}>
          <Grid className={`${classes.gridStyle} ${classes.w100}`} item xs={8}>
            { typeof drawer.params !== 'undefined' ?
              <Typography className={classes.h1} variant="h1">
                { drawer.params.category === 'Glossary of Terms' || drawer.params.category === 'Terms of Service' ?
                  <>
                    <span>{drawer.params.title}</span>
                  </>
                :
                  <>
                    {drawer.params.category}:&nbsp; <span>{drawer.params.title}</span>
                  </>
                }
              </Typography>
            : null }
          </Grid>
          <Grid className={`${classes.gridStyle} ${classes.gridright} ${classes.wauto}`} item xs={4}>
            <IconButton
              className={classNames(classes.icon, classes.iconbtn)}
              aria-label="Close"
              onClick={this.handleDrawerClose}
            >
              <Close className={classes.closev2} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    );
  }
}

DetailsHeader.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
  drawer: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(DetailsHeader);
