import { userRoleList, userRoleById, insertUserRole, modifyUserRole, removeUserRole } from 'api/role';
import { showActionLoading, hideActionLoading } from 'state/loading/actions';
import responseCheker from 'api/responseCheker';
import { openAlertModal } from 'state/modal-drawer/actions';

import {
  SET_ROLE_LIST,
  SET_MORE_ROLE_LIST,
  SET_ROLE_DATA,
  SET_DND_ROLE_DATA,
} from './types';

export const setRoleList = rolelist => ({
  type: SET_ROLE_LIST,
  payload: { rolelist },
});

export const setMoreRoleList = rolelist => ({
  type: SET_MORE_ROLE_LIST,
  payload: { rolelist },
});

export const setRole = role => ({
  type: SET_ROLE_DATA,
  payload: { role },
});

export const setDNDRole = dndrole => ({
  type: SET_DND_ROLE_DATA,
  payload: { dndrole },
});

export const successResponse = (response, type = '') => (dispatch) => {
  dispatch(responseCheker(response));
  if (response) {
    if (type === 'list') {
      dispatch(setRoleList(response.data));
    }
    if (type === 'morelist') {
      dispatch(setMoreRoleList(response.data));
    }
    if (type === 'role') {
      dispatch(setRole(response.data));
    }
  }
  dispatch(hideActionLoading());
};

export const errorResponse = err => (dispatch) => {
  const result = dispatch(responseCheker(err.response));
  if (result === 'error') {
    const modalKey = 'alert';
    const params = {
      type: 'error',
      title: '',
      description: err.response.data.errors[0].detail,
    };
    dispatch(openAlertModal({ modalKey, params }));
  }
  if (result === 'TypeError') {
    const modalKey = 'alert';
    const params = {
      type: 'error',
      title: '',
      description: 'Something went wrong. This has been reported',
    };
    dispatch(openAlertModal({ modalKey, params }));
  }
  dispatch(hideActionLoading());
};

export const fetchRoleList = (limit = 20, page = 1) => (dispatch) => {
  dispatch(showActionLoading());
  userRoleList(limit, page).then(
    response => dispatch(successResponse(response, 'list')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchMoreRoleList = (limit = 20, page = 1) => (dispatch) => {
  dispatch(showActionLoading());
  userRoleList(limit, page).then(
    response => dispatch(successResponse(response, 'morelist')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchRoleById = id => (dispatch) => {
  dispatch(showActionLoading());
  userRoleById(id).then(
    response => dispatch(successResponse(response, 'role')),
    err => dispatch(errorResponse(err)),
  );
};

export const addUserRole = dataToSubmit => (dispatch) => {
  dispatch(showActionLoading());
  insertUserRole(dataToSubmit).then(
    response => dispatch(successResponse(response)),
    err => dispatch(errorResponse(err)),
  );
};

export const updateUserRole = (dataToSubmit, id) => (dispatch) => {
  dispatch(showActionLoading());
  modifyUserRole(dataToSubmit, id).then(
    response => dispatch(successResponse(response)),
    err => dispatch(errorResponse(err)),
  );
};

export const deleteUserRole = (dataToSubmit, id) => (dispatch) => {
  dispatch(showActionLoading());
  removeUserRole(dataToSubmit, id).then(
    response => dispatch(successResponse(response)),
    err => dispatch(errorResponse(err)),
  );
};
