import React, { Component/* , Fragment */ } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import AddMultipleOrderHeader from './AddMultipleOrderHeader';
import AddMultipleOrderListing from './AddMultipleOrderListing';
import styles from './AddEditOrderStyle';

class AddMultipleOrder extends Component {
  render() {
    const { classes, ...others } = this.props;
    return (
      <div className={classes.container}>
        <AddMultipleOrderHeader {...others} submitOrder={this.submitOrder} />
        <div className={classes.content}>
          <Typography className="note">
            Note: Orders with errors will not be uploaded.
            Edit the errors on your CSV and upload it again.
          </Typography>
          <AddMultipleOrderListing {...others} />
        </div>
      </div>
    );
  }
}

AddMultipleOrder.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  // getloadtype: PropTypes.string.isRequired,
  // setloadtype: PropTypes.func.isRequired,
  // setdataentry: PropTypes.func.isRequired,
  // detail: PropTypes.instanceOf(Object).isRequired,
  // orderDetail: PropTypes.func.isRequired,
  // getCarrier: PropTypes.func.isRequired,
  // carrierdata: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(AddMultipleOrder);
