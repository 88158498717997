import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from 'ui/pages/settings/ProfileStyle';
import DrawerContainer from 'ui/components/modal/DrawerContainer';
import DetailsHeader from './DetailsHeader';
import Details from './Details';
import CustomizedExpansionPanel from './CustomizedExpansionPanel';

class Faq extends Component {
  componentWillMount() {
    const { actions } = this.props;
    actions.ui.closeDrawer();
  }

  render() {
    const { classes, ...others } = this.props;
    return (
      <Fragment>
        <div className={classes.faqwrapper}>
          <CustomizedExpansionPanel {...others} />
        </div>
        <DrawerContainer
          {...others}
        >
          <DetailsHeader {...others} />
          <Details {...others} />
        </DrawerContainer>
      </Fragment>
    );
  }
}

Faq.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Faq);
