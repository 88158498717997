import { bindActionCreators } from 'redux';
import { getSupportData, getChatDetail } from 'state/support/selectors';
import { isItShowingLoad, isItActionShowingLoad, getLoadType } from 'state/loading/selectors';
import { getLoginData } from 'state/logged-user/selectors';
import { showDrawer, showModal, showPage } from 'state/modal-drawer/selectors';
import { setLoadType } from 'state/loading/actions';
import { addChat, fetchChatList, fetchMoreChatList, fetchChatDetail, resolved, resetChatList } from 'state/support/actions';
import * as uiActionCreators from 'state/modal-drawer/actions';

export const mapStateToProps = state => ({
  support: getSupportData(state),
  detail: getChatDetail(state),
  loading: isItShowingLoad(state),
  actionloading: isItActionShowingLoad(state),
  drawer: showDrawer(state),
  modal: showModal(state),
  showpage: showPage(state),
  loginData: getLoginData(state),
  getloadtype: getLoadType(state),
  profile: getLoginData(state),
});

export const mapDispatchToProps = dispatch => ({
  addchat: data => dispatch(addChat(data)),
  chatlist: (id, limit, page) => dispatch(fetchChatList(id, limit, page)),
  morechatlist: (id, limit, page) => dispatch(fetchMoreChatList(id, limit, page)),
  resetchatList: () => dispatch(resetChatList()),
  chatdetail: id => dispatch(fetchChatDetail(id)),
  markresolved: (id, status) => dispatch(resolved(id, status)),
  actions: { ui: bindActionCreators(uiActionCreators, dispatch) },
  setloadtype: type => dispatch(setLoadType(type)),
});

