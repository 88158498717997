const styles = theme => ({
  ...theme.classes,
  container: {
    background: theme.palette.common.white,
    position: 'relative',
    padding: 0,
    borderRadius: 8,
    marginTop: -45,
    minHeight: 'calc(100vh - 160px)',
    boxShadow: '0 3px 8px rgba(230, 230, 230, 0.65)',
  },
  supportcontent: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width:1240px)': {
      display: 'block',
    },
  },
  modclosebtn: {
    marginLeft: 10,
  },
  leftcontent: {
    width: 'calc(100% - 506px)',
    display: 'inline-block',
    verticalAlign: 'top',
    padding: '30px 54px 66px 34px',
    '& p.assist': {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '22px',
      color: theme.palette.primary.main,
      marginBottom: 40,
    },
    '& p.to, & p.from, & p.by': {
      fontSize: 14,
      lineHeight: '16px',
      fontWeight: 500,
      color: '#8B8A8A',
      marginBottom: 24,
    },
    '& strong': {
      fontWeight: 500,
      color: theme.palette.common.black,
    },
    '& label.ptype': {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    '& .inputbox': {
      display: 'inline-block',
      verticalAlign: 'middle',
      paddingLeft: 16,
      width: 348,
      '& > div': {
        marginBottom: 0,
      },
    },
    '& .createbtn': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '@media (max-width:1240px)': {
      width: '100%',
      padding: '30px 24px 66px',
    },
  },
  lform: {
    '& textarea': {
      height: 270,
    },
    '@media (max-width:1240px)': {
      '& fieldset': {
        borderColor: '#ACB3B5 !important',
      },
    },
  },
  ptypebox: {
    display: 'block',
    margin: '10px 0 42px',
  },
  rightcontent: {
    width: 506,
    display: 'inline-block',
    verticalAlign: 'top',
    borderLeft: '1px solid #E4E4E4',
    boxShadow: '0 3px 8px rgba(230, 230, 230, 0.65)',
    '@media (max-width:1240px)': {
      width: '100%',
      borderLeft: 0,
      boxShadow: 'none',
      marginBottom: 66,
    },
  },
  btnswrapper: {
    position: 'absolute',
    top: 30,
    right: 34,
    textAlign: 'right',
  },
  submitWrapper: {
    position: 'relative',
    display: 'inline-block',
    '@media (max-width:1240px)': {
      position: 'absolute',
      bottom: 20,
      right: 25,
      '& div.btnwrapper': {
        position: 'relative',
        width: 40,
        height: 40,
      },
    },
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  w148: {
    width: 148,
    height: 34,
    padding: 0,
    textAlign: 'center',
    margin: '0 0 0 16px',
    fontSize: 14,
    fontWeight: 500,
  },
  moretxt: {
    padding: '90px 10px 0 36px',
    fontSize: 14,
    lineHeight: '20px',
    color: theme.palette.text.primary,
    '& strong': {
      fontWeight: 600,
    },
    '@media (max-width:1240px)': {
      padding: 0,
    },
  },
  spacer2: {
    width: '100%',
    height: 4,
    display: 'block',
  },
  notebox: {
    '& .formBlock': {
      width: '100%',
    },
  },
  w100: {
    '@media (max-width:1240px)': {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  desktop: {
    '@media (max-width:1240px)': {
      display: 'none',
    },
  },
  mobile: {
    display: 'none',
    '@media (max-width:1240px)': {
      display: 'inline-block',
    },
  },
  error_label2: {
    '@media (max-width:1240px)': {
      marginBottom: 20,
    },
  },
  inputcontainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 28,
    '& label': {
      color: theme.palette.text.primary,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '19px',
      display: 'inline-block',
      width: 72,
      '@media (max-width:768px)': {
        width: 142,
      },
    },
    '& .inpt': {
      width: '100%',
      display: 'inline-block',
      '& div': {
        margin: 0,
      },
    },
  },
});

export default styles;
