import axios from 'axios';
import { fuelheaders } from 'state/logged-user/actions';
import { SITE_SERVER } from './misc';

if (process.env.NODE_ENV !== 'development') {
  axios.defaults.withCredentials = true;
}

export const chatList = (id, limit, page) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/chats?type_id=${id}&limit=${limit}&page=${page}`,
  headers: fuelheaders(),
});

export const chatDetail = id => axios({
  method: 'GET',
  url: `${SITE_SERVER}/chats/${id}`,
  headers: fuelheaders(),
});

export const newChat = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/chats`,
  headers: fuelheaders(),
  data: dataToSubmit,
});

export const newChatMsg = (id, msgdata) => axios({
  method: 'POST',
  url: `${SITE_SERVER}/chat/${id}/messages`,
  headers: fuelheaders(),
  data: msgdata,
});

export const chatMsgList = (id, limit, page) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/chat/${id}/messages?limit=${limit}&page=${page}`,
  headers: fuelheaders(),
});

export const markResolved = (id, status) => axios({
  method: 'PUT',
  url: `${SITE_SERVER}/chats/${id}/update_status`,
  headers: fuelheaders(),
  data: status,
});

