const styles = theme => ({
  ...theme.classes,
  gridcontainer: {
    padding: '18px 0',
    '@media (max-width:1240px)': {
      padding: '16px 0',
    },
  },
  spgridcontainer: {
    padding: '0 28px 32px',
    '@media (max-width:1240px)': {
      padding: '22px 28px 12px',
    },
  },
  spgridcontainer2: {
    padding: '26px 28px 0',
    '@media (max-width:1240px)': {
      padding: '0 28px 0',
    },
  },
  gridg1: {
    padding: '0 0 36px 0',
    '@media (max-width:1240px)': {
      padding: '16px 0',
    },
  },
  gridg2: {
    padding: '26px 0 34px 0',
    '@media (max-width:1240px)': {
      padding: '6px 32px 35px',
    },
  },
  gridg3: {
    padding: '26px 0 30px 0',
    '@media (max-width:1240px)': {
      padding: '16px 0',
    },
  },
  gridg4: {
    padding: '26px 0 36px 0',
    '@media (max-width:1240px)': {
      padding: '16px 0',
    },
  },
  gridg5: {
    padding: '32px 0 36px 0',
    '@media (max-width:1240px)': {
      padding: '16px 0',
    },
  },
  gridcaption: {
    fontSize: 12,
    lineHeight: '14px',
    color: theme.palette.header.primary,
    marginBottom: 5,
  },
  gridbody: {
    fontSize: 14,
    lineHeight: '16px',
    color: theme.palette.primary.main,
    '@media (max-width:1240px)': {
      fontSize: 12,
    },
  },
  gridbody2: {
    color: `${theme.palette.text.primary} !important`,
  },
  gridbodygray: {
    fontSize: 14,
    lineHeight: '16px',
    color: '#d9d7d7',
  },
  trow: {
    height: 'auto',
    border: '1px solid #d9d7d7',
    '& th': {
      color: theme.palette.primary.light,
      height: 38,
      fontSize: 12,
      fontWeight: 400,
      '@media (max-width:1240px)': {
        padding: 0,
      },
    },
    '& th.desc': {
      width: '56%',
      textAlign: 'left',
      '@media (max-width:1240px)': {
        display: 'none',
        '&.desc2': {
          display: 'table-cell',
        },
      },
    },
    '& th.skudesc': {
      '& span': {
        display: 'none',
      },
      '@media (max-width:1240px)': {
        '& span': {
          display: 'inline',
        },
      },
    },
    '& .tcol2': {
      paddingLeft: 20,
    },
    '& td': {
      height: 44,
      '& .description': {
        display: 'none',
      },
      '@media (max-width:1240px)': {
        padding: 0,
        paddingTop: 16,
        paddingBottom: 16,
        '&.desc': {
          display: 'none',
        },
        '& .description': {
          display: 'block',
          fontSize: 12,
          fontWeight: 400,
        },
      },
    },
    '& td p': {
      color: theme.palette.text.gray20,
      '@media (max-width:1240px)': {
        fontSize: 12,
        fontWeight: 400,
      },
    },
    '& th:first-child': {
      borderTopLeftRadius: 6,
      '@media (max-width:1240px)': {
        borderTopLeftRadius: 0,
        paddingLeft: 30,
      },
    },
    '& th:last-child': {
      borderTopRightRadius: 6,
      '@media (max-width:1240px)': {
        borderTopRightRadius: 0,
        paddingRight: 30,
      },
    },
    '& td:first-child': {
      '@media (max-width:1240px)': {
        paddingLeft: 30,
      },
    },
    '& td:last-child': {
      '@media (max-width:1240px)': {
        paddingRight: 30,
      },
    },
  },
  h6: {
    fontSize: 14,
    fontWeight: 500,
  },
  alignRight: {
    textAlign: 'right',
  },
  table: {
    marginBottom: 20,
    position: 'relative',
    background: '#fff',
    width: '100%',
    borderCollapse: 'inherit',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  t1: {
    fontSize: 12,
    color: theme.palette.primary.light,
    marginBottom: 20,
    '@media (max-width:1240px)': {
      backgroundColor: theme.palette.background.default,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '22px',
      padding: '16px 30px 8px',
      margin: 0,
      color: theme.palette.common.black,
    },
  },
  thead: {
    backgroundColor: theme.palette.background.default,
    boxShadow: '0px 0px 6px 0px rgba(68, 107, 176, 0.91), 0px 10px 6px 0px rgba(68, 107, 176, 0.38)',
    borderRadius: 6,
    '@media (max-width:1240px)': {
      boxShadow: 'none',
      borderRadius: 0,
    },
  },
  tbody: {
    position: 'relative',
    background: '#fff',
    width: '100%',
  },
  tblsortlbl: {
    whiteSpace: 'nowrap',
    '& svg': {
      width: 9,
      fill: '#446BB0',
      marginLeft: 6,
    },
  },
  tlabel: {
    margin: '48px 0 22px',
  },
  orderlines: {
    backgroundColor: '#F6F7F7',
    padding: '24px 28px',
    border: '1px solid #D9D7D7',
    borderRadius: 5,
    marginTop: 50,
    marginBottom: 20,
    '& h2': {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '21px',
      color: theme.palette.primary.main,
      marginBottom: 32,
      '@media (max-width:1240px)': {
        marginBottom: 26,
      },
    },
    '@media (max-width:1240px)': {
      marginTop: 28,
    },
  },
  revieworderlines: {
    marginBottom: 20,
    '& h2': {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '21px',
      color: theme.palette.primary.main,
      marginBottom: 32,
    },
  },
  oltable: {
    marginBottom: 20,
    position: 'relative',
    background: '#F6F7F7',
    width: '100%',
    borderCollapse: 'inherit',
    '& .th .tr th span': {
      fontSize: 12,
      color: '#949DB2',
    },
    '& .th .tr th:first-child': {
      width: '64%',
    },
    '&.review th': {
      padding: '4px 26px 4px 14px',
    },
    '@media (max-width:1240px)': {
      '& th': {
        padding: '4px 16px 4px 14px',
      },
    },
  },
  oltbody: {
    position: 'relative',
    background: '#fff',
    width: '100%',
    '& tr': {
      height: 64,
      '& td:first-child': {
        borderLeft: '1px solid #E8EAEE',
      },
      '& td:last-child': {
        borderRight: '1px solid #E8EAEE',
      },
      '& strong': {
        fontSize: 12,
        fontWeight: 500,
        color: '#333',
      },
      '& label': {
        fontSize: 12,
        fontWeight: 300,
        color: '#333',
      },
      '&.sub': {
        background: '#F6F7F7',
        '& td': {
          border: '0 none',
        },
      },
      '& .subtotal div.subtotalbox': {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '40px 0 0',
        '& .srtotal': {
          width: 305,
          display: 'flex',
          justifyContent: 'space-between',
          borderTop: '1px solid #E8EAEE',
          padding: '20px 14px 0 0',
          '& strong': {
            fontSize: 16,
            '@media (max-width:1240px)': {
              fontSize: 14,
            },
          },
        },
        '@media (max-width:1240px)': {
          '& td': {
            padding: '20px 0 0',
          },
        },
      },
      '& .subtotal div div div.inputbox': {
        display: 'flex',
        alignItems: 'center',
        width: 330,
        justifyContent: 'space-between',
        marginBottom: 14,
        '@media (max-width:1240px)': {
          width: 220,
        },
      },
      '& .subtotal div div div.inputbox span': {
        fontSize: 14,
        fontWeight: 300,
        color: '#5D5B5B',
        '@media (max-width:1240px)': {
          fontSize: 12,
        },
      },
      '& .subtotal div div div.inputbox label': {
        fontSize: 16,
        fontWeight: 400,
        color: '#5D5B5B',
        '@media (max-width:1240px)': {
          fontSize: 14,
        },
      },
      '& .subtotal div div div.inputbox .txtfield': {
        width: 200,
        borderRadius: 5,
        margin: 0,
        '& fieldset': {
          borderColor: '#5D5B5B',
        },
        '& input': {
          fontSize: 14,
          padding: '10px 12px',
          textAlign: 'right',
          color: '#5D5B5B',
          '&:focus': {
            outline: 'none',
          },
          '@media (max-width:1240px)': {
            fontSize: 12,
          },
        },
        '&.disabled fieldset': {
          borderColor: '#E0DFDF',
        },
        '&.disabled input': {
          fontSize: 16,
          color: '#5D5B5B',
          '@media (max-width:1240px)': {
            fontSize: 14,
          },
        },
        '@media (max-width:1240px)': {
          width: 112,
        },
      },
      '& .subtotal div div div.total': {
        display: 'flex',
        alignItems: 'center',
        width: 330,
        justifyContent: 'space-between',
        marginBottom: 14,
        marginTop: 40,
        paddingTop: 20,
        borderTop: '1px solid #E8EAEE',
        '@media (max-width:1240px)': {
          width: '100%',
        },
      },
      '& .subtotal div div div.total strong': {
        fontSize: 20,
        fontWeight: 600,
        color: '#5D5B5B',
        '@media (max-width:1240px)': {
          fontSize: 16,
        },
      },
    },
    '&.review td': {
      padding: '4px 26px 4px 14px',
    },
    '@media (max-width:1240px)': {
      '& td': {
        padding: '4px 16px 4px 14px',
      },
    },
  },
});

export default styles;
