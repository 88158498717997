import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FormField from 'ui/components/form/FormField';
import { update, setFieldValue, populateOptionFields, resetOptionFields } from 'ui/components/form/FormActions';
import styles from './ProductFilterFormStyle';

class ProductFilterForm extends Component {
  state = {
    formdata: {
      vendor_id: {
        element: 'autocomplete',
        value: '',
        config: {
          name: 'vendor_id',
          type: 'number',
          label: 'Vendor Name',
          options: [],
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: true,
        validationMessage: '',
        addeditform: true,
        addNewClicked: (addNew, value) => this.addNewClicked(addNew, value),
        onChange: value => this.onVendorChange(value),
        hasfunction: true,
        loadstatus: false,
        loadbtnstatus: false,
      },
    },
  }
  componentDidUpdate() {
    const {
      getloadtype, setloadtype, actionloading, autoVendor,
    } = this.props;
    if (!actionloading && getloadtype === 'auto vendor') {
      setloadtype('');
      if (autoVendor.length) {
        const list = [];
        for (const key in autoVendor) { // eslint-disable-line
          list.push({ _id: autoVendor[key].attributes.id, name: autoVendor[key].attributes.name }); // eslint-disable-line
        }
        const newFormData = populateOptionFields(this.state.formdata, list, 'vendor_id');
        newFormData.vendor_id.loadstatus = false;
        this.updateFields(newFormData);
      } else {
        const newFormdata = resetOptionFields(this.state.formdata, 'vendor_id');
        newFormdata.vendor_id.loadstatus = false;
        this.updateFields(newFormdata);
      }
    }
  }
  onVendorChange = (txt) => {
    const { getAutoVendor, resetAutoVendor, setloadtype } = this.props;
    if (txt.trim() !== '') {
      getAutoVendor(txt);
    } else {
      resetAutoVendor();
    }
    setloadtype('auto vendor');
    const newFormdata = { ...this.state.formdata };
    newFormdata.vendor_id.loadstatus = true;
    this.updateFields(newFormdata);
  }
  addNewClicked = (addNew, value) => {
    const {
      autoVendor,
      filtersdata,
      setfilters,
      setloadtype,
      handleClose,
    } = this.props;
    let vendorInfo = [];
    let selectedVendor = {};
    if (autoVendor.length) {
      vendorInfo = autoVendor
        .filter(data => data.attributes.id === value)
        .map(vendor => (vendor.attributes));
      const [vendorData] = vendorInfo;
      selectedVendor = vendorData;
    }
    if (typeof selectedVendor === 'undefined') {
      selectedVendor = {};
    }
    const newFormdata = setFieldValue(this.state.formdata, 'vendor_id', value);

    let vendorName = '';
    let vendorId = '';
    for (const k in newFormdata.vendor_id.config.options) { // eslint-disable-line
      if (newFormdata.vendor_id.config.options[k].key === newFormdata.vendor_id.value) {
        vendorName = newFormdata.vendor_id.config.options[k].value;
        vendorId = newFormdata.vendor_id.value;
      }
    }
    const filterData = { id: 1, vendor_id: vendorId, vendor_name: vendorName };

    const filterItems = [...filtersdata];
    if (filterItems.length) {
      let notfound = true;
      for (const key in filterItems) { // eslint-disable-line
        if (filterItems[key].id === filterData.id) {
          filterItems[key].vendor_id = filterData.vendor_id;
          filterItems[key].vendor_name = filterData.vendor_name;
          notfound = false;
        }
      }
      if (notfound) {
        filterItems.push({ ...filterData });
      }
    } else {
      filterItems.push({ ...filterData });
    }
    setfilters(filterItems);
    setloadtype('filter updated');
    handleClose();
  }
  updateFields = (newFormdata) => {
    this.setState({
      ...this.state,
      formdata: newFormdata,
    });
  }
  updateForm = (element) => {
    const { handleClose } = this.props;
    const newFormdata = update(element, this.state.formdata, '');
    this.setState({
      formdata: newFormdata,
    });
    handleClose();
  }
  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <List className={classes.ulistcontainer}>
          <ListItem>
            <FormField
              id="vendor_id"
              formdata={this.state.formdata.vendor_id}
              change={element => this.updateForm(element)}
            />
          </ListItem>
        </List>
      </Fragment>
    );
  }
}

ProductFilterForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  autoVendor: PropTypes.instanceOf(Object).isRequired,
  getAutoVendor: PropTypes.func.isRequired,
  resetAutoVendor: PropTypes.func.isRequired,
  setloadtype: PropTypes.func.isRequired,
  getloadtype: PropTypes.string.isRequired,
  actionloading: PropTypes.bool.isRequired,
  setfilters: PropTypes.func.isRequired,
  filtersdata: PropTypes.instanceOf(Object).isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(ProductFilterForm);
