import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, AppBar, Toolbar, Typography, Fab, IconButton, CircularProgress } from '@material-ui/core';
import { ReactComponent as Close } from 'resources/icons/closev2.svg';
import { withStyles } from '@material-ui/core/styles';
import SnackBarNotify from 'ui/components/common/SnackBarNotify';
import styles from 'ui/components/common/ModalHeaderStyle';

class AddMultipleOrderHeader extends Component {
  state = {
    loading: false,
  };
  componentDidUpdate() {
    const {
      getloadtype, setloadtype, actionloading, importcsvorderdata,
    } = this.props;
    if (actionloading && getloadtype === 'uploading csv product') {
      if (Object.keys(importcsvorderdata).length !== 0) {
        setloadtype('');
        if (importcsvorderdata.attributes.total_data_saved) {
          this.child.showMessage(`${importcsvorderdata.attributes.total_data_saved} order${importcsvorderdata.attributes.total_data_saved > 1 ? 's' : ''} successfully uploaded`, 'success');
          setTimeout(() => {
            this.onCancel();
          }, 5000);
        } else {
          this.child.showMessage('No orders uploaded!', 'warning');
        }
        setTimeout(() => {
          this.setState({ loading: false });
        }, 10);
      }
    }
  }

  onCancel = () => {
    const { actions } = this.props;
    const modalKey = 'modal-order';
    const params = { type: '' };
    actions.ui.closeModal({ modalKey, params });
  }

  proceedUpload = () => {
    const {
      csvorder, importCSVOrder, clearImportCSVOrder, setloadtype,
    } = this.props;
    const filteredItems = csvorder.attributes.filter(item => !Object.keys(item.errors).length);
    if (filteredItems.length) {
      clearImportCSVOrder();
      importCSVOrder({ data: filteredItems });
      setloadtype('uploading csv product');
      this.setState({ loading: true });
    } else {
      this.child.showMessage('No orders uploaded!', 'warning');
    }
  }

  render() {
    const { loading } = this.state;
    const { classes } = this.props;
    return (
      <Fragment>
        <AppBar className={`${classes.appBar} ${classes.appModBar}`}>
          <Toolbar className={`${classes.modalToolbarHeight} ${classes.modalToolbarHeightMod}`}>
            <div className={`${classes.modalToolbarContainer} ${classes.modalToolbarContainerMod}`}>
              <Typography variant="h6" color="inherit" className={classNames(classes.flex, classes.h6)}>
                Check and Verify Orders
              </Typography>
              <div className={classes.submitWrapper}>
                <Button
                  className={classes.modalBtn}
                  variant="contained"
                  color="secondary"
                  onClick={this.proceedUpload}
                  disabled={loading}
                >
                  Proceed to Upload
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
              <Fab onClick={this.onCancel} variant="extended" aria-label="Close" className={classes.close}>
                Close
              </Fab>
              <IconButton
                className={`${classes.icon} ${classes.iconbtn} ${classes.modclose}`}
                aria-label="Close"
                onClick={this.onCancel}
              >
                <Close className={classes.closev2} />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <SnackBarNotify onRef={(instance) => { this.child = instance; }} />
      </Fragment>
    );
  }
}

AddMultipleOrderHeader.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
  setloadtype: PropTypes.func.isRequired,
  actionloading: PropTypes.bool.isRequired,
  getloadtype: PropTypes.string.isRequired,
  csvorder: PropTypes.instanceOf(Object), // eslint-disable-line
  importcsvorderdata: PropTypes.instanceOf(Object), // eslint-disable-line
  importCSVOrder: PropTypes.func,  // eslint-disable-line
  clearImportCSVOrder: PropTypes.func,  // eslint-disable-line
};

export default withStyles(styles)(AddMultipleOrderHeader);
