const styles = theme => ({
  ...theme.classes,
  formcont: {
    paddingTop: 56,
  },
  profileContainer: {
    padding: '0 80px',
  },
  gridcont1: {
    marginBottom: 28,
  },
  colA: {
    padding: 0,
  },
  colL: {
    padding: '0 30px 0 0',
  },
  colR: {
    padding: '0 0 0 30px',
  },
  wh148: {
    width: 148,
    height: 148,
    marginBottom: 14,
  },
  uploadbtn: {
    padding: '0 25px',
    height: 34,
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  delbtn: {
    borderColor: 'transparent',
    width: 148,
    fontWeight: 400,
    color: '#E07E7E',
    height: 34,
    marginTop: 4,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  inputhide: {
    display: 'none',
  },
  w100: {
    '@media (max-width:1240px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      padding: 0,
    },
  },
  desktop: {
    '@media (max-width:1240px)': {
      display: 'none',
    },
  },
  mobile: {
    display: 'none',
    '@media (max-width:1240px)': {
      display: 'inline-block',
    },
  },
});

export default styles;
