import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// import { ReactComponent as SyncBtn } from 'resources/icons/sync-btn.svg';
// import SnackBarNotify from './SnackBarNotify';
import styles from './UpdateSyncBtnStyle';

class UpdateSyncBtn extends Component {
  state = {
    loading: false,
    limit: 20,
    page: 1,
  };

  componentDidUpdate() {
    const {
      actionloading, getloadtype, setloadtype, getimportorders, /* setSortColumn, setSortType, */
    } = this.props;
    if (!actionloading && (getloadtype === 'updating products' || getloadtype === 'updating orders')) {
      if (getloadtype === 'updating products') {
        this.child.showMessage('Successfully updated products!', 'success');
      }
      if (getloadtype === 'updating orders') {
        if (!getimportorders.attributes.wms_orders.pending_orders.length
          && !getimportorders.attributes.wms_orders.shipped_orders.length) {
          this.child.showMessage('Orders are up-to-date', 'success');
        } else {
          this.child.showMessage('Successfully updated orders!', 'success');
        }
      }
      setTimeout(() => {
        this.setState({ ...this.state, loading: false });
      }, 1);
      setloadtype('done sync');
    }
    if (!actionloading && getloadtype === 'You can only import once a day') {
      this.child.showMessage('You can only import once a day', 'error');
      setloadtype('');
      setTimeout(() => {
        this.setState({ ...this.state, loading: false });
      }, 1);
    }
  }

  onUpdate = () => {
    const {
      importProducts, importOrders, setloadtype, location,
    } = this.props;
    switch (location.pathname) {
      case '/products':
      case '/top-selling-products':
      case '/products-low-in-stocks':
      case '/products-out-of-stocks':
        setloadtype('updating products');
        importProducts();
        break;
      case '/orders':
      case '/pending-orders':
      case '/shipped-orders':
      case '/returned-orders':
      case '/return-authorization':
        setloadtype('updating orders');
        importOrders();
        break;
      default:
        break;
    }
    this.setState({ ...this.state, loading: true });
  };

  render() {
    const { classes, getimportinfo/* , displayfor */ } = this.props;
    if (typeof getimportinfo === 'undefined') {
      return true;
    }
    if (Object.keys(getimportinfo).length === 0) {
      return true;
    }
    // const { loading } = this.state;
    return (
      <Fragment>
        { getimportinfo.attributes !== null ?
          <Typography className={`lastupdate ${classes.lastupdate}`}>
            Last updated {getimportinfo.attributes.date_last_updated2}
          </Typography>
        : null }
        {/* <SyncBtn className={`${displayfor} syncbtn ${loading ? classes.rotating : ''}`}
        onClick={this.onUpdate} /> */}
        {/* <SnackBarNotify onRef={(instance) => { this.child = instance; }} /> */}
      </Fragment>
    );
  }
}

UpdateSyncBtn.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  importProducts: PropTypes.func, // eslint-disable-line
  importOrders: PropTypes.func, // eslint-disable-line
  importsInfo: PropTypes.func, // eslint-disable-line
  getimportinfo: PropTypes.object, // eslint-disable-line
  getimportorders: PropTypes.object, // eslint-disable-line
  getProductList: PropTypes.func, // eslint-disable-line
  orderList: PropTypes.func, // eslint-disable-line
  returnOrderList: PropTypes.func, // eslint-disable-line
  setloadtype: PropTypes.func.isRequired,
  getloadtype: PropTypes.string.isRequired,
  actionloading: PropTypes.bool.isRequired,
  displayfor: PropTypes.string, // eslint-disable-line
};

export default withStyles(styles)(UpdateSyncBtn);
