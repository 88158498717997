const styles = theme => ({
  ...theme.classes,
  root: {
    display: 'flex',
  },
  container: {
    padding: 0,
    height: 'calc(100% - 76px)',
  },
  content: {
    'overflow-y': 'auto',
    padding: '12px 74px 0',
    height: '100%',
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.2)',
      borderRadius: 10,
      backgroundColor: '#FEFEFE',
    },
    '&::-webkit-scrollbar': {
      width: 10,
      backgroundColor: '#FEFEFE',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.2)',
      backgroundColor: '#F3F3F3',
    },
  },
  ulist: {
    padding: 0,
    margin: '0 0 60px 0',
    listStyleType: 'none',
    '& li': {
      padding: 0,
      margin: 0,
    },
    '& ul': {
      listStyleType: 'none',
      margin: 0,
    },
    '& ul li': {
      marginBottom: 6,
    },
    '&.glossary': {
      '& ul': {
        padding: 0,
      },
      '& ul li p': {
        marginBottom: 12,
      },
    },
    '&.mobile': {
      display: 'none',
    },
    '@media (max-width:1240px)': {
      '&.desktop': {
        display: 'none',
      },
      '&.mobile': {
        display: 'block',
      },
    },
  },
  txt: {
    fontSize: 14,
    fontWeight: 400,
    color: '#5D5B5B',
    display: 'flex',
    '& span': {
      display: 'inline-block',
      paddingRight: 4,
    },
  },
  txt1: {
    paddingLeft: 17,
  },
  txt2: {
    paddingLeft: 0,
    '& span': {
      paddingRight: 16,
    },
  },
  '@media (max-width:1240px)': {
    container: {
      height: 'calc(100% - 90px)',
    },
    content: {
      padding: 24,
    },
  },
});

export default styles;
