const styles = theme => ({
  ...theme.classes,
  mainwrapper: {
    marginTop: -42,
    paddingTop: 6,
    '@media (max-width:1240px)': {
      marginTop: -46,
      paddingTop: 0,
    },
  },
  mobileselect: {
    display: 'none',
    '@media (max-width:1240px)': {
      padding: '20px 24px 20px',
      display: 'block',
      '& button': {
        color: theme.palette.secondary.main,
      },
    },
  },
  container: {
    background: '#fff',
    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.10)',
    position: 'relative',
    width: '100%',
    padding: '30px 40px 10px',
    borderRadius: 8,
    /* minHeight: '85vh', */
    '@media (max-width:1240px)': {
      borderRadius: 0,
      padding: '38px 24px',
      minHeight: '82vh',
    },
  },
  headercontainer: {
    marginBottom: 36,
    '@media (max-width:1240px)': {
      marginBottom: 0,
    },
  },
  h1: {
    fontSize: 16,
    /* lineHeight: '30px', */
    fontWeight: 500,
    color: theme.palette.primary.main,
    display: 'inline-block',
    verticalAlign: 'middle',
    '&.h1top': {
      padding: '0 24px',
      margin: '22px 0',
    },
  },
  btnwrapper: {
    textAlign: 'right',
  },
  gridlist: {
    '& .griditem.item-30': {
      display: 'none',
    },
    '& .griditem:nth-child(odd)': {
      padding: '0 20px 42px 0',
    },
    '& .griditem:nth-child(even)': {
      padding: '0 0 42px 20px',
    },
    '@media (max-width:1240px)': {
      '& .griditem:nth-child(odd)': {
        padding: 0,
      },
      '& .griditem:nth-child(even)': {
        padding: 0,
      },
      '& .griditem': {
        marginBottom: 28,
      },
    },
  },
  list: {
    position: 'relative',
    border: '1px solid #CACED5',
    height: 145,
    padding: 22,
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.04)',
    '& ul': {
      padding: 0,
    },
    '& li': {
      position: 'static',
      padding: 0,
    },
    '& .item': {
      display: 'flex',
      alignItems: 'center',
      height: 'auto',
      paddingLeft: 24,
    },
    '& .userpic': {
      width: 65,
      height: 65,
      margin: 0,
    },
    '& .email svg, & .email label': {
      display: 'inline-block',
      verticalAlign: 'middle',
      marginRight: 8,
      fontSize: 13,
      fontWeight: 400,
      color: '#43425D',
    },
    '& .item span': {
      fontSize: 16,
      lineHeight: '16px',
      marginBottom: 8,
      fontWeight: 600,
      color: '#4D4F5C',
    },
    '& .item label.email': {
      '@media (max-width:440px)': {
        position: 'absolute',
        left: 26,
        top: 88,
      },
    },
    '& .item span.role': {
      fontSize: 12,
      fontWeight: 400,
      display: 'block',
      color: '#949DB2',
      marginTop: 20,
      marginBottom: 0,
    },
    '@media (max-width:1240px)': {
      '& .avatar': {
        width: 50,
        height: 50,
        fontSize: 18,
      },
      '& .item': {
        minHeight: 52,
        height: 'auto',
      },
      '& .item > div': {
        padding: 0,
      },
    },
  },
  edit: {
    position: 'absolute',
    top: 8,
    right: 47,
    '@media (max-width:440px)': {
      top: 0,
    },
  },
  del: {
    position: 'absolute',
    top: 8,
    right: 10,
    '@media (max-width:440px)': {
      top: 0,
    },
  },
  new: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.secondary.main,
    width: 'auto',
    border: '0 none',
    padding: '0 16px',
    margin: 0,
  },
  extendedIcon: {
    margin: '0 12px 0 0 !important',
  },
  plus: {
    stroke: theme.palette.secondary.main,
    verticalAlign: 'middle',
    marginLeft: 30,
    cursor: 'pointer',
    '@media (max-width:1240px)': {
      position: 'absolute',
      top: -48,
      right: 14,
      marginLeft: 0,
      width: 36,
      height: 36,
      padding: 10,
    },
  },
  w100: {
    '@media (max-width:1240px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      padding: 0,
    },
  },
  desktop: {
    '@media (max-width:1240px)': {
      display: 'none',
    },
  },
  mobile: {
    display: 'none',
    '@media (max-width:1240px)': {
      display: 'inline-block',
    },
  },
  spacer: {
    width: '100%',
    height: 40,
    display: 'block',
  },
});

export default styles;
