
export const getFormattedDate = (d) => {
  const date = d.split('-');
  return `${date[2]}-${date[0]}-${date[1]}`;
};

export const validate = (element, formdata = []) => {
  let error = [true, ''];
  let validEl = '';

  if (typeof (element.value) === 'boolean') {
    validEl = element.value;
  } else if (typeof (element.value) === 'number') {
    validEl = element.value;
  } else {
    validEl = element.value.trim();
  }

  if (element.validation.email) {
    const valid = /\S+@\S+\.\S+/.test(validEl);
    const message = `${!valid ? 'Must be a valid email' : ''}`;
    error = !valid ? [valid, message] : error;
  }

  if (element.validation.change) {
    const validLength = validEl.length > 5 && validEl.length < 17; // eslint-disable-line
    if (validLength) {
      if (typeof formdata.confirmPassword !== 'undefined') {
        const valid = validEl === formdata.confirmPassword.value;
        const message = `${!valid ? 'Passwords do not match' : ''}`;
        error = !valid ? [valid, message] : error;
      }
    } else {
      error = [false, 'Use between 6 and 16 characters for your password'];
    }
  }

  if (element.validation.confirm) {
    if (typeof formdata.changePassword !== 'undefined') {
      const valid = validEl === formdata.changePassword.value;
      const message = `${!valid ? 'Passwords do not match' : ''}`;
      error = !valid ? [valid, message] : error;
    }
  }

  const errmsg = element.shorterrmsg ? 'Field is required' : 'This field is required';

  if (element.validation.required) {
    const valid = validEl !== '';
    const message = `${!valid ? errmsg : ''}`;
    error = !valid ? [valid, message] : error;
  }

  if (typeof validEl === 'string' && validEl.trim() !== '') {
    if (element.validation.multipleemail) {
      const emails = validEl.split(',');
      let allvalid = true;
      emails.forEach((email) => {
        const valid = /\S+@\S+\.\S+/.test(email.trim());
        if (!valid) {
          allvalid = false;
        }
      });
      const message = `${!allvalid ? 'Must be a valid email' : ''}`;
      error = !allvalid ? [allvalid, message] : error;
    }
  }

  return error;
};

export const update = (element, formdata, formName) => { // eslint-disable-line
  const newFormdata = {
    ...formdata,
  };
  const newElement = {
    ...newFormdata[element.id],
  };

  if (newElement.element === 'checkbox') {
    newElement.checked = !newElement.checked;
  }
  switch (element.type) {
    case 'date':
      newElement.value = getFormattedDate(element.event);
      break;
    case 'time':
      newElement.value = element.event;
      break;
    default:
      if (formdata[element.id].restrictInput) {
        newElement.value = element.event.target.value.replace(/[^\w\s-#]/gi, '');
        break;
      }
      if (formdata[element.id].restrictNumberInput) {
        newElement.value = element.event.target.value.replace(/[^0-9]+/g, '').replace(/(\..*)\./g, '$1');
        break;
      }
      newElement.value = element.event.target.value;
      break;
  }

  /* if (element.type === 'date' || element.type === 'time') {
    newElement.value = element.event;
  } else {
    newElement.value = element.event.target.value;
  } */

  if (element.blur || element.change) {
    const validData = validate(newElement, formdata);
    newElement.valid = validData[0]; // eslint-disable-line
    newElement.validationMessage = validData[1]; // eslint-disable-line
  }

  newElement.touched = element.blur;

  if (element.type === 'date') {
    const validData = validate(newElement, formdata);
    newElement.valid = validData[0]; // eslint-disable-line
    newElement.validationMessage = validData[1]; // eslint-disable-line
    newElement.touched = true;
  }

  newFormdata[element.id] = newElement;

  return newFormdata;
};

export const generateData = (formdata, formName) => { // eslint-disable-line
  const dataToSubmit = {};

  for (const key in formdata) { // eslint-disable-line
    if (key !== 'confirmPassword') {
      dataToSubmit[key] = formdata[key].value;
    }
  }

  return dataToSubmit;
};

export const isFormValid = (formdata, formName) => { // eslint-disable-line
  let formIsValid = true;
  for (const key in formdata) { // eslint-disable-line
    if (typeof formdata[key].valid !== 'undefined') {
      formIsValid = formdata[key].valid && formIsValid;
    }
  }
  return formIsValid;
};

export const populateOptionFields = (formdata, arrayData = [], field) => {
  const newArray = [];
  const newFormdata = { ...formdata };

  arrayData.forEach((item) => {
    newArray.push({ key: item._id, value: item.name }); // eslint-disable-line
  });

  newFormdata[field].config.options = newArray;
  return newFormdata;
};

export const resetOptionFields = (formdata, formName) => {
  const newFormdata = { ...formdata };
  for (const key in newFormdata) { // eslint-disable-line
    if (key === formName) {
      newFormdata[key].config.options = [];
      newFormdata[key].valid = false;
      newFormdata[key].touched = false;
      newFormdata[key].validationMessage = '';
      newFormdata[key].value = '';
    }
  }
  return newFormdata;
};

export const resetFields = (formdata, formName = null) => { // eslint-disable-line
  const newFormdata = { ...formdata };

  for (const key in newFormdata) { // eslint-disable-line
    if (formName !== null) {
      if (key === formName) {
        newFormdata[key].value = '';
        newFormdata[key].valid = false;
        newFormdata[key].touched = false;
        newFormdata[key].validationMessage = '';
      }
    } else {
      if (key === 'images') {
        newFormdata[key].value = [];
      } else {
        newFormdata[key].value = '';
      }

      newFormdata[key].valid = false;
      newFormdata[key].touched = false;
      newFormdata[key].validationMessage = '';
    }
  }

  return newFormdata;
};

export const populateFields = (formData, fields) => {
  for (const key in formData) { // eslint-disable-line
    if (typeof fields[key] !== 'undefined') {
      formData[key].value = typeof fields[key] === 'string' ? fields[key].trim() : fields[key]; // eslint-disable-line
      if (formData[key].value !== '') {
        formData[key].valid = true; // eslint-disable-line
        formData[key].touched = true; // eslint-disable-line
      }
      if (formData[key].value === null) {
        formData[key].value = ''; // eslint-disable-line
      }
      formData[key].validationMessage = ''; // eslint-disable-line
    }
  }

  return formData;
};

export const validateInputs = (formData) => {
  for (const key in formData) { // eslint-disable-line
    if (typeof formData[key].validation !== 'undefined') {
      if (formData[key].validation.required) {
        const validData = validate(formData[key]);
        formData[key].valid = validData[0]; // eslint-disable-line
        formData[key].validationMessage = validData[1]; // eslint-disable-line
        formData[key].touched = true; // eslint-disable-line
      }
    }
  }
  return formData;
};

export const setFieldValue = (formdata, formName, fvalue) => {
  const newFormdata = { ...formdata };
  for (const key in newFormdata) { // eslint-disable-line
    if (key === formName) {
      newFormdata[key].valid = true;
      newFormdata[key].touched = true;
      newFormdata[key].validationMessage = '';
      newFormdata[key].value = fvalue;
      if (fvalue === '') {
        newFormdata[key].valid = false;
        newFormdata[key].touched = false;
      }
    }
  }
  return newFormdata;
};

export const clearInput = (formdata, elname) => {
  const FormData = { ...formdata };
  for (const key in FormData) { // eslint-disable-line
    if (elname === key) {
      FormData[key].value = '';
      FormData[key].valid = false;
      FormData[key].touched = false;
      FormData[key].validationMessage = '';
      document.querySelector(`${FormData[key].element}[name="${key}"]`).value = '';
    }
  }
  return FormData;
};
