/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import TermsOfService from './TermsOfService';
import GloassaryOfTerms from './GloassaryOfTerms';
import styles from './DetailsStyle';

class Details extends Component {
  render() {
    const { classes, drawer } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.content}>
          { typeof drawer.params !== 'undefined' ?
            <Fragment>
              { drawer.params.title === 'How do I add a new sales order?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On the left hand sidebar of the site, click on the <strong>Sales Orders</strong> tab.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Here you can click on “Create” and then click “Add Single Order”
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>3.</span>
                        <span>
                          Once you fill out the form, click “Add Order”.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>4.</span>
                        <span>
                          Click “Review & Submit Order” to review your form. Click “Done” to finalize form.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Click on the menu button in the right-hand corner of the site,
                          click on the <strong>Sales Orders</strong> tab.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Here you can click on the “+” and then click “Add Single Order”
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Once you fill out the form, click “Add Order”.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>3.</span>
                        <span>
                          Click “Review & Submit Order” to review your form. Click “Done” to finalize form.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I upload multiple sales orders?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On the left hand sidebar of the site, click on the <strong>Sales Orders</strong> tab.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Here you can click on “Create” and then click “Upload Multiple Orders”
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>3.</span>
                        <span>
                          Select file and click “Open”
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>4.</span>
                        <span>
                          Once the file has been verified, click “Proceed to Upload” to finalize orders submission.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Click on the menu button in the right-hand corner of the site,
                          click on the <strong>Sales Orders</strong> tab.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Here you can click on the “+” and then click “Upload Multiple Orders”
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>3.</span>
                        <span>
                          Select file.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>4.</span>
                        <span>
                          Once the file has been verified, click “Proceed to Upload” to finalize orders submission.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I view all my sales orders?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On the left hand sidebar of the site, click on the <strong>Sales Orders</strong> tab.
                          Here you can view all your orders.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          From here you can search your sales orders by order ID in the search bar located on the left
                          or you can filter your orders by status, date, company name, recipient name, or email.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Click on the menu button in the right-hand corner of the site, click on the <strong>Sales Orders</strong> tab.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          From here you can search your sales orders by order ID in the search bar located on
                          the right or you can filter your orders by date, recipient name, or status.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I view my pending orders?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On your dashboard, you will view an orange button titled “Pending”.
                          Click this button and you will arrive at your pending orders page.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          You can also arrive at this same page if you click on the <strong>Sales Orders</strong> tab
                          on the left hand sidebar and then click on the <strong>Pending Orders</strong> tab at the top of the page.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On your dashboard, you will view an orange button titled “Pending”.
                          Click this button and you will arrive at your pending orders page.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          You can also arrive at this same page if you click on the menu button in the right
                          hand corner of the site. Then click on the <strong>Sales Orders</strong> tab.
                          Click on the <strong>All Orders</strong> dropdown and then click the <strong>Pending Orders</strong> tab.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I view my shipped orders?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On your dashboard, you will view a green button titled “Shipped”.
                          Click this button and you will arrive at your shipped orders page.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          You can also arrive at this same page if you click on the <strong>Sales Orders</strong> tab
                          on the left hand sidebar and then click on the <strong>Shipped Orders</strong> tab at the top of the page.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On your dashboard, you will view a green button titled “Shipped”.
                          Click this button and you will arrive at your shipped orders page.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          You can also arrive at this same page if you click on the menu button in the right
                          hand corner of the site. Then click on the <strong>Sales Orders</strong> tab.
                          Click on the <strong>All Orders</strong> dropdown and then click the <strong>Shipped Orders</strong> tab.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I view my returned orders?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On your dashboard, you will view a yellow button titled “Returned Orders”.
                          Click this button and you will arrive at your returned orders page.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          You can also arrive at this same page if you click on the <strong>Sales Orders</strong> tab
                          on the left hand sidebar and then click on the <strong>Returned Orders</strong> tab at the top of the page.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On your dashboard, you will view a yellow button titled “Returned Orders.”
                          Click this button and you will arrive at your returned orders page.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          You can also arrive at this same page if you click on the menu button in the right
                          hand corner of the site. Then click on the <strong>Sales Orders</strong> tab.
                          Once you have arrived at the <strong>Sales Orders</strong> page,
                          click on the All Orders dropdown and then click the <strong>Returned Orders</strong> tab.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I create a return authorization (RA)?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On your dashboard, you will view a green button titled “Shipped”.
                          Click this button and you will arrive at your shipped orders page.
                          Search for the sales order that you want to create and RA for and then click on the “Create RA” button.
                          Once selecting reason for return and product quantity, click “Create RA” and then click “Submit RA”.
                          Click “Done” to return to your shipped sales orders.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          You can also create an RA in the <strong>Returned</strong> and <strong>RA</strong> tabs
                          by clicking the “Create RA” button.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On your dashboard, you will view a green button titled “Shipped”.
                          Click this button and you will arrive at your shipped orders page.
                          Search for the sales order that you want to create and RA for
                          and then click on the “Create RA” button.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          You can also create an RA in
                          the <strong>Returned</strong> and <strong>RA</strong> tabs
                          by clicking the “+” button.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I add a new purchase order?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On the left hand sidebar of the site, click on the <strong>Purchase Orders</strong> tab.
                          Here you can view all your purchase orders.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click the blue button titled “Create” and fill out your purchase order information.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>3.</span>
                        <span>
                          Click “Submit Order”.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>4.</span>
                        <span>
                          Click “Confirm Order” and then click “Done” to complete this process.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Click on the menu button in the right-hand corner of the site.
                          Then click on <strong>Purchase Orders</strong>. Here you can view all your purchase orders.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          To create a new purchase order, click the blue “+” button and
                          fill out your purchase order information.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>3.</span>
                        <span>
                          Click “Submit Order”.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>4.</span>
                        <span>
                          Click “Review and Submit” to complete this process.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I view a supply receipt?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On the left hand sidebar of the site, click on the <strong>Supply Receipts</strong> tab.
                          Here you can view all your supply receipts.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Search for supply receipts using a purchase order number, filter by transaction date, or vendor name.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Click on the menu button in the right-hand corner of the site.
                          Then click on <strong>Supply Receipts</strong>. Here you can view all your supply receipts.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the blue search icon on the right to search your
                          supply receipts with its purchase order number.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I view my products?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On the left hand sidebar of the site, click on the <strong>Products</strong> tab.
                          Here you can view all your products.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          You can search for a product by typing the product name or SKU in the search bar.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Click on the menu button in the right-hand corner of the site.
                          Then click on <strong>Products</strong>. Here you can view all your products.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          You can search for a product by typing the product name and SKU in the search bar.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I add a new product?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On the left hand sidebar of the side, click on the <strong>Products</strong> tab.
                          Here you can view all your products.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on “Create” and then click “Add Single Product” to enter the information about your product.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>3.</span>
                        <span>
                          Click “Submit Product” to complete the process.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Click on the menu button in the right-hand corner of the site.
                          Then click on <strong>Products</strong>. Here you can view all your products.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the “+ ” button and then click “Add Single Product” enter the information that your product requires.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>3.</span>
                        <span>
                          Click “Submit Product” to complete the process.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I upload multiple products?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On the left hand sidebar of the side, click on the <strong>Products</strong> tab.
                          Here you can view all your products.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on “Create” and then click “Multiple Single Product”.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>3.</span>
                        <span>
                          Select file and click “Open”
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>4.</span>
                        <span>
                          Once the file has been verified, click “Proceed to Upload” to finalize orders submission.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Click on the menu button in the right hand corner of the site.
                          Then click on <strong>Products</strong>. Here you can view all your products.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the “+ ” button and then click  “Multiple Single Product”. enter the information that your product requires.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>3.</span>
                        <span>
                          Select file.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>4.</span>
                        <span>
                          Once the file has been verified, click “Proceed to Upload” to finalize orders submission.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I view my top selling products?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On the left hand sidebar of the side, click on the <strong>Products</strong> tab. Here you can view all your products.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on <strong>Top Selling Products</strong> in the menu at the top of the page. Here you can search for specific products.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Click on the menu button in the right-hand corner of the site.
                          Then click on <strong>Products</strong>. Here you can view all your products.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Once you have arrived at the products page, click on the <strong>All Products</strong> dropdown
                          and then click on the <strong>Top Selling Products</strong> tab.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I view my low stock products?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On the left hand sidebar of the site, click on the <strong>Products</strong> tab.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the <strong>Low Stock</strong> tab at the top of the page.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Click on the menu button in the right-hand corner of the site.
                          Then click on <strong>Products</strong>. Here you can view all your products.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Once you have arrived at the products page, click on the <strong>All Products</strong> dropdown
                          and then click on the <strong>Low Stock</strong> tab.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I view my products that are out of stock?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On the left hand sidebar of the site, click on the <strong>Products</strong> tab.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the <strong>Out of Stock</strong> tab in the menu at the top of the page.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Click on the menu button in the right-hand corner of the site.
                          Then click on <strong>Products</strong> to be directed to the products page.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Once you have arrived at the products page, click on the <strong>All Products</strong> dropdown
                          and then click on the <strong>Out of Stock</strong> tab.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I add a stock alert?' || drawer.params.title === 'How do I make a stock alert?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On the left hand sidebar of the site, click on the <strong>Stock Alerts</strong> tab.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on “Create” and add in the low and over stock alert quantities you want to receive.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>3.</span>
                        <span>
                          Click “Submit Stock Alert”
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Click on the menu button in the right-hand corner of the site.
                          Then click on <strong>Stock Alerts</strong> to be directed to the stock alerts page.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the “Create” button and enter the product SKU,
                          Low Stock Alert Quantity, and Over stock Alert Quantity.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>3.</span>
                        <span>
                          Press “Submit Stock Alert” to create a new stock alert for a product.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I add a new email address to receive stock alerts?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Click on the menu button in the right-hand corner of the site.
                          Then click on <strong>Stock Alerts</strong> to be directed to the stock alerts page.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the pencil icon to edit the “Send All Alerts To” field.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>3.</span>
                        <span>
                          Type an email address into the field and press save.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>4.</span>
                        <span>
                          If you are adding multiple emails, type a comma after the preceding email address.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Click on the menu button in the right-hand corner of the site.
                          Then click on <strong>Stock Alerts</strong> to be directed to the stock alerts page.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the pencil icon to edit the “Send All Alerts To” field.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>3.</span>
                        <span>
                          Type an email address into the field and press save.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I add multiple email addresses to receive stock alerts?' ?
                <ul className={`mobile ${classes.ulist}`}>
                  <li>
                    <Typography className={classes.txt}>
                      <span>1.</span>
                      <span>
                        Click on the menu button in the right-hand corner of the site.
                        Then click on <strong>Stock Alerts</strong> to be directed to the stock alerts page.
                      </span>
                    </Typography>
                  </li>
                  <li>&nbsp;</li>
                  <li>
                    <Typography className={classes.txt}>
                      <span>2.</span>
                      <span>
                        Click on the pencil icon to edit the “Send All Alerts To” field.
                      </span>
                    </Typography>
                  </li>
                  <li>&nbsp;</li>
                  <li>
                    <Typography className={classes.txt}>
                      <span>3.</span>
                      <span>
                        Type a comma (,) into the field and type another email address.
                        Press save to finish adding another email.
                      </span>
                    </Typography>
                  </li>
                </ul>
              : null }
              { drawer.params.title === 'How do I edit a stock alert for my product?' ?
                <ul className={`mobile ${classes.ulist}`}>
                  <li>
                    <Typography className={classes.txt}>
                      <span>1.</span>
                      <span>
                        Click on the menu button in the right-hand corner of the site.
                        Then click on <strong>Stock Alerts</strong> to be directed to the stock alerts page.
                      </span>
                    </Typography>
                  </li>
                  <li>&nbsp;</li>
                  <li>
                    <Typography className={classes.txt}>
                      <span>2.</span>
                      <span>
                        Click on the pencil icon to edit the product with the saved stock alert.
                      </span>
                    </Typography>
                  </li>
                  <li>&nbsp;</li>
                  <li>
                    <Typography className={classes.txt}>
                      <span>3.</span>
                      <span>
                        Once updating the stock alert, click “Submit Stock Alert”.
                      </span>
                    </Typography>
                  </li>
                </ul>
              : null }
              { drawer.params.title === 'How do I view my sales order report?' ?
                <ul className={`desktop1 ${classes.ulist}`}>
                  <li>
                    <Typography className={classes.txt}>
                      <span>1.</span>
                      <span>
                        Select <strong>Reports</strong> on the left hand sidebar and you will
                        be directed to the <strong>Sales Order Report</strong>.
                      </span>
                    </Typography>
                  </li>
                </ul>
              : null }
              { drawer.params.title === 'How do I view my sales by SKU report?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Select <strong>Reports</strong> on the left hand sidebar and you will
                          be directed to the <strong>Sales Order Report</strong>.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the Sales By SKU button next to Orders Over Time at the top of the screen.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>3.</span>
                        <span>
                          Type in a product SKU or description to open your <strong>Sales by SKU</strong> Report.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Select <strong>Reports</strong> on the left hand sidebar and you will be directed
                          to the <strong>Sales Order Report</strong>.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the “Sales By SKU” button at the top of the screen.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>3.</span>
                        <span>
                          Type in a product SKU or description to open your <strong>Sales by SKU</strong> Report.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I view my inventory report?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Select <strong>Reports</strong> on the left hand sidebar and you will
                          be directed to the sales order report.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the <strong>Inventory</strong> tab at the top of the screen to open your inventory report.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Select <strong>Reports</strong> on the left hand sidebar and you will be directed
                          to the sales order report.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the <strong>Orders</strong> dropdown tab at the top of the screen and
                          then select “Inventory” to open your <strong>Inventory</strong> report.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I view my returned orders report?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Select <strong>Reports</strong> on the left hand sidebar and you will
                          be directed to the sales order report.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the <strong>Returns</strong> tab at the top of the screen to open your returned orders report.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Select <strong>Reports</strong> on the left hand sidebar and you will
                          be directed to the sales order report.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the <strong>Orders</strong> dropdown tab at the top of the screen and
                          then select “Returns” to open your <strong>Returned Orders</strong> report.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I view my supply receipts report?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Select <strong>Reports</strong> on the left hand sidebar and you will
                          be directed to the sales order report.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the <strong>Supply Receipts</strong> tab at the top of the screen to open your supply receipts report.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Select <strong>Reports</strong> on the left hand sidebar and you will
                          be directed to the sales order report.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the <strong>Orders</strong> dropdown tab at the top of the screen and
                          then select “Supply Receipts” to open your <strong>Supply Receipts</strong> report.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I export my reports?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Select <strong>Reports</strong> on the left hand sidebar and you will
                          be directed to the sales order report.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the button labeled “Export” located at the top right corner of the screen
                          and choose which system you want to receive your exported data.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Select <strong>Reports</strong> on the left hand sidebar and you will
                          be directed to the sales order report.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          On each of the reports, there is a button labeled “Export” located at the
                          top right corner of the screen. Click on the button for which version
                          you want to receive your exported data.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'Where can I get support?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On the left hand sidebar of the site, click on the <strong>Support</strong> tab.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Fill out the subject of your support question, select a reason, and fill out the email body.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>3.</span>
                        <span>
                          Click “Create Email” to be directed to your primary email client where you can send your support message.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Click on the menu button in the right-hand corner of the site.
                          Click on <strong>Support</strong>.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Fill out the subject of your support question, select a reason, and fill out the email body.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>3.</span>
                        <span>
                          Click “Create Email” to be directed to your primary email client
                          where you can send your support message.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I view and change my notification settings?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On the left hand sidebar of the site, click on the <strong>Settings</strong> tab.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the <strong>Notification Settings</strong> tab to select which notifications you would like to receive.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Click on the menu button in the right-hand corner of the site.
                          Then click on <strong>Settings</strong> to be directed to the settings page.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Select which notifications you would like to receive.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I view and change my account staff?' ||
              drawer.params.title === 'How do I add and change my account staff?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On the left hand sidebar of the site, click on the <strong>Settings</strong> tab.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the <strong>Manage Roles</strong> tab.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>3.</span>
                        <span>
                          To add staff click on the “+” button and add your staff information
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>4.</span>
                        <span>
                          To edit a staff account click on the pencil icon.
                          One you have edited your information, click “Submit”
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>5.</span>
                        <span>
                          To delete a staff account click on the trashcan icon.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Click on the menu button in the right-hand corner of the site.
                          Then click on <strong>Settings</strong> to be directed to the settings page.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the <strong>Notification Settings</strong> drop down, then select <strong>Manage Roles</strong>.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>3.</span>
                        <span>
                          Click on the blue “+” button to add a new staff account.
                          After filling out your new staff information, click “Submit”.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>4.</span>
                        <span>
                          Click on the blue pencil button to edit a staff account.
                          After editing your new staff information, click “Submit”.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>5.</span>
                        <span>
                          Click on the gray trash can icon to delete a staff account.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I view and change my staff roles?' ||
              drawer.params.title === 'How do I add and change my staff roles?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On the left hand sidebar of the site, click on the <strong>Settings</strong> tab.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the <strong>Manage Roles</strong> tab and scroll down to the <strong>Manage Roles</strong> section.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>3.</span>
                        <span>
                          To add a new role, click the “+” button. Once you fill out your new staff role, click “Submit”.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>4.</span>
                        <span>
                          To edit a role click on the pencil icon. Drag each function to your desired access. Then click “Submit”
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>5.</span>
                        <span>
                          To delete a staff account click “Delete”.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Click on the menu button in the right-hand corner of the site.
                          Then click on <strong>Settings</strong> to be directed to the settings page.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the <strong>Notification Settings</strong> drop down, then select <strong>Manage Roles</strong>.
                          Scroll down to the <strong>Manage Roles</strong> section.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>3.</span>
                        <span>
                          Click on the blue “+” button to add a new staff account.
                          After adding access to your new staff role, click “Submit”.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>4.</span>
                        <span>
                          Click on the blue pencil button to edit a staff role.
                          After editing your new staff information, click “Submit”.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>5.</span>
                        <span>
                          Click on the gray trash can icon to delete a staff role.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I view and change my profile?' ||
              drawer.params.title === 'How do I edit my profile?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On the upper right-hand corner of your screen, click on the down arrow next to your profile name.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the <strong>Profile</strong> dropdown and you will be directed to the <strong>Edit Profile</strong> page.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>3.</span>
                        <span>
                          Once editing your profile, click “Update”.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Click on the menu button in the right-hand corner of the site. Then click on <strong>Profile</strong>.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          After editing your profile information, click “Update”.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'How do I view my account information?' ?
                <Fragment>
                  <ul className={`desktop ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          On the upper right-hand corner of your screen, click on the down arrow next to your profile name.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the <strong>Profile</strong> dropdown. Click on the <strong>Account Info</strong> tab on the top of your screen.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                  <ul className={`mobile ${classes.ulist}`}>
                    <li>
                      <Typography className={classes.txt}>
                        <span>1.</span>
                        <span>
                          Click on the menu button in the right-hand corner of the site. Then click on <strong>Profile</strong>.
                        </span>
                      </Typography>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                      <Typography className={classes.txt}>
                        <span>2.</span>
                        <span>
                          Click on the <strong>Edit Profile</strong> dropdown and select <strong>Account Info</strong>.
                        </span>
                      </Typography>
                    </li>
                  </ul>
                </Fragment>
              : null }
              { drawer.params.title === 'Terms of Service' ?
                <TermsOfService />
              : null }
              { drawer.params.title === 'Glossary of Terms' ?
                <GloassaryOfTerms />
              : null }
            </Fragment>
          : null }
        </div>
      </div>
    );
  }
}

Details.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  drawer: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Details);

