import { bindActionCreators } from 'redux';
import { getVendorOrderList, getOrderDetails } from 'state/orders/selectors';
import { getCountries, getAddressData } from 'state/address/selectors';
import { isItShowingLoad, isItActionShowingLoad, getLoadType } from 'state/loading/selectors';
import { setLoadType } from 'state/loading/actions';
import { showDrawer, showModal, showAlertModal } from 'state/modal-drawer/selectors';
import { getLoginData, getUserLoginSettings, getSettings } from 'state/logged-user/selectors';
import {
  getProductsData,
  getProductList,
  getProductTypes,
  getProductInfo,
  getProductDetail,
  getVendorData,
  getVendorOrdersData,
  getAutoCompleteVendor,
  getManufacturerData,
  getAutoCompleteManufacturer,
  getAutoCompleteProduct,
  getFilters,
  getimportInfo,
  getimportProducts,
  getSalesOrder,
  getSalesOrderPage,
  getStockAlerts,
  getStockAlertRecipient,
  getSupplyReceipts,
  getAutoCompleteFreight,
  getfreightData,
  getSupplyReceiptDetail,
  stockAlertSettingsData,
  getProductsFile,
  getCSVProduct,
  getImportCSVProduct,
} from 'state/products/selectors';
import {
  addProduct,
  fetchProductList,
  fetchMoreProductList,
  fetchProductDetail,
  resetProductDetails,
  productTypes,
  setProductInfo,
  addVendor,
  saveVendor,
  addVendorOrders,
  autocompleteVendor,
  resetVendorAutocomplete,
  addManufacturer,
  autocompleteManufacturer,
  resetManufacturerAutocomplete,
  autocompleteProduct,
  resetProductAutocomplete,
  setFilters,
  importsInfo,
  importProducts,
  fetchProductSalesOrder,
  setSalesOrderPage,
  fetchStockAlert,
  addStockAlert,
  updateStockAlert,
  deleteStockAlert,
  fetchStockAlertRecipient,
  addStockAlertRecipient,
  fetchSupplyReceipts,
  fetchMoreSupplyReceipts,
  autocompleteFreight,
  resetFreightAutocomplete,
  addFreight,
  cancelPurchaseOrder,
  fetchSupplyReceiptDetail,
  resetSupplyReceiptDetails,
  fetchStockAlertSettings,
  updateStockAlertSettings,
  setProductsFile,
  reviewCSVProduct,
  importCSVProduct,
  clearImportCSVProduct,
} from 'state/products/actions';
import { fetchVendorOrderList, fetchMoreVendorOrderList, fetchVendorOrderDetails } from 'state/orders/actions';
import { setUserSettings } from 'state/logged-user/actions';
import * as uiActionCreators from 'state/modal-drawer/actions';

export const mapStateToProps = state => ({
  prod: getProductsData(state),
  products: getProductList(state),
  loading: isItShowingLoad(state),
  drawer: showDrawer(state),
  modal: showModal(state),
  alertModal: showAlertModal(state),
  vendororders: getVendorOrderList(state),
  actionloading: isItActionShowingLoad(state),
  getloadtype: getLoadType(state),
  detail: getOrderDetails(state),
  productdetail: getProductDetail(state),
  supplyreceiptdetail: getSupplyReceiptDetail(state),
  producttypes: getProductTypes(state),
  formdata: getProductInfo(state),
  countries: getCountries(state),
  addressData: getAddressData(state),
  vendordata: getVendorData(state),
  vendorordersdata: getVendorOrdersData(state),
  autoVendor: getAutoCompleteVendor(state),
  manufacturerdata: getManufacturerData(state),
  autoManufacturer: getAutoCompleteManufacturer(state),
  autoProduct: getAutoCompleteProduct(state),
  filtersdata: getFilters(state),
  settings: getSettings(state),
  profile: getLoginData(state),
  usersettings: getUserLoginSettings(state),
  getimportinfo: getimportInfo(state),
  getimportproducts: getimportProducts(state),
  salesorder: getSalesOrder(state),
  orderpage: getSalesOrderPage(state),
  stockalerts: getStockAlerts(state),
  stockalertrecipient: getStockAlertRecipient(state),
  supplyreceipts: getSupplyReceipts(state),
  autoFreight: getAutoCompleteFreight(state),
  freightdata: getfreightData(state),
  stockalertsettings: stockAlertSettingsData(state),
  productsfile: getProductsFile(state),
  csvproduct: getCSVProduct(state),
  importcsvproductdata: getImportCSVProduct(state),
});

export const mapDispatchToProps = dispatch => ({
  addproduct: (data, id) => dispatch(addProduct(data, id)),
  getProductList: (
    limit, page, type, text, vendorId, sortColumn,
    sortType,
  ) => dispatch(fetchProductList(limit, page, type, text, vendorId, sortColumn, sortType)),
  getMoreProductList: (
    limit, page, type, text, vendorId, sortColumn,
    sortType,
  ) => dispatch(fetchMoreProductList(limit, page, type, text, vendorId, sortColumn, sortType)),
  getSupplyReceipts: (
    limit, page, type, text, start, end, vendor, sortColumn,
    sortType,
  ) => dispatch(fetchSupplyReceipts(
    limit, page, type, text, start, end, vendor, sortColumn,
    sortType,
  )),
  getMoreSupplyReceipts: (
    limit, page, type, text, start, end, vendor, sortColumn,
    sortType,
  ) => dispatch(fetchMoreSupplyReceipts(
    limit, page, type, text, start, end, vendor, sortColumn,
    sortType,
  )),
  getProductDetail: id => dispatch(fetchProductDetail(id)),
  clearProductDetail: () => dispatch(resetProductDetails()),
  getSupplyReceiptDetail: id => dispatch(fetchSupplyReceiptDetail(id)),
  clearProductSupplyReceiptDetail: () => dispatch(resetSupplyReceiptDetails()),
  getVendorOrderList: (
    limit, page, text,
    sortColumn, sortType,
  ) => dispatch(fetchVendorOrderList(limit, page, text, sortColumn, sortType)),
  getVendorMoreOrderList: (
    limit, page, text,
    sortColumn, sortType,
  ) => dispatch(fetchMoreVendorOrderList(limit, page, text, sortColumn, sortType)),
  setloadtype: type => dispatch(setLoadType(type)),
  orderDetail: ponumber => dispatch(fetchVendorOrderDetails(ponumber)),
  getProductTypes: () => dispatch(productTypes()),
  setformdata: data => dispatch(setProductInfo(data)),
  addvendor: data => dispatch(addVendor(data)),
  savevendor: (id, data) => dispatch(saveVendor(id, data)),
  addvendororders: data => dispatch(addVendorOrders(data)),
  getAutoVendor: txt => dispatch(autocompleteVendor(txt)),
  resetAutoVendor: () => dispatch(resetVendorAutocomplete()),
  addmanufacturer: data => dispatch(addManufacturer(data)),
  getAutoManufacturer: txt => dispatch(autocompleteManufacturer(txt)),
  resetAutoManufacturer: txt => dispatch(resetManufacturerAutocomplete(txt)),
  getAutoProduct: txt => dispatch(autocompleteProduct(txt)),
  resetAutoProduct: txt => dispatch(resetProductAutocomplete(txt)),
  setfilters: data => dispatch(setFilters(data)),
  setsettings: (uid, data) => dispatch(setUserSettings(uid, data)),
  importsInfo: () => dispatch(importsInfo()),
  importProducts: () => dispatch(importProducts()),
  getProductSalesOrder: (
    id, limit, page,
    more,
  ) => dispatch(fetchProductSalesOrder(id, limit, page, more)),
  setorderpage: page => dispatch(setSalesOrderPage(page)),
  getStockAlerts: (limit, page, more) => dispatch(fetchStockAlert(limit, page, more)),
  addStockAlert: data => dispatch(addStockAlert(data)),
  updateStockAlert: (id, data) => dispatch(updateStockAlert(id, data)),
  deleteStockAlert: id => dispatch(deleteStockAlert(id)),
  getStockAlertRecipient: () => dispatch(fetchStockAlertRecipient()),
  addStockAlertRecipient: data => dispatch(addStockAlertRecipient(data)),
  actions: { ui: bindActionCreators(uiActionCreators, dispatch) },
  getAutoFreightForwarder: txt => dispatch(autocompleteFreight(txt)),
  resetFreightForwarder: () => dispatch(resetFreightAutocomplete()),
  addfreight: data => dispatch(addFreight(data)),
  cancelPO: id => dispatch(cancelPurchaseOrder(id)),
  getStockAlertSettings: () => dispatch(fetchStockAlertSettings()),
  updateStockAlertSettings: data => dispatch(updateStockAlertSettings(data)),
  setProductsFile: data => dispatch(setProductsFile(data)),
  reviewCSVProduct: data => dispatch(reviewCSVProduct(data)),
  importCSVProduct: data => dispatch(importCSVProduct(data)),
  clearImportCSVProduct: () => dispatch(clearImportCSVProduct()),
});
