import { createSelector } from 'reselect';

export const getStaffState = state => state.staff;

export const getStaffData = createSelector(
  [getStaffState],
  staff => staff.list,
);

export const getAccountData = createSelector(
  [getStaffState],
  staff => staff.account,
);

export const getRoleName = createSelector(
  [getStaffState],
  staff => staff.rolename,
);

