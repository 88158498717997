import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
import Modal from 'ui/components/modal/Modal';
import DrawerContainer from 'ui/components/modal/DrawerContainer';
import PageLoader from 'ui/components/loader/PageLoader';
import TopBar from 'ui/components/common/TopBar';
import AllFilters from 'ui/components/common/AllFilters';
import { withStyles } from '@material-ui/core/styles';
import { ColumnData } from 'ui/components/common/MenuList';
import { NavLink } from 'react-router-dom';
import { Button } from '@material-ui/core';
import OrderFilterForm from './OrderFilterForm';
import OrderDetailContainer from './OrderDetailContainer';
import AddMultipleOrder from './AddMultipleOrder';
import styles from './OrdersStyle';

const Loading = () => <div>&nbsp;</div>;

const AllOrders = Loadable({
  loader: () => import(/* webpackChunkName: "all_orders" */'./Listing'),
  loading: Loading,
});

const PendingListing = Loadable({
  loader: () => import(/* webpackChunkName: "pending_orders" */'./PendingListing'),
  loading: Loading,
});

const ShippedListing = Loadable({
  loader: () => import(/* webpackChunkName: "shipped_orders" */'./ShippedListing'),
  loading: Loading,
});

const ReturnedListing = Loadable({
  loader: () => import(/* webpackChunkName: "returned_orders" */'./ReturnedListing'),
  loading: Loading,
});

const ReturnAuthorizationListing = Loadable({
  loader: () => import(/* webpackChunkName: "returned_orders" */'./ReturnAuthorizationListing'),
  loading: Loading,
});

const ThirdPartyListing = Loadable({
  loader: () => import(/* webpackChunkName: "third_party_listing" */'./ThirdPartyListing'),
  loading: Loading,
});

const AddEditOrder = Loadable({
  loader: () => import(/* webpackChunkName: "add_edit_order" */'./AddEditOrder'),
  loading: Loading,
});

const CreateRA = Loadable({
  loader: () => import(/* webpackChunkName: "create_ra" */'./CreateRA'),
  loading: Loading,
});

const ReviewSFOrder = Loadable({
  loader: () => import(/* webpackChunkName: "review_sf_order" */'./ReviewSFOrder'),
  loading: Loading,
});

class Orders extends Component {
  state = {
    limit: 20,
    page: 1,
    searchtxt: '',
  }
  componentWillMount() {
    this.searchFilter();
    const { actions } = this.props;
    const modalKey = 'modal-order';
    const params = { type: '' };
    actions.ui.closeModal({ modalKey, params });
    actions.ui.closeDrawer();
  }
  componentDidMount() {
    this.getImportsInfo();
  }
  componentDidUpdate() {
    const { getloadtype, setloadtype } = this.props;
    if (getloadtype === 'filter updated') {
      setloadtype('');
      this.updateFilter();
    }
    if (getloadtype === 'done sync') {
      setloadtype('');
      this.getImportsInfo();
      this.searchFilter();
    }
  }
  onSearch = (e) => {
    if (e.charCode === 13) {
      const txt = e.target.value.trim();
      this.searchFilter(txt);
    }
  }
  onSearchClose = () => {
    const { searchtxt } = this.state;
    if (searchtxt !== '') {
      this.searchFilter('');
    }
  }
  getImportsInfo = () => {
    const { location, importsInfo } = this.props;
    switch (location.pathname) {
      case '/orders':
      case '/pending-orders':
      case '/shipped-orders':
      case '/returned-orders':
      case '/return-authorization':
      case '/third-party':
        importsInfo();
        break;
      default:
        break;
    }
  }
  updateFilter = () => {
    this.searchFilter();
  }
  searchFilter = (txt) => {
    const { limit, page, searchtxt } = this.state;
    const {
      location, orderList, returnOrderList, thirdPartyList, setloadtype,
      filtersdata, setSortColumn, setSortType,
    } = this.props;
    let stxt = txt;
    if (typeof txt === 'undefined') {
      stxt = searchtxt;
    }
    let recipientName = '';
    let startDate = '';
    let endDate = '';
    let typeId = -1;
    let carrierCode = '';
    let companyName = '';
    let emailAdd = '';

    if (filtersdata.length) {
      for (const key in filtersdata) { // eslint-disable-line
        if (filtersdata[key].id === 1) {
          recipientName = filtersdata[key].recipient_name;
        }
        if (filtersdata[key].id === 'date') {
          startDate = filtersdata[key].date_start;
          endDate = filtersdata[key].date_end;
        }
        if (filtersdata[key].id === 4) {
          typeId = filtersdata[key].type;
        }
        if (filtersdata[key].id === 5) {
          carrierCode = filtersdata[key].carrier_code;
        }
        if (filtersdata[key].id === 6) {
          companyName = filtersdata[key].company_name;
        }
        if (filtersdata[key].id === 7) {
          emailAdd = filtersdata[key].recipient_email;
        }
        if (filtersdata[key].id === 8) {
          recipientName = filtersdata[key].recipient_name;
          companyName = filtersdata[key].company_name;
          emailAdd = filtersdata[key].recipient_email;
        }
      }
    }
    switch (location.pathname) {
      case '/orders':
        orderList(typeId, limit, page, stxt, startDate, endDate, recipientName, carrierCode, companyName, emailAdd, 'ordered_at', 'desc');
        setSortColumn('ordered_at2');
        setSortType('desc');
        break;
      case '/pending-orders':
        orderList(1, limit, page, stxt, startDate, endDate, recipientName, carrierCode, companyName, emailAdd, 'ordered_at', 'desc');
        setSortColumn('ordered_at2');
        setSortType('desc');
        break;
      case '/shipped-orders':
        orderList(4, limit, page, stxt, startDate, endDate, recipientName, carrierCode, companyName, emailAdd, 'ordered_at', 'desc');
        setSortColumn('ordered_at2');
        setSortType('desc');
        break;
      case '/returned-orders':
        returnOrderList(
          1, limit, page, stxt, startDate, endDate, recipientName,
          companyName, emailAdd, 'returned_at', 'desc',
        );
        setSortColumn('returned_at');
        setSortType('desc');
        break;
      case '/return-authorization':
        returnOrderList(
          -1, limit, page, stxt, startDate, endDate, recipientName,
          companyName, emailAdd, 'returned_at', 'desc',
        );
        setSortColumn('returned_at');
        setSortType('desc');
        break;
      case '/third-party':
        thirdPartyList(
          -1, limit, page, stxt, startDate, endDate, recipientName,
          companyName, emailAdd, 'returned_at', 'desc',
        );
        setSortColumn('returned_at');
        setSortType('desc');
        break;
      default:
        break;
    }
    if (typeof txt !== 'undefined') {
      this.setState({
        ...this.state,
        searchtxt: txt,
      });
    }
    setloadtype('search');
  }
  handleSorting = (order, orderBy) => {
    this.child.sortOrders(order, orderBy);
  }

  render() {
    const { classes, ...others } = this.props;
    const { loading, profile } = this.props;
    if (!profile.role.role.SALES_ORDERS) {
      return true;
    }
    if (loading) {
      return (
        <Fragment>
          <PageLoader />
        </Fragment>
      );
    }
    const {
      actions,
      modal,
      location,
      settings,
      usersettings,
    } = this.props;
    const { searchtxt } = this.state;
    const modtype = typeof modal.params !== 'undefined' ? modal.params.type : '';
    const initsettings = (typeof usersettings !== 'undefined' && usersettings !== 'null') ? usersettings : ColumnData;
    let menus = settings !== null ? settings : initsettings;
    menus = ColumnData;

    const loc = location.pathname.split('/', 4, 0);
    const page = loc[1];
    let rturns = false;
    let pagefor = 'orders';
    switch (page) {
      case 'returned-orders':
      case 'return-authorization':
      case 'third-party':
        rturns = true;
        pagefor = 'thirdparty';
        break;
      default:
        break;
    }

    return (
      <Fragment>
        { rturns ?
          <div className={classes.btnswrapper}>
            <Button component={NavLink} to="/returned-orders" className={`${page === 'returned-orders' ? 'active' : ''} ${classes.returnbtn}`}>Returned</Button>
            <Button component={NavLink} to="/return-authorization" className={`${page === 'return-authorization' ? 'active' : ''} ${classes.returnbtn}`}>RA</Button>
            <Button component={NavLink} to="/third-party" className={`${page === 'third-party' ? 'active' : ''} ${classes.returnbtn}`}>3rd Party</Button>
          </div>
        : null }
        <TopBar
          menus={menus}
          page="orders"
          {...others}
          sfc
          onSearch={e => this.onSearch(e)}
          onSearchClose={this.onSearchClose}
        >
          <OrderFilterForm {...others} />
        </TopBar>
        <AllFilters
          {...others}
          sfc
          page={pagefor}
          onSearch={e => this.onSearch(e)}
          onSearchClose={this.onSearchClose}
          onRequestSort={this.handleSorting}
        />
        { location.pathname === '/orders' ?
          <AllOrders
            {...others}
            menus={menus}
            searchtxt={searchtxt}
            onRef={(instance) => { this.child = instance; }}
          />
        : null }
        { location.pathname === '/pending-orders' ?
          <PendingListing
            {...others}
            menus={menus}
            searchtxt={searchtxt}
            onRef={(instance) => { this.child = instance; }}
          />
        : null }
        { location.pathname === '/shipped-orders' ?
          <ShippedListing
            {...others}
            menus={menus}
            searchtxt={searchtxt}
            onRef={(instance) => { this.child = instance; }}
          />
        : null }
        { location.pathname === '/returned-orders' ?
          <ReturnedListing
            {...others}
            menus={menus}
            searchtxt={searchtxt}
            onRef={(instance) => { this.child = instance; }}
          />
        : null }

        { location.pathname === '/return-authorization' ?
          <ReturnAuthorizationListing
            {...others}
            menus={menus}
            searchtxt={searchtxt}
            onRef={(instance) => { this.child = instance; }}
          />
        : null }

        { location.pathname === '/third-party' ?
          <ThirdPartyListing
            {...others}
            menus={menus}
            searchtxt={searchtxt}
            onRef={(instance) => { this.child = instance; }}
          />
        : null }

        <DrawerContainer
          {...others}
        >
          <OrderDetailContainer {...others} />
        </DrawerContainer>
        <Modal
          modalKey="modal-order"
          modalState={modal}
          closeAction={actions.ui.closeModal}
        >
          { modtype === 'add' ?
            <AddEditOrder {...others} />
          : null }

          { modtype === 'create' ?
            <CreateRA {...others} />
          : null }

          { modtype === 'review' ?
            <ReviewSFOrder {...others} />
          : null }

          { modtype === 'addmultipleorder' ?
            <AddMultipleOrder {...others} menus={menus} />
          : null }
        </Modal>
      </Fragment>
    );
  }
}

Orders.propTypes = {
  loading: PropTypes.bool.isRequired,
  orderList: PropTypes.func.isRequired,
  setSortColumn: PropTypes.func.isRequired,
  setSortType: PropTypes.func.isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  filtersdata: PropTypes.instanceOf(Object).isRequired,
  profile: PropTypes.instanceOf(Object).isRequired,
  importsInfo: PropTypes.func, // eslint-disable-line
};

export default withStyles(styles)(Orders);
