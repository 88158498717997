import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './SnackBarContainerStyle';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

function SnackBarContainer(props) {
  const {
    classes, className, message, onClose, variant, ...other
  } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className, classes.default)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

SnackBarContainer.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string, // eslint-disable-line
  message: PropTypes.node, // eslint-disable-line
  onClose: PropTypes.func, // eslint-disable-line
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const SnackBarContainerWrapper = withStyles(styles)(SnackBarContainer);

class SnackBarNotify extends Component {
  state = {
    open: false,
    messageInfo: {},
  };

  componentDidMount() {
    const { onRef } = this.props;
    onRef(this);
  }

  queue = [];
  showMessage = (message, type) => {
    this.queue.push({
      message,
      type,
      key: new Date().getTime(),
    });

    if (this.state.open) {
      // immediately begin dismissing current message
      // to start showing new one
      this.setState({ ...this.state, open: false });
    } else {
      this.processQueue();
    }
  };

  processQueue = () => {
    if (this.queue.length > 0) {
      this.setState({
        ...this.state,
        messageInfo: this.queue.shift(),
        open: true,
      });
    }
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ ...this.state, open: false });
  };

  handleExited = () => {
    this.processQueue();
  };

  render() {
    const { messageInfo } = this.state;
    return (
      <Snackbar
        key={messageInfo.key}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={this.state.open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        onExited={this.handleExited}
      >
        <SnackBarContainerWrapper
          onClose={this.handleClose}
          variant={messageInfo.type}
          message={messageInfo.message}
        />
      </Snackbar>
    );
  }
}

SnackBarNotify.propTypes = {
  onRef: PropTypes.func.isRequired,
};

export default SnackBarNotify;

