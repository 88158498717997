import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Avatar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './ProfileMenuStyle';

class UserAvatar extends Component {
  render() {
    const {
      classes,
      iName,
      size,
      theme,
      shadow,
    } = this.props;
    // console.log(this.props.shadow); // eslint-disable-line
    let avatarSize = 'normal';
    switch (size) {
      case 'medium':
        avatarSize = classes.wh44;
        break;
      case 'large':
        avatarSize = classes.wh65;
        break;
      default:
        break;
    }
    let avshadow = '';
    if (typeof shadow !== 'undefined' && shadow) {
      avshadow = classes.avatarShadow;
    }
    return (
      <div className={`userAvatar ${classes.avwrapper}`}>
        <Avatar
          className={
            `
            avatar
            ${classNames(
              avatarSize,
              classes.avatar,
              avshadow,
              theme === 1 ? `${classes.gradg11} ${classes.gradg12} ${classes.gradg13} ${classes.gradg14} ${classes.gradg15} ${classes.gradg16} ${classes.gradg17}` : '',
              theme === 2 ? `${classes.gradg21} ${classes.gradg22} ${classes.gradg23} ${classes.gradg24} ${classes.gradg25} ${classes.gradg26} ${classes.gradg27}` : '',
              theme === 3 ? `${classes.gradg31} ${classes.gradg32} ${classes.gradg33} ${classes.gradg34} ${classes.gradg35} ${classes.gradg36} ${classes.gradg37}` : '',
              theme === 4 ? `${classes.gradg41} ${classes.gradg42} ${classes.gradg43} ${classes.gradg44} ${classes.gradg45} ${classes.gradg46} ${classes.gradg47}` : '',
              theme === 6 ? `${classes.gradg61} ${classes.gradg62} ${classes.gradg63} ${classes.gradg64} ${classes.gradg65} ${classes.gradg66} ${classes.gradg67}` : '',
            )}
            `
          }
        >
          {iName}
        </Avatar>
      </div>
    );
  }
}

UserAvatar.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  iName: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  theme: PropTypes.number.isRequired,
  shadow: PropTypes.bool.isRequired,
};

export default withStyles(styles)(UserAvatar);
