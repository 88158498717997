import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, Grid, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FormField from 'ui/components/form/FormField';
import { update, setFieldValue, populateOptionFields, resetOptionFields, populateFields } from 'ui/components/form/FormActions';
import styles from './OrderFilterFormStyle';

const WAIT_INTERVAL = 500;

class OrderFilterForm extends Component {
  state = {
    formdata: {
      recipientvalue: '',
      order_date_start: {
        element: 'date',
        value: null,
        config: {
          name: 'order_date_start',
          type: 'date',
          label: 'From',
        },
        validation: {
          required: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
        addeditform: true,
      },
      order_date_end: {
        element: 'date',
        value: null,
        config: {
          name: 'order_date_end',
          type: 'date',
          label: 'To',
        },
        validation: {
          required: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
        addeditform: true,
      },
      recipient_name: {
        element: 'autocomplete',
        value: '',
        config: {
          name: 'recipient_name',
          type: 'number',
          label: 'Recipient Name',
          options: [],
          editing: false,
        },
        validation: {
          required: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
        addeditform: true,
        addNewClicked: (addNew, value) => this.addNewClicked(addNew, value),
        onChange: value => this.onRecipientChange(value),
        hasfunction: true,
        loadstatus: false,
        loadbtnstatus: false,
      },
      status: {
        element: 'select',
        value: '',
        config: {
          label: 'Status',
          name: 'status',
          options: [],
        },
        validation: {
          required: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
        showlabel: true,
        addeditform: true,
        showEmpty: true,
      },
      carrier_code: {
        element: 'select',
        value: '',
        config: {
          label: 'Carrier Code',
          name: 'carrier_code',
          options: [],
        },
        validation: {
          required: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
        showlabel: true,
        addeditform: true,
        showEmpty: true,
      },
      company_name: {
        element: 'input',
        value: '',
        config: {
          label: 'Company Name',
          name: 'company_name',
          type: 'text',
        },
        validation: {
          required: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
        addeditform: true,
      },
      recipient_email: {
        element: 'input',
        value: '',
        config: {
          name: 'recipient_email',
          type: 'email',
          label: 'Email',
        },
        validation: {
          required: false,
          email: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
      },
    },
  }

  componentWillMount() {
    const { filtersdata } = this.props;
    const data = filtersdata.filter(sel => sel.id === 8);
    if (data.length) {
      const list = [{ _id: data[0].recipient_value, name: data[0].recipient_name }];
      const newFormdata = populateOptionFields(this.state.formdata, list, 'recipient_name');
      newFormdata.recipient_name.loadstatus = false;
      newFormdata.recipient_name.value = data[0].recipient_value;
      newFormdata.recipient_name.valid = true;
      newFormdata.recipient_name.touched = true;
      newFormdata.recipient_name.config.editing = true;

      newFormdata.company_name.value = data[0].company_name;
      newFormdata.recipient_email.value = data[0].recipient_email;
      this.updateFields(newFormdata);
    }
    this.timer = null;
  }

  componentDidMount() {
    const { filtersdata, orderstatuses } = this.props;
    if (filtersdata.length) {
      for (const key in filtersdata) { // eslint-disable-line
        if (filtersdata[key].id === 'date') {
          const data = {
            order_date_start: filtersdata[key].date_start,
            order_date_end: filtersdata[key].date_end,
          };
          populateFields(this.state.formdata, data);
        }
      }
    }

    if (typeof orderstatuses !== 'undefined' && orderstatuses.length) {
      const list = [];
      for (const key in orderstatuses) { // eslint-disable-line
        if (orderstatuses[key].attributes.id !== -2) {
          let name = orderstatuses[key].attributes.status.charAt(0).toUpperCase()
            + orderstatuses[key].attributes.status.slice(1);
          if (name === 'Pending') {
            name = 'Processing';
          }
          list.push({ _id: orderstatuses[key].attributes.id, name }); // eslint-disable-line
        }
      }
      const newFormData = populateOptionFields(this.state.formdata, list, 'status');
      this.updateFields(newFormData);
    }
  }

  componentDidUpdate() {
    const {
      getloadtype, setloadtype, actionloading, autoRecipient,
    } = this.props;
    if (!actionloading && getloadtype === 'auto vendor') {
      setloadtype('');
      if (autoRecipient.length) {
        const list = [];
        for (const key in autoRecipient) { // eslint-disable-line
          list.push({ _id: autoRecipient[key].attributes.id, name: autoRecipient[key].attributes.recipient_name }); // eslint-disable-line
        }
        const newFormData = populateOptionFields(this.state.formdata, list, 'recipient_name');
        newFormData.recipient_name.loadstatus = false;
        this.updateFields(newFormData);
      } else {
        const newFormdata = resetOptionFields(this.state.formdata, 'recipient_name');
        newFormdata.recipient_name.loadstatus = false;
        this.updateFields(newFormdata);
      }
    }
  }

  onRecipientChange(recipientvalue) {
    clearTimeout(this.timer);
    this.setState({ ...this.state, recipientvalue });
    this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
    const newFormdata = { ...this.state.formdata };
    newFormdata.recipient_name.loadstatus = true;
    this.updateFields(newFormdata);
  }

  triggerChange = () => {
    const { recipientvalue } = this.state;
    const { getAutoRecipient, resetAutoRecipient, setloadtype } = this.props;
    if (recipientvalue.trim() !== '') {
      getAutoRecipient(recipientvalue);
    } else {
      resetAutoRecipient();
    }
    setloadtype('auto vendor');
  }

  addNewClicked = (addNew, value) => {
    const { autoRecipient/* , handleClose */ } = this.props;
    let vendorInfo = [];
    let selectedVendor = {};
    if (autoRecipient.length) {
      vendorInfo = autoRecipient
        .filter(data => data.attributes.id === value)
        .map(vendor => (vendor.attributes));
      const [vendorData] = vendorInfo;
      selectedVendor = vendorData;
    }
    if (typeof selectedVendor === 'undefined') {
      selectedVendor = {};
    }
    const newFormdata = setFieldValue(this.state.formdata, 'recipient_name', value);
    this.updateFields(newFormdata);
  }
  addSearchFilter = (filterData, elid) => {
    const { filtersdata, setfilters, setloadtype } = this.props;
    const filterItems = [...filtersdata];
    if (filterItems.length) {
      let notfound = true;
      for (const key in filterItems) { // eslint-disable-line
        if (filterItems[key].id === filterData.id) {
          if (elid === 'order_date_end') {
            filterItems[key].order_date_end = filterData.order_date_end;
          }
          if (elid === 'status') {
            filterItems[key].status = filterData.status;
            filterItems[key].type = filterData.type;
          }
          if (elid === 'all') {
            filterItems[key].recipient_name = filterData.recipient_name;
            filterItems[key].company_name = filterData.company_name;
            filterItems[key].recipient_email = filterData.recipient_email;
          }
          notfound = false;
        }
      }
      if (notfound) {
        filterItems.push({ ...filterData });
      }
    } else {
      filterItems.push({ ...filterData });
    }
    setfilters(filterItems);
    setloadtype('filter updated');
  }
  addSearchFilterDate = (moreFilter) => {
    const { filtersdata, setfilters, setloadtype } = this.props;
    let filterItems = [...filtersdata];
    if (filterItems.length) {
      moreFilter.forEach((fdata) => {
        const foundItem = moreFilter.find(item => item.id === fdata.id);
        if (foundItem) {
          filterItems = filterItems.filter(fitem => fitem.id !== fdata.id);
          filterItems.push(fdata);
        }
        if (!foundItem) {
          filterItems.push(fdata);
        }
      });
    } else {
      filterItems = [...moreFilter];
    }

    setfilters(filterItems);
    setloadtype('filter updated');
  }
  updateFields = (newFormdata) => {
    this.setState({
      ...this.state,
      formdata: newFormdata,
    });
  }
  updateForm = (element) => {
    const { handleClose } = this.props;
    let closenow = false;
    const filters = [];
    const newFormdata = update(element, this.state.formdata, '');
    if (element.id === 'order_date_start' || element.id === 'order_date_end') {
      if (newFormdata.order_date_start.value !== null
        && newFormdata.order_date_end.value !== null) {
        filters.push({
          id: 'date',
          date_start: newFormdata.order_date_start.value,
          date_end: newFormdata.order_date_end.value,
        });
        closenow = true;
        this.addSearchFilterDate(filters);
      }
    }
    if (element.id === 'status') {
      const status = newFormdata.status.config.options
        .filter(data => data.key === parseInt(newFormdata.status.value, 10))
        .map(type => (type.value));
      const filterData = {
        id: 4,
        type: parseInt(newFormdata.status.value, 10),
        status: status[0],
      };
      this.addSearchFilter(filterData, 'status');
      closenow = true;
    }
    this.setState({
      formdata: newFormdata,
    });
    if (closenow) {
      handleClose();
    }
  }

  handleSubmit = () => {
    const { formdata } = this.state;
    const { handleClose } = this.props;
    let rname = '';
    for (const k in formdata.recipient_name.config.options) { // eslint-disable-line
      if (formdata.recipient_name.config.options[k].key === formdata.recipient_name.value) {
        rname = formdata.recipient_name.config.options[k].value;
      }
    }
    const filterData = {
      id: 8,
      recipient_name: rname,
      recipient_value: formdata.recipient_name.value,
      company_name: formdata.company_name.value,
      recipient_email: formdata.recipient_email.value,
    };
    this.addSearchFilter(filterData, 'all');
    handleClose();
  }

  render() {
    const { classes, location } = this.props;
    let displayStatus = true;
    switch (location.pathname) {
      case '/orders':
        break;
      case '/pending-orders':
      case '/shipped-orders':
      case '/returned-orders':
        displayStatus = false;
        break;
      default:
        break;
    }
    return (
      <Fragment>
        <List className={classes.ulistcontainer}>
          <ListItem className="datelist">
            <Typography variant="h6" className={classes.h6}>
              Order Date
            </Typography>
            <Grid container spacing={24}>
              <Grid className="datestart" item xs={6}>
                <FormField
                  id="order_date_start"
                  formdata={this.state.formdata.order_date_start}
                  change={element => this.updateForm(element)}
                />
              </Grid>
              <Grid className="dateend" item xs={6}>
                <FormField
                  id="order_date_end"
                  formdata={this.state.formdata.order_date_end}
                  change={element => this.updateForm(element)}
                />
              </Grid>
            </Grid>
          </ListItem>
          { displayStatus ?
            <ListItem>
              <FormField
                id="status"
                formdata={this.state.formdata.status}
                change={element => this.updateForm(element)}
              />
            </ListItem>
          : null }
          <ListItem className="gr1">
            <FormField
              id="recipient_name"
              formdata={this.state.formdata.recipient_name}
              change={element => this.updateForm(element)}
            />
            <p className="space" />
            <FormField
              id="company_name"
              formdata={this.state.formdata.company_name}
              change={element => this.updateForm(element)}
            />
            <p className="space" />
            <FormField
              id="recipient_email"
              formdata={this.state.formdata.recipient_email}
              change={element => this.updateForm(element)}
            />
          </ListItem>
          <div className={classes.btnwrapper}>
            <Button
              color="secondary"
              variant="contained"
              onClick={this.handleSubmit}
            >
              Submit
            </Button>
          </div>
        </List>
      </Fragment>
    );
  }
}

OrderFilterForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  autoRecipient: PropTypes.instanceOf(Object).isRequired,
  getAutoRecipient: PropTypes.func.isRequired,
  resetAutoRecipient: PropTypes.func.isRequired,
  setloadtype: PropTypes.func.isRequired,
  getloadtype: PropTypes.string.isRequired,
  actionloading: PropTypes.bool.isRequired,
  setfilters: PropTypes.func.isRequired,
  filtersdata: PropTypes.instanceOf(Object).isRequired,
  handleClose: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  orderstatuses: PropTypes.instanceOf(Array), // eslint-disable-line
};

export default withStyles(styles)(OrderFilterForm);
