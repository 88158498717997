import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Edit } from '@material-ui/icons';
import { ReactComponent as Close } from 'resources/icons/closev2.svg';
// import { ReactComponent as SyncBtn } from 'resources/icons/sync-btn.svg';
import { Grid, Typography, Button, Fab, IconButton/* , CircularProgress */ } from '@material-ui/core';
import styles from './DetailsHeaderStyle';

class DetailsHeader extends Component {
  // state = {
  //   loading: false,
  // };

  componentDidUpdate() {
    const {
      actionloading, getloadtype, setloadtype,
    } = this.props;
    if (!actionloading && getloadtype === 'updating order') {
      // this.child.showMessage('You can only import once a day', 'error');
      /* setTimeout(() => {
        this.setState({ loading: false });
      }, 1); */
      const { orderDetail, detail } = this.props;
      setloadtype('order detail');
      orderDetail(detail.attributes.id);
    }
  }

  onUpdate = () => {
    const { setloadtype, detail, updatesalesorder } = this.props;
    setloadtype('updating order');
    // this.setState({ loading: true });
    const data = { id: detail.attributes.id };
    updatesalesorder(JSON.stringify(data));
  };

  handleDrawerClose = () => {
    if (document.querySelector('.selected') !== null) {
      document.querySelector('.selected').classList.remove('selected');
    }
    const { actions } = this.props;
    actions.ui.closeDrawer();
  };

  showModal = () => {
    const { actions } = this.props;
    const modalKey = 'modal-order';
    const params = { type: 'add' };
    actions.ui.openModal({ modalKey, params });
  };

  showEditModal = (e) => {
    const { actions, setloadtype } = this.props;
    const modalKey = 'modal-order';
    const params = { type: 'add', action: 'edit', id: e.currentTarget.dataset.id };
    setloadtype('order modal');
    actions.ui.openModal({ modalKey, params });
  };

  createRAModal = (e) => {
    e.stopPropagation();
    const { actions, setloadtype } = this.props;
    const modalKey = 'modal-order';
    const params = { type: 'create', id: e.currentTarget.dataset.id };
    setloadtype('ra modal');
    actions.ui.openModal({ modalKey, params });
  };

  cancelAction = (e) => {
    e.stopPropagation();
  }

  render() {
    const { classes, location, detail } = this.props;
    // const { loading } = this.state;
    const data = detail.attributes;
    const showHold = false;
    let showEdit = false;
    const showCancel = false;
    let showCreate = false;
    let returnlbl = false;
    switch (location.pathname) {
      case '/orders':
      case '/pending-orders':
        if (data.order_status_text === 'new') {
          showEdit = true;
          /* showHold = true;
          showCancel = true; */
        }
        if (data.order_status_text === 'shipped') {
          showCreate = true;
        }
        break;
      case '/shipped-orders':
        if (data.has_returned) {
          showCreate = true;
        }
        break;
      case '/returned-orders':
        returnlbl = true;
        break;
      default:
        break;
    }

    return (
      <div className={classes.drawerHeader}>
        <Grid className={classes.headContainer} container spacing={0}>
          <Grid
            className={`${classes.gridStyle} ${classes.gridleft}`}
            item
            xs={5}
            data-lbl={returnlbl ? 'Return Order Details' : 'Order Details'}
          >
            <Typography className={classes.h1} variant="h1">
              { returnlbl ?
                'Return Order Details'
              :
                'Order Details'
              }
            </Typography>
            {/* { data.order_status_text === 'new' || data.order_status_text === 'pending' ?
              <>
                <div className={`submitWrapper ${classes.submitWrapper}`}>
                  <Button
                    className={`${classes.modalBtn} ${classes.modalBtn2}`}
                    variant="contained"
                    color="secondary"
                    disabled={loading}
                    onClick={this.onUpdate}
                  >
                    Update Order
                  </Button>
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
                <SyncBtn className={`syncbtn ${classes.syncbtn} ${loading ? classes.rotating : ''}`}
                onClick={this.onUpdate} />
              </>
            : null } */}
          </Grid>
          <Grid
            className={`${classes.gridStyle} ${classes.gridright}`}
            item
            xs={7}
            data-lbl={returnlbl ? 'Return Order Details' : 'Order Details'}
          >
            { showEdit ?
              <IconButton
                className={`${classes.icon} ${classes.iconbtn}`}
                aria-label="Edit"
                data-id={data.id}
                onClick={this.showEditModal}
              >
                <Edit />
              </IconButton>
            : null }
            { showHold ?
              <Button
                className={`${classes.btn} ${classes.btnhold}`}
                variant="contained"
                size="small"
              >
                Hold
              </Button>
            : null }
            { showCancel ?
              <Fab onClick={this.cancelAction} variant="extended" aria-label="Delete" className={`${classes.cancelbtn} ${classes.cancelbtn2}`}>
                Cancel Order
              </Fab>
            : null }
            { showCreate ?
              <Button
                className={`${classes.btn} ${classes.btncreate}`}
                variant="contained"
                size="small"
                data-id={data.id}
                onClick={this.createRAModal}
              >
                Create RA
              </Button>
            : null }
            <IconButton
              className={`${classes.icon} ${classes.iconbtn}`}
              aria-label="Close"
              onClick={this.handleDrawerClose}
            >
              <Close className={classes.closev2} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    );
  }
}

DetailsHeader.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  setloadtype: PropTypes.func.isRequired,
  detail: PropTypes.instanceOf(Object).isRequired,
  updatesalesorder: PropTypes.func.isRequired,
  getloadtype: PropTypes.string.isRequired,
  actionloading: PropTypes.bool.isRequired,
  orderDetail: PropTypes.func.isRequired,
};

export default withStyles(styles)(DetailsHeader);
