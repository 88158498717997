const styles = theme => ({
  ...theme.classes,
  container: {
    margin: '0 auto',
    width: 1200,
    padding: '0',
    height: '100vh',
    '@media (max-width:1240px)': {
      width: '100%',
    },
  },
  content: {
    background: theme.palette.common.white,
    height: 'calc(100% - 68px)',
    overflowY: 'auto',
    padding: '34px 40px',
    borderRadius: 8,
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.2)',
      borderRadius: 10,
      backgroundColor: '#FEFEFE',
    },
    '&::-webkit-scrollbar': {
      width: 10,
      backgroundColor: '#FEFEFE',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.2)',
      backgroundColor: '#F3F3F3',
    },
    '& p.note': {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',
      color: '#FD6565',
      marginBottom: 16,
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '0 10px',
    },
    '@media (max-width:1240px)': {
      borderRadius: 0,
      padding: '26px 0',
      '& p.note': {
        padding: '0 20px',
      },
    },
  },
});

export default styles;
