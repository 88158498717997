import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
/* import Confetti from 'react-confetti'; */
import Modal from 'ui/components/modal/Modal';
import DrawerContainer from 'ui/components/modal/DrawerContainer';
import PageLoader from 'ui/components/loader/PageLoader';
import TopBar from 'ui/components/common/TopBar';
import { ColumnData } from 'ui/components/common/MenuList';
import ProductFilters from 'ui/components/common/ProductFilters';
import ProductFilterForm from './ProductFilterForm';

const Loading = () => <div>&nbsp;</div>;

const AllProductsListing = Loadable({
  loader: () => import(/* webpackChunkName: "all_products" */'./Listing'),
  loading: Loading,
});

const VendorOrdersListing = Loadable({
  loader: () => import(/* webpackChunkName: "vendor_orders" */'./VendorOrdersListing'),
  loading: Loading,
});

const ProductDetails = Loadable({
  loader: () => import(/* webpackChunkName: "product_details" */'./ProductDetailContainer'),
  loading: Loading,
});

const VendorOrderDetailContainer = Loadable({
  loader: () => import(/* webpackChunkName: "vendor_order_details" */'./VendorOrderDetailContainer'),
  loading: Loading,
});

const AddEditVendorOrder = Loadable({
  loader: () => import(/* webpackChunkName: "add_edit_vendor_order" */'./AddEditVendorOrder'),
  loading: Loading,
});

const ReviewVendorOrder = Loadable({
  loader: () => import(/* webpackChunkName: "review_vendor_order" */'./ReviewVendorOrder'),
  loading: Loading,
});

const AddEditProduct = Loadable({
  loader: () => import(/* webpackChunkName: "add_edit_product" */'./AddEditProduct'),
  loading: Loading,
});

const ReviewProductInfo = Loadable({
  loader: () => import(/* webpackChunkName: "review_product_info" */'./ReviewProductInfo'),
  loading: Loading,
});

const AddMultipleProducts = Loadable({
  loader: () => import(/* webpackChunkName: "add_multiple_products" */'./AddMultipleProducts'),
  loading: Loading,
});

class Products extends Component {
  state = {
    limit: 20,
    page: 1,
    searchtxt: '',
  }
  componentWillMount() {
    this.searchFilter();
    const { actions } = this.props;
    const modalKey = 'modal-products';
    const params = { type: '' };
    actions.ui.closeModal({ modalKey, params });
    actions.ui.closeDrawer();
  }
  componentDidMount() {
    this.getImportProducts();
  }
  componentDidUpdate() {
    const { getloadtype, setloadtype } = this.props;
    if (getloadtype === 'filter updated') {
      setloadtype('');
      this.updateFilter();
    }
    if (getloadtype === 'done sync') {
      setloadtype('');
      this.getImportProducts();
      this.searchFilter();
    }
  }

  onSearch = (e) => {
    if (e.charCode === 13) {
      const txt = e.target.value.trim();
      this.searchFilter(txt);
    }
  }

  onSearchClose = () => {
    const { searchtxt } = this.state;
    if (searchtxt !== '') {
      this.searchFilter('');
    }
  }
  getImportProducts = () => {
    const { location, importsInfo } = this.props;
    switch (location.pathname) {
      case '/products':
      case '/top-selling-products':
      case '/products-low-in-stocks':
      case '/products-out-of-stocks':
        importsInfo();
        break;
      default:
        break;
    }
  }
  updateFilter = () => {
    this.searchFilter();
  }
  searchFilter = (txt) => {
    const { limit, page, searchtxt } = this.state;
    const {
      location, getVendorOrderList, getProductList, setloadtype, filtersdata,
    } = this.props;
    let stxt = txt;
    if (typeof txt === 'undefined') {
      stxt = searchtxt;
    }
    let vendorId = '';
    if (filtersdata.length) {
      for (const key in filtersdata) { // eslint-disable-line
        if (filtersdata[key].id === 1) {
          vendorId = filtersdata[key].vendor_id;
        }
      }
    }
    switch (location.pathname) {
      case '/products-vendor-orders':
        getVendorOrderList(limit, page, stxt, 'expected_date', 'desc');
        break;
      case '/top-selling-products':
        getProductList(limit, 1, null, stxt, vendorId, 'sold_quantity', 'desc');
        break;
      case '/products-out-of-stocks':
        getProductList(limit, page, 2, stxt, vendorId, 'quantity', 'asc');
        break;
      case '/products-low-in-stocks':
        getProductList(limit, page, 3, stxt, vendorId, 'quantity', 'asc');
        break;
      default:
        getProductList(limit, page, null, stxt, vendorId, 'description', 'asc');
        break;
    }
    if (typeof txt !== 'undefined') {
      this.setState({
        ...this.state,
        searchtxt: txt,
      });
    }
    setloadtype('search');
  }

  render() {
    const { loading, profile } = this.props;
    if (!profile.role.role.PRODUCTS) {
      return true;
    }
    if (loading) {
      return (
        <Fragment>
          <PageLoader />
        </Fragment>
      );
    }
    const { searchtxt } = this.state;
    const {
      actions, modal, location, drawer, settings, usersettings,
    } = this.props;
    const modtype = typeof modal.params !== 'undefined' ? modal.params.type : '';
    const drawertype = typeof drawer.params !== 'undefined' ? drawer.params.type : '';
    const initsettings = (typeof usersettings !== 'undefined' && usersettings !== 'null') ? usersettings : ColumnData;
    let menus = settings !== null ? settings : initsettings;
    menus = ColumnData;
    return (
      <Fragment>
        <TopBar
          menus={menus}
          page="products"
          {...this.props}
          sfc
          onSearch={e => this.onSearch(e)}
          onSearchClose={this.onSearchClose}
        >
          <ProductFilterForm {...this.props} />
        </TopBar>
        <ProductFilters
          {...this.props}
          sfc
          page="products"
          onSearch={e => this.onSearch(e)}
          onSearchClose={this.onSearchClose}
        />
        { location.pathname === '/products-vendor-orders' ?
          <VendorOrdersListing {...this.props} menus={menus} searchtxt={searchtxt} />
        :
          <>
            {/* { location.pathname === '/top-selling-products' ?
              <Confetti
                numberOfPieces={600}
                opacity={6.0}
                recycle={false}
                gravity={0.08}
              />
            : null } */}
            <AllProductsListing {...this.props} menus={menus} searchtxt={searchtxt} />
          </>
        }

        <DrawerContainer
          {...this.props}
        >
          <Fragment>
            { drawertype === 'product details' ?
              <Fragment>
                <ProductDetails {...this.props} />
              </Fragment>
            : null }

            { drawertype === 'vendor order' ?
              <Fragment>
                <VendorOrderDetailContainer {...this.props} />
              </Fragment>
            : null }
          </Fragment>
        </DrawerContainer>
        <Modal
          modalKey="modal-products"
          modalState={modal}
          closeAction={actions.ui.closeModal}
        >
          { modtype === 'add' ?
            <AddEditVendorOrder {...this.props} />
          : null }

          { modtype === 'add product' ?
            <AddEditProduct {...this.props} />
          : null }

          { modtype === 'review' ?
            <ReviewVendorOrder {...this.props} />
          : null }

          { modtype === 'review product' ?
            <ReviewProductInfo {...this.props} />
          : null }

          { modtype === 'addmultipleproducts' ?
            <AddMultipleProducts {...this.props} menus={menus} />
          : null }
        </Modal>
      </Fragment>
    );
  }
}

Products.propTypes = {
  loading: PropTypes.bool.isRequired,
  getVendorOrderList: PropTypes.func.isRequired,
  getProductList: PropTypes.func.isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
  setloadtype: PropTypes.func.isRequired,
  getloadtype: PropTypes.string.isRequired,
  filtersdata: PropTypes.instanceOf(Object).isRequired,
  profile: PropTypes.instanceOf(Object).isRequired,
  importsInfo: PropTypes.func, // eslint-disable-line
};

export default Products;
