var monthly = [{
  id: 1,
  month: 'January 2019',
  qty_sold: 504,
  order_percentage: 28
}, {
  id: 2,
  month: 'February 2019',
  qty_sold: 308,
  order_percentage: 20
}, {
  id: 3,
  month: 'March 2019',
  qty_sold: 202,
  order_percentage: 16
}, {
  id: 4,
  month: 'April 2019',
  qty_sold: 186,
  order_percentage: 14
}, {
  id: 5,
  month: 'May 2019',
  qty_sold: 178,
  order_percentage: 10
}, {
  id: 6,
  month: 'June 2019',
  qty_sold: 178,
  order_percentage: 10
}];
var weekly = [{
  id: 1,
  month: 'Week 1 - Jan 1-4',
  qty_sold: 504,
  order_percentage: 28
}, {
  id: 2,
  month: 'Week 2 - Jan 5-11',
  qty_sold: 308,
  order_percentage: 20
}, {
  id: 3,
  month: 'Week 3 - Jan 12-18',
  qty_sold: 202,
  order_percentage: 16
}, {
  id: 4,
  month: 'Week 4 - Jan 19-25',
  qty_sold: 186,
  order_percentage: 14
}, {
  id: 5,
  month: 'Week 5 - Jan 26-30',
  qty_sold: 178,
  order_percentage: 10
}];
var daily = [{
  id: 1,
  month: 'January 1, 2019',
  qty_sold: 504,
  order_percentage: 28
}, {
  id: 2,
  month: 'January 2, 2019',
  qty_sold: 308,
  order_percentage: 20
}, {
  id: 3,
  month: 'January 3, 2019',
  qty_sold: 202,
  order_percentage: 16
}, {
  id: 4,
  month: 'January 4, 2019',
  qty_sold: 186,
  order_percentage: 14
}, {
  id: 5,
  month: 'January 5, 2019',
  qty_sold: 178,
  order_percentage: 10
}, {
  id: 6,
  month: 'January 6, 2019',
  qty_sold: 178,
  order_percentage: 10
}, {
  id: 7,
  month: 'January 7, 2019',
  qty_sold: 178,
  order_percentage: 10
}];
var orders = [{
  id: 1,
  order_id: 'YOGAB-201808232359-311063YB',
  order_date: '1/5/2019 | 22:04',
  company_name: 'IPS Test',
  recipient_name: 'Megghan Kennedy'
}, {
  id: 2,
  order_id: 'YOGAB-201808232359-311063YB',
  order_date: '1/5/2019 | 22:04',
  company_name: 'IPS Test',
  recipient_name: 'Megghan Kennedy'
}, {
  id: 3,
  order_id: 'YOGAB-201808232359-311063YB',
  order_date: '1/7/2019 | 22:04',
  company_name: 'IPS Test',
  recipient_name: 'Megghan Kennedy'
}, {
  id: 4,
  order_id: 'YOGAB-201808232359-311063YB',
  order_date: '1/9/2019 | 22:04',
  company_name: 'IPS Test',
  recipient_name: 'Megghan Kennedy'
}, {
  id: 5,
  order_id: 'YOGAB-201808232359-311063YB',
  order_date: '1/9/2019 | 22:04',
  company_name: 'IPS Test',
  recipient_name: 'Megghan Kennedy'
}, {
  id: 6,
  order_id: 'YOGAB-201808232359-311063YB',
  order_date: '1/11/2019 | 22:04',
  company_name: 'IPS Test',
  recipient_name: 'Megghan Kennedy'
}, {
  id: 7,
  order_id: 'YOGAB-201808232359-311063YB',
  order_date: '1/11/2019 | 22:04',
  company_name: 'IPS Test',
  recipient_name: 'Megghan Kennedy'
}];
var products = [{
  id: 1,
  product_name: 'Trapeze Door Mount',
  sku: 'LB-B1-350-BLUE',
  vendor: 'Megghan Kennedy',
  upc: '752830806269',
  qty_sold: '12,600',
  available_qty: 245
}, {
  id: 2,
  product_name: 'Diffuser',
  sku: 'LB-B1-350-BLUE',
  vendor: 'Megghan Kennedy',
  upc: '752830806269',
  qty_sold: '8,340',
  available_qty: 1200
}, {
  id: 3,
  product_name: 'Oils-Box',
  sku: 'LB-B1-350-BLUE',
  vendor: 'Megghan Kennedy',
  upc: '752830806269',
  qty_sold: '7,234',
  available_qty: 454
}, {
  id: 4,
  product_name: 'Yoga Trapeze Orange',
  sku: 'LB-B1-350-BLUE',
  vendor: 'Megghan Kennedy',
  upc: '752830806269',
  qty_sold: '7,002',
  available_qty: 87
}, {
  id: 5,
  product_name: 'Trapeze Extra Long Handle Orange',
  sku: 'LB-B1-350-BLUE',
  vendor: 'Megghan Kennedy',
  upc: '752830806269',
  qty_sold: '5,230',
  available_qty: 4
}, {
  id: 6,
  product_name: 'PD4631-22',
  sku: 'LB-B1-350-BLUE',
  vendor: 'Megghan Kennedy',
  upc: '752830806269',
  qty_sold: '5104',
  available_qty: 60
}, {
  id: 7,
  product_name: 'TK-MAGNET',
  sku: 'LB-B1-350-BLUE',
  vendor: 'Megghan Kennedy',
  upc: '752830806269',
  qty_sold: '4,056',
  available_qty: 832
}];
var topselling = [{
  id: 1,
  product_name: 'Trapeze Door Mount',
  sku: 'LB-B1-350-BLUE',
  qty_sold: '5,983',
  total_sales_percentage: 34
}, {
  id: 2,
  product_name: 'Diffuser',
  sku: 'LB-B1-350-BLUE',
  qty_sold: '3,689',
  total_sales_percentage: 27
}, {
  id: 3,
  product_name: 'Oils-Box',
  sku: 'LB-B1-350-BLUE',
  qty_sold: '2,781',
  total_sales_percentage: 20
}, {
  id: 4,
  product_name: 'Yoga Trapeze Orange',
  sku: 'LB-B1-350-BLUE',
  qty_sold: '1,342',
  total_sales_percentage: 18
}, {
  id: 5,
  product_name: 'Trapeze Extra Long Handle Orange',
  sku: 'LB-B1-350-BLUE',
  qty_sold: '985',
  total_sales_percentage: 12
}, {
  id: 6,
  product_name: 'PD4631-22',
  sku: 'LB-B1-350-BLUE',
  qty_sold: '723',
  total_sales_percentage: 8
}, {
  id: 7,
  product_name: 'TK-MAGNET',
  sku: 'LB-B1-350-BLUE',
  qty_sold: '127',
  total_sales_percentage: 2
}];
export var getMonthlySales = function getMonthlySales() {
  return monthly;
};
export var getWeeklySales = function getWeeklySales() {
  return weekly;
};
export var getDailySales = function getDailySales() {
  return daily;
};
export var getOrdersReport = function getOrdersReport() {
  return orders;
};
export var getStock = function getStock() {
  return products;
};
export var topSelling = function topSelling() {
  return topselling;
};