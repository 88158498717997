import { getStaff, getStaffById, updateUser, addUser, deleteUser, updatClientInfo } from 'api/staff';
import { showActionLoading, hideActionLoading, showLoading, hideLoading } from 'state/loading/actions';
import { setUserData } from 'state/logged-user/actions';
import responseCheker from 'api/responseCheker';
import { openAlertModal } from 'state/modal-drawer/actions';

import {
  SET_STAFF_DATA,
  SET_ACCOUNT_DATA,
  RESET_ACCOUNT_DATA,
  SET_ROLE_NAME_DATA,
} from './types';

export const setStaff = staff => ({
  type: SET_STAFF_DATA,
  payload: { staff },
});

export const resetAccount = () => ({
  type: RESET_ACCOUNT_DATA,
  payload: {
    account: {},
  },
});

export const setAccount = account => ({
  type: SET_ACCOUNT_DATA,
  payload: { account },
});

export const setRoleName = rolename => ({
  type: SET_ROLE_NAME_DATA,
  payload: { rolename },
});

export const successResponse = response => (dispatch) => {
  dispatch(responseCheker(response));
  if (response.data) {
    dispatch(setStaff(response.data));
  }
  dispatch(hideLoading());
};

export const successAcctResponse = response => (dispatch) => {
  dispatch(responseCheker(response));
  if (response.data) {
    dispatch(setAccount(response.data));
  }
  dispatch(hideLoading());
};

export const errorResponse = (err, type) => (dispatch) => {
  const result = dispatch(responseCheker(err.response));
  if (result === 'error') {
    const modalKey = 'alert';
    const params = {
      type: 'error',
      title: '',
      description: err.response.data.errors[0].detail,
    };
    dispatch(openAlertModal({ modalKey, params }));
  }
  if (result === 'TypeError') {
    const modalKey = 'alert';
    const params = {
      type: 'error',
      title: '',
      description: 'Something went wrong. This has been reported',
    };
    dispatch(openAlertModal({ modalKey, params }));
  }
  if (type !== 'delete') {
    dispatch(hideLoading());
  }
  dispatch(hideActionLoading());
};

export const fetchStaff = (limit = 10, page = 1) => (dispatch) => {
  dispatch(showLoading());
  getStaff(limit, page).then(
    response => dispatch(successResponse(response)),
    err => dispatch(errorResponse(err, 'fetch')),
  );
};

export const fetchStaffById = id => (dispatch) => {
  getStaffById(id).then(
    response => dispatch(successAcctResponse(response)),
    err => dispatch(errorResponse(err, 'fetch')),
  );
};

export const clearAccount = () => (dispatch) => {
  dispatch(resetAccount());
};

export const successUpdateResponse = (response, loginData) => (dispatch) => {
  if (response.data) {
    if (loginData) {
      dispatch(setUserData(loginData));
    }
  }
  dispatch(hideActionLoading());
};

export const successDeleteResponse = response => (dispatch) => {
  if (response) {
    console.log(response); // eslint-disable-line
  }
  dispatch(hideActionLoading());
};

export const updateProfile = (id, data, loginData) => (dispatch) => {
  dispatch(showActionLoading());
  updateUser(id, data).then(
    response => dispatch(successUpdateResponse(response, loginData)),
    err => dispatch(errorResponse(err, 'update')),
  );
};

export const updateClient = (dataToSubmit, loginData) => (dispatch) => {
  dispatch(showActionLoading());
  updatClientInfo(dataToSubmit).then(
    response => dispatch(successUpdateResponse(response, loginData)),
    err => dispatch(errorResponse(err, 'update')),
  );
};

export const addStaff = data => (dispatch) => {
  dispatch(showActionLoading());
  addUser(data).then(
    response => dispatch(successUpdateResponse(response, false)),
    err => dispatch(errorResponse(err, 'add')),
  );
};

export const deleteStaff = id => (dispatch) => {
  dispatch(showActionLoading());
  deleteUser(id).then(
    response => dispatch(successDeleteResponse(response)),
    err => dispatch(errorResponse(err, 'delete')),
  );
};
