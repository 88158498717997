import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getStaffData, getAccountData, getRoleName } from 'state/staff/selectors';
import { isItShowingLoad, isItActionShowingLoad, getLoadType } from 'state/loading/selectors';
import { showModal, showAlertModal } from 'state/modal-drawer/selectors';
import { getLoginData } from 'state/logged-user/selectors';
import { getRoleList } from 'state/role/selectors';
import { fetchRoleList } from 'state/role/actions';
import { setLoadType } from 'state/loading/actions';
import { fetchStaff, fetchStaffById, clearAccount, updateProfile, addStaff, deleteStaff, setRoleName } from 'state/staff/actions';
import * as uiActionCreators from 'state/modal-drawer/actions';

import AccountStaff from './AccountStaff';

export const mapStateToProps = state => ({
  acctStaff: getStaffData(state),
  acctData: getAccountData(state),
  loading: isItShowingLoad(state),
  actionloading: isItActionShowingLoad(state),
  getloadtype: getLoadType(state),
  modal: showModal(state),
  alertModal: showAlertModal(state),
  loginData: getLoginData(state),
  userRoleList: getRoleList(state),
  profile: getLoginData(state),
  rolename: getRoleName(state),
});

export const mapDispatchToProps = dispatch => ({
  fetchstaff: (limit, page) => dispatch(fetchStaff(limit, page)),
  fetchrolelist: (limit, page) => dispatch(fetchRoleList(limit, page)),
  actions: { ui: bindActionCreators(uiActionCreators, dispatch) },
  getStaff: id => dispatch(fetchStaffById(id)),
  resetAcctData: () => dispatch(clearAccount()),
  updateprofile: (id, data, acctData) => dispatch(updateProfile(id, data, acctData)),
  addstaff: data => dispatch(addStaff(data)),
  removestaff: id => dispatch(deleteStaff(id)),
  setloadtype: type => dispatch(setLoadType(type)),
  setrolename: name => dispatch(setRoleName(name)),
});

// connect the component
const AccountStaffContainer = connect(mapStateToProps, mapDispatchToProps)(AccountStaff);

// export connected component (Container)
export default AccountStaffContainer;
