import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, IconButton, InputBase } from '@material-ui/core';
import { ReactComponent as Search } from 'resources/icons/search.svg';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import styles from './TopBarStyle';

class Searchbtn extends Component {
  state = {
    enableSearch: false,
    inputFocused: false,
    searchtxt: '',
  };
  beginSearch = (e) => {
    if (e.charCode === 13) {
      const { triggerSearch } = this.props;
      triggerSearch(e);
    }
  }
  closeSearch = () => {
    const { triggerSearchClose } = this.props;
    triggerSearchClose();
    this.setState({
      ...this.state,
      enableSearch: false,
      searchtxt: '',
    });
  }
  searchNow = () => {
    this.setState({
      ...this.state,
      enableSearch: true,
      inputFocused: true,
    });
  }
  handleChange = (e) => {
    this.setState({ ...this.state, searchtxt: e.target.value });
  };
  handleClick = () => {
    this.setState({ ...this.state, searchtxt: '' });
  };
  render() {
    const { enableSearch, inputFocused, searchtxt } = this.state;
    const { classes, searchType, location } = this.props;
    let searchTitle = 'Search';
    switch (location.pathname) {
      case '/orders':
      case '/pending-orders':
      case '/shipped-orders':
      case '/returned-orders':
      case '/return-authorization':
        searchTitle = 'Search Order ID';
        break;
      case '/address-book':
        searchTitle = 'Search Customer Name';
        break;
      case '/products-vendor-orders':
        searchTitle = 'Search PO Number';
        break;
      case '/products':
        searchTitle = 'Search Product Name';
        break;
      default:
        break;
    }

    let stype = '';
    if (typeof searchType !== 'undefined') {
      stype = searchType;
    }

    return (
      <div className={classes.searchbox}>
        <div className={classes.main}>
          <IconButton className={`${classes.searchicon} ${classes.iconbtn}`} aria-label="Search" onClick={this.searchNow}>
            <Search className={classes.icon} />
          </IconButton>
          <Typography className={`${classes.stitle} ${stype}`} component="span" onClick={this.searchNow}>
            {searchTitle}
          </Typography>
        </div>
        {enableSearch ?
          <div className={`${classes.absearch} ${stype}`}>
            <div className={`${classes.absearchicon} ${stype}`}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder={searchTitle}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              autoFocus={inputFocused}
              inputProps={{ 'aria-label': 'Search' }}
              onKeyPress={e => this.beginSearch(e)}
              value={searchtxt}
              onChange={e => this.handleChange(e)}
              onClick={e => this.handleClick(e)}
            />
            <CloseIcon className={classes.closesearch} onClick={e => this.closeSearch(e)} />
          </div>
        : null }
      </div>
    );
  }
}

Searchbtn.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  triggerSearch: PropTypes.func.isRequired,
  triggerSearchClose: PropTypes.func.isRequired,
  searchType: PropTypes.string, // eslint-disable-line
};

export default withStyles(styles)(Searchbtn);
