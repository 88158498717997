import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, List, ListItem, ListItemAvatar, ListItemText, IconButton } from '@material-ui/core';
import { ReactComponent as Mail } from 'resources/icons/mail.svg';
import { ReactComponent as Edit } from 'resources/icons/edit.svg';
import { ReactComponent as Delete } from 'resources/icons/delete.svg';
import PageLoader from 'ui/components/loader/PageLoader';
import UserAvatar from 'ui/components/common/UserAvatar';
import { getTheme } from 'api/misc';
/* import PageLoader from 'ui/components/loader/PageLoader'; */
import styles from './AccountStaffStyle';

class AccountStaffListing extends Component {
  componentDidUpdate() {
    const {
      actions, actionloading, getloadtype, setloadtype, fetchstaff, alertModal, removestaff,
    } = this.props;
    if (!actionloading && getloadtype === 'delete staff') {
      setloadtype('staff listing');
      fetchstaff(20, 1);
    }
    if (!actionloading && getloadtype === 'staff listing') {
      setloadtype('');
    }
    if (typeof alertModal.params.type !== 'undefined' && alertModal.params.type === 'response') {
      if (alertModal.params.response === 'yes') {
        if (getloadtype === 'remove staff') {
          const modalKey = '';
          const params = {};
          actions.ui.closeAlertModal({ modalKey, params });
          setloadtype('delete staff');
          removestaff(alertModal.params.staff_id);
        }
      }
    }
  }

  showModal = (e) => {
    e.stopPropagation();
    const { actions, getStaff } = this.props;
    const modalKey = 'modal-staff';
    const params = { type: 'edit' };
    actions.ui.openModal({ modalKey, params });
    getStaff(parseInt(e.currentTarget.dataset.id, 10));
  };

  handleRemove = (e) => {
    const { actions, setloadtype } = this.props;
    const modalKey = 'alert';
    const params = {
      type: 'confirm',
      title: `${e.currentTarget.dataset.name} will be deleted permanently!`,
      description: 'Are you sure to proceed?',
      staff_id: e.currentTarget.dataset.id,
    };
    setloadtype('remove staff');
    actions.ui.openAlertModal({ modalKey, params });
  }

  render() {
    const { loading, actionloading, getloadtype } = this.props;
    if (loading) {
      return (
        <Fragment>
          <PageLoader />
        </Fragment>
      );
    }
    if (actionloading && getloadtype === 'delete staff') {
      return (
        <Fragment>
          <PageLoader />
        </Fragment>
      );
    }
    if (actionloading && getloadtype === 'staff listing') {
      return (
        <Fragment>
          <PageLoader />
        </Fragment>
      );
    }
    const {
      classes, acctStaff, loginData,
    } = this.props;
    return (
      <div>
        <Grid className={classes.gridlist} container>
          { typeof acctStaff.data !== 'undefined' ?
            acctStaff.data.map(staff => (
              <Grid className={`griditem item-${staff.attributes.id} ${classes.w100}`} item xs={6} key={staff.attributes.id}>
                <List className={classes.list}>
                  <ListItem alignItems="flex-start">
                    { loginData.role.is_owner ?
                      <Fragment>
                        { loginData.customer_user.email !== staff.attributes.email ?
                          <Fragment>
                            <IconButton className={classNames(classes.iconbtn, classes.edit)} aria-label="Edit" data-id={staff.attributes.id} onClick={this.showModal}>
                              <Edit />
                            </IconButton>
                            <IconButton
                              className={classNames(classes.iconbtn, classes.del)}
                              aria-label="Delete"
                              data-id={staff.attributes.id}
                              data-name={`${staff.attributes.firstname} ${staff.attributes.lastname}`}
                              onClick={this.handleRemove}
                            >
                              <Delete />
                            </IconButton>
                          </Fragment>
                        : null }
                      </Fragment>
                    : null }
                    <ListItemAvatar>
                      <UserAvatar iName={`${staff.attributes.firstname[0]}${staff.attributes.lastname[0]}`} size="large" theme={getTheme(staff.attributes.firstname[0])} shadow={false} />
                    </ListItemAvatar>
                    <div className="item">
                      <ListItemText
                        primary={`${staff.attributes.firstname} ${staff.attributes.lastname}`}
                        secondary={
                          <Fragment>
                            <Typography component="label" className="email" color="textPrimary">
                              <Mail /> {staff.attributes.email}
                            </Typography>
                            <Typography component="span" className="role">
                              Role: {staff.attributes.role.name}
                            </Typography>
                          </Fragment>
                        }
                      />
                    </div>
                  </ListItem>
                </List>
              </Grid>
            ))
          : null }
        </Grid>
      </div>
    );
  }
}

AccountStaffListing.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  acctStaff: PropTypes.instanceOf(Object).isRequired,
  loginData: PropTypes.instanceOf(Object).isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
  alertModal: PropTypes.instanceOf(Object).isRequired,
  getStaff: PropTypes.func.isRequired,
  removestaff: PropTypes.func.isRequired,
  setloadtype: PropTypes.func.isRequired,
  getloadtype: PropTypes.string.isRequired,
  actionloading: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchstaff: PropTypes.func.isRequired,
};

export default withStyles(styles)(AccountStaffListing);

