/* import { makeRequest, METHODS } from '@entando/apimanager';
import { getCarrierServiceData } from 'test/mocks/orders'; */
import axios from 'axios';
import { fuelheaders, fuelmultiheaders } from 'state/logged-user/actions';
import { SITE_SERVER } from './misc';

if (process.env.NODE_ENV !== 'development') {
  axios.defaults.withCredentials = true;
}

export const requestOrders = (
  type, limit, page, text, start, end, name, code, company, email, sortColumn, sortType,
  exceptOrderStatusNew,
) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/orders?limit=${limit}&page=${page}${type !== null ? `&type=${type}` : ''}${text !== null ? `&text=${text}` : ''}${start !== null ? `&order_date_start=${start}` : ''}${end !== null ? `&order_date_end=${end}` : ''}${name !== null ? `&recipient_name=${name}` : ''}${code !== null ? `&carrier_code=${code}` : ''}${company !== null ? `&company_name=${company}` : ''}${email !== null ? `&recipient_email=${email}` : ''}${sortColumn !== null ? `&sort_column=${sortColumn}` : ''}${sortType !== null ? `&sort_type=${sortType}` : ''}${exceptOrderStatusNew !== null ? `&except_order_status_new=${exceptOrderStatusNew}` : ''}`,
  headers: fuelheaders(),
});

export const requestOrdersItemList = (
  type, limit, page, sku, order, recipient, po,
  email, tracking, start, end, sortColumn, sortType, shippedStart, shippedEnd,
) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/orderitems?limit=${limit}&page=${page}${type !== null ? `&type=${type}` : ''}${sku !== null ? `&sku=${sku}` : ''}${order !== null ? `&order_no=${order}` : ''}${recipient !== null ? `&recipient_name=${recipient}` : ''}${po !== null ? `&po_no=${po}` : ''}${email !== null ? `&recipient_email=${email}` : ''}${tracking !== null ? `&tracking_no=${tracking}` : ''}${start !== null ? `&order_date_start=${start}` : ''}${end !== null ? `&order_date_end=${end}` : ''}${sortColumn !== null ? `&sort_column=${sortColumn}` : ''}${sortType !== null ? `&sort_type=${sortType}` : ''}${shippedStart !== null ? `&shipped_date_start=${shippedStart}` : ''}${shippedEnd !== null ? `&shipped_date_end=${shippedEnd}` : ''}`,
  headers: fuelheaders(),
});

export const requestReturnOrders = (
  status, limit, page, text, start, end, name, company, email,
  sortColumn, sortType, display,
) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/returnorders?status=${status}&limit=${limit}&page=${page}${text !== null ? `&text=${text}` : ''}${start !== null ? `&date_start=${start}` : ''}${end !== null ? `&date_end=${end}` : ''}${name !== null ? `&recipient_name=${name}` : ''}${company !== null ? `&company_name=${company}` : ''}${email !== null ? `&recipient_email=${email}` : ''}${sortColumn !== null ? `&sort_column=${sortColumn}` : ''}${sortType !== null ? `&sort_type=${sortType}` : ''}${typeof display !== 'undefined' && display !== null ? `&display=${display}` : '&display=0'}`,
  headers: fuelheaders(),
});

export const requestReturnOrdersReport = (
  status, limit, page, text, start, end, sku,
  recipient, email, ra, sortColumn, sortType, display,
) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/returnorders?status=${status}&limit=${limit}&page=${page}${text !== null ? `&text=${text}` : ''}${start !== null ? `&date_start=${start}` : ''}${end !== null ? `&date_end=${end}` : ''}${sku !== null ? `&sku=${sku}` : ''}${recipient !== null ? `&recipient_name=${recipient}` : ''}${email !== null ? `&recipient_email=${email}` : ''}${ra !== null ? `&ra_number=${ra}` : ''}${sortColumn !== null ? `&sort_column=${sortColumn}` : ''}${sortType !== null ? `&sort_type=${sortType}` : ''}${typeof display !== 'undefined' && display !== null ? `&display=${display}` : '&display=0'}`,
  headers: fuelheaders(),
});

export const requestVendorOrders = (limit, page, text, sortColumn, sortType) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/vendorders?limit=${limit}&page=${page}${text !== null ? `&text=${text}` : ''}${sortColumn !== null ? `&sort_column=${sortColumn}` : ''}${sortType !== null ? `&sort_type=${sortType}` : ''}`,
  headers: fuelheaders(),
});

export const requestOrderDetails = id => axios({
  method: 'GET',
  url: `${SITE_SERVER}/orders/${id}`,
  headers: fuelheaders(),
});

export const requestOrderItems = ranumber => axios({
  method: 'GET',
  url: `${SITE_SERVER}/returnorders/${ranumber}`,
  headers: fuelheaders(),
});

export const requestVendorOrderDetails = ponumber => axios({
  method: 'GET',
  url: `${SITE_SERVER}/vendorders/${ponumber}`,
  headers: fuelheaders(),
});

export const postSalesOrder = (dataToSubmit, id) => axios({
  method: id !== null ? 'PUT' : 'POST',
  url: `${SITE_SERVER}/orders${id !== null ? `/${id}` : ''}`,
  headers: fuelheaders(),
  data: dataToSubmit,
});

export const postReturnOrders = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/returnorders`,
  headers: fuelheaders(),
  data: dataToSubmit,
});

export const requestCarrierService = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/orders/create`,
  headers: fuelheaders(),
});

/* export const requestCarrierService = () => ( // eslint-disable-line
  makeRequest({
    uri: `${SITE_SERVER.replace('/api', '')}/storage/data/carrierServiceLevels.json`,
    method: METHODS.GET,
    mockResponse: getCarrierServiceData(),
    useAuthentication: false,
  })
); */

export const postOrderRecipient = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/order_recipients`,
  headers: fuelheaders(),
  data: dataToSubmit,
});

export const updateOrderRecipient = (id, dataToSubmit) => axios({
  method: 'PUT',
  url: `${SITE_SERVER}/order_recipients/${id}`,
  headers: fuelheaders(),
  data: dataToSubmit,
});

export const autoSearchRecipient = (txt, limit, page) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/autocomplete/order_recipients?limit=${limit}&page=${page}&text=${txt}`,
  headers: fuelheaders(),
});

export const importWMSOrders = () => axios({
  method: 'POST',
  url: `${SITE_SERVER}/wms_imports/orders`,
  headers: fuelheaders(),
});

export const importWMSOrdersById = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/wms_imports/by_order_id`,
  headers: fuelheaders(),
  data: dataToSubmit,
});

export const lastImportOrders = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/wms_imports/orders`,
  headers: fuelheaders(),
});

export const autoSearchOrders = (txt, status, limit, page) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/autocomplete/orders?limit=${limit}&page=${page}&text=${txt}${status !== null ? `&order_status=${status}` : ''}`,
  headers: fuelheaders(),
});

export const cancelSalesOrder = id => axios({
  method: 'PUT',
  url: `${SITE_SERVER}/orders/${id}/cancel`,
  headers: fuelheaders(),
});

export const fetchOrderStatuses = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/orders/statuses`,
  headers: fuelheaders(),
});

export const postCSVOrder = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/orders/loadcsvdata`,
  headers: fuelmultiheaders(),
  data: dataToSubmit,
});

export const saveCSVOrder = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/orders/importcsvdata`,
  headers: fuelheaders(),
  data: dataToSubmit,
});

export const requestThirdParty = (
  status, limit, page, text, start, end, name, company, email,
  sortColumn, sortType,
) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/thirdparty_returnorders?status=${status}&limit=${limit}&page=${page}${text !== null ? `&text=${text}` : ''}${start !== null ? `&date_start=${start}` : ''}${end !== null ? `&date_end=${end}` : ''}${name !== null ? `&recipient_name=${name}` : ''}${company !== null ? `&company_name=${company}` : ''}${email !== null ? `&recipient_email=${email}` : ''}${sortColumn !== null ? `&sort_column=${sortColumn}` : ''}${sortType !== null ? `&sort_type=${sortType}` : ''}`,
  headers: fuelheaders(),
});

export const requestThirdPartyDetails = id => axios({
  method: 'GET',
  url: `${SITE_SERVER}/thirdparty_returnorders/${id}`,
  headers: fuelheaders(),
});
