import { createSelector } from 'reselect';

export const salesReportSelector = state => state.reports.sales;
export const ordersReportSelector = state => state.reports.orders;
export const salesTabSelector = state => state.reports.tab;
export const stockProductsSelector = state => state.reports.products;
export const viewReportSelector = state => state.reports.view;
export const sellingProductsSelector = state => state.reports.sellproducts;
export const dashboardSelector = state => state.reports.dashboard;
export const weeklyProductsSelector = state => state.reports.weeklyproducts;
export const monthlyProductsSelector = state => state.reports.monthlyproducts;
export const timePeriodSelector = state => state.reports.timeperiod;
export const orDailySelector = state => state.reports.ordaily;
export const orWeeklySelector = state => state.reports.orweekly;
export const orMonthlySelector = state => state.reports.ormonthly;
export const orMonthlyDashboardSelector = state => state.reports.ormonthlydashboard;
export const prodMonthlySelector = state => state.reports.prodmonthly;
export const prodWeeklySelector = state => state.reports.prodweekly;
export const prodDailySelector = state => state.reports.proddaily;
export const dailyPropsSelector = state => state.reports.dailyprops;
export const skuSelector = state => state.reports.skudata;
export const dayDataSelector = state => state.reports.daydata;
export const reportsColumnDataSelector = state => state.reports.reportColumnData;
export const exportdataSelector = state => state.reports.exportdata;

export const salesReport = createSelector(
  salesReportSelector,
  sales => sales,
);

export const ordersReport = createSelector(
  ordersReportSelector,
  orders => orders,
);

export const salesTab = createSelector(
  salesTabSelector,
  tab => tab,
);

export const stockProducts = createSelector(
  stockProductsSelector,
  products => products,
);

export const viewReport = createSelector(
  viewReportSelector,
  view => view,
);
export const topSellingProducts = createSelector(
  sellingProductsSelector,
  sellproducts => sellproducts,
);

export const dashboardData = createSelector(
  dashboardSelector,
  dashboard => dashboard,
);

export const monthlyProductData = createSelector(
  monthlyProductsSelector,
  monthlyproducts => monthlyproducts,
);

export const weeklyProductData = createSelector(
  weeklyProductsSelector,
  weeklyproducts => weeklyproducts,
);

export const getTimePeriod = createSelector(
  timePeriodSelector,
  timeperiod => timeperiod,
);

export const getOrderDailyReports = createSelector(
  orDailySelector,
  ordaily => ordaily,
);

export const getOrderWeeklyReports = createSelector(
  orWeeklySelector,
  orweekly => orweekly,
);

export const getOrderMonthlyReports = createSelector(
  orMonthlySelector,
  ormonthly => ormonthly,
);

export const getOrderMonthlyReportsDashboard = createSelector(
  orMonthlyDashboardSelector,
  ormonthlydashboard => ormonthlydashboard,
);

export const getProductMonthlyReports = createSelector(
  prodMonthlySelector,
  prodmonthly => prodmonthly,
);

export const getProductWeeklyReports = createSelector(
  prodWeeklySelector,
  prodweekly => prodweekly,
);

export const getProductDailyReports = createSelector(
  prodDailySelector,
  proddaily => proddaily,
);

export const getDailyProps = createSelector(
  dailyPropsSelector,
  dailyprops => dailyprops,
);

export const getSKUData = createSelector(
  skuSelector,
  skudata => skudata,
);

export const getDayData = createSelector(
  dayDataSelector,
  daydata => daydata,
);

export const getReportsColumnData = createSelector(
  reportsColumnDataSelector,
  reportColumnData => reportColumnData,
);

export const getExportUrlData = createSelector(
  exportdataSelector,
  exportdata => exportdata,
);
