import { createSelector } from 'reselect';

export const roleListSelector = state => state.role.list;
export const roleSelector = state => state.role.role;
export const dndroleSelector = state => state.role.dndrole;

export const getRoleList = createSelector(
  [roleListSelector],
  list => list,
);

export const getAccountData = createSelector(
  [roleSelector],
  role => role,
);

export const getDNDRoleData = createSelector(
  [dndroleSelector],
  dndrole => dndrole,
);

