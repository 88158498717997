const styles = theme => ({
  ...theme.classes,
  h6mobile: {
    '@media (max-width:1240px)': {
      fontSize: 16,
      fontWeight: 500,
      padding: '20px 0',
      width: '20%',
      lineHeight: '22px',
      paddingRight: 10,
    },
  },
  toolbarContainerMobile: {
    '@media (max-width:1240px)': {
      height: 'auto',
    },
  },
  notifheader: {
    backgroundColor: theme.palette.background.page,
    position: 'relative',
    width: '100%',
    height: 142,
    boxShadow: '0 0 12px rgba(68, 107, 176, 0.91)',
    borderRadius: 6,
    padding: '32px 40px',
    zIndex: 0,
    marginTop: 12,
    '& label': {
      color: theme.palette.primary.main,
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '21px',
      marginBottom: 4,
    },
    '& label span': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      padding: '5px 11px',
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    '& p': {
      color: theme.palette.text.primary,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '16px',
    },
    '@media (max-width:1240px)': {
      boxShadow: 'none',
      padding: '10px 26px',
      height: 120,
      borderBottom: '1px solid #E8EAEE',
      borderRadius: 0,
      '& label': {
        fontSize: 16,
      },
      '&.notifheader': {
        backgroundColor: theme.palette.common.white,
        borderBottom: '1px solid #E8EAEE',
        margin: 0,
        borderRadius: 0,
        padding: '28px 30px',
      },
      '&.notifheader p': {
        paddingTop: 24,
      },
    },
  },
});

export default styles;
