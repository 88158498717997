import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { ReactComponent as Filter } from 'resources/icons/filter.svg';
import { ReactComponent as Close } from 'resources/icons/closev2.svg';
import { Grow, Paper, Popper, Typography, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './FilterStyle';

class Filters extends Component {
  state = {
    open: false,
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = (event) => {
    const dialog = document.querySelector('div[role="dialog"]');
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    if (dialog !== null) {
      return;
    }

    this.setState({ open: false });
  };

  closeFilter = () => {
    this.setState({ open: false });
  }

  render() {
    const { classes, children } = this.props;
    const { open } = this.state;

    return (
      <Fragment>
        <div className={classes.filter}>
          <IconButton
            className={classes.iconbtn}
            buttonRef={(node) => {
              this.anchorEl = node;
            }}
            aria-owns={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={this.handleToggle}
            disableRipple
          >
            <span>Filter</span> <Filter />
          </IconButton>
        </div>
        <Popper
          className={classes.popper}
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <div>
                    <div className={classes.listhead}>
                      <Typography>Filters</Typography>
                      <Close onClick={this.handleClose} className={classes.closeX} />
                    </div>
                    {React.cloneElement(children, { handleClose: this.closeFilter })}
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Fragment>
    );
  }
}

Filters.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Filters);
