import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Column } from 'resources/icons/columns.svg';
import { ReactComponent as Arrowdown } from 'resources/icons/arrow-down-sort.svg';
import { ReactComponent as Arrowup } from 'resources/icons/arrow-up-sort.svg';
import { ReactComponent as Close } from 'resources/icons/closev2.svg';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Grow, Paper, Popper, Typography, List, ListItem, ListItemText, IconButton, Checkbox, ListItemSecondaryAction } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './ColumnsStyle';

class Columns extends Component {
  state = {
    open: false,
    checked: [0],
  };

  handleCheckboxToggle = value => (e) => {
    const {
      menus, setsettings, location, profile,
    } = this.props;
    const mlist = { ...menus };
    const ischecked = e.target.checked;
    switch (location.pathname) {
      case '/products':
      case '/top-selling-products':
      case '/products-low-in-stocks':
      case '/products-out-of-stocks':
        for (const key in mlist.product) { // eslint-disable-line
          if (mlist.product[key].name === value) {
            mlist.product[key].checked = ischecked;
          }
        }
        break;
      case '/orders':
        for (const key in mlist.allorders) { // eslint-disable-line
          if (mlist.allorders[key].name === value) {
            mlist.allorders[key].checked = ischecked;
          }
        }
        break;
      case '/pending-orders':
        for (const key in mlist.pending) { // eslint-disable-line
          if (mlist.pending[key].name === value) {
            mlist.pending[key].checked = ischecked;
          }
        }
        break;
      case '/shipped-orders':
        for (const key in mlist.shipped) { // eslint-disable-line
          if (mlist.shipped[key].name === value) {
            mlist.shipped[key].checked = ischecked;
          }
        }
        break;
      case '/returned-orders':
        for (const key in mlist.returned) { // eslint-disable-line
          if (mlist.returned[key].name === value) {
            mlist.returned[key].checked = ischecked;
          }
        }
        break;
      default:
        break;
    }
    setsettings(profile.customer_user.id, mlist);
    // console.log(mlist); // eslint-disable-line
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      ...this.state,
      checked: newChecked,
    });
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({
      ...this.state,
      open: false,
    });
  };

  render() {
    const {
      classes, menus, location,
    } = this.props;
    const { open } = this.state;
    let showcolumn = true;
    let menulist = [];
    switch (location.pathname) {
      case '/products':
      case '/top-selling-products':
      case '/products-low-in-stocks':
      case '/products-out-of-stocks':
        menulist = menus.product;
        break;
      case '/products-vendor-orders':
        showcolumn = false;
        break;
      case '/orders':
        menulist = menus.allorders;
        break;
      case '/pending-orders':
        menulist = menus.pending;
        break;
      case '/shipped-orders':
        menulist = menus.shipped;
        break;
      case '/returned-orders':
        menulist = menus.returned;
        break;
      default:
        break;
    }
    return (
      <Fragment>
        { showcolumn ?
          <Fragment>
            <div className={classes.column}>
              <IconButton
                className={classes.iconbtn}
                buttonRef={(node) => {
                  this.anchorEl = node;
                }}
                aria-owns={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={this.handleToggle}
                disableRipple
              >
                <span>Layout</span> <Column />
              </IconButton>
            </div>
            <Popper
              className={classes.popper}
              open={open}
              anchorEl={this.anchorEl}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper className={classes.paper}>
                    <ClickAwayListener onClickAway={this.handleClose}>
                      <div>
                        <div className={classes.listhead}>
                          <Typography>Manage Columns</Typography>
                          <Close onClick={this.handleClose} className={classes.closeX} />
                        </div>
                        <List className={classes.ulistcontainer}>
                          {menulist.map(menu => (
                            <ListItem
                              key={menu.name}
                            >
                              <Checkbox
                                className={classes.chkbox}
                                classes={
                                  {
                                    checked: !menu.fixed ?
                                      classes.checked : classes.checkeddisabled,
                                  }
                                }
                                checked={menu.checked}
                                tabIndex={-1}
                                disableRipple
                                disabled={menu.fixed}
                                onChange={this.handleCheckboxToggle(menu.name)}
                              />
                              <ListItemText
                                className={!menu.fixed ? classes.itext : classes.itextdisabled}
                                primary={menu.label}
                              />
                              <ListItemSecondaryAction className="listAction" classes={{ root: classes.actions }}>
                                <IconButton className={classes.arrowiconbtn} aria-label="Down" disabled={menulist.length === menu.name}>
                                  <Arrowdown />
                                </IconButton>
                                <IconButton className={classes.arrowiconbtn} aria-label="Up" disabled={menu.name === 1}>
                                  <Arrowup />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                        </List>
                      </div>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Fragment>
        : null }
      </Fragment>
    );
  }
}

Columns.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  setsettings: PropTypes.func, // eslint-disable-line
};

export default withStyles(styles)(Columns);
