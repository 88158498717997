import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FormField from 'ui/components/form/FormField';
import { update, populateFields } from 'ui/components/form/FormActions';
import styles from './OrderFilterFormStyle';

class OrderDateFilterForm extends Component {
  state = {
    formdata: {
      order_date_start: {
        element: 'date',
        value: null,
        config: {
          name: 'order_date_start',
          type: 'date',
          label: 'From',
        },
        validation: {
          required: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
        addeditform: true,
      },
      order_date_end: {
        element: 'date',
        value: null,
        config: {
          name: 'order_date_end',
          type: 'date',
          label: 'To',
        },
        validation: {
          required: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
        addeditform: true,
      },
    },
  }

  componentDidMount() {
    const { filtersdata } = this.props;
    if (filtersdata.length) {
      for (const key in filtersdata) { // eslint-disable-line
        if (filtersdata[key].id === 'date') {
          const data = {
            order_date_start: filtersdata[key].date_start,
            order_date_end: filtersdata[key].date_end,
          };
          populateFields(this.state.formdata, data);
        }
      }
    }
  }

  getFormattedDate = (plus = 0) => {
    const date = new Date();
    if (plus > 0) {
      date.setDate(date.getDate() + plus);
    }
    const day = (`0${date.getDate()}`).slice(-2);
    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  addSearchFilter = (moreFilter) => {
    const { filtersdata, setfilters, setloadtype } = this.props;
    let filterItems = [...filtersdata];
    if (filterItems.length) {
      moreFilter.forEach((fdata) => {
        const foundItem = moreFilter.find(item => item.id === fdata.id);
        if (foundItem) {
          filterItems = filterItems.filter(fitem => fitem.id !== fdata.id);
          filterItems.push(fdata);
        }
        if (!foundItem) {
          filterItems.push(fdata);
        }
      });
    } else {
      filterItems = [...moreFilter];
    }

    setfilters(filterItems);
    setloadtype('filter updated');
  }
  updateFields = (newFormdata) => {
    this.setState({
      ...this.state,
      formdata: newFormdata,
    });
  }

  updateForm = (element) => {
    const { handleClose } = this.props;
    let closenow = false;
    const filters = [];
    const newFormdata = update(element, this.state.formdata, '');
    if (newFormdata.order_date_start.value !== null && newFormdata.order_date_end.value !== null) {
      filters.push({
        id: 'date',
        date_start: newFormdata.order_date_start.value,
        date_end: newFormdata.order_date_end.value,
      });
      closenow = true;
      this.addSearchFilter(filters);
    }
    this.setState({
      formdata: newFormdata,
    });
    if (closenow) {
      handleClose();
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <List className={classes.ulistcontainer}>
          <ListItem className="datelist">
            <Grid container >
              <Grid className="datestart dategrid" item xs={12}>
                <FormField
                  id="order_date_start"
                  formdata={this.state.formdata.order_date_start}
                  change={element => this.updateForm(element)}
                />
              </Grid>
            </Grid>
            <Grid container >
              <Grid className="dateend dategrid" item xs={12}>
                <FormField
                  id="order_date_end"
                  formdata={this.state.formdata.order_date_end}
                  change={element => this.updateForm(element)}
                />
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Fragment>
    );
  }
}

OrderDateFilterForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  setloadtype: PropTypes.func.isRequired,
  setfilters: PropTypes.func.isRequired,
  filtersdata: PropTypes.instanceOf(Object).isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(OrderDateFilterForm);
