const styles = theme => ({
  ...theme.classes,
  paper: {
    backgroundColor: theme.palette.common.white,
    marginRight: 12,
    width: 208,
    boxShadow: '0 0 12px rgba(68, 107, 176, 0.91)',
    borderRadius: 6,
  },
  menuButton: {
    display: 'none',
    '@media (max-width:1240px)': {
      display: 'inline-block',
      marginRight: -12,
      '& svg': {
        fill: theme.palette.secondary.main,
      },
    },
  },
  ucontainer: {
    padding: 0,
    display: 'inline-block',
    verticalAlign: 'middle',
    height: 60,
    '@media (max-width:1240px)': {
      display: 'none',
    },
  },
  uitem: {
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  uitemtext: {
    padding: '0 15px',
    '& span': {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '16px',
      color: theme.palette.text.primary,
      marginBottom: '2px',
    },
    '& p': {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '14px',
    },
  },
  avatar: {
    width: 34,
    height: 34,
    borderRadius: '50%',
    letterSpacing: 1,
    fontSize: 14,
    fontWeight: 600,
  },
  avatarShadow: {
    boxShadow: '0 1px 16px rgba(150, 163, 175, 0.84)',
  },
  dropdown: {
    position: 'absolute',
    top: '48%',
    right: 2,
    fill: theme.palette.header.primary,
  },
  ulistcontainer: {
    padding: 0,
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '100%',
    '& li': {
      padding: '20px 26px',
      borderTop: '1px solid #E8EAEE',
    },
    '& a, & p': {
      fontSize: 14,
      lineHeight: '19px',
      textDecoration: 'none',
      color: theme.palette.secondary.main,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    '& p': {
      color: '#FF6565',
    },
    '& a svg, & p svg': {
      marginRight: 18,
    },
    '& label': {
      fontSize: 11,
      fontWeight: 600,
      color: theme.palette.primary.light,
    },
  },
  listhead: {
    padding: '8px 12px !important',
    border: '0 none !important',
    '& p': {
      fontSize: 11,
      lineHeight: '13px',
      fontWeight: 500,
    },
  },
  ulistitem: {
    position: 'relative',
    /* cursor: 'pointer', */
    padding: '26px 26px !important',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& p': {
      cursor: 'auto',
    },
  },
  ulistitemtext: {
    padding: '0 0 0 15px',
    '& span': {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '16px',
      color: theme.palette.text.primary,
      marginBottom: '2px',
    },
    '& p': {
      fontSize: 12,
      fontWeight: 300,
      lineHeight: '14px',
      color: theme.palette.primary.light,
    },
  },
  wh44: {
    width: 44,
    height: 44,
    fontSize: 22,
  },
  wh65: {
    width: 65,
    height: 65,
    fontSize: 22,
  },
  avwrapper: {
    display: 'inline-block',
  },
});

export default styles;
