/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Typography, ClickAwayListener } from '@material-ui/core';
import { ReactComponent as Plus } from 'resources/icons/plus.svg';
import { ReactComponent as AddOrder } from 'resources/icons/add-order.svg';
import { ReactComponent as UploadOrder } from 'resources/icons/upload-order.svg';
import { ReactComponent as AddProduct } from 'resources/icons/add-product.svg';
import { ReactComponent as UploadProduct } from 'resources/icons/upload-product.svg';
import { ReactComponent as DownloadOrder } from 'resources/icons/download-order.svg';
import SnackBarNotify from 'ui/components/common/SnackBarNotify';
import styles from './TopBarStyle';

class AddCommonBtn extends Component {
  state = {
    open: false,
  };
  addModal = (e) => {
    e.stopPropagation();
    const { actions, page, location } = this.props;
    let modalKey = '';
    let params = {};
    switch (page) {
      case 'orders':
        params = { type: 'add', action: 'add' };
        modalKey = 'modal-order';
        actions.ui.openModal({ modalKey, params });
        break;
      case 'products':
        params = { type: 'add', action: 'add', source: 'listing' };
        if (location.pathname !== '/products-vendor-orders') {
          params = { type: 'add product', action: 'add', source: 'listing' };
        }
        modalKey = 'modal-products';
        actions.ui.openModal({ modalKey, params });
        break;
      default:
        break;
    }
  };

  uploadModal = () => {
    const { actions, page } = this.props;
    let modalKey = '';
    let params = {};
    switch (page) {
      case 'orders':
        params = { type: 'addmultipleorder', action: 'addmultipleorder' };
        modalKey = 'modal-order';
        actions.ui.openModal({ modalKey, params });
        break;
      case 'products':
        params = { type: 'addmultipleproducts', action: 'addmultipleproducts' };
        modalKey = 'modal-products';
        actions.ui.openModal({ modalKey, params });
        break;
      default:
        break;
    }
  };

  handleClickAway = () => {
    this.setState({ ...this.state, open: false });
  };

  handleCreate = () => {
    this.setState({ ...this.state, open: true });
  };

  validateFile = (obj) => {
    // setErrorFileUpload('');
    if (!obj.files.length) return;

    const allowedExtensions = ['csv'];
    const sizeLimit = 100000000; // 100 MB
    const { name: fileName, size: fileSize } = obj.files[0];
    const fileExtension = fileName.split('.').pop();

    const { page, setProductsFile, setOrderFile } = this.props;

    if (!allowedExtensions.includes(fileExtension)) {
      // setErrorFileUpload('Please upload only csv file');
      document.getElementById('contained-button-file').value = null;
      // setPathFile('');
      switch (page) {
        case 'orders':
          setOrderFile({});
          break;
        case 'products':
          setProductsFile({});
          break;
        default:
          break;
      }
      return;
    } else if (fileSize > sizeLimit) {
      // setErrorFileUpload('File size too large (must be less than 5MB)');
      document.getElementById('contained-button-file').value = null;
      switch (page) {
        case 'orders':
          setOrderFile({});
          break;
        case 'products':
          setProductsFile({});
          break;
        default:
          break;
      }
      this.child.showMessage('The File is too large. Allowed maximum size is 100MB', 'error');
      return;
    }
    const datas = new FormData();
    datas.append('file', obj.files[0]);
    switch (page) {
      case 'orders':
        setOrderFile(datas);
        break;
      case 'products':
        setProductsFile(datas);
        break;
      default:
        break;
    }
    this.uploadModal();
  }

  render() {
    const { open } = this.state;
    const { classes, searchType, page } = this.props;
    const addTitle = 'Create';

    let stype = '';
    if (typeof searchType !== 'undefined') {
      stype = searchType;
    }

    return (
      <div className={classes.createbtncontainer}>
        <div className={`${classes.addbtn} ${stype}btn`} onClick={this.handleCreate}>
          <IconButton className={classes.iconbtn} aria-label="Add">
            <Plus
              className={classes.icon}
            />
          </IconButton>
          <Typography className={`${classes.addtitle} ${stype}`} component="span">
            {addTitle}
          </Typography>
        </div>
        { open ?
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <div className={classes.addcontainer}>
              { page === 'orders' ?
                <>
                  <input
                    accept=".csv"
                    className={classes.ulploadinput}
                    id="contained-button-file"
                    type="file"
                    onChange={e => this.validateFile(e.target)}
                  />
                  <ul>
                    <li className="header">
                      <strong>Add New Orders</strong>
                    </li>
                    <li onClick={this.addModal}>
                      <span><AddOrder /></span><label>Add Single Order</label>
                    </li>
                    <li>
                      <label className="uploadlbl" htmlFor="contained-button-file">&nbsp;</label>
                      <span><UploadOrder /></span><label>Upload Multiple Orders</label>
                    </li>
                    <li>
                      <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/spreadsheets/d/1ZAHSHqbJQ73x9wyUiElKsYeYDDPlEQQFI9wJDMWrofg/edit?usp=sharing">
                        <span><DownloadOrder /></span>
                        <label className="medium">Download Sample CSV File For Multiple Orders</label>
                      </a>
                    </li>
                  </ul>
                </>
              : '' }
              { page === 'products' ?
                <>
                  <input
                    accept=".csv"
                    className={classes.ulploadinput}
                    id="contained-button-file"
                    type="file"
                    onChange={e => this.validateFile(e.target)}
                  />
                  <ul>
                    <li className="header">
                      <strong>Add New Product</strong>
                    </li>
                    <li onClick={this.addModal}>
                      <span><AddProduct /></span><label>Add Single Product</label>
                    </li>
                    <li>
                      <label className="uploadlbl" htmlFor="contained-button-file">&nbsp;</label>
                      <span><UploadProduct /></span><label>Upload Multiple Products</label>
                    </li>
                    <li>
                      <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/spreadsheets/d/1S9aPHVc0FakbN7RCvrtdmAyQlUdCE4IWZ7VUdRBukt0/edit?usp=sharing">
                        <span><DownloadOrder /></span>
                        <label className="medium">Download Sample CSV File For Multiple Products</label>
                      </a>
                    </li>
                  </ul>
                </>
              : '' }
            </div>
          </ClickAwayListener>
        : null }
        <SnackBarNotify onRef={(instance) => { this.child = instance; }} />
      </div>
    );
  }
}

AddCommonBtn.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  searchType: PropTypes.string, // eslint-disable-line
  actions: PropTypes.instanceOf(Object).isRequired,
  page: PropTypes.string.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  setProductsFile: PropTypes.func, // eslint-disable-line
  setOrderFile: PropTypes.func, // eslint-disable-line
};

export default withStyles(styles)(AddCommonBtn);
