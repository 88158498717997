import {
  requestOrders,
  requestOrderDetails,
  requestReturnOrders,
  requestReturnOrdersReport,
  requestOrderItems,
  requestVendorOrders,
  requestVendorOrderDetails,
  requestCarrierService,
  postSalesOrder,
  postReturnOrders,
  postOrderRecipient,
  updateOrderRecipient,
  autoSearchRecipient,
  importWMSOrders,
  lastImportOrders,
  importWMSOrdersById,
  autoSearchOrders,
  cancelSalesOrder,
  fetchOrderStatuses,
  requestOrdersItemList,
  postCSVOrder,
  saveCSVOrder,
  requestThirdParty,
  requestThirdPartyDetails,
} from 'api/orders';
import { showActionLoading, hideActionLoading, showLoading, hideLoading, setLoadType } from 'state/loading/actions';
import responseCheker from 'api/responseCheker';
import { openAlertModal } from 'state/modal-drawer/actions';

import {
  SET_ORDERS_DATA,
  SET_MORE_ORDERS_DATA,
  SET_ORDER_DETAILS_DATA,
  RESET_ORDERS_DATA,
  SET_RA_DATA,
  SET_EDIT_DATA,
  SET_RECIPIENT_DATA,
  SET_CARRIER_SERVICE_DATA,
  SET_ORDER_INFO_DATA,
  SET_RETURNED_ORDERS_DATA,
  SET_MORE_RETURNED_ORDERS_DATA,
  SET_FILTERS_DATA,
  SET_RECIPIENT_AUTOCOMPLETE_DATA,
  RESET_RECIPIENT_AUTOCOMPLETE_DATA,
  SET_VENDOR_ORDERS_DATA,
  SET_MORE_VENDOR_ORDERS_DATA,
  SET_IMPORT_INFO_DATA,
  SET_IMPORT_ORDERS_DATA,
  SET_SORT_COLUMN,
  SET_SORT_TYPE,
  SET_ORDERS_AUTOCOMPLETE_DATA,
  RESET_ORDERS_AUTOCOMPLETE_DATA,
  SET_ORDER_STATUSES_DATA,
  SET_ORDERS_ITEM_DATA,
  SET_MORE_ORDERS_ITEM_DATA,
  SET_ORDER_FILE_DATA,
  SET_CSV_ORDER_DATA,
  SET_IMPORT_CSV_ORDER_DATA,
} from './types';

export const setOrders = orders => ({
  type: SET_ORDERS_DATA,
  payload: { orders },
});

export const setMoreOrders = orders => ({
  type: SET_MORE_ORDERS_DATA,
  payload: { orders },
});

export const setOrdersItem = ordersItem => ({
  type: SET_ORDERS_ITEM_DATA,
  payload: { ordersItem },
});

export const setMoreOrdersItem = ordersItem => ({
  type: SET_MORE_ORDERS_ITEM_DATA,
  payload: { ordersItem },
});

export const setVendorOrders = vendororders => ({
  type: SET_VENDOR_ORDERS_DATA,
  payload: { vendororders },
});

export const setMoreVendorOrders = vendororders => ({
  type: SET_MORE_VENDOR_ORDERS_DATA,
  payload: { vendororders },
});

export const setReturnedOrders = returnedorders => ({
  type: SET_RETURNED_ORDERS_DATA,
  payload: { returnedorders },
});

export const setMoreReturnedOrders = returnedorders => ({
  type: SET_MORE_RETURNED_ORDERS_DATA,
  payload: { returnedorders },
});

export const setOrderDetails = detail => ({
  type: SET_ORDER_DETAILS_DATA,
  payload: { detail },
});

export const resetOrders = () => ({
  type: RESET_ORDERS_DATA,
});

export const setRAData = radata => ({
  type: SET_RA_DATA,
  payload: { radata },
});

export const setDataEntry = dataentry => ({
  type: SET_EDIT_DATA,
  payload: { dataentry },
});

export const setRecipient = recipientdata => ({
  type: SET_RECIPIENT_DATA,
  payload: { recipientdata },
});

export const setCarrierService = carrierdata => ({
  type: SET_CARRIER_SERVICE_DATA,
  payload: { carrierdata },
});

export const setOrderInfo = orderinfo => ({
  type: SET_ORDER_INFO_DATA,
  payload: { orderinfo },
});

export const setFilters = filters => ({
  type: SET_FILTERS_DATA,
  payload: { filters },
});

export const setRecipientAutocomplete = autocompleteRecipient => ({
  type: SET_RECIPIENT_AUTOCOMPLETE_DATA,
  payload: { autocompleteRecipient },
});

export const resetRecipientAutocomplete = () => ({
  type: RESET_RECIPIENT_AUTOCOMPLETE_DATA,
});

export const setImportInfo = importinfo => ({
  type: SET_IMPORT_INFO_DATA,
  payload: { importinfo },
});

export const setImportOrders = importorders => ({
  type: SET_IMPORT_ORDERS_DATA,
  payload: { importorders },
});

export const setSortColumn = sortColumn => ({
  type: SET_SORT_COLUMN,
  payload: { sortColumn },
});

export const setSortType = sortType => ({
  type: SET_SORT_TYPE,
  payload: { sortType },
});

export const setOrdersAutocomplete = autocompleteOrders => ({
  type: SET_ORDERS_AUTOCOMPLETE_DATA,
  payload: { autocompleteOrders },
});

export const resetOrderAutocomplete = () => ({
  type: RESET_ORDERS_AUTOCOMPLETE_DATA,
});

export const setOrdersStatuses = orderstatuses => ({
  type: SET_ORDER_STATUSES_DATA,
  payload: { orderstatuses },
});

export const setOrderFile = orderfile => ({
  type: SET_ORDER_FILE_DATA,
  payload: { orderfile },
});

export const setCSVOrder = csvorder => ({
  type: SET_CSV_ORDER_DATA,
  payload: { csvorder },
});

export const setImportCSVOrder = importcsvorder => ({
  type: SET_IMPORT_CSV_ORDER_DATA,
  payload: { importcsvorder },
});

export const clearImportCSVOrder = () => ({
  type: SET_IMPORT_CSV_ORDER_DATA,
  payload: { importcsvorder: {} },
});

export const successResponse = (response, type) => (dispatch) => {
  dispatch(responseCheker(response));
  let cond = type;
  if (response) {
    if (cond === 'list' || cond === 'slist') {
      dispatch(setOrders(response.data));
    }
    if (cond === 'morelist') {
      dispatch(setMoreOrders(response.data));
    }
    if (cond === 'order_item') {
      dispatch(setOrdersItem(response.data));
    }
    if (cond === 'more_order_item') {
      dispatch(setMoreOrdersItem(response.data));
    }
    if (cond === 'vlist' || cond === 'vslist') {
      dispatch(setVendorOrders(response.data));
    }
    if (cond === 'vmorelist') {
      dispatch(setMoreVendorOrders(response.data));
    }
    if (cond === 'returnlist' || cond === 'sreturnlist') {
      dispatch(setReturnedOrders(response.data));
    }
    if (cond === 'morereturnlist') {
      dispatch(setMoreReturnedOrders(response.data));
    }
    if (cond === '3rdpartylist' || cond === 's3rdpartylist') {
      dispatch(setReturnedOrders(response.data));
    }
    if (cond === 'more3rdpartylist') {
      dispatch(setMoreReturnedOrders(response.data));
    }
    if (cond === 'detail') {
      dispatch(setOrderDetails(response.data));
      dispatch(setDataEntry(response.data.attributes));
      dispatch(setRAData(response.data.attributes));
    }
    if (cond === 'addrecipient') {
      dispatch(setRecipient(response.data.data));
    }
    if (cond === 'autocomplete') {
      dispatch(setRecipientAutocomplete(response.data.data));
    }
    if (cond === 'importsinfo') {
      dispatch(setImportInfo(response.data));
    }
    if (cond === 'importorders') {
      dispatch(setImportOrders(response.data));
    }
    if (cond === 'autocompleteorder') {
      dispatch(setOrdersAutocomplete(response.data.data));
    }
    if (cond === 'order status') {
      dispatch(setOrdersStatuses(response.data.data));
    }
    if (cond === 'carrires') {
      dispatch(setCarrierService(response.data.data.attributes));
    }
    if (cond === 'reviewcsvorder') {
      dispatch(setCSVOrder(response.data.data));
    }
    if (cond === 'importcsvorder') {
      dispatch(setImportCSVOrder(response.data.data));
    }
  }

  if (cond === 'slist'
    || cond === 'morelist'
    || cond === 'sreturnlist'
    || cond === '3rdpartylist'
    || cond === 's3rdpartylist'
    || cond === 'addsalesorder'
    || cond === 'returnorders'
    || cond === 'addrecipient'
    || cond === 'autocomplete'
    || cond === 'importsinfo'
    || cond === 'importorders'
    || cond === 'update_sales_orders'
    || cond === 'autocompleteorder'
    || cond === 'cancel order'
    || cond === 'order status'
    || cond === 'more_order_item'
    || cond === 'carrires'
    || cond === 'reviewcsvorder'
    || cond === 'importcsvorder'
    || cond === 'detail') {
    cond = false;
  }

  if (cond) {
    dispatch(hideLoading());
  }
  dispatch(hideActionLoading());
};

export const errorResponse = (err, type = true, bypassError = false) => (dispatch) => {
  const result = dispatch(responseCheker(err.response));
  if (result === 'error') {
    if (!bypassError) {
      let errmsg = err.response.data.errors[0].detail;
      if (errmsg === 'The shipping address1 field is required.') {
        errmsg = 'Address field is required';
      }
      const modalKey = 'alert';
      const params = {
        type: 'error',
        title: '',
        description: errmsg,
      };
      dispatch(openAlertModal({ modalKey, params }));
      dispatch(setLoadType('msg error'));
    } else {
      dispatch(setLoadType(err.response.data.errors[0].detail));
    }
  }
  if (result === 'TypeError') {
    const modalKey = 'alert';
    const params = {
      type: 'error',
      title: '',
      description: 'Something went wrong. This has been reported',
    };
    dispatch(openAlertModal({ modalKey, params }));
  }
  if (type) {
    dispatch(hideLoading());
  }
  dispatch(hideActionLoading());
};

export const addSalesOrder = (dataToSubmit, id = null) => (dispatch) => {
  dispatch(showActionLoading());
  postSalesOrder(dataToSubmit, id).then(
    response => dispatch(successResponse(response, 'addsalesorder')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const addReturnOrders = dataToSubmit => (dispatch) => {
  dispatch(showActionLoading());
  postReturnOrders(dataToSubmit).then(
    response => dispatch(successResponse(response, 'returnorders')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const fetchOrderList = (
  type, limit, page, text, start, end, name, code, company, email, sortColumn,
  sortType, exceptOrderStatusNew,
) => (dispatch) => {
  let cond = 'list';
  let txt = null;
  let dstart = null;
  let dend = null;
  let cname = null;
  let ccode = null;
  let ccompany = null;
  let cemail = null;
  let eosnew = null;
  let loadAction = false;
  if (typeof start !== 'undefined') {
    dstart = start;
    if (start === '') {
      dstart = null;
    }
    loadAction = true;
  }
  if (typeof end !== 'undefined') {
    dend = end;
    if (end === '') {
      dend = null;
    }
    loadAction = true;
  }
  if (typeof name !== 'undefined') {
    cname = name;
    if (name === '') {
      cname = null;
    }
    loadAction = true;
  }
  if (typeof code !== 'undefined') {
    ccode = code;
    if (code === '') {
      ccode = null;
    }
    loadAction = true;
  }
  if (typeof company !== 'undefined') {
    ccompany = company;
    if (company === '') {
      ccompany = null;
    }
    loadAction = true;
  }
  if (typeof email !== 'undefined') {
    cemail = email;
    if (email === '') {
      cemail = null;
    }
    loadAction = true;
  }
  if (typeof text !== 'undefined') {
    txt = text;
    if (text === '') {
      txt = null;
    }
    cond = 'slist';
    loadAction = true;
  }
  if (typeof exceptOrderStatusNew !== 'undefined') {
    eosnew = exceptOrderStatusNew;
    if (exceptOrderStatusNew === '') {
      eosnew = null;
    }
    loadAction = true;
  }
  if (loadAction) {
    dispatch(showActionLoading());
  } else {
    dispatch(showLoading());
  }
  requestOrders(
    type, limit, page, txt, dstart, dend, cname, ccode, ccompany, cemail, sortColumn,
    sortType, eosnew,
  ).then(
    response => dispatch(successResponse(response, cond)),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchMoreOrderList = (
  type, limit, page, text, start, end, name, code, company, email, sortColumn,
  sortType, exceptOrderStatusNew,
) => (dispatch) => {
  let txt = null;
  let dstart = null;
  let dend = null;
  let cname = null;
  let ccode = null;
  let ccompany = null;
  let cemail = null;
  let eosnew = null;
  if (typeof start !== 'undefined') {
    dstart = start;
    if (start === '') {
      dstart = null;
    }
  }
  if (typeof end !== 'undefined') {
    dend = end;
    if (end === '') {
      dend = null;
    }
  }
  if (typeof name !== 'undefined') {
    cname = name;
    if (name === '') {
      cname = null;
    }
  }
  if (typeof code !== 'undefined') {
    ccode = code;
    if (code === '') {
      ccode = null;
    }
  }
  if (typeof company !== 'undefined') {
    ccompany = company;
    if (company === '') {
      ccompany = null;
    }
  }
  if (typeof email !== 'undefined') {
    cemail = email;
    if (email === '') {
      cemail = null;
    }
  }
  if (typeof text !== 'undefined') {
    txt = text;
    if (text === '') {
      txt = null;
    }
  }
  if (typeof exceptOrderStatusNew !== 'undefined') {
    eosnew = exceptOrderStatusNew;
    if (exceptOrderStatusNew === '') {
      eosnew = null;
    }
  }
  dispatch(showActionLoading());
  requestOrders(
    type, limit, page, txt, dstart, dend, cname, ccode, ccompany, cemail, sortColumn,
    sortType, eosnew,
  ).then(
    response => dispatch(successResponse(response, 'morelist')),
    err => dispatch(errorResponse(err)),
  );
};

/* order line items report */
export const fetchOrderItemList = (
  type, limit, page, sku, order, recipient, po,
  email, tracking, start, end, sortColumn, sortType, shippedStart, shippedEnd,
) => (dispatch) => {
  let psku;
  let porder;
  let precipient;
  let ppo;
  let pemail;
  let ptracking;
  let dstart = null;
  let dend = null;
  let dshippedStart = null;
  let dshippedEnd = null;
  let loadAction = false;
  if (typeof sku !== 'undefined') {
    psku = sku;
    if (sku === '') {
      psku = null;
    }
    loadAction = true;
  }
  if (typeof order !== 'undefined') {
    porder = order;
    if (order === '') {
      porder = null;
    }
    loadAction = true;
  }
  if (typeof recipient !== 'undefined') {
    precipient = recipient;
    if (recipient === '') {
      precipient = null;
    }
    loadAction = true;
  }
  if (typeof po !== 'undefined') {
    ppo = po;
    if (po === '') {
      ppo = null;
    }
    loadAction = true;
  }
  if (typeof email !== 'undefined') {
    pemail = email;
    if (email === '') {
      pemail = null;
    }
    loadAction = true;
  }
  if (typeof tracking !== 'undefined') {
    ptracking = tracking;
    if (tracking === '') {
      ptracking = null;
    }
    loadAction = true;
  }
  if (typeof start !== 'undefined') {
    dstart = start;
    if (start === '') {
      dstart = null;
    }
    loadAction = true;
  }
  if (typeof end !== 'undefined') {
    dend = end;
    if (end === '') {
      dend = null;
    }
    loadAction = true;
  }
  if (typeof shippedStart !== 'undefined') {
    dshippedStart = shippedStart;
    if (shippedStart === '') {
      dshippedStart = null;
    }
    loadAction = true;
  }
  if (typeof shippedEnd !== 'undefined') {
    dshippedEnd = shippedEnd;
    if (shippedEnd === '') {
      dshippedEnd = null;
    }
    loadAction = true;
  }
  if (loadAction) {
    dispatch(showActionLoading());
  } else {
    dispatch(showLoading());
  }
  requestOrdersItemList(
    type, limit, page, psku, porder, precipient, ppo,
    pemail, ptracking, dstart, dend, sortColumn, sortType, dshippedStart, dshippedEnd,
  ).then(
    response => dispatch(successResponse(response, 'order_item')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchMoreOrderItemList = (
  type, limit, page, sku, order, recipient, po,
  email, tracking, start, end, sortColumn, sortType, shippedStart, shippedEnd,
) => (dispatch) => {
  let psku;
  let porder;
  let precipient;
  let ppo;
  let pemail;
  let ptracking;
  let dstart = null;
  let dend = null;
  let dshippedStart = null;
  let dshippedEnd = null;
  if (typeof sku !== 'undefined') {
    psku = sku;
    if (sku === '') {
      psku = null;
    }
  }
  if (typeof order !== 'undefined') {
    porder = order;
    if (order === '') {
      porder = null;
    }
  }
  if (typeof recipient !== 'undefined') {
    precipient = recipient;
    if (recipient === '') {
      precipient = null;
    }
  }
  if (typeof po !== 'undefined') {
    ppo = po;
    if (po === '') {
      ppo = null;
    }
  }
  if (typeof email !== 'undefined') {
    pemail = email;
    if (email === '') {
      pemail = null;
    }
  }
  if (typeof tracking !== 'undefined') {
    ptracking = tracking;
    if (tracking === '') {
      ptracking = null;
    }
  }
  if (typeof start !== 'undefined') {
    dstart = start;
    if (start === '') {
      dstart = null;
    }
  }
  if (typeof end !== 'undefined') {
    dend = end;
    if (end === '') {
      dend = null;
    }
  }
  if (typeof shippedStart !== 'undefined') {
    dshippedStart = shippedStart;
    if (shippedStart === '') {
      dshippedStart = null;
    }
  }
  if (typeof shippedEnd !== 'undefined') {
    dshippedEnd = shippedEnd;
    if (shippedEnd === '') {
      dshippedEnd = null;
    }
  }
  dispatch(showActionLoading());
  requestOrdersItemList(
    type, limit, page, psku, porder, precipient, ppo,
    pemail, ptracking, dstart, dend, sortColumn, sortType, dshippedStart, dshippedEnd,
  ).then(
    response => dispatch(successResponse(response, 'more_order_item')),
    err => dispatch(errorResponse(err)),
  );
};
/* end order line items report */


export const fetchReturnOrderList = (
  status, limit, page, text, start, end, name, company, email,
  sortColumn, sortType, display,
) => (dispatch) => {
  let cond = 'returnlist';
  let txt = null;
  let dstart = null;
  let dend = null;
  let cname = null;
  let ccompany = null;
  let cemail = null;
  let loadAction = false;
  if (typeof start !== 'undefined') {
    dstart = start;
    if (start === '') {
      dstart = null;
    }
    loadAction = true;
  }
  if (typeof end !== 'undefined') {
    dend = end;
    if (end === '') {
      dend = null;
    }
    loadAction = true;
  }
  if (typeof name !== 'undefined') {
    cname = name;
    if (name === '') {
      cname = null;
    }
    loadAction = true;
  }
  if (typeof company !== 'undefined') {
    ccompany = company;
    if (company === '') {
      ccompany = null;
    }
    loadAction = true;
  }
  if (typeof email !== 'undefined') {
    cemail = email;
    if (email === '') {
      cemail = null;
    }
    loadAction = true;
  }
  if (typeof text !== 'undefined') {
    txt = text;
    if (text === '') {
      txt = null;
    }
    cond = 'sreturnlist';
    loadAction = true;
  }
  if (loadAction) {
    dispatch(showActionLoading());
  } else {
    dispatch(showLoading());
  }
  requestReturnOrders(
    status, limit, page, txt, dstart, dend, cname, ccompany, cemail,
    sortColumn, sortType, display,
  ).then(
    response => dispatch(successResponse(response, cond)),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchMoreReturnOrderList = (
  status, limit, page, text, start, end, name, company, email,
  sortColumn, sortType, display,
) => (dispatch) => {
  let txt = null;
  let dstart = null;
  let dend = null;
  let cname = null;
  let ccompany = null;
  let cemail = null;
  if (typeof start !== 'undefined') {
    dstart = start;
    if (start === '') {
      dstart = null;
    }
  }
  if (typeof end !== 'undefined') {
    dend = end;
    if (end === '') {
      dend = null;
    }
  }
  if (typeof name !== 'undefined') {
    cname = name;
    if (name === '') {
      cname = null;
    }
  }
  if (typeof company !== 'undefined') {
    ccompany = company;
    if (company === '') {
      ccompany = null;
    }
  }
  if (typeof email !== 'undefined') {
    cemail = email;
    if (email === '') {
      cemail = null;
    }
  }
  if (typeof text !== 'undefined') {
    txt = text;
    if (text === '') {
      txt = null;
    }
  }
  dispatch(showActionLoading());
  requestReturnOrders(
    status, limit, page, txt, dstart, dend, cname, ccompany, cemail,
    sortColumn, sortType, display,
  ).then(
    response => dispatch(successResponse(response, 'morereturnlist')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchReturnOrderListReport = (
  status, limit, page, text, start, end, sku,
  recipient, email, ra, sortColumn, sortType, display,
) => (dispatch) => {
  let cond = 'returnlist';
  let txt = null;
  let dstart = null;
  let dend = null;
  let csku = null;
  let crecipient = null;
  let cemail = null;
  let cra = null;
  let loadAction = false;
  if (typeof start !== 'undefined') {
    dstart = start;
    if (start === '') {
      dstart = null;
    }
    loadAction = true;
  }
  if (typeof end !== 'undefined') {
    dend = end;
    if (end === '') {
      dend = null;
    }
    loadAction = true;
  }
  if (typeof sku !== 'undefined') {
    csku = sku;
    if (sku === '') {
      csku = null;
    }
    loadAction = true;
  }
  if (typeof recipient !== 'undefined') {
    crecipient = recipient;
    if (recipient === '') {
      crecipient = null;
    }
    loadAction = true;
  }
  if (typeof email !== 'undefined') {
    cemail = email;
    if (email === '') {
      cemail = null;
    }
    loadAction = true;
  }
  if (typeof ra !== 'undefined') {
    cra = ra;
    if (ra === '') {
      cra = null;
    }
    loadAction = true;
  }
  if (typeof text !== 'undefined') {
    txt = text;
    if (text === '') {
      txt = null;
    }
    cond = 'sreturnlist';
    loadAction = true;
  }
  if (loadAction) {
    dispatch(showActionLoading());
  } else {
    dispatch(showLoading());
  }
  requestReturnOrdersReport(
    status, limit, page, txt, dstart, dend, csku,
    crecipient, cemail, cra, sortColumn, sortType, display,
  ).then(
    response => dispatch(successResponse(response, cond)),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchMoreReturnOrderListReport = (
  status, limit, page, text, start, end, sku,
  recipient, email, ra, sortColumn, sortType, display,
) => (dispatch) => {
  let txt = null;
  let dstart = null;
  let dend = null;
  let csku = null;
  let crecipient = null;
  let cemail = null;
  let cra = null;
  if (typeof start !== 'undefined') {
    dstart = start;
    if (start === '') {
      dstart = null;
    }
  }
  if (typeof end !== 'undefined') {
    dend = end;
    if (end === '') {
      dend = null;
    }
  }
  if (typeof sku !== 'undefined') {
    csku = sku;
    if (sku === '') {
      csku = null;
    }
  }
  if (typeof recipient !== 'undefined') {
    crecipient = recipient;
    if (recipient === '') {
      crecipient = null;
    }
  }
  if (typeof email !== 'undefined') {
    cemail = email;
    if (email === '') {
      cemail = null;
    }
  }
  if (typeof ra !== 'undefined') {
    cra = ra;
    if (ra === '') {
      cra = null;
    }
  }
  if (typeof text !== 'undefined') {
    txt = text;
    if (text === '') {
      txt = null;
    }
  }
  dispatch(showActionLoading());
  requestReturnOrdersReport(
    status, limit, page, txt, dstart, dend, csku,
    crecipient, cemail, cra, sortColumn, sortType, display,
  ).then(
    response => dispatch(successResponse(response, 'morereturnlist')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchVendorOrderList = (limit, page, text, sortColumn, sortType) => (dispatch) => {
  let cond = 'vlist';
  let txt = null;
  if (typeof text !== 'undefined') {
    dispatch(showActionLoading());
    txt = text;
    if (text === '') {
      txt = null;
    }
    cond = 'vslist';
  } else {
    dispatch(showLoading());
  }
  requestVendorOrders(limit, page, txt, sortColumn, sortType).then(
    response => dispatch(successResponse(response, cond)),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchMoreVendorOrderList = (limit, page, text, sortColumn, sortType) => (dispatch) => {
  let txt = null;
  if (typeof text !== 'undefined') {
    txt = text;
    if (text === '') {
      txt = null;
    }
  }
  dispatch(showActionLoading());
  requestVendorOrders(limit, page, txt, sortColumn, sortType).then(
    response => dispatch(successResponse(response, 'vmorelist')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchOrderDetail = id => (dispatch) => {
  dispatch(showActionLoading());
  requestOrderDetails(id).then(
    response => dispatch(successResponse(response, 'detail')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchOrderItems = ranumber => (dispatch) => {
  dispatch(showActionLoading());
  requestOrderItems(ranumber).then(
    response => dispatch(successResponse(response, 'detail')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchVendorOrderDetails = ponumber => (dispatch) => {
  dispatch(showActionLoading());
  requestVendorOrderDetails(ponumber).then(
    response => dispatch(successResponse(response, 'detail')),
    err => dispatch(errorResponse(err)),
  );
};

export const getCarrierService = () => (dispatch) => {
  dispatch(showActionLoading());
  requestCarrierService().then(
    response => dispatch(successResponse(response, 'carrires')),
    err => dispatch(errorResponse(err)),
  );
};

/* export const getCarrierService = () => dispatch => new Promise((resolve) => {
  dispatch(showActionLoading());
  requestCarrierService().then((response) => {
    response.json().then((json) => {
      if (response.ok) {
        dispatch(setCarrierService(json.payload));
        dispatch(hideActionLoading());
        resolve(json.payload);
      } else {
        console.log('error: ',
        json.errors.map(err => err.message).join(', ')); // eslint-disable-line
        resolve();
      }
    });
  });
}); */

/* export const fetchOrderDetailForRA = id => (dispatch) => {
  dispatch(showActionLoading());
  requestOrderDetails(id).then(
    response => dispatch(successResponse(response, 'detailforRA')),
    err => dispatch(errorResponse(err)),
  );
}; */

export const addOrderRecipient = dataToSubmit => (dispatch) => {
  dispatch(showActionLoading());
  postOrderRecipient(dataToSubmit).then(
    response => dispatch(successResponse(response, 'addrecipient')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const saveOrderRecipient = (id, dataToSubmit) => (dispatch) => {
  dispatch(showActionLoading());
  updateOrderRecipient(id, dataToSubmit).then(
    response => dispatch(successResponse(response, 'addrecipient')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const autocompleteRecipient = (txt, limit = 20, page = 1) => (dispatch) => {
  dispatch(showActionLoading());
  autoSearchRecipient(txt, limit, page).then(
    response => dispatch(successResponse(response, 'autocomplete')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const importsInfo = () => (dispatch) => {
  dispatch(showActionLoading());
  lastImportOrders().then(
    response => dispatch(successResponse(response, 'importsinfo')),
    err => dispatch(errorResponse(err, false, true)),
  );
};

export const importOrders = () => (dispatch) => {
  dispatch(showActionLoading());
  importWMSOrders().then(
    response => dispatch(successResponse(response, 'importorders')),
    err => dispatch(errorResponse(err, false, true)),
  );
};

export const updateSalesOrder = dataToSubmit => (dispatch) => {
  dispatch(showActionLoading());
  importWMSOrdersById(dataToSubmit).then(
    response => dispatch(successResponse(response, 'update_sales_orders')),
    err => dispatch(errorResponse(err, false, true)),
  );
};

export const autocompleteOrder = (txt, status = 4, limit = 20, page = 1) => (dispatch) => {
  dispatch(showActionLoading());
  autoSearchOrders(txt, status, limit, page).then(
    response => dispatch(successResponse(response, 'autocompleteorder')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const cancelOrder = id => (dispatch) => {
  dispatch(showActionLoading());
  cancelSalesOrder(id).then(
    response => dispatch(successResponse(response, 'cancel order')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const getOrderStatuses = () => (dispatch) => {
  dispatch(showActionLoading());
  fetchOrderStatuses().then(
    response => dispatch(successResponse(response, 'order status')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const reviewCSVOrder = dataToSubmit => (dispatch) => {
  dispatch(showActionLoading());
  postCSVOrder(dataToSubmit).then(
    response => dispatch(successResponse(response, 'reviewcsvorder')),
    err => dispatch(errorResponse(err, false)),
  );
};

export const importCSVOrder = dataToSubmit => (dispatch) => {
  dispatch(showActionLoading());
  saveCSVOrder(JSON.stringify(dataToSubmit)).then(
    response => dispatch(successResponse(response, 'importcsvorder')),
    err => dispatch(errorResponse(err, false)),
  );
};

// third party

export const fetchThirdPartyList = (
  status, limit, page, text, start, end, name, company, email,
  sortColumn, sortType,
) => (dispatch) => {
  let cond = '3rdpartylist';
  let txt = null;
  let dstart = null;
  let dend = null;
  let cname = null;
  let ccompany = null;
  let cemail = null;
  let loadAction = false;
  if (typeof start !== 'undefined') {
    dstart = start;
    if (start === '') {
      dstart = null;
    }
    loadAction = true;
  }
  if (typeof end !== 'undefined') {
    dend = end;
    if (end === '') {
      dend = null;
    }
    loadAction = true;
  }
  if (typeof name !== 'undefined') {
    cname = name;
    if (name === '') {
      cname = null;
    }
    loadAction = true;
  }
  if (typeof company !== 'undefined') {
    ccompany = company;
    if (company === '') {
      ccompany = null;
    }
    loadAction = true;
  }
  if (typeof email !== 'undefined') {
    cemail = email;
    if (email === '') {
      cemail = null;
    }
    loadAction = true;
  }
  if (typeof text !== 'undefined') {
    txt = text;
    if (text === '') {
      txt = null;
    }
    cond = 's3rdpartylist';
    loadAction = true;
  }
  if (loadAction) {
    dispatch(showActionLoading());
  } else {
    dispatch(showLoading());
  }
  requestThirdParty(
    status, limit, page, txt, dstart, dend, cname, ccompany, cemail,
    sortColumn, sortType,
  ).then(
    response => dispatch(successResponse(response, cond)),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchMoreThirdPartyList = (
  status, limit, page, text, start, end, name, company, email,
  sortColumn, sortType,
) => (dispatch) => {
  let txt = null;
  let dstart = null;
  let dend = null;
  let cname = null;
  let ccompany = null;
  let cemail = null;
  if (typeof start !== 'undefined') {
    dstart = start;
    if (start === '') {
      dstart = null;
    }
  }
  if (typeof end !== 'undefined') {
    dend = end;
    if (end === '') {
      dend = null;
    }
  }
  if (typeof name !== 'undefined') {
    cname = name;
    if (name === '') {
      cname = null;
    }
  }
  if (typeof company !== 'undefined') {
    ccompany = company;
    if (company === '') {
      ccompany = null;
    }
  }
  if (typeof email !== 'undefined') {
    cemail = email;
    if (email === '') {
      cemail = null;
    }
  }
  if (typeof text !== 'undefined') {
    txt = text;
    if (text === '') {
      txt = null;
    }
  }
  dispatch(showActionLoading());
  requestThirdParty(
    status, limit, page, txt, dstart, dend, cname, ccompany, cemail,
    sortColumn, sortType,
  ).then(
    response => dispatch(successResponse(response, 'more3rdpartylist')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchThirdPartyDetail = id => (dispatch) => {
  dispatch(showActionLoading());
  requestThirdPartyDetails(id).then(
    response => dispatch(successResponse(response, 'detail')),
    err => dispatch(errorResponse(err)),
  );
};
