const styles = theme => ({
  ...theme.classes,
  gridcontainer: {
    padding: '18px 0',
  },
  gridcaption: {
    fontSize: 12,
    lineHeight: '14px',
    color: theme.palette.header.primary,
    marginBottom: 5,
  },
  gridbody: {
    fontSize: 14,
    lineHeight: '16px',
    color: theme.palette.primary.main,
    '&.wbreak': {
      wordBreak: 'break-all',
    },
  },
  gridbody2: {
    color: `${theme.palette.text.primary} !important`,
  },
  gridbodygray: {
    fontSize: 14,
    lineHeight: '16px',
    color: '#d9d7d7',
  },
  trow: {
    height: 'auto',
    border: '1px solid #d9d7d7',
    '& th': {
      color: theme.palette.primary.light,
      height: 38,
      fontSize: 12,
      fontWeight: 400,
    },
    '& th.desc': {
      width: '56%',
      textAlign: 'left',
    },
    '& td': {
      /* border: '1px solid #d9d7d7', */
      height: 44,
    },
    '& td p': {
      color: theme.palette.text.gray20,
    },
    '& th:first-child': {
      borderTopLeftRadius: 6,
    },
    '& th:last-child': {
      borderTopRightRadius: 6,
    },
  },
  h6: {
    fontSize: 14,
    fontWeight: 500,
  },
  alignRight: {
    textAlign: 'right',
  },
  table: {
    marginBottom: 20,
    position: 'relative',
    background: '#fff',
    width: '100%',
    borderCollapse: 'inherit',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  t1: {
    fontSize: 12,
    color: theme.palette.primary.light,
    marginBottom: 20,
  },
  thead: {
    backgroundColor: theme.palette.background.default,
    boxShadow: '0px 0px 6px 0px rgba(68, 107, 176, 0.91), 0px 10px 6px 0px rgba(68, 107, 176, 0.38)',
    borderRadius: 6,
  },
  tbody: {
    position: 'relative',
    background: '#fff',
    width: '100%',
  },
  tblsortlbl: {
    whiteSpace: 'nowrap',
    '& svg': {
      width: 9,
      fill: '#446BB0',
      marginLeft: 6,
    },
  },
  tlabel: {
    margin: '48px 0 22px',
  },
  textbreak: {
    wordBreak: 'break-all',
    paddingRight: 20,
  },
});

export default styles;
