import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import { update, isFormValid, populateFields, populateOptionFields } from 'ui/components/form/FormActions';
import FormField from 'ui/components/form/FormField';
import styles from 'ui/components/form/InputFormStyle';
import pageStyle from './AccountStaffFormStyle';

class AccountStaffForm extends Component {
  state = {
    formError: false,
    formdata: {
      firstname: {
        element: 'input',
        value: '',
        config: {
          label: 'First Name',
          name: 'firstname',
          type: 'text',
        },
        validation: {
          required: true,
        },
        valid: false,
        touched: true,
        validationMessage: '',
        addeditform: false,
      },
      lastname: {
        element: 'input',
        value: '',
        config: {
          label: 'Last Name',
          name: 'lastname',
          type: 'text',
        },
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        validationMessage: '',
        addeditform: false,
      },
      email: {
        element: 'input',
        value: '',
        config: {
          label: 'Email',
          name: 'email',
          type: 'email',
        },
        validation: {
          required: true,
          email: true,
        },
        valid: false,
        touched: false,
        validationMessage: '',
        addeditform: false,
      },
      role: {
        element: 'select',
        value: '',
        config: {
          label: 'Role *',
          name: 'role',
          options: [],
        },
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        validationMessage: '',
        showlabel: true,
        addeditform: false,
      },
      changePassword: {
        element: 'input',
        value: '',
        config: {
          name: 'change_password',
          type: 'password',
          label: 'Change Password',
        },
        validation: {
          required: false,
          change: true,
        },
        valid: true,
        touched: false,
        validationMessage: '',
        addeditform: false,
      },
      confirmPassword: {
        element: 'input',
        value: '',
        config: {
          name: 'confirm_password',
          type: 'password',
          label: 'Confirm New Password',
        },
        validation: {
          required: false,
          confirm: true,
        },
        valid: true,
        touched: false,
        validationMessage: '',
        addeditform: false,
      },
    },
  }

  componentDidMount() {
    const {
      acctData, onRef, modal, userRoleList,
    } = this.props;
    if (typeof modal.params !== 'undefined' && modal.params.type === 'edit') {
      if (Object.keys(acctData).length !== 0) {
        const data = {
          firstname: acctData.attributes.firstname,
          lastname: acctData.attributes.lastname,
          email: acctData.attributes.email,
          role: acctData.attributes.role_id,
        };
        populateFields(this.state.formdata, data);
      }
    }
    if (typeof modal.params !== 'undefined' && modal.params.type === 'add') {
      const data = {
        ...this.state.formdata,
      };
      data.changePassword.validation.required = true;
      data.changePassword.valid = false;
      data.confirmPassword.validation.required = true;
      data.confirmPassword.valid = false;
      this.initFields(data);
    }
    const userRoles = userRoleList.dataArr;
    if (userRoles.length) {
      const rolelist = [];
      for (const key in userRoles) { // eslint-disable-line
        rolelist.push({ _id: userRoles[key].attributes.id, name: userRoles[key].attributes.name }); // eslint-disable-line
      }
      const newFormData = populateOptionFields(this.state.formdata, rolelist, 'role');
      if (typeof modal.params !== 'undefined' && modal.params.type === 'add') {
        newFormData.role.value = userRoles[0].attributes.id;
        newFormData.role.valid = true;
        newFormData.role.touched = true;
      }
      this.updateFields(newFormData);
    }
    onRef(this);
  }

  componentDidUpdate() {
    const {
      actionloading, resetAcctData, getloadtype, setloadtype, actions, fetchstaff, setrolename,
    } = this.props;
    if (!actionloading && (getloadtype === 'add staff' || getloadtype === 'edit staff')) {
      resetAcctData();
      fetchstaff(10, 1);
      const fdata = {
        ...this.state.formdata,
      };
      const rolename = fdata.role.config.options
        .filter(sel => sel.key === parseInt(fdata.role.value, 10));
      setrolename(rolename[0].value);
      if (getloadtype === 'add staff') {
        setloadtype('added role name');
      }
      if (getloadtype === 'edit staff') {
        setloadtype('updated role name');
      }
      const modalKey = 'modal-staff';
      actions.ui.closeModal({ modalKey });
    }
  }

  componentWillUnmount() {
    this.props.onRef(null);
  }

  initFields = (data) => {
    this.setState({
      ...this.state,
      formdata: data,
    });
  }

  updateFields = (newFormdata) => {
    this.setState({
      ...this.state,
      formdata: newFormdata,
    });
  }

  updateForm = (element) => {
    const { modal } = this.props;
    const tmpFormdata = {
      ...this.state.formdata,
    };
    if (element.id === 'changePassword') {
      if (element.event.currentTarget.value.trim() !== '') {
        tmpFormdata.changePassword.validation.required = true;
      } else {
        tmpFormdata.changePassword.validation.required = false;
      }
    }
    this.setState({
      ...this.state,
      formdata: tmpFormdata,
    });

    const newFormdata = update(element, this.state.formdata, '');
    if (element.id === 'changePassword') {
      if (newFormdata.changePassword.validationMessage === 'Passwords do not match') {
        newFormdata.changePassword.validationMessage = '';
        newFormdata.confirmPassword.validationMessage = 'Passwords do not match';
        newFormdata.confirmPassword.validation.required = true;
        newFormdata.confirmPassword.valid = false;
      }
    }
    if (newFormdata.changePassword.value.trim().length > 5 && newFormdata.changePassword.value.trim().length < 17) { // eslint-disable-line
      if (newFormdata.changePassword.value.trim() === newFormdata.confirmPassword.value.trim()) {
        newFormdata.changePassword.valid = true;
        newFormdata.confirmPassword.valid = true;
        if (modal.params.type === 'edit') {
          newFormdata.changePassword.validation.required = false;
          newFormdata.confirmPassword.validation.required = false;
        }
      }
    } else {
      newFormdata.changePassword.valid = false;
    }

    this.setState({
      ...this.state,
      formError: false,
      formdata: newFormdata,
    });
  }

  submitForm = () => {
    const {
      acctData,
      addstaff,
      setloadtype,
      updateprofile,
      modal,
    } = this.props;

    if (modal.params.type === 'edit') {
      const newFormdata = {
        ...this.state.formdata,
      };
      if (
        newFormdata.changePassword.value.trim() === ''
        && newFormdata.confirmPassword.value.trim() === ''
      ) {
        newFormdata.changePassword.valid = true;
        newFormdata.confirmPassword.valid = true;
      }
      this.setState({
        ...this.state,
        formdata: newFormdata,
      });
    }

    const formIsValid = isFormValid(this.state.formdata, '');

    if (formIsValid) {
      const data = {
        firstname: this.state.formdata.firstname.value,
        lastname: this.state.formdata.lastname.value,
        email: this.state.formdata.email.value,
        password: this.state.formdata.changePassword.value,
        role_id: this.state.formdata.role.value,
      };
      if (modal.params.type === 'edit') {
        acctData.attributes.firstname = this.state.formdata.firstname.value;
        acctData.attributes.lastname = this.state.formdata.lastname.value;
        acctData.attributes.email = this.state.formdata.email.value;

        for (const key in data) { // eslint-disable-line
          if (key === 'password' && data[key] === '') {
            delete data[key];
          }
        }

        /* console.log(data); // eslint-disable-line */
        setloadtype('edit staff');
        updateprofile(acctData.attributes.id, data, false);
      }
      if (modal.params.type === 'add') {
        const username = data.email.split('@', 1, 0);
        [data.username] = username;
        setloadtype('add staff');
        addstaff(data);
        /* console.log(data); // eslint-disable-line */
      }
    } else {
      this.setState({
        ...this.state,
        formError: true,
      });
    }
  }

  render() {
    const { classes } = this.props;
    /* console.log(this.props); // eslint-disable-line */
    return (
      <Fragment>
        <Grid
          className={classNames(classes.gridContainer)}
          container
          spacing={0}
        >
          <Grid className={`${classes.colA} ${classes.w100}`} item xs={4}>
            <FormField
              id="firstname"
              formdata={this.state.formdata.firstname}
              change={element => this.updateForm(element)}
            />
            <FormField
              id="lastname"
              formdata={this.state.formdata.lastname}
              change={element => this.updateForm(element)}
            />
            <FormField
              id="email"
              formdata={this.state.formdata.email}
              change={element => this.updateForm(element)}
            />
            <FormField
              id="role"
              formdata={this.state.formdata.role}
              change={element => this.updateForm(element)}
            />
            <FormField
              id="changePassword"
              formdata={this.state.formdata.changePassword}
              change={element => this.updateForm(element)}
            />
            <FormField
              id="confirmPassword"
              formdata={this.state.formdata.confirmPassword}
              change={element => this.updateForm(element)}
            />
            { this.state.formError ?
              <div className={classes.error_label}>
                  Please check your data
              </div>
              : null }
          </Grid>
          <Grid className={classes.desktop} item xs={6} />
        </Grid>
      </Fragment>
    );
  }
}

AccountStaffForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  acctData: PropTypes.instanceOf(Object).isRequired,
  userRoleList: PropTypes.instanceOf(Object).isRequired,
  modal: PropTypes.instanceOf(Object).isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
  onRef: PropTypes.func.isRequired,
  resetAcctData: PropTypes.func.isRequired,
  addstaff: PropTypes.func.isRequired,
  setloadtype: PropTypes.func.isRequired,
  updateprofile: PropTypes.func.isRequired,
  fetchstaff: PropTypes.func.isRequired,
  setrolename: PropTypes.func.isRequired,
  actionloading: PropTypes.bool.isRequired,
  getloadtype: PropTypes.string.isRequired,
};

/* export default withStyles(styles)(AccountStaffForm); */

export default withStyles(theme => ({
  ...styles(theme),
  ...pageStyle(theme),
}))(AccountStaffForm);
