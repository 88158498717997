const styles = theme => ({
  ulistcontainer: {
    padding: 0,
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '100%',
    '& li': {
      position: 'relative',
      display: 'block',
      padding: '22px 35px 2px',
      borderTop: '1px solid #E8EAEE',
      '& .dategrid': {
        padding: 0,
        '& .rootdate': {
          marginTop: 8,
          marginBottom: 22,
        },
      },
      '& .space': {
        width: '100%',
        height: 12,
        margin: 0,
      },
      '@media (max-width:1240px)': {
        padding: '20px 20px 20px',
        border: '0 none',
        '& .datestart': {
          padding: '0 5px 0 0',
        },
        '& .dateend': {
          padding: '0 0 0 5px',
        },
        '& > div, & > div > div': {
          position: 'relative',
          margin: 0,
        },
        '&.datelist div': {
          width: '100%',
          position: 'relative',
          margin: 0,
        },
        '& .dateIcon': {
          top: -1,
          right: -38,
          zIndex: 2,
        },
        '&.gr1': {
          borderTop: '1px solid #E8EAEE',
        },
        '& .space': {
          height: 22,
        },
      },
      '@media (max-width:370px)': {
        '& .dateIcon': {
          right: -26,
        },
      },
    },
    '@media (max-width:1240px)': {
      padding: '0 0 20px 0',
    },
  },
  h6: {
    color: theme.palette.text.primary,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '14px',
    marginBottom: 20,
  },
  submit2: {
    top: 27,
    right: 40,
    position: 'absolute',
    boxShadow: 'none',
    textTransform: 'capitalize',
    fontSize: 12,
    padding: 2,
  },
  btnwrapper: {
    padding: '0 35px 25px',
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      height: 34,
      padding: '0 78px',
      minWidth: 'auto',
      fontSize: 14,
      minHeight: 34,
      boxShadow: 'none',
      fontWeight: 500,
      borderRadius: 100,
      textTransform: 'capitalize',
    },
    '@media (max-width:1240px)': {
      padding: '0 20px 10px',
      '& button span': {
        color: '#fff',
      },
    },
  },
});

export default styles;
