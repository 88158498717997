const styles = theme => ({
  title: {
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 400,
    color: theme.palette.primary.light,
    marginBottom: 18,
    '@media (max-width:1240px)': {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '19px',
      color: theme.palette.primary.main,
      marginBottom: 22,
      '&.htitle': {
        color: '#2C3033',
        lineHeight: '36px',
        margin: 0,
      },
    },
  },
  btnswrapper: {
    width: '100%',
    textAlign: 'right',
    marginTop: 16,
  },
  btn: {
    position: 'relative',
    display: 'inline-block',
  },
  boxshadow: {
    boxShadow: '0 0 12px rgba(68, 107, 176, 0.91)',
    border: '0 none',
    '@media (max-width:1240px)': {
      borderRadius: 6,
    },
  },
  rcontainer: {
    position: 'relative',
    width: '100%',
    marginBottom: 25,
  },
  rhead: {
    backgroundColor: '#C5D1E0',
    position: 'relative',
    height: 32,
    display: 'flex',
    alignItems: 'center',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    width: '100%',
    padding: '12px 18px 0',
    '& label': {
      fontSize: 14,
      fontWeight: 600,
      color: theme.palette.primary.main,
      width: '95%',
      lineHeight: '16px',
    },
    '& label span': {
      fontSize: 14,
      fontWeight: 600,
      color: theme.palette.text.gray20,
    },
    '& .close': {
      position: 'absolute',
      top: 13,
      right: 12,
      width: 10,
      height: 10,
      stroke: theme.palette.secondary.main,
      cursor: 'pointer',
    },
    '& .edit': {
      position: 'absolute',
      top: 10,
      right: 32,
      height: 15,
      fill: theme.palette.secondary.main,
      cursor: 'pointer',
    },
    '@media (max-width:1240px)': {
      '& label span': {
        display: 'none',
      },
    },
  },
  rcontent: {
    backgroundColor: '#C5D1E0',
    position: 'relative',
    zIndex: 2,
    width: '100%',
    margin: 0,
    padding: '0 6px 8px',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    '& div': {
      position: 'relative',
    },
    '& span': {
      fontSize: 12,
      fontWeight: 400,
      color: '#5D5B5B',
      paddingLeft: 22,
    },
    '& svg': {
      position: 'absolute',
      top: 16,
      left: 12,
      fill: '#949DB2',
    },
    '& .location svg': {
      top: 10,
    },
    '& .email svg': {
      top: 12,
    },
    '& .contact svg': {
      top: 11,
    },
  },
  col1a1: {
    '@media (max-width:1240px)': {
      marginTop: 8,
    },
  },
  col2a1: {
    '@media (max-width:1240px)': {
      marginBottom: 14,
      maxWidth: '60%',
      display: 'inline-block',
    },
    '@media (max-width:345px)': {
      maxWidth: '100%',
      display: 'block',
      marginBottom: 0,
    },
  },
  col3a1: {
    '@media (max-width:1240px)': {
      marginBottom: 14,
      maxWidth: '40%',
      display: 'inline-block',
    },
    '@media (max-width:345px)': {
      maxWidth: '100%',
      display: 'block',
    },
  },
  sc2: {
    backgroundColor: theme.palette.common.white,
    '@media (max-width:1240px)': {
      backgroundColor: theme.palette.background.paper,
      padding: '26px 10px 24px',
      '&.sc2content': {
        backgroundColor: theme.palette.common.white,
        paddingTop: 84,
        borderRadius: 0,
        height: '100vh',
        boxShadow: 'none',
        padding: '26px 20px 24px',
      },
      '&.sc2': {
        backgroundColor: theme.palette.common.white,
        padding: '0 20px 24px',
        border: 0,
        '& .w100': {
          maxWidth: '100%',
          flexBasis: '100%',
          padding: 0,
        },
        '& .w50l': {
          maxWidth: '50%',
          flexBasis: '50%',
          padding: '0 10px 0 0',
        },
        '& .w50r': {
          maxWidth: '50%',
          flexBasis: '50%',
          padding: '0 0 0 10px',
        },
      },
    },
  },
  sc3: {
    '@media (max-width:1240px)': {
      w100: {
        padding: 0,
        maxWidth: '100%',
        flexBasis: '100%',
      },
      '& .w50l': {
        maxWidth: '50%',
        flexBasis: '50%',
        padding: '0 10px 0 0',
      },
      '& .w50r': {
        maxWidth: '50%',
        flexBasis: '50%',
        padding: '0 0 0 10px',
      },
      '& .w33l': {
        maxWidth: '33.33%',
        flexBasis: '33.33%',
        padding: '0 5px 0 0',
      },
      '& .w33c': {
        maxWidth: '33.33%',
        flexBasis: '33.33%',
        padding: '0 2.5px',
      },
      '& .w33r': {
        maxWidth: '33.33%',
        flexBasis: '33.33%',
        padding: '0 0 0 5px',
      },
    },
  },
  lblsizes: {
    fontSize: 14,
    lineHeight: 16,
    color: theme.palette.text.primary,
    marginBottom: 6,
  },
  lwh: {
    '& p': {
      minWidth: 70,
      lineHeight: '12px',
      top: 38,
    },
  },
  manufacturerbox: {
    backgroundColor: '#fff',
    position: 'relative',
    height: 36,
    width: '100%',
    padding: '0 32px 0 12px',
    borderRadius: 4,
    border: `1px solid ${theme.palette.text.primary}`,
    '& .close': {
      position: 'absolute',
      top: 13,
      right: 12,
      width: 10,
      height: 10,
      stroke: theme.palette.primary.main,
      cursor: 'pointer',
    },
    '& .legend': {
      position: 'absolute',
      top: -8,
      left: 12,
      color: '#5d5b5b',
      fontSize: 11,
      fontWeight: 400,
      zIndex: 1,
      '& .title': {
        position: 'relative',
        zIndex: 1,
      },
      '& .bg': {
        backgroundColor: '#fff',
        position: 'absolute',
        top: 7,
        left: 0,
        width: '100%',
        height: 2,
        zIndex: 0,
      },
    },
    '& .name': {
      color: theme.palette.primary.main,
      fontWeight: 400,
      fontSize: 14,
      height: 34,
      display: 'flex',
      alignItems: 'center',
    },
    '@media (max-width:1240px)': {
      marginBottom: 20,
    },
  },
  add: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    height: 36,
    minHeight: '36px',
    fontSize: 14,
    fontWeight: 500,
    boxShadow: 'none',
    borderRadius: 100,
    border: '0 none',
    textTransform: 'capitalize',
    padding: '0 20px',
    '& svg': {
      stroke: theme.palette.common.white,
      verticalAlign: 'middle',
      marginLeft: 8,
      width: 12,
      height: 12,
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    },
    '& span': {
      color: '#fff',
    },
    '@media (max-width:1240px)': {
      marginTop: 16,
    },
  },
  orderlines: {
    '& .listbtn': {
      position: 'relative',
      textAlign: 'right',
      '& .delicon': {
        position: 'absolute',
        right: 40,
        top: -6,
      },
      '& .delicon svg': {
        width: 18,
        height: 18,
      },
      '@media (max-width:1240px)': {
        textAlign: 'left',
        '& .delicon': {
          position: 'absolute',
          right: 0,
          top: -62,
        },
      },
    },
  },
  w100: {
    '@media (max-width:1240px)': {
      padding: 0,
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  wl: {
    '@media (max-width:1240px)': {
      padding: '0 !important',
      maxWidth: '30%',
      flexBasis: '30%',
    },
  },
  wr: {
    '@media (max-width:1240px)': {
      padding: 0,
      maxWidth: '70%',
      flexBasis: '70%',
      '& .inputHolder': {
        paddingRight: '40%',
      },
    },
  },
  lblmobile: {
    display: 'none',
    '@media (max-width:1240px)': {
      display: 'block',
      fontSize: 12,
      lineHeight: '14px',
      paddingBottom: 10,
      color: theme.palette.primary.light,
    },
    '@media (max-width:345px)': {
      fontSize: 11,
    },
  },
  cancel: {
    display: 'inline-block',
  },
  addnew: {
    '@media (max-width:345px)': {
      height: 30,
      width: 148,
    },
  },
  viewrapper: {
    '& .vilbl': {
      display: 'none',
    },
    '& .vi2 .w100 p': {
      zIndex: 2,
    },
    '@media (max-width:1240px)': {
      border: 0,
      padding: 0,
      '& .vilbl': {
        display: 'block',
        fontSize: 16,
        fontWeight: 400,
        color: theme.palette.primary.main,
        paddingBottom: 20,
        padding: '26px 20px 20px',
      },
      '& .w100': {
        maxWidth: '100%',
        flexBasis: '100%',
        padding: 0,
      },
      '& .vi1': {
        padding: '0 20px 25px',
        borderBottom: '1px solid #D9D7D7',
      },
      '& .vi2': {
        backgroundColor: theme.palette.common.white,
        padding: '40px 20px 0',
      },
    },
  },
  nospace: {
    '@media (max-width:1240px)': {
      display: 'none',
    },
  },
  spacermobile: {
    display: 'none',
    '@media (max-width:1240px)': {
      display: 'block',
    },
  },
  anvheader: {
    display: 'none',
    '@media (max-width:1240px)': {
      backgroundColor: theme.palette.background.default,
      position: 'fixed',
      top: 0,
      left: 0,
      display: 'block',
      height: 60,
      width: '100%',
      padding: '10px 14px 0 24px',
      zIndex: 2,
      '& .right': {
        textAlign: 'right',
      },
    },
  },
  anvcontainer: {
    '@media (max-width:1240px)': {
      backgroundColor: theme.palette.common.white,
      position: 'fixed',
      top: 0,
      zIndex: 10000,
      overflow: 'auto',
      height: '100vh',
    },
  },
  desktopdisplay: {
    display: 'block',
    '@media (max-width:1240px)': {
      display: 'none',
    },
  },
  mobiledisplay: {
    display: 'none',
    '@media (max-width:1240px)': {
      display: 'block',
    },
  },
  modalAddBtn: {
    width: 96,
  },
  modclosebtn: {
    marginLeft: 10,
  },
  htitle: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.primary.main,
    marginBottom: 20,
    '@media (max-width:1240px)': {
      padding: '0 18px',
    },
  },
  pogrid: {
    '& textarea': {
      height: 136,
    },
  },
  pogrid2: {
    '& textarea': {
      height: 166,
    },
  },
  vidbox: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& .btn': {
      width: 80,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      height: 34,
      flex: 'none',
    },
    '&.mid .btn': {
      position: 'relative',
      width: 56,
    },
    '& button': {
      backgroundColor: '#09B0EE',
      fontSize: 14,
      padding: 0,
      color: '#fff',
      textTransform: 'capitalize',
      borderRadius: 100,
      minWidth: 66,
      height: 30,
      '&:hover': {
        backgroundColor: '#09B0EE',
      },
    },
    '&.mid button': {
      fontSize: 12,
      minWidth: 48,
    },
    '&.mid .closebtn': {
      backgroundColor: '#fff',
      position: 'absolute',
      top: -30,
      right: -20,
      display: 'block',
      borderRadius: '100%',
      width: 26,
      height: 26,
      cursor: 'pointer',
      border: '1px solid',
      borderColor: '#C5D1E0',
    },
    '&.mid .closebtn svg': {
      width: 24,
      height: 24,
      stroke: '#949db2',
      fill: '#fff',
      padding: 7,
    },
  },
  disabledbtn: {
    backgroundColor: '#D9D7D7 !important',
    color: '#fff !important',
  },
  focusbtn: {
    backgroundColor: '#09B0EE',
    color: '#fff',
  },
  sizetype: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '& label': {
      color: '#1F1C1C',
      width: 176,
      fontSize: 14,
      flex: 'none',
    },
    '@media (max-width:1240px)': {
      display: 'block',
    },
  },
  manufacturerid: {
    position: 'relative',
    '& span.err': {
      position: 'absolute',
      top: 36,
      left: 1,
      color: 'red',
      fontSize: 11,
      fontWeight: 300,
      zIndex: 2,
    },
  },
  inptnote: {
    fontSize: 11,
    marginTop: -18,
    marginBottom: 12,
    color: '#333',
  },
  addmanufacturerbox: {
    backgroundColor: '#fff',
    position: 'absolute',
    top: -22,
    left: -10,
    width: '240px',
    height: 'auto',
    zIndex: 2,
    boxShadow: '0 0 12px rgb(68 107 176 / 91%)',
    borderRadius: 5,
    padding: '20px 12px 6px',
    '& .inputHolder': {
      width: '100%',
    },
    '@media (max-width:1240px)': {
      left: 0,
      width: '100%',
    },
  },
  orno: {
    position: 'relative',
    '& span.input-group-text': {
      position: 'absolute',
      top: 1,
      right: 1,
      display: 'flex',
      alignItems: 'center',
      padding: '.375rem .75rem',
      height: 34,
      marginBottom: 0,
      fontSize: '1rem',
      fontWeight: 400,
      color: '#495057',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      backgroundColor: '#e9ecef',
      border: '1px solid #ced4da',
      borderRadius: '.25rem',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      letterSpacing: 1,
    },
  },
});

export default styles;
