import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, AppBar, Toolbar, Typography, CircularProgress, Fab, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as Close } from 'resources/icons/close.svg';
import { ReactComponent as CloseMobile } from 'resources/icons/closev2.svg';
import styles from 'ui/components/common/ModalHeaderStyle';

class AddEditStaffHeader extends Component {
  state = {
    loading: false,
  };
  onCancel = () => {
    const { actions, resetAcctData, setloadtype } = this.props;
    setloadtype('');
    resetAcctData();
    const modalKey = 'modal-staff';
    actions.ui.closeModal({ modalKey });
  }

  addUpdate = () => {
    const { submitStaff } = this.props;
    this.setState({
      loading: true,
    });
    submitStaff();
  }
  render() {
    const { loading } = this.state;
    const { classes, modal, actionloading } = this.props;
    let saving = loading;
    if (!actionloading) {
      saving = false;
    }
    return (
      <Fragment>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.modalToolbarHeight}>
            <div className={classes.modalToolbarContainer}>
              <Typography variant="h6" color="inherit" className={classNames(classes.flex, classes.h6)}>
                { typeof modal.params !== 'undefined' && modal.params.type === 'edit' ?
                  'Edit Staff'
                :
                  'Add Staff'
                }
              </Typography>
              <div className={classes.submitWrapper}>
                <Button
                  className={`${classes.modalBtn} ${classes.staffmodalBtn}`}
                  variant="contained"
                  color="secondary"
                  disabled={saving}
                  onClick={this.addUpdate}
                >
                  Submit
                </Button>
                {saving && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
              <Fab onClick={this.onCancel} variant="extended" aria-label="Close" className={classes.close}>
                Close
                <Close className={classes.extendedIcon} />
              </Fab>
              <IconButton
                className={classNames(classes.icon, classes.iconbtn, classes.stafficonbtn)}
                aria-label="Close"
                onClick={this.onCancel}
              >
                <CloseMobile className={classes.closev2} />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </Fragment>
    );
  }
}

AddEditStaffHeader.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
  modal: PropTypes.instanceOf(Object).isRequired,
  resetAcctData: PropTypes.func.isRequired,
  setloadtype: PropTypes.func.isRequired,
  submitStaff: PropTypes.func.isRequired,
  actionloading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(AddEditStaffHeader);
