const styles = theme => ({
  ...theme.classes,
  container: {
    margin: '0 auto',
    width: 1200,
    padding: '0',
    height: '100vh',
    '@media (max-width:1240px)': {
      width: '100%',
    },
  },
  content: {
    background: theme.palette.common.white,
    height: 'calc(100% - 68px)',
    overflowY: 'auto',
    padding: '44px 40px',
    borderRadius: 8,
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.2)',
      borderRadius: 10,
      backgroundColor: '#FEFEFE',
    },
    '&::-webkit-scrollbar': {
      width: 10,
      backgroundColor: '#FEFEFE',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.2)',
      backgroundColor: '#F3F3F3',
    },
    '@media (max-width:1240px)': {
      borderRadius: 0,
      '&.rolecontent': {
        padding: '25px 12px',
        position: 'relative',
      },
    },
    '&.stockcontent': {
      '@media (max-width:540px)': {
        padding: '24px 20px',
      },
    },
  },
  inptbox: {
    width: 348,
    marginBottom: 20,
    '@media (max-width:1240px)': {
      '&.inptbox': {
        width: '100%',
        marginBottom: 18,
      },
      '& .inputHolder > div': {
        margin: 0,
      },
    },
  },
  note: {
    color: theme.palette.text.gray20,
    fontSize: 11,
    fontWeight: 400,
    marginTop: 12,
    '@media (max-width:1240px)': {
      '&.note': {
        position: 'absolute',
        top: 68,
        paddingLeft: 108,
        color: '#878D9B',
        lineHeight: '12px',
        marginTop: 0,
      },
    },
  },
});

export default styles;
