const styles = () => {
  const drawerWidth = '100%';
  return {
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      display: 'block',
      backgroundColor: '#fff',
      top: 0,
      left: 'auto',
      right: 0,
      width: `calc(${drawerWidth} - 32%)`,
      'overflow-y': 'hidden',
      zIndex: 1210,
      '@media (max-width:1240px)': {
        width: '100%',
      },
    },
    drawerPaperShadow: {
      boxShadow: '0 0 18px rgba(68, 107, 176, 0.91)',
      border: '0 none',
    },
  };
};

export default styles;
