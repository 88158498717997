const style = theme => ({
  ...theme.classes,
  listingloader: {
    '@media (max-width:1240px)': {
      marginTop: '30%',
    },
  },
  tblwrapper: {
    padding: '8px 6px 6px',
    overflow: 'hidden',
    '@media (max-width:1240px)': {
      padding: '75px 0 0 0',
    },
  },
  tblwrapperstatic: {
    padding: '0 6px 6px',
    overflow: 'hidden',
    marginTop: -8,
    '&.csv': {
      overflow: 'visible',
      marginTop: 0,
    },
    '@media (max-width:1240px)': {
      padding: '42px 0 0 0',
      marginTop: -5,
      '&.csv': {
        marginTop: 0,
      },
    },
  },
  hasfilter: {
    padding: '0 6px',
    /* padding: '30px 6px 6px',
    '@media (max-width:1240px)': {
      padding: '75px 0 0 0',
    }, */
  },
  table: {
    position: 'relative',
    background: '#fff',
    width: '100%',
    borderCollapse: 'inherit',
    '& tr:hover .btns': {
      display: 'flex',
    },
  },
  tbody: {
    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.10)',
    position: 'relative',
    background: '#fff',
    width: '100%',
  },
  tbodylist: {
    '& td': {
      whiteSpace: 'initial',
    },
  },
  tbodyfilter: {
    '& tr': {
      height: 40,
    },
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  csvstatus: {
    width: '10%',
    textAlign: 'center',
  },
  status: {
    textTransform: 'capitalize',
    '&[data-status="new"]': {
      color: '#339B40',
    },
    '&[data-status="pending"]': {
      color: '#B557FB',
    },
    '&[data-status="shipped"]': {
      color: '#99CE43',
    },
    '&[data-status="cancelled"]': {
      color: '#949DB2',
    },
    '&[data-status="on hold"]': {
      color: '#E3C000',
    },
    '&[data-status="backordered"]': {
      color: '#FF6565',
    },
  },
  hold: {
    backgroundColor: '#E3C000',
    width: 68,
    height: 24,
    lineHeight: '24px',
    color: '#fff',
    fontSize: 12,
    fontWeight: 400,
    borderRadius: 100,
    textAlign: 'center',
    display: 'inline-block',
    marginLeft: 16,
    '&:hover': {
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    },
  },
  cancel: {
    backgroundColor: '#fff',
    width: 68,
    height: 24,
    color: '#09B0EE',
    fontSize: 12,
    fontWeight: 400,
    borderRadius: 100,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
    border: '1px solid #C5D1E0',
    '&:hover': {
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    },
  },
  create: {
    backgroundColor: '#fff',
    width: 95,
    fontWeight: 500,
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    lineHeight: '20px',
    '@media (max-width:1240px)': {
      width: 75,
    },
  },
  tg1: {
    padding: '4px 0 4px 29px',
    '@media (max-width:1350px)': {
      padding: '4px 0 4px 12px',
    },
  },
  tg2: {
    padding: '4px 20px',
  },
  tg3: {
    padding: '4px 0',
  },
  statusplus: {
    width: '100%',
    '& a': {
      color: '#09B0ee',
    },
    '& .verified': {
      fontSize: 12,
      fontWeight: 400,
      color: '#339B40 !important',
    },
    '& .error': {
      fontSize: 12,
      fontWeight: 400,
      color: '#FF6565 !important',
    },
    '@media (max-width:1240px)': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  edit: {
    color: '#09B0EE',
    width: 20,
    height: 20,
  },
  actionbtns: {
    backgroundColor: '#FAFAFB',
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: 160,
    display: 'none',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& .btn-container': {
      padding: '0 15px',
      display: 'flex',
      alignItems: 'center',
    },
  },
  cmdbtns: {
    width: 60,
    '& .btn-container': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& [aria-label="Delete"] svg': {
        width: 15,
        height: 15,
      },
    },
  },
  tglast: {
    padding: '0 !important',
    width: 0,
  },
  tglastb: {
    padding: '0 40px 0 0 !important',
  },
  tddesktop: {
    display: 'table',
    '@media (max-width:1240px)': {
      display: 'none',
    },
  },
  tdmobile: {
    display: 'none',
    '@media (max-width:1240px)': {
      display: 'table',
    },
  },
  tblrowMobile: {
    position: 'relative',
    height: 82,
    zIndex: 1,
  },
  tblrowMobile2: {
    height: 145,
  },
  tblrowMobile3: {
    height: 100,
  },
  tblrowMobile4: {
    position: 'static',
  },
  tdleft: {
    padding: '0 10px 0 24px',
    whiteSpace: 'inherit',
  },
  tdright: {
    padding: '0 20px 0 0',
  },
  txtOrderNo: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '19px',
    color: theme.palette.secondary.main,
    marginBottom: 6,
  },
  txtDescription: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '19px',
    color: theme.palette.secondary.main,
    marginBottom: 12,
  },
  txtRC: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    color: theme.palette.text.primary,
    marginBottom: 5,
    '& label': {
      marginRight: 10,
      color: theme.palette.primary.light,
      '@media (max-width:370px)': {
        marginRight: 2,
      },
    },
    '&[data-status="Received"], &[data-status="Awaiting Return"]': {
      position: 'absolute',
      top: 18,
      right: 20,
      color: '#B557FB',
      fontSize: 14,
      lineHeight: '19px',
      fontWeight: 500,
    },
    '&[data-status="Awaiting Return"]': {
      color: '#339B40',
    },
  },
  txtDate: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '19px',
    marginBottom: 6,
    color: theme.palette.primary.light,
  },
  txtStatus: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '16px',
  },
  filterMobile: {
    display: 'none',
    '@media (max-width:1240px)': {
      display: 'block',
      '& .filters': {
        position: 'relative',
        top: 0,
        bottom: 0,
      },
    },
  },
  hasfilterMobile: {
    '@media (max-width:1240px)': {
      marginTop: 40,
      '& .filters': {
        backgroundColor: theme.palette.common.white,
        padding: '16px 20px',
      },
    },
  },
  iddate: {
    display: 'flex',
    flexDirection: 'column',
    '& :nth-child(1)': {
      order: 2,
    },
    '& :nth-child(2)': {
      order: 1,
    },
    '& :nth-child(3)': {
      order: 4,
    },
    '& :nth-child(4)': {
      order: 5,
    },
    '& :nth-child(5)': {
      order: 6,
    },
    '& :nth-child(6)': {
      order: 3,
    },
  },
  allorders: {
    display: 'flex',
    flexDirection: 'column',
    '& :nth-child(1)': {
      order: 1,
    },
    '& :nth-child(2)': {
      order: 3,
    },
    '& :nth-child(3)': {
      order: 2,
    },
  },
});

export default style;
