const styles = theme => ({
  ...theme.classes,
  appBar: {
    position: 'relative',
    background: theme.palette.background.default,
    color: theme.palette.common.black,
    boxShadow: 'none',
    minHeight: 68,
    padding: '0 40px !important',
    '&.exportheader': {
      width: '100%',
      margin: '0 auto',
    },
    '@media (max-width:1240px)': {
      background: '#fff',
      padding: '0 28px !important',
      borderBottom: '1px solid #E8EAEE',
      '&.graybg': {
        background: theme.palette.background.default,
      },
    },
    '@media (max-width:345px)': {
      padding: '0 14px !important',
    },
  },
  appModBar: {
    '@media (max-width:1240px)': {
      backgroundColor: theme.palette.background.default,
      minHeight: '60px',
      borderBottom: 'none',
    },
  },
  modalToolbarHeight: {
    minHeight: 68,
    padding: 0,
  },
  modalToolbarHeightMod: {
    '@media (max-width:1240px)': {
      backgroundColor: theme.palette.background.default,
      minHeight: '60px',
    },
  },
  modalToolbarContainer: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    height: 68,
    display: 'flex',
    alignItems: 'center',
    '@media (max-width:1240px)': {
      backgroundColor: '#fff',
      '&.graybg': {
        background: theme.palette.background.default,
      },
    },
  },
  modalToolbarContainerMod: {
    '@media (max-width:1240px)': {
      backgroundColor: theme.palette.background.default,
      minHeight: '60px',
    },
  },
  flex: {
    flex: 1,
  },
  h6: {
    fontSize: 16,
    fontWeight: 400,
    '@media (max-width:1240px)': {
      fontWeight: 500,
      '& span': {
        display: 'none',
      },
    },
  },
  submitWrapper: {
    position: 'relative',
  },
  expbtns: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  modclose: {
    display: 'none',
    marginRight: -12,
    '@media (max-width:1240px)': {
      display: 'inline-block',
      margin: '0 -12px 0 10px',
    },
  },
  modalBtnMobile: {
    '@media (max-width:1240px)': {
      '& span.stext': {
        display: 'none',
      },
    },
  },
  stafficonbtn: {
    display: 'none',
    '@media (max-width:1240px)': {
      margin: '0 -10px 0 10px',
      display: 'inline-block',
    },
  },
  staffmodalBtn: {
    '@media (max-width:1240px)': {
      padding: '0 20px',
    },
  },
  settingsicon: {
    margin: '2px 12px 0 0',
    '& svg': {
      width: 20,
      height: 20,
    },
  },
  expmodalbtn: {
    padding: '0 26px',
    marginLeft: 12,
  },
});

export default styles;
