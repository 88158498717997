import { createSelector } from 'reselect';

export const getOrdersState = state => state.orders;
export const orderDetailSelector = state => state.orders.detail;
export const RADataSelector = state => state.orders.radata;
export const editDataSelector = state => state.orders.dataentry;
export const vendorOrderSelector = state => state.orders.vendorlist;
export const recipientSelector = state => state.orders.recipientdata;
export const carrierServiceSelector = state => state.orders.carrierdata;
export const orderTypesSelector = state => state.orders.ordertypesdata;
export const orderInfoSelector = state => state.orders.orderinfo;
export const returnedOrderSelector = state => state.orders.returnedlist;
export const filtersSelector = state => state.orders.filters;
export const recipientAutoCompleteSelector = state => state.orders.autocompleteRecipient;
export const importInfoSelector = state => state.orders.importinfo;
export const importOrdersSelector = state => state.orders.importorders;
export const sortColumnSelector = state => state.orders.sortColumn;
export const sortTypeSelector = state => state.orders.sortType;
export const ordersAutoCompleteSelector = state => state.orders.autocompleteOrders;
export const orderstatusesSelector = state => state.orders.orderstatuses;
export const ordersitemlistSelector = state => state.orders.ordersitemlist;
export const orderFileSelector = state => state.orders.orderfile;
export const csvOrderSelector = state => state.orders.csvorder;
export const importcsvOrderSelector = state => state.orders.importcsvorder;

export const getOrdersData = createSelector([getOrdersState], orders => orders.list);

export const getReturnedOrdersData = createSelector(
  returnedOrderSelector,
  returnedlist => returnedlist,
);

export const getOrderDetails = createSelector(
  orderDetailSelector,
  detail => detail,
);

export const getRAData = createSelector(
  RADataSelector,
  radata => radata,
);

export const getDataEntry = createSelector(
  editDataSelector,
  dataentry => dataentry,
);

export const getVendorOrderList = createSelector(
  vendorOrderSelector,
  vendorlist => vendorlist,
);

export const getRecipientData = createSelector(
  recipientSelector,
  recipientdata => recipientdata,
);

export const getCarrierServiceData = createSelector(
  carrierServiceSelector,
  carrierdata => carrierdata,
);

export const getOrderTypesData = createSelector(
  orderTypesSelector,
  ordertypesdata => ordertypesdata,
);

export const getOrderInfo = createSelector(
  orderInfoSelector,
  orderinfo => orderinfo,
);

export const getFilters = createSelector(
  filtersSelector,
  filters => filters,
);

export const getAutoCompleteRecipient = createSelector(
  recipientAutoCompleteSelector,
  autocompleteRecipient => autocompleteRecipient,
);

export const getimportInfo = createSelector(
  importInfoSelector,
  importinfo => importinfo,
);

export const getimportOrders = createSelector(
  importOrdersSelector,
  importorders => importorders,
);

export const getSortColumn = createSelector(
  sortColumnSelector,
  sortColumn => sortColumn,
);

export const getSortType = createSelector(
  sortTypeSelector,
  sortType => sortType,
);

export const getAutoCompleteOrders = createSelector(
  ordersAutoCompleteSelector,
  autocompleteOrders => autocompleteOrders,
);

export const getOrderStatusesData = createSelector(
  orderstatusesSelector,
  orderstatuses => orderstatuses,
);

export const getOrderItemData = createSelector(
  ordersitemlistSelector,
  ordersitemlist => ordersitemlist,
);

export const getOrderFile = createSelector(
  orderFileSelector,
  orderfile => orderfile,
);

export const getCSVOrder = createSelector(
  csvOrderSelector,
  csvorder => csvorder,
);

export const getImportCSVOrder = createSelector(
  importcsvOrderSelector,
  importcsvorder => importcsvorder,
);
