import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchZipCodeList, fetchCountryState, setAddressData } from 'state/address/actions';
import { getCountries, getZipCityState, getCounytryState } from 'state/address/selectors';
import { setLoadType } from 'state/loading/actions';
import { isItActionShowingLoad, getLoadType } from 'state/loading/selectors';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { update, populateFields, populateOptionFields, isFormValid, validateInputs } from 'ui/components/form/FormActions';
import { capitalized } from 'api/misc';
import FormField from 'ui/components/form/FormField';
import styles from './InputFormStyle';

class AddZipCityState extends Component {
  state = {
    formdata: {
      address: {
        element: 'textarea',
        value: '',
        config: {
          name: 'address',
          type: 'text',
          label: 'Address *',
        },
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        validationMessage: '',
      },
      country: {
        element: 'select',
        value: 'US',
        config: {
          label: 'Country',
          name: 'country',
          options: [],
        },
        validation: {
          required: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
        showlabel: true,
        addeditform: true,
      },
      zip: {
        element: 'input',
        value: '',
        config: {
          name: 'zip',
          type: 'text',
          label: 'Zip',
        },
        validation: {
          required: false,
        },
        valid: false,
        touched: false,
        validationMessage: '',
        addeditform: true,
        shorterrmsg: true,
      },
      state: {
        element: 'input',
        value: '',
        config: {
          label: 'State/Province',
          name: 'state',
          options: [],
          type: 'text',
        },
        validation: {
          required: false,
        },
        valid: false,
        touched: false,
        validationMessage: '',
        showlabel: true,
        addeditform: true,
        showEmpty: true,
        shorterrmsg: true,
      },
      city: {
        element: 'input',
        value: '',
        config: {
          name: 'city',
          type: 'text',
          label: 'City',
        },
        validation: {
          required: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
        addeditform: true,
      },
    },
  }

  componentDidMount() {
    const {
      onRef, countries, loginData, formfor, edit, editdata,
    } = this.props;
    if (countries !== null) {
      if (countries.length) {
        const countrylist = [];
        for (const key in countries) { // eslint-disable-line
          countrylist.push({ _id: countries[key].attributes.country_code, name: countries[key].attributes.country_name }); // eslint-disable-line
        }
        const newFormData = populateOptionFields(this.state.formdata, countrylist, 'country');
        this.updateFields(newFormData);
      }
    }
    if (typeof loginData !== 'undefined') {
      if (loginData.customer.billing_country === 'US') {
        const { getState, setloadtype } = this.props;
        setloadtype('state');
        getState();
      }
      const data = {
        address: loginData.customer.billing_address1,
        country: loginData.customer.billing_country,
        zip: loginData.customer.billing_zip,
        state: loginData.customer.billing_state,
        city: loginData.customer.billing_city,
      };
      setTimeout(() => {
        populateFields(this.state.formdata, data);
      }, 100);
    }
    if (typeof editdata !== 'undefined' && Object.keys(editdata).length) {
      const newFormdata = { ...this.state.formdata };
      let data = {};
      let country = '';
      if (edit === 'recipient') {
        data = {
          address: editdata.shipping_address1,
          country: editdata.shipping_country_code,
          zip: editdata.shipping_zip,
          state: editdata.shipping_state,
          city: editdata.shipping_city,
        };
        country = editdata.shipping_country_code;
      }
      if (edit === 'vendor') {
        data = {
          address: editdata.address1,
          country: editdata.country_code,
          zip: editdata.zip,
          state: editdata.state,
          city: editdata.city,
        };
        country = editdata.country_code;
      }

      setTimeout(() => {
        if (country !== 'US') {
          newFormdata.state.element = 'input';
        }
        populateFields(newFormdata, data);
      }, 5);
    }

    const formData = { ...this.state.formdata };
    if (formData.country.value === 'US') {
      formData.state.element = 'select';
      const { getState, setloadtype } = this.props;
      setloadtype('state');
      getState();
    }
    if (typeof formfor !== 'undefined' && formfor === 'add purchase order') {
      formData.address.config.label = 'Address *';
      formData.address.validation.required = true;
      formData.address.valid = false;
      formData.address.touched = false;
    }
    this.updateFields(formData);

    onRef(this);
  }

  componentDidUpdate() {
    const {
      actionloading, setloadtype, getloadtype, countrystate, zipcitystate,
    } = this.props;
    if (!actionloading && getloadtype === 'state') {
      setloadtype('');
      if (countrystate.length) {
        const statelist = [];
        for (const key in countrystate) { // eslint-disable-line
          statelist.push({ _id: countrystate[key].attributes.code, name: countrystate[key].attributes.name }); // eslint-disable-line
        }
        const newFormData = populateOptionFields(this.state.formdata, statelist, 'state');
        this.updateFields(newFormData);
      }
    }
    if (!actionloading && getloadtype === 'statecity') {
      setloadtype('');
      if (zipcitystate.length) {
        const newFormdata = {
          ...this.state.formdata,
        };
        newFormdata.state.value = zipcitystate[0].attributes.State;
        newFormdata.state.valid = true;
        if (zipcitystate.length === 1) {
          newFormdata.city.value = capitalized(zipcitystate[0].attributes.City);
          newFormdata.city.valid = true;
        }
        setTimeout(() => {
          this.setState({
            formdata: newFormdata,
          });
        }, 1);
      }
    }
    /* console.log(this.state); // eslint-disable-line */
  }

  updateFields = (newFormdata) => {
    this.setState({
      formdata: newFormdata,
    });
  }

  updateForm = (element) => {
    const newFormdata = update(element, this.state.formdata, 'address');
    if (this.state.formdata.country.value === 'US') {
      if (element.id === 'zip') {
        const { getStateCity, setloadtype } = this.props;
        setloadtype('statecity');
        getStateCity(10, parseInt(newFormdata.zip.value, 10));
      }
    }

    if (element.id === 'country') {
      if (newFormdata.country.value === 'US') {
        newFormdata.state.element = 'select';
        const { getState, setloadtype } = this.props;
        setloadtype('state');
        getState();
      } else {
        newFormdata.state.element = 'input';
      }
    }
    this.setState({
      formdata: newFormdata,
    });
  }

  submitAddress = () => {
    const { setAddress, setloadtype } = this.props;
    const newFormdata = { ...this.state.formdata };
    switch (newFormdata.country.value) {
      case 'US':
      case 'MX':
      case 'CA':
        newFormdata.state.validation.required = true;
        if (newFormdata.state.value.trim() === '') {
          newFormdata.state.valid = false;
          newFormdata.state.touched = false;
        }
        newFormdata.zip.validation.required = true;
        if (newFormdata.zip.value.trim() === '') {
          newFormdata.zip.valid = false;
          newFormdata.zip.touched = false;
        }
        break;
      default:
        newFormdata.state.validation.required = false;
        newFormdata.state.valid = true;
        newFormdata.state.touched = true;
        newFormdata.zip.validation.required = false;
        newFormdata.zip.valid = true;
        newFormdata.zip.touched = true;
        break;
    }
    const formIsValid = isFormValid(newFormdata);
    let data = {};
    if (formIsValid) {
      data = { isvalid: true, formdata: newFormdata };
    } else {
      const newData = validateInputs(newFormdata);
      data = { isvalid: false, formdata: newData };
    }
    setAddress(data);
    setloadtype('address data');
  }

  render() {
    const { classes } = this.props;
    /* console.log(this.props); // eslint-disable-line */
    return (
      <Fragment>
        <FormField
          id="address"
          formdata={this.state.formdata.address}
          change={element => this.updateForm(element)}
        />
        <FormField
          id="country"
          formdata={this.state.formdata.country}
          change={element => this.updateForm(element)}
        />
        <Grid className={classes.gridContainer} container spacing={0}>
          <Grid className={`${classes.col1a} ${classes.statestyle2}`} item xs={3}>
            <FormField
              id="zip"
              formdata={this.state.formdata.zip}
              change={element => this.updateForm(element)}
            />
          </Grid>
          <Grid className={`${classes.col2a} ${classes.statestyle}`} item xs={5}>
            <FormField
              id="state"
              formdata={this.state.formdata.state}
              change={element => this.updateForm(element)}
            />
          </Grid>
          <Grid className={`${classes.col3a} ${classes.statestyle2}`} item xs={4}>
            <FormField
              id="city"
              formdata={this.state.formdata.city}
              change={element => this.updateForm(element)}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

AddZipCityState.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  countries: PropTypes.instanceOf(Array).isRequired,
  zipcitystate: PropTypes.instanceOf(Object).isRequired,
  countrystate: PropTypes.array, // eslint-disable-line
  getStateCity: PropTypes.func.isRequired,
  getState: PropTypes.func.isRequired,
  setloadtype: PropTypes.func.isRequired,
  getloadtype: PropTypes.string.isRequired,
  actionloading: PropTypes.bool.isRequired,
  loginData: PropTypes.object, // eslint-disable-line
  onRef: PropTypes.func.isRequired,
  setAddress: PropTypes.func.isRequired,
  formfor: PropTypes.string, // eslint-disable-line
  edit: PropTypes.string, // eslint-disable-line
  editdata: PropTypes.object, // eslint-disable-line
};

export const mapStateToProps = state => ({
  countries: getCountries(state),
  zipcitystate: getZipCityState(state),
  countrystate: getCounytryState(state),
  actionloading: isItActionShowingLoad(state),
  getloadtype: getLoadType(state),
});

export const mapDispatchToProps = dispatch => ({
  getStateCity: (limit, zip) => dispatch(fetchZipCodeList(limit, zip)),
  getState: () => dispatch(fetchCountryState()),
  setAddress: data => dispatch(setAddressData(data)),
  setloadtype: type => dispatch(setLoadType(type)),
});

// eslint-disable-next-line
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddZipCityState));
