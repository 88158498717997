const styles = theme => ({
  ...theme.classes,
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 32px',
    height: 76,
  },
  headContainer: {
    minHeight: 30,
    alignItems: 'center',
  },
  h1: {
    fontSize: 16,
    lineHeight: '26px',
    fontWeight: 500,
    display: 'inline-block',
    verticalAlign: 'middle',
    color: theme.palette.primary.light,
    '& span': {
      color: theme.palette.primary.main,
    },
  },
  gridStyle: {
    padding: 0,
  },
  gridright: {
    textAlign: 'right',
    lineHeight: '30px',
  },
  icon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    color: theme.palette.secondary.main,
    marginRight: 8,
    cursor: 'pointer',
  },
  '@media (max-width:1240px)': {
    drawerHeader: {
      padding: '0 22px',
      height: 90,
      borderBottom: '1px solid #E8EAEE',
    },
    h1: {
      fontSize: 16,
      lineHeight: '26px',
      display: 'block',
      color: '#000',
      '& span': {
        display: 'block',
      },
    },
  },
  w100: {
    '@media (max-width:1240px)': {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  wauto: {
    '@media (max-width:1240px)': {
      position: 'absolute',
      top: 8,
      right: 6,
      maxWidth: 'none',
      flexBasis: 'auto',
    },
  },
});

export default styles;
