const styles = theme => ({
  ulistcontainer: {
    padding: 0,
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '100%',
    '& li': {
      display: 'block',
      padding: '22px 35px 2px',
      borderTop: '1px solid #E8EAEE',
      '@media (max-width:1240px)': {
        padding: '20px 20px 20px',
        border: '0 none',
        '& .datestart': {
          padding: '0 5px 0 0',
        },
        '& .dateend': {
          padding: '0 0 0 5px',
        },
        '& > div, & > div > div': {
          position: 'relative',
          margin: 0,
        },
        '&.datelist div': {
          width: '100%',
          position: 'relative',
          margin: 0,
        },
        '& .dateIcon': {
          top: -1,
          right: -38,
          zIndex: 2,
        },
      },
      '@media (max-width:370px)': {
        '& .dateIcon': {
          right: -26,
        },
      },
    },
    '@media (max-width:1240px)': {
      padding: '0 0 20px 0',
    },
  },
  h6: {
    color: theme.palette.text.primary,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '14px',
    marginBottom: 20,
  },
});

export default styles;
