const styles = theme => ({
  ...theme.classes,
  mainwrapper: {
    marginTop: -42,
    paddingTop: 6,
    '@media (max-width:1240px)': {
      marginTop: -46,
      paddingTop: 0,
    },
  },
  mobileselect: {
    display: 'none',
    '@media (max-width:1240px)': {
      padding: '20px 24px 20px',
      display: 'block',
      '& button': {
        color: theme.palette.secondary.main,
      },
    },
  },
  container: {
    background: '#fff',
    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.10)',
    position: 'relative',
    width: '100%',
    padding: '30px 40px',
    borderRadius: 8,
    minHeight: '85vh',
    '@media (max-width:1240px)': {
      borderRadius: 0,
      padding: '38px 24px',
      minHeight: '82vh',
    },
  },
  customcontainer: {
    padding: 0,
    minHeight: '85vh',
    marginBottom: 20,
    '@media (max-width:1240px)': {
      '&.faqcontainer': {
        minHeight: '100vh',
        marginBottom: 0,
      },
    },
  },
  headercontainer: {
    marginBottom: 36,
    '@media (max-width:1240px)': {
      marginBottom: 0,
    },
  },
  h1: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '30px',
    color: theme.palette.primary.main,
  },
  btnwrapper: {
    textAlign: 'right',
    '& .btnbox': {
      display: 'inline-block',
      position: 'relative',
    },
    '@media (max-width:1240px)': {
      position: 'absolute',
      top: -46,
      right: 19,
    },
  },
  modalBtnMobile: {
    '@media (max-width:1240px)': {
      width: 118,
      height: 30,
      minHeight: '30px',
    },
  },
  submitWrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  desktop: {
    '@media (max-width:1240px)': {
      display: 'none',
    },
  },
  mobile: {
    display: 'none',
    '@media (max-width:1240px)': {
      display: 'inline-block',
    },
  },
  w100: {
    '@media (max-width:1240px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      '&.panel': {
        backgroundColor: '#fff',
        paddingBottom: 48,
      },
    },
  },
});

export default styles;
