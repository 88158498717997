import { createSelector } from 'reselect';

export const getSupportState = state => state.support;

export const getSupportData = createSelector([getSupportState], support => support.list);

export const getChatDetail = createSelector([getSupportState], support => support.detail);

export const getChatMsg = createSelector([getSupportState], support => support.msg);

export const getChatMsgList = createSelector([getSupportState], support => support.msglist);
