import { chatList, newChat, chatDetail, newChatMsg, chatMsgList, markResolved } from 'api/support';
import { showActionLoading, hideActionLoading, showLoading, hideLoading } from 'state/loading/actions';
import responseCheker from 'api/responseCheker';
import { openAlertModal } from 'state/modal-drawer/actions';

import {
  SET_SUPPORT_DATA,
  SET_MORE_SUPPORT_DATA,
  RESET_SUPPORT_DATA,
  SET_CHAT_DETAIL_DATA,
  SET_CHAT_MSG_DATA,
  SET_CHAT_MSG_LIST_DATA,
  SET_MORE_CHAT_MSG_LIST_DATA,
} from './types';

export const setSupport = support => ({
  type: SET_SUPPORT_DATA,
  payload: { support },
});

export const setMoreSupport = support => ({
  type: SET_MORE_SUPPORT_DATA,
  payload: { support },
});

export const resetChatList = () => ({
  type: RESET_SUPPORT_DATA,
});

export const setChatDetail = detail => ({
  type: SET_CHAT_DETAIL_DATA,
  payload: { detail },
});

export const setMsg = msg => ({
  type: SET_CHAT_MSG_DATA,
  payload: { msg },
});

export const setMsgList = msglist => ({
  type: SET_CHAT_MSG_LIST_DATA,
  payload: { msglist },
});

export const setMoreMsgList = msglist => ({
  type: SET_MORE_CHAT_MSG_LIST_DATA,
  payload: { msglist },
});

export const successResponse = (response, type) => (dispatch) => {
  dispatch(responseCheker(response));
  let cond = type;
  if (response) {
    if (cond === 'list') {
      dispatch(setSupport(response.data));
    }
    if (cond === 'morelist') {
      dispatch(setMoreSupport(response.data));
    }
    if (cond === 'detail') {
      dispatch(setChatDetail(response));
    }
    if (cond === 'newchatmsg') {
      dispatch(setMsg(response.data));
    }
    if (cond === 'chatmsglist') {
      dispatch(setMsgList(response.data));
    }
    if (cond === 'morechatmsglist') {
      dispatch(setMoreMsgList(response.data));
    }
  }

  if (cond === 'morelist'
    || cond === 'chatmsglist'
    || cond === 'morechatmsglist'
    || cond === 'newchatmsg'
    || cond === 'detail') {
    cond = false;
  }

  if (cond) {
    dispatch(hideLoading());
  }
  dispatch(hideActionLoading());
};

export const errorResponse = (err, type = true) => (dispatch) => {
  const result = dispatch(responseCheker(err.response));
  if (result === 'error') {
    const modalKey = 'alert';
    const params = {
      type: 'error',
      title: '',
      description: err.response.data.errors[0].detail,
    };
    dispatch(openAlertModal({ modalKey, params }));
  }
  if (result === 'TypeError') {
    const modalKey = 'alert';
    const params = {
      type: 'error',
      title: '',
      description: 'Something went wrong. This has been reported',
    };
    dispatch(openAlertModal({ modalKey, params }));
  }
  if (type) {
    dispatch(hideLoading());
  }
  dispatch(hideActionLoading());
};

export const fetchChatList = (id = 1, limit = 10, page = 1) => (dispatch) => {
  dispatch(showLoading());
  chatList(id, limit, page).then(
    response => dispatch(successResponse(response, 'list')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchMoreChatList = (id = 1, limit = 10, page = 1) => (dispatch) => {
  dispatch(showActionLoading());
  chatList(id, limit, page).then(
    response => dispatch(successResponse(response, 'morelist')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchChatDetail = (id = 1) => (dispatch) => {
  dispatch(showActionLoading());
  chatDetail(id).then(
    response => dispatch(successResponse(response, 'detail')),
    err => dispatch(errorResponse(err)),
  );
};

export const addChat = data => (dispatch) => {
  dispatch(showActionLoading());
  newChat(data).then(
    response => dispatch(successResponse(response, false)),
    err => dispatch(errorResponse(err)),
  );
};

export const addChatMsg = (id, msgdata) => (dispatch) => {
  dispatch(showActionLoading());
  newChatMsg(id, msgdata).then(
    response => dispatch(successResponse(response, 'newchatmsg')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchChatMsgList = (id = 1, limit = 10, page = 1) => (dispatch) => {
  dispatch(showActionLoading());
  chatMsgList(id, limit, page).then(
    response => dispatch(successResponse(response, 'chatmsglist')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchMoreChatMsgList = (id = 1, limit = 10, page = 1) => (dispatch) => {
  dispatch(showActionLoading());
  chatMsgList(id, limit, page).then(
    response => dispatch(successResponse(response, 'morechatmsglist')),
    err => dispatch(errorResponse(err)),
  );
};

export const resolved = (id, status) => (dispatch) => {
  dispatch(showActionLoading());
  markResolved(id, status).then(
    response => dispatch(successResponse(response, false)),
    err => dispatch(errorResponse(err, false)),
  );
};
