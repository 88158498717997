import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { ReactComponent as Dropdown } from 'resources/icons/arrowdownpointer.svg';
import styles from 'ui/components/common/TableHeadStyle';

class ListingHead extends Component {
  createSortHandler = property => (event) => {
    if (property === 'submitted_by') {
      return false;
    }
    this.props.onRequestSort(event, property);
    return true;
  };

  render() {
    const {
      classes, order, orderBy, menus,
    } = this.props;
    return (
      <TableHead className={classes.theadstatic}>
        <TableRow className={classes.thtrstatic}>
          {menus.map(
            row => (
              <Fragment key={row.name}>
                { row.checked ?
                  <TableCell
                    className={classes.tg1}
                    key={row.name}
                    align="left"
                    sortDirection={orderBy === row.name ? order : false}
                  >
                    { row.sorting ?
                      <TableSortLabel
                        className={`${row.name} ${classes.tblsortlbl}`}
                        active={orderBy === row.name}
                        direction={order}
                        onClick={this.createSortHandler(row.name)}
                        IconComponent={Dropdown}
                      >
                        {row.label}
                      </TableSortLabel>
                    :
                      <TableSortLabel
                        className={`${row.name} ${classes.tblsortlbldisabled}`}
                        active={orderBy === row.name}
                        direction={order}
                        IconComponent={Dropdown}
                      >
                        {row.label}
                      </TableSortLabel>
                    }
                  </TableCell>
                : null }
              </Fragment>
            ),
            this,
          )}
          <TableCell className={classes.tglast} />
        </TableRow>
      </TableHead>
    );
  }
}

ListingHead.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  menus: PropTypes.instanceOf(Object).isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default withStyles(styles)(ListingHead);
